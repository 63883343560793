<template>
	<div class="ar-main">
		<el-card >
			<div class="textLeft  marT22">
				<div class="flex flexStart">
					<div class="w140 setDateInput ">
						<p class="marB9 title-type">起始日期</p>
						<el-date-picker
						    v-model="formData.startDate"
							value-format="yyyy-MM-dd"
						    type="date"
							@change="getFormatDate"
						    placeholder="选择开始日期">  
						</el-date-picker>
					</div>
					<div  class="w140 setDateInput">
						<p  class="marB9 title-type">结束日期</p>
						<el-date-picker
						    v-model="formData.endDate"
							value-format="yyyy-MM-dd"
						    type="date"
							@change="getNowFormatDate"
						    placeholder="选择结束日期">  
						</el-date-picker>
					</div>
					<div  class="w140 setSelect">
						<p  class="marB9 title-type">单据类型</p>
						<el-select v-model="formData.orderType " class="w130 widthSet2" filterable :filter-method="sreachBillType" placeholder="请选择单据类型" @change="getSelectType">
							<el-option
								v-for="item in billTypeList"
								:key="item.id"
								:label="item.cName"
								:value="item.id">
							</el-option>
						</el-select>
					</div>
					<div  class="marR20 setInput setInput195 ">
						<p  class="marB9 title-type">业务单号</p>
						<el-input v-model="formData.orderNo" placeholder="请输入业务单号" @change="getOrderNo"></el-input>
					</div>
				</div>
				<div class="flex flexStart marT15">
					<div  class="w140 marR20 setInput">
						<p  class="marB9 title-type">送审人</p>
						<el-input v-model="formData.sentEmpNo" placeholder="请选择送审人"  @change="getSentEmpNo"></el-input>
					</div>
					<div  class="w140 marR20 setInput">
						<p  class="marB9 title-type">审批人</p>
						<el-input v-model="formData.approveEmpName" placeholder="请选择审批人"  @change="getApproveEmpName"></el-input>
					</div>
					<div class="flex flexEnd">
						<el-button type="primary" class="button1" @click="queryTypeList">查 询</el-button>
						<el-button  @click="clear" class="button1">清 除</el-button>
					</div>
				</div>
			</div>
		</el-card>
		<el-card  class="marT20">
			<div class="overflowX textLeft" v-if="approvalList && approvalList.length>0" 
					v-loading = "loading"
					element-loading-background = "rgba(255, 255, 255, .8)"
					element-loading-text = "加载中，请稍后..."
					element-loading-spinner = "el-icon-loading">
				<div class="borderB-ed color80 padTB14 flex nowrap" style="width:1900px;">
					<span style="width:30px;"></span>
					<span class="w150 padL10">单据类型</span>								
					<span class="w150 padL10">订单号</span>
					<span class="w180 padL10">订单描述</span>
					<span class="w120 padL10">送审人</span>
					<span class="w120 padL10">送审人部门</span>
					<span class="w150 padL10">送审时间</span>
					<span class="w100 padL10">流程状态</span>
					<span class="w100 padL10">审批最终结果</span>
					<span class="w100 padL10">审批级别</span>
					<span class="w100 padL10">审批人</span>
					<span class="w150 padL10">审批时间</span>				
					<span class="w100 padL10">审批结果</span>
					<span class="w100 padL10">审批意见</span>
					<span class="w100 padL10">审批方式</span>
					<span class="w150 padL10">审批规则名称</span>
				</div>
				<div style="height: 100%;" class="bghover">
					<div class="float padTB14 flex nowrap borderB-ed " v-for="(item,index) in approvalList" :key="index" >
						<span style="width:30px;">{{index+1}}</span>
						<span class="w150 padL10 setHidden">{{item.orderTypeName}}</span>
						<span class="w150 padL10 setHidden line-h22 pointer colorzs" @click="goToDetail(item)">{{item.orderNo}}</span>
						<el-popover
						    placement="bottom"
						    width="400"
						    trigger="hover"
						    :content="item.ddms">
						   <span class="w180 padL10 setHidden padR10 pointer "  slot="reference">{{item.ddms}}</span>
						</el-popover>	
						
						<span class="w120 padL10 setHidden">{{item.sentEmpName}}</span>
						<span class="w120 padL10 setHidden">{{item.sentDeptName}}</span>
						<span class="w150 padL10 setHidden">{{item.sentTime}}</span>
						<span class="w100 padL10">{{item.flowState==1?'运行中':'已结束'}}</span>
						<span class="w100 padL10">
							<span v-if="item.approveState==1">审批中</span>
							<span v-if="item.approveState==2">通过</span>
							<span v-if="item.approveState==3">未通过</span>
							<span v-if="item.approveState==4">取消</span>
						</span>
						<span class="w100 padL10">{{item.approveLevel}}</span>
						<span class="w100 padL10 setHidden">{{item.approveEmpName}}</span>
						<span class="w150 padL10 setHidden">{{item.completeTime}}</span>
						<span class="w100 padL10">
							<span v-if="item.completeState==1">通过</span>
							<span v-if="item.completeState==0">未通过</span>
						</span>  
						 
						<span class="w100 padL10 setHidden">{{item.appRemark}}</span>
						<span class="w100 padL10">
							<span v-if="item.completeWay==1">PC2</span>
							<span v-if="item.completeWay==2">手机app</span>
							<span v-if="item.completeWay==3">邮件</span>
							<span v-if="item.completeWay==4">短信</span>
						</span>
						<span class="w150 padL10 setHidden pointer colorzs" @click="goToAppProgress(item)">{{item.gzmc}}</span>
					</div>
				</div>
			</div>	
			<div v-if="approvalList.length==0 && !loading">
				<el-empty description="未查到符合的审批记录"></el-empty>
			</div>
			<div class="block marT20 textLeft fPage" v-if="approvalList && approvalList.length>0" >
			    <el-pagination
					background
					v-if="pageInfo.total"
			        @current-change="currentChange"
			        :current-page="pageInfo.currentPage"
			        :page-sizes="[10, 20, 30, 40]"
			        :page-size="pageInfo.pageSize"
			        layout="total, prev, pager, next"
			        :total="pageInfo.total">
			    </el-pagination>
			</div>
		</el-card>
		<el-dialog 
			title="审批进度"
			:visible.sync="visible"
			class="ap-main"
			>
			<el-card >
				<div class="textLeft font16 marB30">
					审批进度{{'('+approvalInfo.spjd+')'}}
				</div>
				<div :style="{height: (leng*80)+'px',}" >
					<el-steps  direction="vertical" :active="leng" class="sp-item">
						<el-step title="提交审批" :description="approvalInfo.sentTime" status="success"></el-step>
						<el-step  	v-for="(item, index) in approvalInfo.spjdInfoList" :key="index"
									:title="item.flowState==0?'未开始':item.flowState==1?'已通过':item.flowState==2?'已拒绝':'审批中'"
									:description="item.flowState==1 || item.flowState==2?item.completeTime+' 审批人：'+item.approveEmpName+' 审批意见：'+item.appRemark:'审批人:'+item.approveEmpName"
									:status="item.flowState==1?'success':item.flowState==2?'error':item.flowState==0?'success':'finish'"
									>
						</el-step>
					</el-steps>
				</div>
			</el-card>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'ApprovalRecord',
		data() {
			return {
				formData: {
					approveEmpName: "", //审批人
					count: 10,
					endDate: "",
					orderNo: "",   //单号
					orderType: "",  //单据类型
					pageNum: 1,
					sentEmpNo: "", //送审人
					startDate: "",
				},
				
				billTypeList:[], //单据类型类表
				newBillTypeList:[], // 备份单据类型列表 用于搜索
				date:[],
				
				
				approvalList:[], //审批记录列表
				pageInfo:{ //分页信息
					currentPage:1,
					pageSize:10,
					total:0,
				},
				
				approvalInfo: {}, // 审批对象
				leng: 0,
				
				send:{
					sendImg:'', //送审图片
					name:'', //送审名字
				},
				appProvalList:[],
				appProvaInfo:{
					sendImg:'', //送审图片
					name:'', //送审名字
				},
				visible:false,
				
				loading: false,
			
			}
		},
		mounted(){
			// 获取单据类型列表
			this.getBClass();
			this.getFormatDate();
			this.getNowFormatDate();
		},
		methods: {
			// 设置默认日期
			getFormatDate(e){
				if(e){
					this.formData.startDate = e;
					if(this.formData.startDate && this.formData.endDate){
						if(this.formData.startDate>this.formData.endDate){
							this.formData.endDate = this.formData.startDate;
						}
					}
				}else{
					// 获取时间
					var nowDate = new Date();
					// 获取当前时间
					var date = new Date(nowDate);
					// 获取一周时间
					date.setDate(date.getDate() - 7);
					var seperator1 = '-'
					var year = date.getFullYear()
					var month = date.getMonth() + 1
					var strDate = date.getDate()
					if (month >= 1 && month <= 9) {
					    month = '0' + month
					}
					if (strDate >= 0 && strDate <= 9) {
					    strDate = '0' + strDate
					}
					// 一周前的日期
					var formatDate = year + seperator1 + month + seperator1 + strDate
					this.formData.startDate = formatDate;
				}    	
			},
			// 今天日期
			getNowFormatDate(e){
				if(e){
					this.formData.endDate = e;
					if(this.formData.startDate && this.formData.endDate){
						if(this.formData.startDate>this.formData.endDate){
							this.formData.endDate = this.formData.startDate;
						}
					}
				}else{
					var date = new Date()
					var seperator1 = '-'
					var year = date.getFullYear()
					var month = date.getMonth() + 1
					var strDate = date.getDate()
					if (month >= 1 && month <= 9) {
						month = '0' + month
					}
					if (strDate >= 0 && strDate <= 9) {
						strDate = '0' + strDate
					}
					var nowData = year + seperator1 + month + seperator1 + strDate   
					this.formData.endDate = nowData;
				}
			},
			// 获取选择的单据类型
			getSelectType(e){
				this.formData.pageNum = 1;
				this.queryTypeList();
			},
			// 输入单号
			getOrderNo(){
				this.resetPageNum();
			},
			// 输入送审人
			getSentEmpNo(){
				this.resetPageNum();
			},
			// 输入审批人
			getApproveEmpName(){
				this.resetPageNum();
			},
			// 重置分页
			resetPageNum(){
				this.formData.pageNum = 1;
			},
			// 获取单据类型列表
			getBClass(){
				let obj = {
					parNo: 110
				}
				this.$conn.getConn('commu.getBClass',{jsfile:false})(obj).then((res)=>{
					let data = res.data || {};
					let dataList = [{
									by1: "",
									cName: "全部类型",
									id: "",
									parNo: "110",
									sn: 1,
								}	
							];
					let list = dataList.concat(data.list) ;
					this.billTypeList = list || [];
					this.newBillTypeList = list || [];
					this.formData.orderType = this.billTypeList[0].id;
					this.queryTypeList()
				}).catch((err)=>{
					this.message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 查询订单类型列表
			queryTypeList(){
				this.loading = true;
				let queryParams = {
						approveEmpName:this.formData.approveEmpName || "", //审批人
						count: this.formData.count || 10,
						endDate: this.formData.endDate || "",
						orderNo: this.formData.orderNo || "",   //单号
						orderType: this.formData.orderType || "",  //单据类型
						pageNum: this.formData.pageNum || 1,
						sentEmpNo: this.formData.sentEmpNo || '', //送审人
						startDate: this.formData.startDate || '',
				}
				
				this.$conn.getConn('commu.vipBpmList',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					this.approvalList = data.list || [];
					this.pageInfo.total = data.total;
					this.pageInfo.pageSize = data.count;
					this.pageInfo.currentPage = data.pageNum;
					this.loading = false;
				}).catch((err)=>{
					this.loading = false;
					this.message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 搜索单据类型
			sreachBillType(e){
				if(e){
					this.billTypeList = [];
					this.newBillTypeList.forEach((item)=>{
						if(item.cName.indexOf(e)!=-1 ){
							this.billTypeList.push(item)
						}
					})
				}else{
					this.billTypeList = this.newBillTypeList;
					this.formData.orderType = this.billTypeList[0].id;
				}
				
			},
			// 去订单详情
			goToDetail(item){
				let query = {};
				query = {
					orderId: item.orderId,
					orderType: item.orderType
				}
				
				let url = this.$dict.detailConfig(item.orderType)
				if(url){
					let routeUrl = this.$router.resolve({path: url,query:query});
					window.open(routeUrl.href,'_blank')
				}else{
					this.$message({
						type:'error',
						message:'请配置路由'
					})
				}
				
			},
			// 审批进度弹窗页面
			goToAppProgress(item){
				this.visible = true;
				let queryParams = {};
				queryParams = {
					orderId:item.orderId,
					orderType: item.orderType
				};
				this.$conn.getConn('commu.appProgress',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					this.approvalInfo = data;
					
					this.approvalInfo.spjdInfoList.map((item,index)=>{
						if(item.approveEmpName){
							let sentEmpName = [];
							let name = '';
							sentEmpName = item.approveEmpName.split(">");
							name = sentEmpName[1];
							item.approveEmpName = name;
						}
						this.spStatus = item.flowState;
						return item
					})
					this.leng = this.approvalInfo.spjdInfoList.length+1;
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 选中当前页数
			currentChange(e){
				this.formData.pageNum = e;
				this.queryTypeList();
			},
			// 清空选项
			clear(){
				this.date = [];
				this.formData= {
					approveEmpName: "", //审批人
					count: 10,
					endDate: "",
					orderNo: "",   //单号
					orderType: "",  //单据类型
					pageNum: 1,
					sentEmpNo: "", //送审人
					startDate: "",
				}
				this.getFormatDate();
				this.getNowFormatDate();
			},
		
		}
	}
</script>

<style lang="scss" scoped>
	.ar-main{
		font-size: 12px;
		font-family: '微软雅黑';
		font-weight: 400;
		.el-card.is-always-shadow{
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
			border-radius: 5px;	
		}
		.bghover :hover{
			background: #F5F7FA;
		}
		.ap-main{
			margin: 0 auto;
			
			.setButton{
				width:100px;
				height: 39px;
				margin-top: 30px;
				font-size: 14px;
			}
			.marTop10{
				margin-top: 10px;
			}
			.w-auto{
				width: auto;
			}
			.marTop40{
				margin-top: 40px;
			}
			.img{
				width: 28px;
				height: 28px;
			}
			.marB115{
				margin-bottom: 115px;
			}
			.textLeft{
				text-align: left;
			}
			.flex{
				display: flex;
			}
			.h28{
				height: 28px;
			}
			.line-h28{
				line-height: 28px;
			}
			
			.marLR15{
				margin:0 15px;
			}
			.start{
				justify-content: flex-start;
			}
			.marB30{
				margin-bottom: 30px;
			}
			.font16{
				font-size: 16px;
			}
			.line-h28{
				line-height: 28px;
			}
			.bfb-w5{
				width: 5%;
			}
			.bfb-w90{
				width: 90%;
			}
			.h200{
				height: 200px;
			}
			.mh200{
				min-height: 200px;
			}
			.lineB{
				display: inline-block;
			}
		}
		.marT20{
			margin-top: 20px;
		}
		.flex{
			display: flex;
		}
		.wrap{
			flex-wrap: wrap;
		}
		.nowrap{
			flex-wrap: nowrap;
		}
		.flexEnd{
			align-items: flex-end;
		}
		/deep/ .el-input__inner{
			height: 40px;
			line-height: 40px;
			border: none;
			background-color: #F5F5F5;
			border-radius: 2px;
			font-size: 14px;
		} 
		.title-type{
			font-size: 12px;
			font-weight: 400;
			color: #B3B3B3;
			line-height: 12px;
		}
		.marT15{
			margin-top: 15px;
		}
		.marB9{
			margin-bottom: 9px;
		}
		.setInput{
			.el-input{
				width: 140px;
				margin-right: 20px;
			}
			/deep/ .el-input__inner{
				width: 140px;
			}
		}
		.setInput195{
			.el-input{
				width: 195px;
				margin-right: 20px;
			}
			/deep/ .el-input__inner{
				width: 195px;
			}
		}
		.setSelect{
			width: 150px;
			margin-right: 20px;
			/deep/ .el-input__inner{
				width: 150px;
			}
		}
		.setDateInput{
			.el-input{
				width: 140px;
				margin-right: 20px;
			}
			/deep/ .el-input__inner{
				width: 140px;
			}
			/deep/ input::-webkit-input-placeholder {
				color: #B3B3B3;
				font-size: 14px;
			  }
			/deep/ input::-moz-input-placeholder {
				color: #B3B3B3;
				font-size: 14px;
			  }
			/deep/ input::-ms-input-placeholder {
				color: #B3B3B3;
				font-size: 14px;
			  }
		}

		.textLeft{
			text-align: left;
		}
		.fPage {
			/deep/ .el-input{
				width: 80px;
			}
			/deep/ .el-input__inner{
					width: 80px;
			}
		}
		.button1{
			min-width: 70px;
			height: 40px;
			font-size: 14px;
		}
		.color80{
			color: #808080;
		}
		.colorzs{
			color: #66b1ff;
		}
		.padTB14{
			padding: 14px 0;
		}
		.bgF2{
			background: #F2F2F2;
		}
		.line-h22{
			line-height: 22px;
		}
		.w100{
			width: 100px;
		}
		.w120{
			width: 120px;
		}
		.w150{
			width: 150px;
		}
		.w180{
			width: 180px;
		}
		.pointer{
			cursor: pointer;
		}
		.padL10{
			padding-left: 10px;
		}
		.padR10{
			padding-right: 10px;
		}
		.setHidden{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.overflowX{
			overflow-x: scroll;
			width: 868px;
		}
		.float{
			float: left;
			span{
				display: inline-block;
				line-height: 12px;
			}
		}
		.borderB-ed{
			border-bottom: 1px solid #EDEDED;
		}
	}
</style>
