<template>
	<div class="cl-main">
		<el-card>
			<div class="flex between">
				<div class="font15 bold line-h32">
					常旅客
				</div>
				<el-button class="el-icon-plus" type="primary" @click="addContants">
					新增旅客
				</el-button>				
			</div>
			<div style="height: 20px;"></div>
			<el-table
				:data="contactsList"
				style="width: 100%"
				:header-cell-style="header"
				v-loading = "loading"
				element-loading-background = "rgba(255, 255, 255, .8)"
				element-loading-spinner = "el-icon-loading"
				element-loading-text = "加载中，请稍后..."
				class="bghover"		
			>
				<el-table-column type="index" width="50"></el-table-column>
				<el-table-column label="旅客" prop="chName" width="120"></el-table-column>
				<el-table-column label="英文名" prop="enName"></el-table-column>
				<el-table-column label="手机号" prop="phoneNumber" width="150"></el-table-column>     
				<el-table-column label="证件号" >
					<template slot-scope="scope">
						<div v-if="scope.row.idNumber">(身份证{{scope.row.idNumber}})</div>
						<div v-if="scope.row.passport">(护照{{scope.row.passport}})</div>				
					</template>  
				</el-table-column>
				<el-table-column label="操作" width="100">
					<template slot-scope="scope">
						<el-button type="text" @click="editCommonContants(scope.row)">编辑</el-button>
						<el-button type="text" @click="removeContants(scope.row.id)">删除</el-button>			
					</template>  
				</el-table-column>  	   
			</el-table>	
		</el-card>
		<el-dialog
			:title="title"
			:close-on-click-modal="false"
			:visible.sync="visible"
			:before-close="handleClose"
			style="text-align: left;"
			>
			<el-form :model="ruleForm" :inline="true" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="中文姓名" prop="chName">
					<el-input v-model="ruleForm.chName"  placeholder="请输入中文姓名" @input="createpy()"></el-input>
				</el-form-item>
				<el-form-item label="英文名字" class="name" prop="enName">
					<el-input v-model="idCardParams.firstName" class="last"  placeholder="英文名字"></el-input>
					<span class="padLR5">/</span>
					<el-input v-model="idCardParams.lastName" class="first" placeholder="英文姓"></el-input>
				</el-form-item>
				<el-form-item label="性别">
					<div class="" style="width: 200px;">
						<el-radio v-model="radio" label="M">男</el-radio>
						<el-radio v-model="radio" label="F">女</el-radio>
					</div>
					
				</el-form-item>
				<el-form-item label="常用手机" prop="phoneNumber">
					<el-input v-model="ruleForm.phoneNumber"  placeholder="请输入常用手机"></el-input>
				</el-form-item>
				<el-form-item label="固定电话" prop="tel">
					<el-input v-model="ruleForm.tel" placeholder="请输入固定电话"></el-input>
				</el-form-item>
				<el-form-item label="邮箱" prop="email">
					<el-input v-model="ruleForm.email" placeholder="请输入邮箱"></el-input>
				</el-form-item>
				<el-form-item label="身份证" prop="idNumber">
					<el-input v-model="ruleForm.idNumber" placeholder="请输入身份证号"></el-input>
				</el-form-item>
				<el-form-item label="护照号码" prop="passport">
					<el-input v-model="ruleForm.passport"  placeholder="请输入护照号码"></el-input>
				</el-form-item>
				<el-form-item label="护照有效期"  placeholder="请选择护照有效期">
					<el-date-picker
					    v-model="ruleForm.passportPeriod"
					    type="date"
						value-format="yyyy-MM-dd"
					    placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="签发地">
					<el-select v-model="ruleForm.placeOfIssue" filterable :filter-method="sreachQFD"  placeholder="请选择签发地">
						<el-option
							v-for="item in placeOfIssueList"
							:key="item.id"
							:label="item.cName"
							:value="item.id">
						</el-option>
					</el-select>	
				</el-form-item>
				<el-form-item label="出生地">
					<el-select v-model="ruleForm.birthPlace" filterable :filter-method="sreachCSD"  placeholder="请选择出生地">
						<el-option
							v-for="item in birthPlaceList"
							:key="item.id"
							:label="item.cName"
							:value="item.id">
						</el-option>
					</el-select>	
				</el-form-item>
			</el-form>	
			<span slot="footer" class="dialog-footer">
				<el-button @click="handleClose">取消</el-button>
				<el-button type="primary" @click="submit('ruleForm')">保存</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import pinyin from 'js-pinyin';
	export default{
		name: 'Passenger',
		data() {
			// 验证中文名
			var valiChName = (rule, value, callback)=> {
				const chNameReg = /^[\u4e00-\u9fa5]+$/
				if ( chNameReg.test(value)) {
					callback()
				}else {
				  return callback(new Error('请输入正确的中文名'))
				}	
			};
			// 验证英文文名
			let valiEnName = (rule, value, callback)=>{
				let nameList = [];
				let name = '';
				name = this.idCardParams.firstName+''+this.idCardParams.lastName;
				
				const enNameReg = /^[a-zA-Z]+$/ // 验证没有空格的英文名
				if(value && value!='/'){
					if(name.length < 50 && enNameReg.test(name)) {
						callback()
					}else {
						return callback(new Error('英文姓名有错误'))
					}	
				}else{
					callback()
				}
					
			};
			// 验证邮箱
			var valiEmail = (rule, value, callback)=> {
				const mailReg = /^([a-zA-Z0-9_-])+@([a-zA-Z0-9_-])+(.[a-zA-Z0-9_-])+/
					if(value){
						if (mailReg.test(value)) {
						    callback()
						} else {
						    callback(new Error('请输入正确的邮箱格式'))
						}
					}else{
						callback()
					}
			};
			// 验证手机号
			let valiPhoneNumber = (rule, value, callback)=> {
				const phoneReg = /^1[3|4|5|7|8][0-9]{9}$/
				setTimeout(() => {
				    if (phoneReg.test(value)) {
				        callback()
				    } else {
				        callback(new Error('手机号码格式不正确'))
				    }
				}, 100)
			};
			// 身份证号的验证规则
			let valiIdNumber = (rule, value, callback) => {
			    const reg = /(^\d{15}$)|(^\d{18}$)|(^\d{17}(\d|X|x)$)/
			        if (reg.test(value)) {
						callback()
			        }else {
						return callback(new Error('请输入正确的身份证号'))
			        }
			     
			    };
			// 验证护照
			let valiPassport = (rule, value, callback) => {
					/* 护照
					*	护照有四种类型：普通护照、公务护照、澳门特别行政区护照和香港特别行政区护照四种类型，不同的护照类型护照号码格式也是不一样的。
					* 		一、普通护照：E+8 位数字编号
					* 		二、公务电子护照，公务护照又分为公务、公务普通和外交护照三个类别
					*			公务护照：SE+7 位数字编码
					*			外交护照：DE+7 位数字编码
					*			公务普通护照：PE+7 位数字编码
					* 
					* 		三、澳门特别行政区护照：MA+7 位编号
					* 		四、香港特别行政区护照：K+8 位编号。
					*/
			        let regs = /(^1[45][0-9]{7}|([P|p|S|s]\d{7})|([S|s|G|g]\d{8})|([Gg|Tt|Ss|Ll|Qq|Dd|Aa|Ff]\d{8})|([H|h|M|m]\d{8，10})$)/;
					let regm = /^([EK]\d{8}|(SE|DE|PE|MA)\d{7})$/;
					if(value){
						if (regs.test(value) || regm.test(value)) {
							callback();
						} else {
							callback(new Error('请输入正确的护照号'));
						}
					}else{
						callback();
					}
			    };
			// 验证固定电话
			let	valiTel = (rule, value, callback) => {
			        let telReg = new RegExp(/^\d{3}-\d{7,8}|\d{4}-\d{7,8}$/);
					if(value){
						if(!telReg.test(value)){
							callback(new Error('电话号码格式错误'));
						}else{
							callback();
						}
					}else{
						callback()
					}						
			    };
			return {
				header:{background:'#F2F2F2',},
				contactsList: [], // 常用联系人列表
				isEmpty:false, // 常用联系人列表为空时展示
				radio:'M', //男女
				visible: false, 
				ruleForm: {  // 基本信息参数
				    birthPlace: "",
				    email: "",
					chName:"",
					enName:"",
				    idNumber: "",
				    passport: "",
				    passportPeriod: "",
				    phoneNumber: "",
				    placeOfIssue: "",
				    sex: "",
				    tel: "",
					contactsId:'',
				},
				idcardList:[],  // 常用联系人
				idCardParams : { // 常用联系人身份信息
					empId: "",
					firstName: "",
					idNo: "",
					idType: "",
					lastName: "",
					placeOfIssue: "",
					placeOfIssueName: "",
					validDate: "",
				},
				rules: {
					email:[
						{ validator: valiEmail, trigger: 'blur' }
					],
					enName:[
						{ validator: valiEnName, trigger: 'blur' }
					],
					passport:[
						{ validator: valiPassport, trigger: 'blur'}
					],
					tel:[
						{ validator: valiTel, trigger: 'blur'}
						],
				    phoneNumber: [
				        { required: true, message: '请输入手机号', trigger: 'blur' },
				        { min: 11, max: 11, message: '手机号长度为11位', trigger: 'blur' },
						{ validator: valiPhoneNumber, trigger: 'blur'}
				    ],
					chName: [
						{ required: true, message: '请输入中文姓名', trigger: 'blur'},
						{ validator: valiChName, trigger: 'blur' }
					],
					idNumber :[
						{ required: true, message: '请输入证件号', trigger: 'blur' },
						{ min: 18, max: 18, message: '证件号长度为18位', trigger: 'blur' },
						{ validator: valiIdNumber, trigger: 'blur'}
					],
				},
				placeOfIssueList: [] , // 签发地
				birthPlaceList: [], // 出生地
				newNationList: [], // 国家列表
				
				title: '',
				
				loading: false,
			}
		},
		mounted() {
			this.getContants();
			this.getProCity();
			
		},
		methods: {
			/*输入中文名补全拼音*/
			createpy(){
				if(!this.ruleForm.chName){
					this.idCardParams.firstName = '';
					this.idCardParams.lastName = '';
				}
				let pinyin = require('js-pinyin');
				    pinyin.setOptions({checkPolyphone: false, charCase: 0});
					/*
					*   getCamelChars: 获取拼音首字母
					*	getFullChars: 获取拼音
					*	_capitalize: 首字母大写
					* 
					* */
				if(this.ruleForm.chName.length==1){
					let x = pinyin.getFullChars(this.ruleForm.chName[0]);
					this.idCardParams.lastName = x.toUpperCase();
					this.idCardParams.firstName = '';
				}else if(this.ruleForm.chName.length>1){
					let x = pinyin.getFullChars(this.ruleForm.chName[0]);
					let str = this.ruleForm.chName.substr(1);
					let m = pinyin.getFullChars(str);
					this.idCardParams.lastName = x.toUpperCase();
					this.idCardParams.firstName = m.toUpperCase();
				}	
					
			},
			// 获取常用联系人列表
			getContants(){
				this.loading = true;
				let obj = {}
				this.$conn.getConn('user.contactsGet')(obj,(res)=>{
					let data = res.data || [];
					this.contactsList = data;
					if(this.contactsList.length>0){
						this.isEmpty = false;
					}else{
						this.isEmpty = true;
					}
					this.loading = false;
				},(err)=>{
					this.loading = false;
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			addContants(){
				this.title = '新增联系人';
				this.visible = true;
				this.ruleForm= {  // 基本信息参数
				    birthPlace: "",
				    email: "",
					chName:"",
					enName:"",
				    idNumber: "",
				    passport: "",
				    passportPeriod: "",
				    phoneNumber: "",
				    placeOfIssue: "",
				    sex: "",
				    tel: "",
					contactsId:'',
				}
				this.idCardParams.firstName = "";
				this.idCardParams.lastName = "";
				
			},
			// 国家 省份 proCity.js 数据是否存在本地缓存
			getProCity(){
				let countryInfo = this.$common.localget('countryInfo');
				if(JSON.stringify(countryInfo)=='{}' || !countryInfo){
					this.getNontion();
				}else{
					let dataList = [];
					for(let key in countryInfo){
						dataList.push(countryInfo[key]);	
					}
					this.placeOfIssueList = dataList;
					this.birthPlaceList = dataList;
						
					
					this.newNationList = dataList;
				}
			},
			// 保存常用联系人
			submit(formName){
				this.$refs[formName].validate((valid) => {
				    if (valid) {
						this.idcardList = [];
						this.idcardList.push(this.idCardParams);
						let params = {}
						if(this.ruleForm.contactsId){
							params = {
								birthPlace: this.ruleForm.birthPlace || "",
								email: this.ruleForm.email || "",
								chName: this.ruleForm.chName || "",
								enName: (this.idCardParams.firstName+'/'+this.idCardParams.lastName) || "",
								idNumber: this.ruleForm.idNumber || "",
								passport: this.ruleForm.passport || "",
								passportPeriod: this.ruleForm.passportPeriod || "",
								phoneNumber: this.ruleForm.phoneNumber || "",
								placeOfIssue: this.ruleForm.placeOfIssue || "",
								sex: this.ruleForm.sex || "",
								tel: this.ruleForm.tel || "",
								idcardList: this.idcardList,
								contactsId: this.ruleForm.contactsId,
							}
						}else {
							params = {
								birthPlace: this.ruleForm.birthPlace || "",
								email: this.ruleForm.email || "",
								chName: this.ruleForm.chName || "",
								enName: (this.idCardParams.firstName+'/'+this.idCardParams.lastName) || "",
								idNumber: this.ruleForm.idNumber || "",
								passport: this.ruleForm.passport || "",
								passportPeriod: this.ruleForm.passportPeriod || "",
								phoneNumber: this.ruleForm.phoneNumber || "",
								placeOfIssue: this.ruleForm.placeOfIssue || "",
								sex: this.ruleForm.sex || "",
								tel: this.ruleForm.tel || "",
								idcardList: this.idcardList,
							}
						}
						 
						this.$conn.getConn('user.contactsEdit',{jsfile:false})(params).then(res=>{
							this.$message({
								type: 'success',
								message: '保存成功'
							})
							this.visible = false;
							this.getContants()
						}).catch(err=>{
							this.$message({
								type: 'error',
								message: err.tips
							})
						})
				    } else {
				      return false;
				    }      
				});
			},
			// 获取国家信息列表
			getNontion(type){	
				this.$conn.getConn('commu.baseData',{jsfile:true})({}).then((res)=>{
					let data = res || {};
					this.$common.localset('countryInfo',data.country)
					this.$common.localset('provinceList', data.province['00002']);
					this.$common.localset('allCityListInfo',data.city)
	
					let dataList = [];
					// 国家
					this.birthPlaceList = [];
					this.placeOfIssueList = [];
					for(let key in data.country){
						dataList.push(data.country[key]);	
					}
					if(type == 'placeOfIssue'){
						this.placeOfIssueList = dataList;
					}else if(type == 'birthPlace'){
						this.birthPlaceList = dataList;
					}else{
						this.placeOfIssueList = dataList;
						this.birthPlaceList = dataList;
					}
				
					this.newNationList = dataList;
				})
			},
			// 签发地
			sreachQFD(e){
				if(e){
					this.placeOfIssueList = [];
					this.newNationList.forEach((item,index)=>{
						if(item.by1.indexOf(e.toUpperCase())!=-1){
							this.placeOfIssueList.push(item)
						}
					})
				}else{
					this.placeOfIssueList = this.newNationList;
				}
			},
			// 出生地
			sreachCSD(e){
				if(e){
					this.birthPlaceList = [];
					this.newNationList.forEach((item,index)=>{
						if(item.by1.indexOf(e.toUpperCase())!=-1){
							this.birthPlaceList.push(item)
						}
					})
				}else{
					this.birthPlaceList = this.newNationList;
				}
			},
			// 编辑常用联系人
			editCommonContants(item){
				this.title = '编辑联系人';
				this.visible = true;
				this.ruleForm= {
				    birthPlace: item.birthPlace,
				    email: item.email,
					chName: item.chName,
					enName: item.enName,
				    idNumber: item.idNumber,
				    passport: item.passport,
				    passportPeriod: item.passportPeriod,
				    phoneNumber: item.phoneNumber,
				    placeOfIssue: item.placeOfIssue,
				    sex: item.sex,
				    tel: item.tel,
					contactsId: item.contactsId,
				},
				item.idcardList.forEach((params)=>{
					let idCardInfo = {
						empId: params.empId,
						firstName: params.firstName,
						idNo: params.idNo,
						idType: params.idType,
						lastName: params.lastName,
						placeOfIssue: params.placeOfIssue,
						placeOfIssueName: params.placeOfIssueName,
						validDate: params.validDate,
					}
					this.idCardParams = idCardInfo	
				})
				
			},
			// 删除常用联系人
			removeContants(id){
				let obj = {
					contactsId: id
				}
				this.$confirm('此操作将永久删除该选项, 是否继续?', '删除', {
				        confirmButtonText: '确定',
				        cancelButtonText: '取消',
				        type: 'warning'
				    }).then(() => {
						this.$conn.getConn('user.contactsDel',{jsfile:false})(obj).then((res)=>{
							this.$message({
								type: 'success',
								message: '删除成功'
							})
							this.visible = false;
							this.getContants()
						}).catch((err)=>{
							this.$message({
								type: 'error',
								message: err.tips
							})
						}) 	  							 
					}).catch(() => {
						 this.$message({
							type: 'info',
							message: '已取消删除'							
						 });     
					});       	   	   
			},
			// 关闭页面
			handleClose(){
				this.visible = false;
			},
		}
		
	}
</script>

<style scoped lang="scss">
	.cl-main{
		font-family: '微软雅黑';
		.el-card.is-always-shadow{
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
			border-radius: 5px;	
		}
		/deep/ .el-dialog{
			width: 660px;
		}
		/deep/ .el-dialog__header{
			padding: 20px;
			border-bottom: 1px solid #DCDCDC;
		}
		/deep/ .el-dialog__body{
			padding: 20px;
		}
		/deep/ .el-dialog__footer{
			padding: 15px 30px 20px ;
			border-top: 1px solid #DCDCDC;
		}
		/deep/ .el-select .el-input__inner{
			width: 200px;
		}
		/deep/ .el-input__inner{
			border-radius: 0;
		}
		/deep/ .el-date-editor--date{
			width: 200px;
		}
		.padLR5{
			padding:0 5px;
		}
		.bghover :hover{
			background-color: #F5F7FA;
		}
		.name{
			.first {
				width: 78px;
				// /deep/ .el-input__inner{
				// 	// width: 50px;
				// }
			}
			.last {
				width: 106px;
				// /deep/ .el-input__inner{
				//  	width: 100px;
				// }
			}
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.add-title{
			font-size: 12px;
			line-height: 12px;
			font-weight: 400;
			color: #808080;
		}
		.add-text{
			font-size: 12px;
			font-weight: 500;
			color: #282828;
		}
		.font15{
			font-size: 15px;
		}
		.bold{
			font-weight: bold;
		}
		.padTB14{
			padding: 14px 0;
		}
		.padTB18{
			padding: 18px 0;
		}
		.bfb-w15{
			width: 15%;
		}
		.bfb-w20{
			width: 20%;
		}
		.bfb-w30{
			width: 30%;
		}
		.bgF2{
			background: #F2F2F2;
		}
		.color80{
			color: #808080;
		}
		.marT20{
			margin-top: 20px;
		}
		.line-h32{
			line-height: 32px;
		}
		.lineH38{
			line-height: 38px;
		}
		.borderB-bc{
			border-bottom: 1px solid #DCDCDC;
		}
		.btn{
			/deep/ .el-button--small{
				padding: 0 ;
			}
		}
		.color28{
			color: #282828;
		}
		.minh831{
			min-height: 831px;
		}
		
	}
</style>
