<template>
	<div class="indetail">
		<el-card class='crad'>
			<div class="header">
				<p>订单号：{{orderDetail.orderNo}}</p>
			</div>
			<div class="incontent">
				<div class="inLeft">
					城市信息
				</div>
				<div class="inRight lineH78 text" >
					入住城市：{{orderDetail.cityName}}
				</div>
			</div>
			<div class="incontent">
				<div class="inLeft">
					入住/离店日期
				</div>
				<div class="inRight lineH78 text" >
					入住/离店日期：{{orderDetail.rzrq}} 至 {{orderDetail.ldrq}} 共 {{orderDetail.nights}} 夜
				</div>
			</div>
			<div class="incontent">
				<div class="inLeft">
					其他信息
				</div>
				<div class="inRight bzxx text" >
					<div>房间数：{{orderDetail.rooms}} 间</div>
					<div>期望价格：<span style="color:#f5a337;">{{orderDetail.expectBudget}}￥</span></div>
					<div style="display: inline-block;word-break:break-all">
						备注：{{orderDetail.content?orderDetail.content:'无'}}
					</div>
				</div>
			</div>
			<div class="incontent">
				<div class="inLeft">
					联系人信息
				</div>
				<div class="inRight lineH78 text" >
					<span>联系人：{{orderDetail.linkman}}</span>
					<span class="text">联系电话：{{orderDetail.linkTel}}</span>
					<span class="text">联系邮箱：{{orderDetail.linkEmail}}</span>
				</div>
			</div>
		</el-card>	
		<el-card class='crad'>
			<div class="header">
				<p>留言板</p>
			</div>
			<div class="incontent">
				<div class="inLeft">
					输入留言
				</div>
				<div class="inRight" style="padding:20px 40px;">
					<el-input type="textarea" v-model="note" :rows="2" placeholder="可发送更多需求,获得更优质的服务"></el-input>
				</div>
			</div>
		</el-card>	
		<el-button class="button" @click="send">发 送</el-button>
	</div>
</template>

<script>
	export default{
		name: 'InHtDetail',
		data() {
			return {
				orderDetail:{},
				note :'', //签注内容
				configData:{},// 总公司信息
			}
		},
		mounted(){
			
			
			this.configData = this.$common.getConfigData() || {};
			
		
			let orderId= this.$route.query.orderId || '';
			this.getDetail(orderId);
		},
		methods: {
			getDetail(orderId){
				let params = {
					id: orderId
				};
				let getHtNeedOrderDetail = this.$conn.getConn('hotelOrder.getHtNeedOrderDetail')
				getHtNeedOrderDetail(params,(res)=>{
					this.orderDetail = res.data || {};
				},(err)=>{
					this.$alert(err.tips,'提示',{
						type:'error'
					})
				})
			},
			send(){
				if(!this.note){
					this.$alert('签注信息为空，请输入签注信息','',{
						type:'error',
					})
					return false;
				}
				let params = {
						orderId: this.$route.query.orderId,
						note: this.note,
					}
				let savehtNeedNote = this.$conn.getConn('hotelOrder.savehtNeedNote');
				savehtNeedNote(params,(res)=>{
					this.getDetail(this.$route.query.orderId)
					this.$message.success('签注信息已发送')
					this.note = "";
				},(err)=>{
					this.$alert(err.tips,'提示',{
						type:'error'
					})
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.indetail{
		margin: 0 auto;
		width: 1200px;
		.crad{
			margin: 40px 0;
			.header{
				display: flex;
				line-height: 40px;
			}
			.incontent{
				display: flex;
				margin-bottom: 10px;
				min-height: 80px;
				border: 1px solid #E6E6E6;
				box-sizing: border-box;
				.inLeft{
					width: 160px;
					display: flex;
					justify-content: center;
					align-items: center;
					background: #FAFAFA;
					border-right: 1px solid #E6E6E6;    
				}
				.inRight{
					width: 960px;
					.text{
						text-align: left;
						padding-left: 40px;
					}
				}
				.padTB20{
					padding: 20px 0;
				}
				.lineH78{
					line-height: 78px;
				}
				.bzxx{
					line-height: 30px;
					padding: 20px;
				}
				.text{
					text-align: left;
					padding-left: 40px;
				}
			}
		}
		.button{
			width: 150px;
			height: 50px;
			font-size: 14px;
		}
	}
</style>
