<template>
	<div class="tfa-main">
		<el-card>
			<div class="flex between">
				<div class="flex flexStart textLeft">
					<div class="marR15 setSelect">
						<p class="marB10 top-title">日期类型</p>
						<el-select v-model="dataParams.dateType" placeholder="日期类型" @change="getDateChange">
						    <el-option
						      v-for="item in dateTypeList"
						      :key="item.id"
						      :label="item.text"
						      :value="item.id">
						    </el-option>
						</el-select>
					</div>
					<div class="marR15 setSelect">
						<p class="marB10 top-title">申请状态</p>
						<el-select v-model="dataParams.status" placeholder="状态"  @change="getStatusChange">
						    <el-option
						      v-for="item in travelFormsStatusList"
						      :key="item.id"
						      :label="item.text"
						      :value="item.id">
						    </el-option>
						</el-select>
					</div>
					<div class="marR15 setDateInput">
						<p class="marB10 top-title">起始日期</p>
						<el-date-picker
						    v-model="dataParams.dateBegin"
							value-format="yyyy-MM-dd"
							:clearable="false"
							prefix-icon="false"
						    type="date"
							@change="getFormatDate"
						    placeholder="选择开始日期">  
						</el-date-picker>
					</div>
					<div class="marR15 setDateInput">
						<p  class="marB10 top-title">结束日期</p>
						<el-date-picker
						    v-model="dataParams.dateEnd"
							value-format="yyyy-MM-dd"
							:clearable="false"
							prefix-icon="false"
						    type="date"
							@change="getNowFormatDate"
						    placeholder="选择结束日期">  
						</el-date-picker>
					</div>
					<div class="setInput marR15 textLeft">
						<p  class="marB10 top-title">出行人/目的地/申请单号</p>
						<el-input v-model="dataParams.ccr" placeholder="出行人/目的地/申请单号" @change="getCcrChange"></el-input>
					</div>
				</div>
				<div class="flex flexEnd">
					<el-button type="primary" class="button " @click="query">查询</el-button>
					<el-button type="primary" plain class="button" @click="clear">清空</el-button>
					<el-button type="primary" plain class="button" @click="exportSqd">导出</el-button>
				</div>	
			</div>
		</el-card>
		<el-card class="marT20 setWord">
			<div v-if="isAddCcFormBtn" class="textLeft marB15">
				<el-button type="primary" plain class="button1" @click="goToAddForm">新增出差申请单</el-button>
				<el-button type="primary" v-if="isKaiQiFeiKong" class="button1" @click="sendEtclCCForm">费控推送出差申请单</el-button>
			</div>
			<el-table :data="travelFormList" 
				v-loading = "loading"
				element-loading-background = "rgba(255, 255, 255, .5)"
				element-loading-text = "加载中，请稍后..."
				element-loading-spinner = "el-icon-loading"
				>
				<el-table-column type="index"></el-table-column>
				<el-table-column label="申请单号" width="160">
					<template slot-scope="scope">
						<div class="flex-r-c">
							<el-button type="text" @click="goToDetail(scope.row)">
								{{scope.row.ccsqdNo}}
							</el-button>
							<img :src="bgdImage" v-show="scope.row.hasChange == 1" style="margin-right:2px">
							<i class="iconfont yj-icon-weizhang" v-show="scope.row.violateitems" style="font-size:18px;color:red" ></i>
						</div>
					</template>  
				</el-table-column>
				<el-table-column
				    label="申请单状态"
				    width="80">
					<template slot-scope="scope">
						<span v-if="scope.row.status==0">草稿</span>
						<span v-if="scope.row.status==1" style="color: #FF9524;">审批中</span>
						<span v-if="scope.row.status==2" style="color: #409EFF;">审批通过</span>
						<span v-if="scope.row.status==3" style="color: red;">审批拒绝</span>
						<span v-if="scope.row.status==4">报销中</span>
						<span v-if="scope.row.status==5">已报销</span>
                        <span v-if="scope.row.status==7">已取消</span>
					</template>  
				</el-table-column>
				<el-table-column show-overflow-tooltip  prop="ccrAndSxr" label="出行人员" width="160"></el-table-column>
				<el-table-column
				    label="出差时间"
				    width="180">
					<template slot-scope="scope">
						<span style="line-height:25px">{{scope.row.dateBegin}}</span>
						<span style="line-height:25px">至</span>
						<span style="line-height:25px">{{scope.row.dateEnd}}</span>
					</template>    
				</el-table-column>
				<el-table-column prop="ccsy" label="出差事由"  width="120"></el-table-column>
				<el-table-column prop="cclxmc" label="出差类型"  width="120"  show-overflow-tooltip></el-table-column>
				<el-table-column label="目的地" width="150">
					<template slot-scope="scope">
						<span v-if="scope.row.customizeFlag==2">
							{{scope.row.manyMdd}}
						</span>
						<span v-else>
							{{scope.row.mdd}}
						</span>
					</template>  
				</el-table-column>
				<el-table-column prop="zys" label="总预算" width="80"></el-table-column>
				<el-table-column prop="reqTime" label="申请时间" width="120"></el-table-column>
				<el-table-column
					  fixed="right"
				      label="操作"
				      width="120">
					<template slot-scope="scope">
						<el-button type="text" v-if="scope.row.status==0" @click="save(scope.row)">送审</el-button>
						<el-button type="text"  v-if="scope.row.status==0 && scope.row.isChange==0" @click="edit(scope.row.ccsqdId,scope.row.customizeFlag)">编辑</el-button>
						<el-button type="text" v-if="scope.row.status==0" @click="remove(scope.row.ccsqdId)">删除</el-button>	
                        <el-button type="text" v-if="scope.row.status==2" @click="withDraw(scope.row.ccsqdId)">取消</el-button>					
						<el-button type="text" v-if="scope.row.status==3" @click="againSubmitSqd(scope.row)">再次提交</el-button> 
						<el-button type="text" v-if="scope.row.status==1 && scope.row.cancelFlag" @click="revokeCcsqd(scope.row)">撤回</el-button> 
					</template>  
				</el-table-column>
			</el-table>
			<!-- <div class="flex title borderT-EB line-h40">	
				<div style="width:30px;"></div>
				<div class="w120">申请单号</div>
				<div class="w70">申请单状态</div>
				<div class="w80">出行人员</div>
				<div class="w80">出差时间</div>
				<div class="w80">出差事由</div>
                <div class="w80">出差类型</div>
				<div class="w80">目的地</div>
				<div class="w70">总预算</div>
				<div class="w100">申请时间</div>
				<div class="w60">操作</div>
			</div>
			<div v-if="!loading">
				<div class="flex borderT-EB line-h40" v-for="(item, index) in travelFormList" :key="index">
					<div style="width:30px;" class="flex-center">{{index+1}}</div>
					<div class="w120 color409eff pointer flex-center" @click="goToDetail(item)">
				        <img src="../../../assets/image/xak/bian.png" alt="" v-show="item.isChange==1" style="margin-right:2px">				
						{{item.ccsqdNo}}
				   
					</div>
					<div class="w70 flex-center">
						<span v-if="item.status==0">草稿</span>
						<span v-if="item.status==1" style="color: #FF9524;">审批中</span>
						<span v-if="item.status==2" style="color: #409EFF;">审批通过</span>
						<span v-if="item.status==3" style="color: red;">审批拒绝</span>
						<span v-if="item.status==4">报销中</span>
						<span v-if="item.status==5">已报销</span>
					</div>
					<div class="w80 setHide flex-center">
						<el-tooltip   placement="top" effect="dark"  :content="item.ccrAndSxr">
						    <el-button type="text" class=" setHide color3">{{item.ccrAndSxr}}</el-button>
						</el-tooltip>
					</div>
					
				    <div  class="w80 flex-column">
				        <span style="line-height:25px">{{item.dateBegin}}</span>
				        <span style="line-height:25px">至</span>
				        <span style="line-height:25px">{{item.dateEnd}}</span>
				    </div>
					<div class="w80 setHide  flex-center" >
						<el-tooltip   placement="top" effect="dark" :content="item.ccsy" v-if="item.ccsy">
						    <el-button type="text" class="w80 setHide color3">{{item.ccsy}}</el-button>
						</el-tooltip>
					</div>
				  
				    <div class="w80 setHide  flex-center" >
						<el-tooltip   placement="top" effect="dark" :content="item.cclxmc" v-if="item.cclxmc">
						    <el-button type="text" class="w80 setHide padR10  color3">{{item.cclxmc}}</el-button>
						</el-tooltip>
					</div>
					<div class="w80 setHide flex-center">
				        <el-tooltip   placement="top" effect="dark" :content="item.manyMdd" v-if="item.manyMdd">
						    <el-button type="text" class="w80 setHide padR10  color3">{{item.manyMdd}}</el-button>
						</el-tooltip>
				    </div>
					<div class="w70 setHide flex-center">{{item.zys}}</div>
					<div class="w100 flex-center">{{item.reqTime}}</div>
					<div class="w60" style="line-height: 20px;">
						<div v-if="item.status==0">
							<el-button type="text" style="padding: 5px 0;" @click="save(item)">送审</el-button>
						</div>
						<div v-if="item.status==0 && item.isChange==0">
							<el-button type="text" style="padding: 5px 0;" @click="edit(item.ccsqdId,item.customizeFlag)">编辑</el-button>
						</div>
						<div v-if="item.status==0">
							<el-button type="text" style="padding: 5px 0;" @click="remove(item.ccsqdId)">删除</el-button>
						</div>
						<div v-if="item.status==3">
							<el-button type="text" style="padding: 5px 0;" @click="againSubmitSqd(item)">再次提交</el-button>
						</div>       
					</div>
				</div>
			</div> 
			<div v-if="loading" style="color:#007FE9;padding: 20px 0;"><span class="el-icon-loading"></span>加载中...</div>-->
			<el-pagination
				class="textLeft borderT-EB padT20 flex-center"
				background
				v-if="dataParams.total"
			    @current-change="handleCurrentChange"
			    :current-page.sync="dataParams.pageNum"
			    layout="total, prev, pager, next"
				:total="dataParams.total">
			</el-pagination>
		</el-card>
	</div>
</template>

<script>
    import { mapGetters } from 'vuex';
	export default{
		name: 'TravelFormAdmin',
		data() {
			return {
				dataParams:{
					dateType:1,
					status:'',
					ccr:'',
					dateBegin:'',
					dateEnd:'',
					pageNum: 1,
					count: 10,
					total:0,
				},
				bgdImage:require("@/assets/image/xak/bian.png"),
				isAddCcFormBtn:false,
				dateTypeList:[
					{
						text: '申请日期',
						id: 1,
					},
					{
						text: '出发日期',
						id: 2,
					}
				],
				travelFormsStatusList:[  //申请单状态
					{
						text: '全部',
						id: '',
					},
					{
						text: '草稿',
						id: 0,
					},
					{
						text: '审批中',
						id: 1,
					},
					{
						text: '审批通过',
						id: 2,
					},
					{
						text: '审批拒绝',
						id: 3,
					},
					{
						text: '报销中',
						id: 4,
					},
					{
						text: '已报销',
						id: 5,
					},
                    {
						text: '已取消',
						id: 7,
					}
				],
				travelFormList: [], //申请单列表
				loading: false,
				empInfo:{},
				vipCorp:{},
			}
		},
		created(){
			let userInfo = this.$common.getUserInfo() || {};
			this.empInfo = userInfo.empInfo || {};
			this.vipCorp = userInfo.vipCorp || {};
		},
		computed:{
            ...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			isKaiQiFeiKong(){
				return this.$common.isKaiQiFeiKong();
			}
		},
		mounted(){
			this.getFormatDate();
			this.getNowFormatDate();
			this.getParameter();
			this.query();
			// 接受子页面方法刷新需要我审批
			window['travelFormAdminRefresh'] = (val) =>{
				if(val==1){
					this.query();
				}
			}
		},
		methods:{
            withDraw(ccsqdId){
                this.$conn.getConn('xakCcsqd.fastBookCcsqdCancelService')({ccsqdId:ccsqdId}, res => {
                            this.$alert('取消成功','',{
                            type:'success'
                            
                        })
                        this.query();
                    }, (err) => {
                            this.$alert('取消失败','',{
                            type:'warning'
                        })
                    });
            },
			// 修改日期重置 分页
			getDateChange(){
				this.dataParams.pageNum = 1;
				this.query();
			},
			// 修改状态重置 分页
			getStatusChange(){
				this.dataParams.pageNum = 1;
				this.query();
			},
			// 输入出行人 重置分页
			getCcrChange(){
				this.dataParams.pageNum = 1;
			},
			// 设置默认日期
			getFormatDate(e){
				if(e){
					this.dataParams.dateBegin = e;
					if(this.dataParams.dateBegin && this.dataParams.dateEnd){
						if(this.dataParams.dateBegin>this.dataParams.dateEnd){
							this.dataParams.dateEnd = this.dataParams.dateBegin;
						}
					}
				}else{
					// 获取时间
					var nowDate = new Date();
					// 获取当前时间
					var date = new Date(nowDate);
					// 获取一周时间
					date.setDate(date.getDate() - 7);
					var seperator1 = '-'
					var year = date.getFullYear()
					var month = date.getMonth() + 1
					var strDate = date.getDate()
					if (month >= 1 && month <= 9) {
					    month = '0' + month
					}
					if (strDate >= 0 && strDate <= 9) {
					    strDate = '0' + strDate
					}
					// 一周前的日期
					var formatDate = year + seperator1 + month + seperator1 + strDate
					this.dataParams.dateBegin = formatDate;  
				}  	
			},
			
			// 今天日期
			getNowFormatDate(e){
				if(e){
					this.dataParams.dateEnd = e;
					if(this.dataParams.dateBegin && this.dataParams.dateEnd){
						if(this.dataParams.dateBegin>this.dataParams.dateEnd){
							this.dataParams.dateEnd = this.dataParams.dateBegin;
						}
					}
				}else{
					var date = new Date()
					var seperator1 = '-'
					var year = date.getFullYear()
					var month = date.getMonth() + 1
					var strDate = date.getDate()
					if (month >= 1 && month <= 9) {
						month = '0' + month
					}
					if (strDate >= 0 && strDate <= 9) {
						strDate = '0' + strDate
					}
					var nowData = year + seperator1 + month + seperator1 + strDate   
					this.dataParams.dateEnd = nowData;
				}
				
			},
			// 去详情
			goToDetail(item){
                // let orderType = item.customizeFlag!=2? 11099:11100;
                console.log(item,"item")
                let path = item.customizeFlag!=2? "/ccsqdDetail":'/xakCcsqdDetail';
				let query = {};
				query = {
					orderId: item.ccsqdId,
					orderType:11099
				}
				let routeUrl = this.$router.resolve({path,query:query});
				window.open(routeUrl.href,'_blank')
			},
            againSubmitSqd(item){ // 再次提交
                this.$conn.getConn('xakCcsqd.resetFastBookCcsqd')({id:item.ccsqdId}, res => {
                            this.$alert('提交成功！','',{
                            type:'success'
                            
                        })
                        this.query();
                    }, (err) => {
                            this.$alert('提交失败','',{
                            type:'warning'
                        })
                    });
            },

			// 是否显示出差申请单按钮
			getParameter(){
				let queryParams = {};
					queryParams = {
						paramNo: 20023,
					}
				this.$conn.getConn('commu.parameter',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					if(data.value1==1){
						this.isAddCcFormBtn = true;
					}else{
						this.isAddCcFormBtn = false;
					}
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 查询出差申请单列表
			query(){
				this.loading = true;
				let queryParams = {};
					queryParams = {
						dateType: this.dataParams.dateType,
						dateBegin: this.dataParams.dateBegin,
						dateEnd: this.dataParams.dateEnd,
						ccr: this.dataParams.ccr,
						status: this.dataParams.status,
						pageNum: this.dataParams.pageNum,
						count: this.dataParams.count,
                        // showCancel:1
					}
				this.$conn.getConn('travelRequest.findVipCcsqdList',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					this.travelFormList = data.list;
					this.dataParams.total = data.total;
					this.dataParams.pageNum = data.pageNum;
                    
					this.dataParams.count = data.count;
					this.loading = false;
				}).catch((err)=>{
					this.loading = false;
					this.$message({
						type: 'error',
						message: err.tips
					})
				})	
			},
			//导出出差申请单
			exportSqd(){
				let exportData = this.getExportData();
				let params = {
					"acs": "1",
					"ccr":  this.dataParams.ccr,
					"dateBegin":  this.dataParams.dateBegin,
					"dateEnd":  this.dataParams.dateEnd,
					"dateType": this.dataParams.dateType,
					"exportData": exportData,
					"exportName": "出差申请单导出",
					"status": this.dataParams.status,
				}
				this.$conn.getConn('travelRequest.findVipCcsqdListExport')(params,(res)=>{
					this.$alert('出差申请单导出成功','提示',{
						type:'success',
						callback: action => {
							window.open('#/taskList',"_blank")
						},
					})
				},(err)=>{
					
				})
			},
			// 获取导出内容
			getExportData() {
				var arr = [{
					name: 'ccsqdNo',
					text: '申请单号'
				},{
					name: 'createdate',
					text: '申请时间'
				},{
					name: 'status',
					text: '申请单状态'
				},{
					name: 'applyEmpName',
					text: '申请人'
				},{
					name: 'empRankName',
					text: '职级'
				},{
					name: 'applyPhoneNumber',
					text: '手机号码'
				},{
					name: 'ccrAndSxr',
					text: '出差人员'
				},{
					name: 'dateBegin',
					text: '出差日期始'
				},{
					name: 'dateEnd',
					text: '出差日期止'
				},{
					name: 'cclxName',
					text: '出差类型名称'
				},{
					name: 'cclxCode',
					text: '出差类型编号'
				},{
					name: 'ccsy',
					text: '出差事由'
				},{
					name: 'by1',
					text: '其他事项'
				},{
					name: 'mdd',
					text: '目的地'
				},{
					name: 'zys',
					text: '预算'
				}]
				
				var tmpArr = [];
				for(var i=0;i<arr.length;i++) {
					var info = arr[i] || {};
					var str = i + '|' + info.text + '|' + info.name;
					tmpArr.push(str);
				}
				return tmpArr.join(",");
			},
			// 分页信息
			handleCurrentChange(e){
				this.dataParams.pageNum = e;
				this.query();
			},
			// 编辑出差申请单
			edit(ccsqdId,customizeFlag){
				let path = customizeFlag!=2? "/newCcsqd":'/xakCcsqd';
				let routeUrl = this.$router.resolve({
						path,
						query:{ccsqdId:ccsqdId}
				    });
				window.open(routeUrl.href,'_blank')
			},
			// 撤回申请单
			revokeCcsqd(item) {
				// orderType : !!ccsqdId ? "11098" : "11099",
				let param = {
					orderId : item.ccsqdId,
					orderType : 11099,
				}
				this.$confirm('撤回此条申请单？', '提示', {
					confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {	
					this.$conn.getConn('commu.vipBpmSendAppCancel', {jsfile: false})(param).then((res)=>{
						this.$message({
							type: 'success',
							message: '撤回成功'
						})
						this.query();
					}).catch((err)=>{
					    this.$message({
					    	type: 'error',
					    	message: err.tips
					    })	
					})
				}).catch(() => {
				    this.$message({
				      type: 'info',
				      message: '已取消撤回'
				    });            
				});
			},
			// 送审
			save(item){
				if(!item.appId){
					this.$alert('送审数据缺少审批，请点击编辑选择审批规则','',{
						type:'warning'
					})
					return 
				}
				let queryParams = {};
					queryParams = {
						orderId:item.ccsqdId,
						orderType: 11099,
						spgzId:	item.appId,
					}
				this.$confirm('送审此条申请单？', '提示', {
					confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {	
					this.$conn.getConn('commu.sendApp',{jsfile:false})(queryParams).then((res)=>{
						this.$message({
							type: 'success',
							message: '送审成功'
						})
						this.query();
							
					
					}).catch((err)=>{
					    this.$message({
					    	type: 'error',
					    	message: err.tips
					    })	
					})
				}).catch(() => {
				    this.$message({
				      type: 'info',
				      message: '已取消送审'
				    });            
				});	
					
			},
			// 删除出差申请单
			remove(id){
				let queryParams = {};
					queryParams = {
						ccsqdId: id
					}
				this.$confirm('确定删除此条申请单？', '提示', {
					confirmButtonText: '确定',
				    cancelButtonText: '取消',
				    type: 'warning'
				}).then(() => {
				    this.$conn.getConn('travelRequest.travelRequestDel',{jsfile:false})(queryParams).then((res)=>{
				    	if(res.httpStatus==200){
							this.$message({
								type: 'success',
								message: '删除成功'
							})
							this.query();
						}
				    }).catch((err)=>{
				    	this.$message({
				    		type: 'error',
				    		message: err.tips
				    	})
				    })	
				}).catch(() => {
				    this.$message({
				      type: 'info',
				      message: '已取消删除'
				    });            
				});					    
			},
			// 重置信息
			clear(){
				this.dataParams.dateType = 1;
				this.dataParams.status = '';
				this.dataParams.ccr = '';
				this.getFormatDate();
				this.getNowFormatDate();
				this.dataParams.pageNum = 1;
				this.dataParams.count = 10;
				// this.dataParams.total = 0;
			},
			// 推送出差申请到etcl
			sendEtclCCForm(){
				let queryParams = {};
					queryParams = {
						corpId: this.empInfo.corpId,
						empId: this.empInfo.empId,
						endDate:this.dataParams.dateEnd,
						startDate: this.dataParams.dateBegin,
					}
				this.$conn.getConn('travelRequest.travelApplySync',{jsfile:false})(queryParams).then((res)=>{
					this.$message({
						type: 'success',
						message: '推送成功'
					})
				}).catch((err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})	
			},
			// 新增出差申请单
			goToAddForm(){
                let parsm20033=this.getVipParamterByParNo(20033, 0)  //1标准版,2定制版,默认为1"
				let path = parsm20033==1? "/newCcsqd":'/xakCcsqd';
				let routeUrl = this.$router.resolve({
						path
				    });
				window.open(routeUrl.href,'_blank')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tfa-main{
		font-family: '微软雅黑';
        margin-bottom: 40px;
		.el-card.is-always-shadow{
			box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
			border-radius: 5px;	
		}
		
		/deep/ .el-input__inner{
			border: none;
			background-color: #F5F5F5;
			height: 40px;
			line-height: 40px;
			border-radius: 0;
			font-size: 14px;
		}
		.top-title{
			font-size: 12px;
			font-weight: 400;
			color: #B3B3B3;
		}
        .flex-center{
            display: flex;
            justify-content: center;
            align-items: center;
        }
				.flex-r-c{
            display: flex;
            align-items: center;
        }
        .flex-column{
            display: flex;
            flex-direction: column;

        }
		.flex{
			display: flex;
		}
		.flexStart{
			justify-content: flex-start;
		}
		.flexEnd{
			align-items: flex-end;
		}
		.between{
			justify-content: space-between;
		}
		.box{
			box-sizing: content-box;
		}
		.textCenter{
			text-align: center;
		}
		.borderT-EB{
			border-top: 1px solid #EBEBEB;;
		}
		.line-h40{
			line-height: 40px;
		}
		.pointer{
			cursor: pointer;
		}
		.color409eff{
			color:#409eff;
		}
		.padT20{
			padding-top: 20px;
		}
		.padR10{
			padding-right: 10px;
		}
		.setHide{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.font14{
			font-size: 14px;
		}
		.bgf5{
			background-color: #f5f5f5;
		}
		.padL10{
			padding-left: 10px;
		}
		.w60{
			width: 60px;
		}
		.w70{
			width: 70px;
		}
		.w80{
			width: 80px;
		}
		.w90{
			width: 90px;
		}
		.w100{
			width: 100px;
		}
		.w120{
			width: 120px;
		}
        .w135{
            width: 135px;
        }
		.w140{
			width: 140px;
		}
		.w170{
			width: 170px;
		}
		.textLeft{
			text-align: left;
		}
		.marT20{
			margin-top: 20px;
		}
		.marB10{
			margin-bottom: 10px;
		}
		.marB15{
			margin-bottom: 15px;
		}
		.colorB3{
			color: #B3B3B3; 
		}
		.color3{
			color: #303133; 	
		}
		.setInput{
			.el-input{
				width: 160px;		
			}
			/deep/ .el-input__inner{
				width: 160px;
			}
		}
		.setDateInput{
			.el-input{
				width: 110px;		
			}
			/deep/ .el-input__inner{
				width: 110px;
				padding-left: 15px;
				padding-right: 15px;
			}
			/deep/ input::-webkit-input-placeholder {
				color: #B3B3B3;
			  }
			/deep/ input::-moz-input-placeholder {
				color: #B3B3B3;
			  }
			/deep/ input::-ms-input-placeholder {
				color: #B3B3B3;
			  }
		}
		.setSelect{
			width: 110px;
			/deep/ .el-input__inner{
				width: 110px;
			}
		}
		.marR15{
			margin-right: 15px;
		}
		.setWord{
			font-size: 12px;
			font-weight: 400;
			.title{
				color: #808080;
			}
		}
		.button{
			width: 60px;
			height: 40px;
			font-size: 14px;
			letter-spacing: 2px;
		}
		.button1{
			height: 30px;
			font-size: 12px;
			font-weight: 400;
			border-radius: 0;	
		}
		.setButton{
			border: none;
			font-size: 12px;
			font-weight: 400;
			color: #1C83ED;
			padding: 0;
		}
	}
</style>
