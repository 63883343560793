<template>
	<div class="atf-main">
		<div class="main">
			<h1 class="seth1">新建出差申请单</h1>
			<el-card class="marT20">
				<ccsqd-header ref="ccsqdHeader" :detail="detailHeader" :detailCxrList="detailCxrList" :routeLength="routeLength" 
					@gznrData="getGznrData"
					@ccrFormInfo="ccrFormInfo" 
					@getCcsqdDateInfo="getCcsqdDateInfo" 
					@ccProjectInfo="ccProjectInfo" 
					@selectCxrList="selectCxrList" 
					@allFormData="allFormData"
				></ccsqd-header>
				<div class="borderB-F0"></div>
				<div class="setPad">
					<div class="flex between">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">行程计划</span>
							<span class="setText">详细填写您的差旅行程，更方便您审批通过</span>
						</div>
						<el-button class="setBtn" @click="addVipRouteTkTr">添加行程</el-button>
					</div>
					<div class="table-main">
						<div class="tabTitle" v-show="vipRouteList.length>0">
							<div class="w50">序号</div>
							<div class="w94">行程类型</div>
							<div class="w114">起飞/出发日期</div>
							<div class="w220">出发/到达城市</div>
							<div class="w97">出行人</div>
							<div class="w97">航班号/车次</div>
							<div class="w97">舱位/席别</div>
							<div class="w97">单价(元)</div>
							<div class="w97">
								<el-tooltip  effect="dark" content="火车票/机票 费用预算规则: 出行人数 * 单价" placement="top">
								     <el-button type="text">费用预算(元)</el-button> 
								</el-tooltip>
							</div>
							
							
							<div class="w116">违背备注</div>
							<div class="w59">操作</div>
						</div>
						<div v-for="(item, index) in vipRouteList" :key="item.proType+''+index" class="tabContent" @click="item.proType==10904?trIndex=index:tkIndex=index">
							<div class="w50 model-k">{{index+1}}</div>
							<div class="w94 model-k">
								<el-select v-model="item.proType" placeholder="请选择" @change="chooseRouteTypeTop(item.proType,index)">
								    <el-option
								      v-for="proTypeItem in proTypeList"
								      :key="proTypeItem.value"
								      :label="proTypeItem.name"
								      :value="proTypeItem.value"
								      :disabled="proTypeItem.disabled">
								    </el-option>
								  </el-select>
								<!-- <div class="relative">
									<div class="setdiv">
										{{item.proType=='10901'?'机票':'火车票'}}
									</div>
									<i class="tql-icon-t absolute toptableR" @click="chooseRouteTypeTop(item.proType,index)"></i>
									<i class="tql-icon-b absolute bottomtableR" @click="chooseRouteTypeTop(item.proType,index)"></i>
								</div> -->	
							</div>
							<div class="w114 model-k">
								<el-date-picker
									value-format="yyyy-MM-dd"
									class="dateW94"
									prefix-icon=" "
									:clearable="false"
								    v-model="item.departDate"
								    type="date"
									@change="getChangeDate(item)"
									:picker-options="pickerOptions(ccsqdRange )"
								    placeholder="出发/起飞日期"
								    >
								</el-date-picker>
							</div>
							<div class="w220 model-k cityInput" v-if="item.proType=='10901'">
								<yj-tk-city :keywords="item.depJHName"  @chooseCity="chooseDepCity" style="display: inline-block;">
									<div slot="referenceContent" class="setInputw">
										<el-input placeholder="出发" v-model="item.departCityName" @input="queryCityName(item.proType,item.departCityName,index)" @click.native="queryTkTrIndex(index)"></el-input>
									</div>
								</yj-tk-city>
								<yj-tk-city :keywords="item.arrJHName" @chooseCity="chooseArrCity" style="display: inline-block;">
									<div slot="referenceContent" class="setInputw">
										<el-input placeholder="到达" v-model="item.arriveCityName" @input="queryArrCityName(item.proType,item.arriveCityName,index)" @click.native="queryTkTrIndex(index)"></el-input>
									</div>
								</yj-tk-city>
								<el-button class="el-icon-search btn39"  @click="getTkList(item,index)"></el-button>
							</div>
							<div class="w220 model-k cityInput" v-if="item.proType=='10904'">
								<yj-tr-city  :keywords="item.depJHName" :isModalStation="true"  @checkTrCity="chooseDepCity" style="display: inline-block;">	
									<div slot="content">
										<el-input placeholder="出发" v-model="item.departCityName" @input="queryCityName(item.proType,item.departCityName,index)" @click.native="queryTkTrIndex(index)"></el-input>
									</div>	
								</yj-tr-city>
								<yj-tr-city  :keywords="item.arrJHName" :isModalStation="true" @checkTrCity="chooseArrCity" style="display: inline-block;">
									<div slot="content">
										<el-input placeholder="到达" v-model="item.arriveCityName" @input="queryArrCityName(item.proType,item.arriveCityName,index)" @click.native="queryTkTrIndex(index)"></el-input>
									</div>	
								</yj-tr-city>
								<el-button class="el-icon-search btn39" @click="getTrList(item,index)"></el-button>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.cxr" class="inputw76"  @click.native="screenCXR(item.proType=='10904'?'tr':'tk',index)" readonly></el-input>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.flightNo" class="inputw76" ></el-input>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.cabin" class="inputw76"  ></el-input>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.dj" class="inputw76"  @change="getDj(item,index)" ></el-input>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.fyys" class="inputw76" disabled ></el-input>
							</div>
							<div class="w116 model-k">
								<!-- 当违背时才输入违背原因 -->
								<el-input :disabled="item.against==0" v-model="item.wbyy"  class="against"></el-input>
							</div>
							<div class="w59 model-k setRemove" @click="removeCurrent(index,1,item)">
								删除
							</div>			
						</div>
					</div>
				</div>
				<div class="setPad">
					<div class="flex between setButton">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">住宿安排</span>
						</div>
						<el-button class="btnw39h39" @click="addVipRouteHotel">添加行程</el-button>
					</div>
					<div class="table-main">
						<div class="tabTitle" v-show="viphtRouteList.length>0">
							<div class="w50">序号</div>
							<div class="w94">入住城市</div>
							<div class="w240">入住/离店日期</div>
							<div class="w114">入住人</div>
							<div class="w97">酒店名称</div>
							<div class="w97">房型</div>
							<div class="w77">房间数</div>
							<div class="w97">单价(元)</div>
							<div class="w97">
								<el-tooltip  effect="dark" content="酒店费用预算规则: 入住人数 * 单价 * 间夜数" placement="top">
								    <el-button type="text">费用预算(元)</el-button> 
								</el-tooltip>
							</div>
							<div class="w116">违背备注</div>
							<div class="w59">操作</div>
						</div>
						<div v-for="(item, index) in viphtRouteList" :key="index" class="tabContent" @click="htIndex=index">
							<div class="w50 model-k">{{index+1}}</div>
							<div class="w94 model-k htInput"> 
								<yj-car-city  :modelType="2" :keywords="item.depHtName"  :inputWidth="76"  @checkCarCity="checkHtCity">
									<div slot="content">
										<el-input placeholder="入住离店城市" v-model="item.departCityName" @input="queryCityName(10903,item.departCityName,index)"></el-input>
									</div>	
								</yj-car-city>
							</div>
							<div class="w240 model-k cityInput" style="display: flex;">
								<el-date-picker
									prefix-icon=" "
									:clearable="false"
									value-format="yyyy-MM-dd"
									class="dateW180"
								    v-model="item.dateList"
								    type="daterange"
									:picker-options="pickerOptions(ccsqdRange)"
								    range-separator="至"
								    start-placeholder="开始日期"
								    end-placeholder="结束日期"
									@change="getHotelDate(item,index)"
									>
								</el-date-picker>
								<el-button class="el-icon-search btn39"  @click="getHtList(item,index)"></el-button>
							</div>
							<div class="w114 model-k">
								<el-input v-model="item.cxr" class="inputw76" @click.native="screenCXR('ht',index)" readonly></el-input>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.flightNo" class="inputw76" ></el-input>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.cabin" class="inputw76" ></el-input>
							</div>
							<div class="w77 model-k input56">
								<el-input v-model="item.fjs" class="inputw56" ></el-input>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.dj" class="inputw76" @change="getDj(item,index)"></el-input>
							</div>
							<div class="w97 model-k">
								<el-input v-model="item.fyys" class="inputw76" disabled ></el-input>
							</div>
							<div class="w116 model-k">
								<!-- :disabled="item.against==0" -->
								<el-input :disabled="item.against==0" v-model="item.wbyy"  class="against"></el-input>
							</div>
							<div class="w59 model-k setRemove" @click="removeCurrent(index,2,item)">
								删除
							</div>
						</div>
					</div>
				</div>
				
				<div class="setPad">
					<div class="flex between setButton">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">用车出行</span>
						</div>
						<el-button class="btnw39h39" @click="addVipRouteCar">添加用车</el-button>
					</div>
					<div class="table-main">
						<div class="tabTitle" v-show="vipCarRouteList.length>0">
							<div class="w50">序号</div>
							<div class="w120">用车城市</div>
							<div class="w360">开始日期/结束日期</div>
							<div class="w136">用车人</div>
							<div class="w136">单价(元)</div>
							<div class="w136">
								<el-tooltip  effect="dark" content="用车费用预算规则: 单价 * 天数" placement="top">
								   <el-button type="text">费用预算(元)</el-button> 
								</el-tooltip></div>
							<div class="w136">违背备注</div>
							<div class="w59">操作</div>
						</div>
						<div v-for="(item, index) in vipCarRouteList" :key="item.proType+''+index" class="tabContent">
							<div class="w50 model-k">{{index+1}}</div>
							<div class="w120 model-k" @click="carIndex=index"> 
								<yj-car-city  :keywords="item.depCarName"  :inputWidth="76"  @checkCarCity="checkCarCity">
									<div slot="content">
										<el-input placeholder="用车城市" style="width:104px;" v-model="item.departCityName" @input="queryCityName(10906,item.departCityName,index)"></el-input>
									</div>	
								</yj-car-city>
							</div>
							<div class="w360 model-k carDate">		
								<el-date-picker
									prefix-icon=" "
									style="border-right:block;"
									:clearable="false"
									value-format="yyyy-MM-dd"
									class="dateW280"
								    v-model="item.dateList"
								    type="daterange"
									:picker-options="pickerOptions(ccsqdRange)"
								    range-separator="至"
								    start-placeholder="开始日期"
								    end-placeholder="结束日期"
									@change="getCarDate(item,index)"
									>
								</el-date-picker>	
							</div>
							<div class="w136 model-k carInput">
								<el-input v-model="item.cxr" class="inputw124" @click.native="screenCXR('car',index)" readonly></el-input>
							</div>
							<div class="w136 model-k carInput">
								<el-input v-model="item.dj" class="inputw124" @change="getDj(item,index)"></el-input>
							</div>
							<div class="w136 model-k carInput">
								<el-input v-model="item.fyys" class="inputw124" disabled readonly></el-input>
							</div>
							<div class="w136 model-k carInput">
								<el-input :disabled="item.against==0" v-model="item.wbyy"  class="against"></el-input>
							</div>
							<div class="w59 model-k setRemove" @click="removeCurrent(index,3,item)">
								删除
							</div>
						</div>
					</div>
				</div>
				
				<div class="setPad">
					<div class="flex between">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">其他备注</span>
							<span class="setText">填写其他交通方式，发生日期，费用说明等</span>
						</div>
					</div>
					<el-input  type="textarea" class="marT17" v-model="remarks"></el-input>
				</div>
				<div class="setPad">
					<div class="flex between">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">费用合计</span>
						</div>
					</div>
					<div class="ys-main">
						<div class="ys-header">
							<div>交通预算(元)</div>
							<div>住宿预算(元)</div>
							<div>其他预算(元)</div>
						</div>
						<div class="ys-content">
							<div class="w114">
								<el-input suffix-icon="el-icon-coin" v-model="ysInfo.jtys"></el-input>
							</div>
							<div class="w114">
								<el-input suffix-icon="el-icon-coin" v-model="ysInfo.zsys"></el-input>
							</div>
							<div class="w114">
								<el-input suffix-icon="el-icon-coin" v-model="ysInfo.qtys"></el-input>
							</div>
						</div>
						<div class="ys-footer">
							<span class="footer2">{{zys}}元</span>
							<span class="footer1">预计本次出差费用</span>
							
						</div>
					</div>
				</div>
				<div class="setPad">
					<div class="flex between">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">审批事项</span>
						</div>
					</div>
					<!-- <approval :ccrInfo="ccrInfo" :approvalId="detailAppId" :formInfo="formInfo" @approvalInfo="chooseApproval" @approvalList="getApprovalList"></approval> -->
					
					<approval :ccrInfo="ccrInfo" :approvalPropObj="{approvalId: detailAppId}" :initQuery="ccsqdId? false: true" :formInfo="formInfo" @approvalInfo="chooseApproval" @approvalList="getApprovalList"></approval>
				</div>
				<div class="setPad" v-if="showApproval" style="padding-top:0;">
					<div class="flex between">
						<div>
							<span class="setTitleLeft"></span>
							<span class="setTitle">审批流程</span>
						</div>
					</div>
					<yj-approval-rule-detail style="padding:20px 0;" :appId="appId" :detail="appDetail" :sendPerson="sendPerson" ></yj-approval-rule-detail>
				</div>
				<div class="borderB-F0"></div>
				<div class=" setPadTop">
					<el-button type="primary" :loading="loading && loadingStatus==1" class="setBtn1" plain @click="saveAndApproval(1)">
						保存草稿
					</el-button>
					<el-button type="primary" :loading="loading && loadingStatus==2" class="setBtn2" @click="saveAndApproval(2)">
						保存并送审
					</el-button>
				</div>
			</el-card>
		</div>
		<!-- 选择出行人 -->
		<el-dialog title="选择出行人" :close-on-click-modal="false" :visible.sync="cxrVisible" class="cxrDialog" >
			<div class="cxr-con">
				<el-checkbox-group v-model="checkCxrList">
					<!-- 出行人可能包含常旅客， 此处用出行人id-->
				    <el-checkbox v-for="item in currentCxrList" :key="item.empId" :label="item.empId" class="cxr-content">
						<span>姓名：</span>
						<span style="width: 200px;">{{item.cxrName || item.empName}}</span>
						<span>电话：</span>
						<span style="width: 200px;">{{item.phoneNumber}}</span>
						<span>部门：</span>
						<span style="width: 200px;">{{item.deptName}}</span>		
					</el-checkbox>
				</el-checkbox-group>
			</div>
			<div slot="footer" class="dialog-footer">
			    <el-button type="primary" @click="determineCxr(mkType)">确 定</el-button>
			</div>
		</el-dialog>
		<!-- 火车票查询	 -->
		<div class="tr-search" v-if="searchTrVisible">
			<div class="dialog-main">
				<div class="dialogTitle">
					<span class="font18">火车票查询</span>
					<span class="el-icon-close iconClose" @click="closeSearch"></span>
				</div>
				<div class="searchTop">
					<div class="searchCon">
						<span class="label">出发城市:</span>
						<el-input v-model="trInfo.depCity" :disabled="true"></el-input>
					</div>
					<div class="iconhc iconfont yj-icon-train2huoche">
					</div>
					<div class="searchCon">
						<span class="label">到达城市:</span>
						<el-input v-model="trInfo.arrCity" :disabled="true"></el-input>	
					</div>
					<el-date-picker
						type="date"
						v-model="trInfo.depDate"
						value-format="yyyy-MM-dd"
						:disabled="true"
						:picker-options="pickerOptions(ccsqdRange)"
						class="searchDate"
						placeholder="选择日期">  
					</el-date-picker>
					<el-button type="primary" class="button"  @click="searchTr(2)">
						<span v-show="trQueryStatus != 1">搜索您的车次</span>
						<span v-show="trQueryStatus == 1"><span class="el-icon-loading"></span>搜索中...</span>
					</el-button>
				</div>
				<div class="search-content">
					<train-list-loading :loading="trQueryStatus==1 && allLoading"></train-list-loading>
					<!-- 新增出差申请单搜索 不显示违背 -->
					<train-query-list v-if="trQueryStatus>1 || !allLoading" :trCityQuery="trCityQuery" :showDate="false" :ccsqdSearch="true"  @close="closeTrainInfo"></train-query-list>
				</div>			
			</div>	
		</div>
	
		<!-- 机票航班搜索弹窗 -->
		<div class="tch-search" v-if="searchTkVisible">
			<div class="search-main">
				<div class="searchTitle">
					<span class="font18">航班查询</span>
					<span class="el-icon-close iconClose" @click="closeSearch"></span>
				</div>
				<div class="searchTop">
					<div class="searchCon">
						<span class="label">出发城市:</span>
						<el-input v-model="tkInfo.depCity" :disabled="true"></el-input>
					</div>
					<div class="iconfont yj-icon-jh_aircraft" style="font-size: 24px; color:#00A7FA;line-height: 45px;margin: 0 15px;">
					</div>
					<div class="searchCon">
						<span class="label">到达城市:</span>
						<el-input v-model="tkInfo.arrCity" :disabled="true"></el-input>	
					</div>
					<el-date-picker
						type="date"
						v-model="tkInfo.depDate"
						value-format="yyyy-MM-dd"
						:disabled="true"
						:picker-options="pickerOptions(ccsqdRange)"
						class="searchDate"
						placeholder="选择日期">  
					</el-date-picker>
					<el-button type="primary" class="button" :loading="loadingFlightList==1" @click="searchTk">
						<span v-show="loadingFlightList != 1">搜索</span>
						<span v-show="loadingFlightList == 1">搜索航班中...</span>
					</el-button>
				</div>
				<div class="list-main">
					<flight-list
					    v-if="flightList.length>0 && loadingFlightList > 1"
						:flightList="flightList"
						:flightMap="flightMap" 
						:priceMap="flightMap.priceMap"
						:planeMap="flightMap.planeMap" 
						@chooseFlight="toChooseFlight">
					</flight-list>
					
					<el-empty :image-size="200" v-show="loadingFlightList == 2 && flightList.length==0"></el-empty>
					
					<flight-list-loading :loading="loadingFlightList == 1"></flight-list-loading>
				</div>
			</div>
		</div>
	
		<!-- 入住酒店查询 -->
		<div class="ht-query" v-if="searchHtVisible">
			<div class="ht-main">
				<div class="searchTitle">
					<span class="font18">酒店查询</span>
					<span class="el-icon-close iconClose" @click="closeSearch"></span>
				</div>
				<ht-cn-search @searchHotel="searchHotel" :newCcsqdSearch="true" style="border-bottom: 3px solid #eef1f6;"></ht-cn-search>
				<hotel-list :search="isSearchHtList" @clearSearch="clearHtSearch" :cxrList="currentCxrList" @htInfo="htYDInfo"></hotel-list>
			</div>
		</div>	
	</div>
</template>

<script>
	import htCnSearch from '@/views/htCn/HtCnComponent/htCnSearch';
import FlightList from '@/views/tk/tkComponent/tkQuery/FlightList';
import FlightListLoading from '@/views/tk/tkComponent/tkQuery/FlightListLoading';
import TrainListLoading from '@/views/tr/trComponents/trQuery/TrainListLoading';
import TrainQueryList from '@/views/tr/trComponents/trQuery/TrainQueryList';
import { mapMutations, mapState } from 'vuex';
import Approval from './ccsqdModule/Approval';
import CcsqdHeader from './ccsqdModule/CcsqdHeader';
import HotelList from './ccsqdModule/HotelList';
	
	// 机票模块公共js文件
	import tkCommonJs from '@/views/tk/tkCommon/tkCommon.js';
	export default{ 
		name: 'NewCcsqd',
		data() {
			return {
				header:{ // 行程计划 ， 住宿安排 表格头部底色
					color:'#808080',
					background: '#F7F7F7',
					'text-align': 'center',
				},
				
				loading:false, //加载状态
				loadingStatus:0,  //草稿 与 送审
				proTypeList:[
					{
						name:'飞机',
						value:'10901',
					},
					{
						name:'火车',
						value:'10904',
					},
				],
				proTypeCode: '10901',//行程类型 ， 默认机票
				detailHeader:{}, // 详情头部信息
				cxrVisible:false, //出行人是否显示
				ccrInfo: {}, //出差人信息
				formInfo:{}, //出差项目 表单信息
				vipCostCenterList:[], // 乘车人成本中心集合列表
				currentIndex: 0,//当前类型索引
				
				jhIndex: 0, //获取选中 火车票 机票 行程索引
				trIndex: 0, //火车票索引
				htIndex: 0, //酒店索引
				tkIndex: 0, //机票索引
				carIndex: 0, //用车索引 
				
				trInfo:{
					depCity:'',
					depCitySzm:'',
					arrCity:'',
					arrCitySzm:'',
					depDate:'',
				}, //火车票查询信息
				tkInfo:{
					depszm:'',
					arrszm:'',
					depCity:'',
					arrCity:'',
					depDate:'',
				}, //机票查询信息
				htInfo:{
					depCity:'',
					depCitySzm:'',
					arrCity:'',
					arrCitySzm:'',
					depDate:'',
					cityId:'',
				}, //酒店查询信息
				htSearchList:[],
				jdType:1,
				cfSearch: false, //重复搜索
				searchTrVisible: false, //火车票搜索
				searchTkVisible: false, //机票搜索
				searchHtVisible: false, //酒店搜索
				vipRouteList: [], //机票 火车票列表
				viphtRouteList: [], //酒店列表
				vipCarRouteList: [], //用车列表
				loadingFlightList: 0, // 加载航班列表   0 ：未查询  1：正在查询  2.查询有结果 3. 查询无结果 4.查询异常
			
				// 航班列表
				flightList:[],
				flightMap:{},
				
				isSearchHtList: false, // 是否搜索酒店列表
				
				currentCxrList: [], // 当前出行人列表(所有出行人)	
				checkCxrList:[], // 选中出行人列表 (选中的出行人)	
				
				remarks:'', //其他备注
				
				vipPersonList:[], // 当前出行人 
				xcxh:0, //行程序号
				vipRouteInfo: {
					against: 0, // 是否违背
					arriveAirport: "", //: 到达机场/车站三字码 
					arriveAirportName: "", //到达机场/车站 ,
					arriveCity: "", //到达城市id 
					arriveCityName: "", //到达城市
					arriveDate: "", //到达日期/离店日期 
					arriveTime: "", //到达时间
					cabin: "", //舱位/席别/房型
					cxr: '', //出行人使用逗号分割
					cxrId: '', //出行人id使用逗号分割
					departAirport: "", //出发机场/车站三字码
					departAirportName: "", //出发机场/车站 
					departCity: "", //出发城市/入住城市id
					departCityName: "", //出发城市/入住城市
					departDate: "", // 出发日期/入住日期
					departTime: "", //出发时间 
					dj: 0, //单价
					fjs: 1, //房间数
					flightNo: "", //航班号/车次/酒店名称
					fyys: 0, //费用预算
					// isChange: "", // 是否变更
					proType: '', //产品代码来自b_class
					routeId: "", //行程id修改变更单时 
					// routeStatus: "", //行程状态
					travelStandard: "", //出差标准
					violateitem: "", //违背事项说明
					violateitemCode: "", //违背事项代码
					wbyy: "", //违背原因备注说明
					xcxh: this.xcxh, //行程序号
					yroId: "", //原行程id
					depHtName:'',
					depJHName:'',
					arrJHName:'',
					depCarName:'',
				}, // 一条出行人行程信息

				
				hcpys: 0, // 火车票预算
				ycys: 0, // 用车预算
				jpys: 0, // 机票预算
				// zys: 0, //总预算时
				ysInfo:{ // 预算
					qtys: 0,
					jtys: 0,
					zsys: 0,
				},
				appId:'', // 选择审批规则id
				detailAppId:'', // 出差申请单详情 审批id
				ccsqdDateArr:[], // 出差申请单日期
				appDetail:{ // 审批人层层级
					currentLevel:0,
				},
				sendPerson:{ //送审人信息
					empList: [],
					level: 1,
				}, 
				showApproval: false, //是否显示审批详情
				
				
				hotelDateStartRange:'', // 当酒店选择相同日期是控制日期范围
				hotelDateEndRange:'', // 当酒店选择相同日期是控制日期范围
				
				hotelDay:1, // 酒店入住天数，默认为1
				mkType:'', // 模块类型
				
				repeatFlag:0, //保存出差申请单前验证是否重复订单
				
				delRouteIdsList:[], // 删除行程id列表
				
				routeLength:0, //行程长度
				carDay:1, //用车天数，默认为1
				detailCxrList:[],
				
				trCityQuery:{}, // 查询的火车票数据城市
				
				approvalPropObj: {}, // 审批流子组件所传的值
			}
		},
		computed:{
			...mapState({
				searchData: state => state.train.trainQuery.searchData, // 查询搜索火车票数据
				tkQuery: state => state.tk.tkQuery, // 机票查询数据
				htCnParams: state => state.htCn.htCnParams,	
			}),
			userInfo(){
				let userInfo = this.$common.getUserInfo() || {};
				return userInfo;
			},
			empInfo(){
				return this.userInfo.empInfo || {};
			},
			vipCorp(){
				return this.userInfo.vipCorp || {};
			},
			// 显示项目
			showProject(){
				let param = this.$common.getVipParamterByParNo(this.$store,20003,3);
				return param==1 && this.tripType==1;
			},
			// 显示成本中心
			showCostCenter(){
				let param = this.$common.getVipParamterByParNo(this.$store,20002,3);
				return param==1 && this.tripType==1;
			},			
			// 出差项目 是否开启必填 0不开启  1开启
			projectRequired(){
				let param = this.$common.getVipParamterByParNo(this.$store,10996,3);
				return param==1  && this.tripType==1 && this.showProject
			},
			 // 成本中心 是否开启必填 0不开启  1开启
			costCenterRequired(){
				let param = this.$common.getVipParamterByParNo(this.$store,10995,3);
				return param==1 && this.tripType==1 && this.showCostCenter
			},
			// 出差申请单 费用预算是否必填 0否 1填  必填控制时费用预算必须大于0
			ccsqdYs(){
				let param = this.$common.getVipParamterByParNo(this.$store,10998,3);
				return param
			},
			query(){
				return this.$route.query || {};
			},
			ccsqdId(){
				return this.query.ccsqdId;
			},
			// 火车票查询状态
			trQueryStatus(){
				return this.searchData.trQueryStatus;
			},
			// 全部加载
			allLoading(){
				return this.searchData.allLoading;
			},	
		

            zys:{
                get: function () {
                  	let price = 0; //一个计算属性，如果没有设置 setter,也就是传入的是一个函数，或者传入的对象里没有 set 属性，当你尝试直接该改变这个这个计算属性的值，都会报 was assigned to but it has no setter。
                    price =  this.$common.addition(this.ysInfo.jtys,this.ysInfo.zsys,this.ysInfo.qtys) 
                    return price
                },
                set: function (value) {
                }
            },
			ccsqdRange(){
				let arr = this.$refs['ccsqdHeader']['ccsqdDateRange'] || [];
				return arr
			},
		},
		components:{
			'ccsqd-header':CcsqdHeader,
			'approval':Approval,
			'train-list-loading':TrainListLoading,
			'train-query-list':TrainQueryList,
			'flight-list':FlightList,
			'flight-list-loading':FlightListLoading,
			'ht-cn-search':htCnSearch,
			'hotel-list':HotelList,
		},
		mounted() {
			// 如果有出差申请单id 则为修改
			if(this.ccsqdId){
				this.queryCcsqd(this.ccsqdId)
			}
			let obj = {
					empId: this.empInfo.empId,
					empName:  this.empInfo.empName,
					empNo:  this.empInfo.empNo,
					vipdeptName: this.empInfo.deptName,
					ssr:1, // 送审人 传1 其他不是
				};
			this.sendPerson.empList.push(obj);
			
		},
		methods: {
			...mapMutations({
				SET_TrainData: 'train/setTrainData',
				SET_SearchData:'train/setSearchData',
				SET_TkQuery: 'tk/setTkQuery',
				SET_HTCN_PARAMS:'htCn/SET_HTCN_PARAMS',
			}),
			// 出差申请单日期范围控制
			pickerOptions(ccsqdRange,canMap = {}){
				
				let min = canMap.min;
				let max = canMap.max;
				let minDay = ccsqdRange[0];
				let maxDay = ccsqdRange[1];
				minDay = min?(min>minDay?min:minDay):minDay;
				maxDay = max?(max>maxDay?maxDay:max):maxDay;
				return { // 默认当天之前 日期不能选择
						disabledDate: (time) => {
							let minTime = new Date(new Date(minDay).toLocaleDateString()).getTime();
							let maxTime = new Date(new Date(maxDay).toLocaleDateString()).getTime();
							return time.getTime() < minTime  || time.getTime() > maxTime;
						}
				}
				
			},
			// 出差申请单 详情 当有id时，表示编辑
			queryCcsqd(ccsqdId){
				this.vipRouteList = [];
				this.viphtRouteList = [];
				this.vipCarRouteList = [];
				let queryString = {
						ccsqdId:ccsqdId
						}
				this.$conn.getConn('travelRequest.travelRequestInfo')(queryString,(res)=>{
					let data = res.data || {};
					let list = data.allRouteList;
					this.xcxh = list.length;
					for(let i=0; i<list.length; i++){
						if(list[i][0].proType==10901 || list[i][0].proType==10904){
							list[i][0].yroId = list[i][0].routeId;
							list[i][0].checkCxrList = list[i][0].cxrId.split(',');
							this.vipRouteList.push(list[i][0]);
						}else if(list[i][0].proType==10903){
							list[i][0].yroId = list[i][0].routeId;
							list[i][0].dateList = [list[i][0].departDate,list[i][0].arriveDate];
							list[i][0].checkCxrList = list[i][0].cxrId.split(',');
							this.viphtRouteList.push(list[i][0])
						}else if(list[i][0].proType==10906){
							list[i][0].yroId = list[i][0].routeId;
							list[i][0].dateList = [list[i][0].departDate,list[i][0].arriveDate]
							list[i][0].checkCxrList = list[i][0].cxrId.split(',');
							this.vipCarRouteList.push(list[i][0])
						}
					}
					this.currentCxrList = data.personList;
					this.detailCxrList = data.personList;
					this.routeLength = this.vipRouteList.length + this.viphtRouteList.length + this.vipCarRouteList.length;
					
					this.formInfo = {
						ccsqdId:this.$route.query.ccsqdId,
						ccsqdNo:data.ccsqdNo,
						ccr: data.ccr,
						ccsy: data.ccsy,
						costCenterCode: data.costCenterCode,
						costCenterId: data.costCenterId,
						costCenterName: data.costCenterName,
						dateBegin: data.dateBegin,
						dateEnd: data.dateEnd,
						mdd: data.mdd,
						project: data.project,
						projectCode: data.projectCode,
						projectId: data.projectId,
						projectName: data.projectName,
						sxr: data.sxr,
					}

					this.vipPersonList = data.personList;  // 出行人信息列表
					this.vipCostCenterList = data.costCenter; // 选择的成本中心列表
					this.detailHeader = {
						appId: data.appId, //审批规则id ,
						ccsy: data.ccsy, //出差事由
						ccr: data.ccr, //出差人 ,
						ccsqdNo: data.ccsqdNo,// 单号
						ccsqdId:  data.id,// 出差申请单id
						costCenterCode: data.costCenterCode, // 成本中心编码
						costCenterId: data.costCenterId, // 成本中心id
						costCenterName: data.costCenterName, //默认成本中心
						dateBegin: data.dateBegin, //出差日始
						dateEnd: data.dateEnd, //出差日止
						mdd: data.mdd, //目的地
						project: data.project, //是否是出差项目
						projectCode: data.projectCode, //出差项目编码
						projectId: data.projectId, // 出差项目id
						projectName: data.projectName, // 出差项目名称
						sxr: data.sxr, //随行人 
						reqTime: data.reqTime, // 申请时间
					}
					this.remarks = data.remark;
					this.hcpys = data.hcpys;
					this.jpys = data.jpys;
					this.ycys = data.ycys;
					this.detailAppId = data.appId;
					this.ysInfo = {
						qtys: data.qtys,
						jtys: data.jtys,
						zsys: data.zsys,
					}
				},(err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 筛选出行人
			screenCXR(type,index){
				if(type=='ht'){
					this.mkType = 'ht';
					this.checkCxrList = this.viphtRouteList[index].checkCxrList;
				}else if(type=='tk'){
					this.mkType = 'tk';
					this.checkCxrList = this.vipRouteList[index].checkCxrList;
				}else if(type=='tr'){
					this.mkType = 'tr';
					this.checkCxrList = this.vipRouteList[index].checkCxrList;
				}else if(type=='car'){
					this.mkType = 'car';
					this.checkCxrList = this.vipCarRouteList[index].checkCxrList;
				}
				this.cxrVisible = true;
				
			},
		
			
			// 出行人数据
			selectCxrList(list){
				this.vipCostCenterList = []; //成本中心列表
				this.vipPersonList = [];     //出行人数据列表
				this.currentCxrList = list;  //当前出行人列表
				this.checkCxrList = [];      //出行人id集合列表
				this.currentCxrList.map((item)=>{
					// 出行人 id 处理
					if(item.contactsId){
						item.empId = item.contactsId
						this.checkCxrList.push(item.contactsId)
					}else{
						this.checkCxrList.push(item.empId)
					}
					
					let obj = {}; //成本中心对象
						obj.ccr = item.cxrName || item.empName;
						obj.ccsqdNo = item.ccsqdNo;
						obj.corpId = item.corpId;
						obj.costCenterCode = item.costCenterCode;
						obj.costCenterName = item.costCenterName;
						obj.costId = item.costCenterId;
						
						this.vipCostCenterList.push(obj) 
					let info = {}; //出行人信息
						info.cxrName = item.cxrName || item.empName;
						info.deptName = item.deptName;
						info.empId = item.cxrType==2?item.contactsId:item.empId;
						info.empName = item.empName;
						info.empNo = item.empNo;
						info.empRank = item.empRank;
						info.empRankName = item.empRankName;
						info.exPerson = item.contactsId?1:0; //cxrType 1员工  2常旅客 
						info.personId = '' // 此处处理常旅客id
						info.sxr = item.sxr==0?0:1;
						this.vipPersonList.push(info);
					return item	
				})
				this.vipRouteList = [];
				this.viphtRouteList = [];
				this.vipCarRouteList = [];
				this.xcxh = 1;
				this.ysInfo = { // 预算
					qtys: 0,
					jtys: 0,
					zsys: 0,
				}
			},
			// 出行人重置处理
			determineCxr(type){
				let list = [];
				let empNameList = [];
				let empIdList = [];
				if(this.checkCxrList.length==0){
					this.$confirm('出行人不能为空！', '提示', {
					    confirmButtonText: '确定',
					    cancelButtonText: '取消',
					    type: 'warning'     
					})
					return false
				}

				this.currentCxrList.forEach((item)=>{
					if(this.checkCxrList.indexOf(item.empId)>-1){
						empNameList.push(item.empName || item.cxrName);
						empIdList.push(item.empId);
					}
				})
				this.vipRouteInfo.cxr = empNameList.length>0?empNameList.join(","):'';
				this.vipRouteInfo.cxrId = empIdList.length>0?empIdList.join(","):'';
				
				if(type == 'ht'){
					this.viphtRouteList[this.htIndex].cxr = this.vipRouteInfo.cxr ;
					this.viphtRouteList[this.htIndex].cxrId = this.vipRouteInfo.cxrId ;
					this.viphtRouteList[this.htIndex].fjs = this.checkCxrList.length;
					this.viphtRouteList[this.htIndex].checkCxrList =  this.checkCxrList;
					this.getFjs(this.viphtRouteList[this.htIndex],this.htIndex);
				}else if(type == 'tk'){	
					this.vipRouteList.map((item,index)=>{
						if(index == this.tkIndex){
							item.cxr = this.vipRouteInfo.cxr ;
							item.cxrId = this.vipRouteInfo.cxrId ;
							item.checkCxrList = this.checkCxrList;
						}
						return item
					})
					this.getDj(this.vipRouteList[this.tkIndex], this.tkIndex);
				}else if(type == 'tr'){
					this.vipRouteList[this.trIndex].cxr = this.vipRouteInfo.cxr ;
					this.vipRouteList[this.trIndex].cxrId = this.vipRouteInfo.cxrId ;
					this.vipRouteList[this.trIndex].checkCxrList =  this.checkCxrList;
					this.getDj(this.vipRouteList[this.trIndex], this.trIndex);
				}else if(type == 'car'){
					this.vipCarRouteList[this.carIndex].cxr = this.vipRouteInfo.cxr ;
					this.vipCarRouteList[this.carIndex].cxrId = this.vipRouteInfo.cxrId ;
					this.vipCarRouteList[this.carIndex].checkCxrList =  this.checkCxrList;
					this.getDj(this.vipCarRouteList[this.carIndex], this.carIndex);
				}
				
				this.cxrVisible = false;
			},
			// 表单头部所有数据
			allFormData(form){
				this.formInfo = form;
			},
			// 出差申请单日期 控制酒店日期取值
			getCcsqdDateInfo(dateArr){
				this.ccsqdDateArr = dateArr;
				if(dateArr.length == 2){
					this.hotelDateStartRange = dateArr[0];
					this.formInfo.dateBegin = dateArr[0];
					this.formInfo.dateEnd = dateArr[1];
					this.hotelDateEndRange = this.$common.getAppointDay(dateArr[0],1)
					
				}
			},
			// 获取酒店日期，操作主动选择相同日期 时 重置入住离店日期 
			getHotelDate(item, index){
				var arriveDate = new Date(item.dateList[1]);
				var departDate = new Date(item.dateList[0]);
				var startTime = departDate.getTime();
				var endTime = arriveDate.getTime();
				if(startTime == endTime){
					this.$alert('酒店入住离店日期相同','',{
						confirmButtonText: '确定',
						type:'warning',
						callback: action => {
						    this.viphtRouteList[index].arriveDate = this.hotelDateEndRange;
							this.viphtRouteList[index].departDate = this.hotelDateStartRange; 
						}
					})
				}
				this.viphtRouteList[index].arriveDate = item.dateList[1];
				this.viphtRouteList[index].departDate = item.dateList[0]; 
				this.viphtRouteList = this.$common.deepCopy(this.viphtRouteList)
				this.hotelDay = this.$common.getDiffDay(item.dateList[0],item.dateList[1])
				if(item.dj){
					this.getDj(item, index)
				}

			},
			// 获取用车日期
			getCarDate(item, index){
				var arriveDate = new Date(item.dateList[1]);
				var departDate = new Date(item.dateList[0]);
				
				this.vipCarRouteList[index].arriveDate = item.dateList[1];
				this.vipCarRouteList[index].departDate = item.dateList[0]; 
				this.vipCarRouteList = this.$common.deepCopy(this.vipCarRouteList)
				this.carDay = this.$common.getDiffDay(item.dateList[0],item.dateList[1])+1;
				
				if(item.dj){
					this.getDj(item, index)
				}
			
			},
			// 出差项目头部信息 form 是表单信息  info 为补充信息
			ccProjectInfo(form,info){
				this.formInfo = form;
				this.ccrInfo = this.$common.deepCopy(info);
			},
			// 审批规则信息
			getGznrData(data){
				console.log(data)
			},
			// 出差人信息
			ccrFormInfo(info){
				this.ccrInfo = this.$common.deepCopy(info);
			},
			// 选择出发城市
			chooseDepCity(info){
				let list = [];
				let obj = {};
				list = this.vipRouteList;
				list.map((item,i)=>{
					if(i==this.jhIndex){
						item.departAirport = info.threeCode;
						item.departCityName = info.airportName || info.cityName;
						item.departCity = info.csbh?info.csbh:info.id || info.cityId;
					}
					return item
				})
				obj = list[0];
				this.$set(this.vipRouteList,0,obj)
				
			},
			// 选择到达城市
			chooseArrCity(info){
				let list = [];
				let obj = {};
				list = this.vipRouteList;
				list.map((item,i)=>{
					if(i==this.jhIndex){
						item.arriveAirport = info.threeCode;
						item.arriveCityName = info.airportName || info.cityName;
						item.arriveCity = info.csbh?info.csbh:info.id || info.cityId;
					}
					return item
				}); 
				obj = list[0];
				this.$set(this.vipRouteList,0,obj)
			},
			
			// 用车城市
			checkCarCity(val){
				let list = [];
				let obj = {};
				list = this.vipCarRouteList;
				list.map((item,i)=>{
					if(i==this.carIndex){
						item.departCityName = val.cityName || val.airportName;
						item.departCity = val.csbh?val.csbh:val.id || val.cityId;
					}
					return item
				})
				obj = list[0];
				this.$set(this.vipCarRouteList,0,obj);
			},
			// 查询当前是机票 火车票
			queryTkTrIndex(index){
				this.jhIndex = index;	
			},
			// 机票，火车票增加行程行息
			addVipRouteTkTr(){
				let empNameList = [];
				let empIdList = [];
				this.currentCxrList.forEach(item=>{
					if(item.sxr==0){
						empNameList.push(item.empName);
						empIdList.push(item.empId);
					}else if(item.sxr==1){
						empNameList.push(item.cxrName || item.empName);
						if(this.ccsqdId){
							empIdList.push(item.empId);
						}else{
							if(item.contactsId){
								empIdList.push(item.contactsId);
							}else{
								empIdList.push(item.empId);
							}
							
						}
					}
					
				})
				this.checkCxrList = empIdList;
				this.proTypeCode = '10901';
				this.xcxh++ ;
				this.vipRouteInfo = {
					cxr: empNameList.length>0?empNameList.join(','):'', //出行人使用逗号分割
					cxrId: empIdList.length>0?empIdList.join(','):'', //出行人id使用逗号分割
					proType: '10901', //产品代码来自b_class
					xcxh: this.xcxh, //行程序号
					departDate: this.ccsqdRange.length>0?this.ccsqdRange[0]:this.$common.getToDay(),
					arriveDate:"",
					// arriveDate: this.ccsqdRange.length>0?this.ccsqdRange[1]:this.$common.getAppointDay(this.$common.getToDay(),1),
					against: 0,
					violateitem: "",
					violateitemCode: "",
					wbyy: "",
					// isChange: "",
					routeId: "",
					// routeStatus: "",
					checkCxrList:this.checkCxrList, //暂存出行人 信息， 提交时删除
				}
				this.trInfo.depDate = this.vipRouteInfo.departDate;
				this.tkInfo.depDate = this.vipRouteInfo.departDate;
				let obj = this.$common.deepCopy(this.vipRouteInfo)
				this.vipRouteList.push(obj);	
				this.routeLength = this.vipRouteList.length + this.viphtRouteList.length + this.vipCarRouteList.length;
			},
			//获取火车票列表行息
			getTrList(item,index){
				this.trIndex = index;
				this.searchTrVisible = true;
				var errMsg = "";
				if(!item.departAirport){
					errMsg = "请选择出发城市"
				}else if(!item.arriveAirport){
					errMsg = "请选择到达城市"
				}else if(!item.departDate){
					errMsg = "请选择出发时间"		
				}else if(item.departDate){
					let departDate = new Date(item.departDate);
					let startDate = new Date(this.ccsqdDateArr[0])
					let endDate = new Date(this.ccsqdDateArr[1])
					if(startDate.getTime()>departDate.getTime() || departDate.getTime()>endDate.getTime()){
						errMsg = '查询日期不在出差申请单范围内'
					}
					
				}
				
				if(errMsg){
					this.$message.error(errMsg)
					return
				}
				this.trInfo.depCitySzm = item.departAirport;
				this.trInfo.arrCitySzm = item.arriveAirport;
				this.trInfo.depCity = item.departCityName;
				this.trInfo.arrCity = item.arriveCityName;
				this.trInfo.depDate = item.departDate;
				
				this.trCityQuery = {
					depCity: item.departCityName,
					arrCity: item.arriveCityName,
				}
				let obj = {
					depCity: item.departCityName, //出发城市
					arrCity: item.arriveCityName, //到达城市
					depDate: item.departDate,
				}
				this.SET_SearchData(obj)
				this.searchTr(1)
				
			},
			
			// 获取火车票列表
			searchTr(num){
				if(this.cfSearch){
					return
				}
				// num 传1 全局加载 2 局部加载 
				this.SET_SearchData({
					trQueryStatus: 1,
					allLoading: num==1?true:false,
					partLoading: num==1?false:true,
					searchNum: num==1?0:1,
				})
				
				let queryString = {
					byTrainDate: this.trInfo.depDate,
					ccsqdId: '',
					ccsqdRouteId: '' ,
					fromStationCode: this.trInfo.depCitySzm,
					minRank: 1,
					//allTrains: true, 
					toStationCode: this.trInfo.arrCitySzm,
					//includePrice: true, //是否包含票价 
					tripType: 2,
				}
				this.$conn.getConn('trBook.trSearch')(queryString,(res)=>{
					let data = res.data || {};
					if(data.trainList.length>0){ // 查询有结果
						this.SET_SearchData({
							trQueryStatus: 2,
							partLoading:true,
							searchNum:1,
						})
					}else{ // 查询无结果
						this.SET_SearchData({
							trQueryStatus: 3,
							partLoading:true,
							searchNum:1,
						})
					}
					this.SET_TrainData({
						trainData: data
					})
					this.cfSearch = false;			
				},(err)=>{
					this.$message.error(err.tips)
					this.SET_SearchData({
						trQueryStatus: 4,
						partLoading:false,
						searchNum:0,
					})
				})
			},
			// 关闭火车票页面 , 选中火车票信息
			closeTrainInfo(bool,info){
				this.vipRouteList.map((item,index)=>{
					if(this.trIndex == index){
						item.against = info.selectSeat.wb;
						item.flightNo = info.trainCode;
						item.cabin = info.selectSeat.seatName;
						item.dj = info.selectSeat.price;
						item.fyys = info.selectSeat.price * this.checkCxrList.length;
						item.departAirportName = info.fromStationName;
						item.departTime = info.startTime;
						item.arriveAirportName = info.toStationName;
						item.arriveTime = info.arriveTime;
						item.arriveDate = this.$common.getAppointDay(item.departDate,info.arriveDays);
					}
					return item
				})
				this.searchTrVisible = bool;
				let hcppriceList = [];
				if(this.vipRouteList.length>0){
					let list = [];
						list = this.vipRouteList;
					for(var i=0 ; i<list.length; i++){
						if(list[i].fyys){
							hcppriceList.push(list[i].fyys)
							
						}
						
					}
				}
				this.ysInfo.jtys = this.$common.addition(hcppriceList,this.ycys)
				this.hcpys = this.$common.addition(hcppriceList)
			},
			// 酒店增加行程
			addVipRouteHotel(){
				let empNameList = [];
				let empIdList = [];
				this.checkCxrList = [];
				this.currentCxrList.forEach(item=>{
					if(item.sxr==0){
						empNameList.push(item.empName);
						empIdList.push(item.empId);
					}else if(item.sxr==1){
						empNameList.push(item.cxrName || item.empName);
						if(this.ccsqdId){
							empIdList.push(item.empId);
						}else{
							if(item.contactsId){
								empIdList.push(item.contactsId);
							}else{
								empIdList.push(item.empId);
							}
							
						}
					}
				})
				this.checkCxrList = empIdList;
				let toDay =  this.ccsqdRange.length>0?this.ccsqdRange[0]:this.$dateTime.getToDay(); //今天
				let arrDay = this.$dateTime.getAppointDay(toDay,1); //指定天数
				this.xcxh++ ;
				this.vipRouteInfo = {
					cxr: empNameList.length>0?empNameList.join(','):'', //出行人使用逗号分割
					cxrId: empIdList.length>0?empIdList.join(','):'', //出行人id使用逗号分割
					proType: '10903', //产品代码来自b_class
					xcxh: this.xcxh, //行程序号
					departDate: toDay,
					arriveDate: arrDay,
					against: 0,
					violateitem: "",
					violateitemCode: "",
					wbyy: "",
					// isChange: "",
					routeId: "",
					// routeStatus: "",
					departCityName:'',
					dateList:[toDay,arrDay],
					checkCxrList:this.checkCxrList, //暂存出行人 信息， 提交时删除
				}
				
				let obj = this.$common.deepCopy(this.vipRouteInfo);
				
				this.viphtRouteList.push(obj);
				// this.viphtRouteList = this.$common.deepCopy(this.viphtRouteList);
				this.routeLength = this.vipRouteList.length + this.viphtRouteList.length + this.vipCarRouteList.length;
			},
			// 搜索酒店信息
			getHtList(item,index){
				this.htIndex = index;
				this.getDiffDate(item.departDate,item.arriveDate,index)
				let errMsg = '';
				if(!this.viphtRouteList[index].departCity){
					errMsg = '请选择入住城市';
				}else if(!item.departDate){
					errMsg = '请选择入住时间';
				}else if(!item.arriveDate){
					errMsg = '请选择离店时间';
				}else if(item.departDate && item.arriveDate){
					let departDate = new Date(item.departDate);
					let arriveDate = new Date(item.arriveDate);
					let startDate = new Date(this.ccsqdDateArr[0]);
					let endDate = new Date(this.ccsqdDateArr[1]);
					if(startDate.getTime()>departDate.getTime() || (arriveDate.getTime()<startDate.getTime() || arriveDate.getTime()>endDate.getTime())){
						errMsg = '查询日期不在出差申请单范围内'
					}
					
				}
				
				if(errMsg){
					this.$alert(errMsg,'',{
						type:'error'
					})
					return false
				}
				let params = {
					checkInDate: item.departDate, //日期开始时间
					checkOutDate: item.arriveDate, // 日期结束时间
					cityId: item.departCity,
					ccsqdCityName: item.departCityName,
				}
				this.SET_HTCN_PARAMS(params);
				this.searchHtVisible = true;
				this.searchHotel()
			},
			// 酒店城市
			checkHtCity(obj){
				this.viphtRouteList.map((item,i)=>{
					if(i==this.htIndex){
						item.departAirport = obj.threeCode;
						item.departCityName = obj.airportName || obj.cityName;
						item.departCity = obj.csbh?obj.csbh:obj.id || obj.cityId;
					}
					return item
				})
				let params = {
					ccsqdCityName:obj.cityName, // 城市名字
					cityId: obj.id, //城市或者商圈id
				}
				this.htInfo.cityId = obj.id;
				this.SET_HTCN_PARAMS(params)
			},
			// 搜索酒店
			searchHotel(){
				this.isSearchHtList = true;
			},
			// 清除搜索
			clearHtSearch(){
				this.isSearchHtList = false;
			},
			// 酒店预定信息
			htYDInfo(info){
				this.viphtRouteList.map((item, index)=>{
					if(this.htIndex == index){
						item.cabin = info.cabin;
						item.flightNo = info.flightNo;
						item.dj = info.dj;
						item.fjs = this.checkCxrList.length;
						item.fyys = ((info.dj * 1000) * this.checkCxrList.length * this.hotelDay)/1000;
					}
					return item
				})		
				let zsPriceList = [];
				if(this.viphtRouteList.length>0){
					let list = [];
						list = this.viphtRouteList;
					for(var i=0 ; i<list.length; i++){
						if(list[i].fyys){
							zsPriceList.push(list[i].fyys) 
						}
						
					}
				}
				this.ysInfo.zsys = this.$common.addition(zsPriceList);
				this.searchHtVisible = false;	
			},
			// 用车增加行程
			addVipRouteCar(){
				let empNameList = [];
				let empIdList = [];
				this.checkCxrList = [];
				this.currentCxrList.forEach(item=>{
					if(item.sxr==0){
						empNameList.push(item.empName);
						empIdList.push(item.empId);
					}else if(item.sxr==1){
						empNameList.push(item.cxrName || item.empName);
						if(this.ccsqdId){
							empIdList.push(item.empId);
						}else{
							if(item.contactsId){
								empIdList.push(item.contactsId);
							}else{
								empIdList.push(item.empId);
							}
						}
					}
				})
				this.checkCxrList = empIdList;
				let toDay =  this.ccsqdRange.length>0?this.ccsqdRange[0]:this.$dateTime.getToDay(); //今天
				let arrDay = this.$dateTime.getAppointDay(toDay,1); //指定天数
				this.xcxh++ ;
				this.vipRouteInfo = {
					cxr: empNameList.length>0?empNameList.join(','):'', //出行人使用逗号分割
					cxrId: empIdList.length>0?empIdList.join(','):'', //出行人id使用逗号分割
					proType: '10906', //产品代码来自b_class
					xcxh: this.xcxh, //行程序号
					departDate: toDay,
					arriveDate: arrDay,
					against: 0,
					violateitem: "",
					violateitemCode: "",
					wbyy: "",
					// isChange: "",
					routeId: "",
					dateList:[toDay,arrDay],
					// routeStatus: "",
					checkCxrList:this.checkCxrList,
				}
				
				let info = this.$common.deepCopy(this.vipRouteInfo);
				this.vipCarRouteList.push(info);
				this.vipCarRouteList = this.$common.deepCopy(this.vipCarRouteList);
				this.routeLength = this.vipRouteList.length + this.viphtRouteList.length + this.vipCarRouteList.length;
			},

			// 查询当前用车城市
			queryCarIndex(index){
				this.carIndex = index;	
			},
			// 关闭机票 酒店 火车票 查询列表
			closeSearch(){
				this.searchTkVisible = false;
				this.searchTrVisible = false;
				this.searchHtVisible = false;
			},
			//获取机票信息
			getTkList(item,index){
				this.tkIndex = index;
				let errMsg = ''
				if(!item.departAirport){
					errMsg = '请选择出发城市'
				}else if(!item.arriveAirport){
					errMsg = '请选择到达城市'
				}else if(!item.departDate){
					errMsg = '请选择出发日期'
				}else if(item.departDate){
					let departDate = new Date(item.departDate);
					let startDate = new Date(this.ccsqdDateArr[0])
					let endDate = new Date(this.ccsqdDateArr[1])
					if(startDate.getTime()>departDate.getTime() || departDate.getTime()>endDate.getTime()){
						errMsg = '查询日期不在出差申请单范围内'
					}
					
				}
				this.tkInfo.depszm = item.departAirport;
				this.tkInfo.arrszm = item.arriveAirport;
				this.tkInfo.depCity = item.departCityName;
				this.tkInfo.arrCity = item.arriveCityName;
				this.tkInfo.depDate = item.departDate;
				if(errMsg){
					this.$message.error(errMsg)
					return
				}
				this.searchTkVisible = true;
				this.searchTk()
				
			},
			// 机票查询
			searchTk(){
				this.loadingFlightList = 1;
				let queryString = {
						arriveAirport: this.tkInfo.arrszm,
						departAirport: this.tkInfo.depszm,
						departDate: this.tkInfo.depDate,
						prdType: 'C', //G为政采票，C为普通票,
						sharFlight: 0,
						showStop: 0,
						tripType: 2,
				};
				this.$conn.getConn('tkBook.queryFlight')(queryString,(res)=>{
					let data = res.data || {};
					this.handleFlightListData(data)
				},(err)=>{
					this.$message.error(err.tips)
					this.loadingFlightList = 4;
				})
			},
			// 存储、并处理航班数据
			handleFlightListData(data){
				
				let flightList = this.$common.objToArr(data.flightMap || {});
				// 处理航班列表，重新排序格式
				let newFlightList = tkCommonJs.chuLiFlightCabinPriceArr(flightList, data.priceMap,data.planeMap,data.flightMap,{});
				
				this.flightList = newFlightList;
				this.flightMap = data.flightMap || {};
				
				// this.cacheId = data.cacheId;
				if(this.flightList.length>0){ // 查询有结果
					this.loadingFlightList = 2;
				}else{ // 查询无结果
					this.loadingFlightList =3;
				}
			},
			// 选择某一航班
			toChooseFlight(flightItem){
				let dj = 0;
				// let alldjList = [];
				dj = this.$common.addition(flightItem.chooseCabin.fcny,flightItem.tax, flightItem.airportFee,flightItem.chooseCabin.serviceFee);
				this.vipRouteList.map((item,index)=>{
					if(index == this.tkIndex){
						item.flightNo = flightItem.flightNo;
						item.cabin = flightItem.minFilterPriceCabinTypeName;
						item.dj = dj;
						item.fyys = ((dj * 1000) * this.checkCxrList.length)/1000;
						item.departAirportName = flightItem.departAirportName;
						item.departTime = flightItem.departTime;
						item.arriveAirportName = flightItem.arriveAirportName;
						item.arriveTime = flightItem.arriveTime;
						item.arriveDate = flightItem.arriveDate;
					}
					return item
				})
				this.searchTkVisible = false;
				let tkPriceList = [];
				if(this.vipRouteList.length>0){
					let list = [];
						list = this.vipRouteList;
					for(var i=0 ; i<list.length; i++){
						if(list[i].fyys){
							tkPriceList.push(list[i].fyys)	
						}
					}
				}
				this.ysInfo.jtys = this.$common.addition(tkPriceList,this.hcpys,this.ycys);
				this.jpys = this.$common.addition(tkPriceList);
			},
			
		
			//添加过渡动画
			getLoading(bool){
				this.loading = bool;
			},
			
			
			// 获取航班号，车次号 ，酒店房型
			getFlightNo(item,index){
				let list = [];
				if(item.proType=='10901' || item.proType=='10904'){
					list = this.vipRouteList;
					list[index].flightNo = item.flightNo;
					this.$set(this.vipRouteList,index,list[index])
					
				}else{
					list = this.viphtRouteList;
					list[index].flightNo = item.flightNo;
					this.$set(this.viphtRouteList,index,list[index])
				}

			},
			// 获取类型
			getCabin(item,index){
				let list = [];
				if(item.proType=='10901' || item.proType=='10904'){
					list = this.vipRouteList;
					list[index].cabin = item.cabin;
					this.$set(this.vipRouteList,index,list[index])
				}else{
					list = this.viphtRouteList;
					list[index].cabin = item.cabin;
					this.$set(this.viphtRouteList,index,list[index])
				}
			},
			// 获取单价  设置单价
			getDj(item,index){
				var carPriceList = [];
				var trPriceList = [];
				var tkPriceList = [];
				var htPriceList = [];
				let priceNum = /^(?:[1-9]\d*|0)(?:\.\d+)?$/;
				
				if(item.dj==0 || (priceNum.test(item.dj) || item.dj=="")){
					
					// 出行人id
					let cxrIdList =  item.cxrId.split(",");
					// // 出行人数
					let personNum = this.checkCxrList.length;
					// 费用预算
					
					
					if(item.proType=='10901' || item.proType=='10904'){
						this.vipRouteList[index].fyys = (personNum*(item.dj?item.dj:0));
						this.vipRouteList.forEach((ysItem,i)=>{
							if(ysItem.proType=='10901'){
								tkPriceList.push(ysItem.fyys)
							}else if(ysItem.proType=='10904'){
								trPriceList.push(ysItem.fyys)
							}
						})
						
						this.jpys = this.$common.addition(tkPriceList);
						this.hcpys = this.$common.addition(trPriceList);
					}else if(item.proType=='10903'){
						this.viphtRouteList[index].fyys = item.fjs?(item.fjs*(item.dj?item.dj:0)*this.hotelDay):(item.dj?item.dj:0)*this.hotelDay;
						this.viphtRouteList.forEach((ysItem)=>{	
							if(ysItem.fyys){
								htPriceList.push(ysItem.fyys) 							
							}
						})
						this.ysInfo.zsys =  this.$common.addition(htPriceList);
						
					}else if(item.proType=='10906'){
						this.vipCarRouteList[index].fyys = item.dj?item.dj*this.carDay:0;
						this.vipCarRouteList.forEach((ysItem)=>{
							if(ysItem.fyys){
								carPriceList.push(ysItem.fyys)
							}
						})
						this.ycys = this.$common.addition(carPriceList);
					}  					
					
					this.ysInfo.jtys = this.$common.addition(this.jpys,this.hcpys,this.ycys);	
				}
			},
			// 房间数
			getFjs(item,index){
				let list = [];
				if(item.fjs>this.checkCxrList.length){
					this.$message.warning('酒店房间数量不可大于出行人数量');
					list = this.viphtRouteList;
					list[index].fjs = this.checkCxrList.length;
					this.$set(this.viphtRouteList,index,list[index])
				}
				if(item.dj){
					list = this.viphtRouteList;
					list[index].fyys = (item.fjs * item.dj * this.hotelDay);
					this.$set(this.viphtRouteList,index,list[index])
				}else{
					list = this.viphtRouteList;
					list[index].fyys = 0;
					this.$set(this.viphtRouteList,index,list[index])
				}
			},
			getDiffDate(startDate,endDate,index){
				this.hotelDay = this.$common.getDiffDay(startDate,endDate)
				this.carDay = this.$common.getDiffDay(startDate,endDate)
			},
			// 选中审批规则
			chooseApproval(info,bool){
				this.appId = info.gzid;
				this.showApproval = bool;
			},
			// 获取审批规则列表
			getApprovalList(list, bool){
				this.showApproval = bool;
			},
			
			// 选中机票，火车票类型 （只使用与两个选项）
			chooseRouteTypeTop(code,index){
				
				for(let i=0; i<this.vipRouteList.length; i++){
					if(i==index){
						this.vipRouteList[i].proType = code;
						this.vipRouteList[i].departCityName = '';
						this.vipRouteList[i].arriveCityName = '';
						this.vipRouteList[i].flightNo = '';
						this.vipRouteList[i].cabin = '';
						break;
					}
				}
			},
			// 搜索输入触发 强制刷新页面 赋值出发/入住地址
			queryCityName(type,name,index){
				let list = [];
				if(type=='10901'){
					list = this.vipRouteList;
					list.map((item,i)=>{
						if(i==index){
							item.depJHName = name;
						}
						
						return item
					});
					this.vipRouteList = this.$common.deepCopy(list);
				}else if(type=='10904'){
					list = this.vipRouteList;
					list.map((item,i)=>{
						if(i==index){
							item.depJHName = name;
						}
						return item
					})
					this.vipRouteList = this.$common.deepCopy(list);
				}else if(type=='10903'){
					list = this.viphtRouteList;
					list.map((item,i)=>{
						if(i==index){
							item.depHtName = name;
						}
						return item
					})
					this.viphtRouteList = this.$common.deepCopy(list);
				}else if(type=='10906'){
					list = this.vipCarRouteList;
					list.map((item,i)=>{
						if(i==index){
							item.depCarName = name;
						}
						return item
					})
					this.vipCarRouteList = this.$common.deepCopy(list);
				}
			},
			// 强制刷新页面 赋值到达地址
			queryArrCityName(type,name,index){
				let list = [];
				let obj = {};
				list = this.vipRouteList;
				list.map((item,i)=>{
					if(i==index){
						item.arrJHName = name;
					}
					return item
				})
				obj = list[0];
				this.$set(this.vipRouteList,0,obj)
			},
			// 删除当前行程行息
			removeCurrent(index,num,cItem){
				
				// 1 机票 火车票
				if(num==1){
					this.vipRouteList.forEach((item,i)=>{
						if(index==i){
							this.delRouteIdsList.push(item.routeId)
							this.ysInfo.jtys = this.ysInfo.jtys - (item.fyys?item.fyys:0);	
							this.vipRouteList.splice(i,1);
						}
					})
					
				}else if(num==2){
					// 2 酒店
					this.viphtRouteList.forEach((item,i)=>{
						if(index==i){
							this.delRouteIdsList.push(item.routeId)
							this.ysInfo.zsys = this.ysInfo.zsys - (item.fyys?item.fyys:0);
							this.viphtRouteList.splice(i,1);
						}
					})
				}else if(num==3){
					// 3用车
					this.vipCarRouteList.forEach((item,i)=>{
						if(index==i){
							this.delRouteIdsList.push(item.routeId)
							this.ysInfo.jtys = this.ysInfo.jtys - (item.fyys?item.fyys:0);
							this.vipCarRouteList.splice(i,1);
						}
					})
				}	
				this.routeLength = this.vipRouteList.length + this.viphtRouteList.length + this.vipCarRouteList.length;
			},
			// 重新计算下费用
			resetCalcFee(){
				// this.viphtRouteList,this.vipCarRouteList
				if(this.viphtRouteList && this.viphtRouteList.length>0){
					this.viphtRouteList.forEach((routeItem,routeIndex)=>{
						this.getDj(routeItem,routeIndex);
					})
				}

				if(this.vipCarRouteList && this.vipCarRouteList.length>0){
					this.vipCarRouteList.forEach((routeItem,routeIndex)=>{
						this.getDj(routeItem,routeIndex);
					})
				}


			},
			// 验证保存参数
			vailParams(num,info){
				let errMsg = '';
				if(num==2 && !info.appId){
					errMsg = "送审前请选择审批规则"
				}else if(!this.formInfo.dateBegin){
					errMsg = "请选择出差申请单开始时间";
				}else if(!this.formInfo.dateEnd){
					errMsg = "请选择出差申请单结束时间";
				}
				
				
				if(info.vipRoute.length>0){
					let sBool = true;
					let eBool = true;
					var boolList = info.vipRoute.map(item=>{
						let bool = false;
						if(item.departDate){
							if(this.formInfo.dateBegin<=item.departDate){
								sBool = true;
							}else{
								sBool = false;
							}
							// sBool =	this.$common.getDiffSize(item.departDate,this.formInfo.dateBegin)
						}else if(item.arriveDate){
							if(this.formInfo.dateEnd>=item.arriveDate){
								eBool = true;
							}else{
								eBool = false;
							}
							// eBool =	this.$common.getDiffSize(this.formInfo.dateEnd,item.arriveDate) 
						}
						
						if(sBool && eBool){
							bool = true;
						}else{
							bool = false;
						}
						return bool
					})
					if(boolList.indexOf(false) != -1){
						errMsg = "行程日期不在出差日期范围，请重新选择";
					}
				}
				
				if(this.projectRequired && info.project==1){
					if(!info.projectId){
						errMsg = "请选择出差项目";
					}
				}else if(this.costCenterRequired){
					if(!info.costCenterId){
						errMsg = "请选择成本中心";
					}
				}else if(this.ccsqdYs==1 && info.zys==0){
					errMsg = "总预算必须大于0元";
				}else if(!info.mdd){
					errMsg = "请填写申请标题";
				}else if(!info.ccsy){
					errMsg = "请填写出差事由";
				}else if(info.vipRoute.length==0){
					errMsg="请添加出行信息";
				}else if(this.vipRouteList.length>0){
					this.vipRouteList.forEach((item,index)=>{
						if(!item.departCityName){
							errMsg= "行程计划第"+ (index+1) +"条出发地址为空";
						}else if(!item.arriveCityName){
							errMsg= "行程计划第"+ (index+1) +"条到达地址为空";
						}
					})
				}else if(this.viphtRouteList.length>0){
					this.viphtRouteList.forEach((item,index)=>{
						if(!item.departCityName){
							errMsg= "住宿安排第"+ (index+1) +"条入住城市为空";
						}
					})
				}else if(this.vipCarRouteList.length>0){
					this.vipCarRouteList.forEach((item,index)=>{
						if(!item.departCityName){
							errMsg= "用车城市第"+ (index+1) +"条出行城市为空";
						}
					})
				}
				
				if(errMsg){
					this.$message({
						type: 'error',
						message: errMsg
					})
					return false;
				}

				return true
			},
			// 保存并送审
			saveAndApproval(num){
				this.$nextTick(()=>{
					this.saveAndApprovalLoading(num)
				})
			},
			// 保存并送审
			saveAndApprovalLoading(num){

				// 重新计算下，各个单价；或者主动失去焦点
				this.resetCalcFee();


				this.loadingStatus = num;
				// 防止出发点击
				if(this.loading){
					return
				}
				this.loading = true;
				let routeTypeList = this.vipRouteList.concat(this.viphtRouteList,this.vipCarRouteList);
				routeTypeList.map((item)=>{
					if(item.depHtName || item.depJHName || item.arrJHName || item.depCarName){
						delete item.depHtName;
						delete item.depJHName;
						delete item.arrJHName;
						delete item.depCarName;
						return  item;
					}
					delete item.dateList;
					delete item.isChange;
					delete item.checkCxrList;
					// delete item.routeStatus
					// delete item.arriveCityInternational
					return item;
				})
				
				let querypParams = {};
				querypParams = {
					VipCar: [],
					VipCostCenterList: this.vipCostCenterList,//
					addMethod: num,
					appId: this.appId,
					by1: this.remarks,
					remark: this.remarks,
					ccr: this.formInfo.ccr,
					ccsqdId: this.formInfo.ccsqdId,
					// ccsqdNo: this.formInfo.ccsqdNo,
					ccsy: this.formInfo.ccsy,
					costCenterCode: this.formInfo.costCenterCode,
					costCenterId: this.formInfo.costCenterId,
					costCenterName: this.formInfo.costCenterName,
					dateBegin: this.formInfo.dateBegin,
					dateEnd: this.formInfo.dateEnd,
					delRouteIds: this.ccsqdId?this.delRouteIdsList.join(","):'', //删除行程id集合
					hcpys: this.hcpys,
					jpys: this.jpys,
					jtys: this.ysInfo.jtys,
					mdd: this.formInfo.mdd,
					project: this.formInfo.project,
					projectCode: this.formInfo.projectCode,
					projectId: this.formInfo.projectId,
					projectName: this.formInfo.projectName,
					qtys: this.ysInfo.qtys,
					sxr: this.formInfo.sxr,  
					vipPerson: this.vipPersonList,
					vipRoute: routeTypeList,
					ycys: this.ycys,
					zsys: this.ysInfo.zsys,
					zys: this.zys,
					repeatFlag: this.repeatFlag,
				}
				
				let bool = this.vailParams(num,querypParams);
				if(!bool){
					this.loadingStatus = 0;
					this.loading = false;
					return false
				}
				this.$conn.getConn('travelRequest.travelRequestAdd')(querypParams,(res)=>{
					let data = res.data|| {};
					this.loadingStatus = 0;
					this.loading = false;
					let errMsg = "";
					if(data.status==1){
						errMsg = '送审失败'
					}else if(data.status==0){
						errMsg = '草稿保存成功'
					}else if(data.status==2 && num==1){
						errMsg = '草稿保存成功'
					}else if(data.status==2 && num==2){
						errMsg = '送审成功'
					}
					if(data.status==1){
						this.$message({
							type: 'error',
							message: errMsg
						})
						return
					}else{
						this.$message({
							type: 'success',
							message: errMsg
						})
						// 保存成功 通知父页面 刷新
						window.opener.travelFormAdminRefresh(1);
						this.close();
					}
				},(err)=>{
					this.loadingStatus = 0;
					this.loading = false;
					if(err.errCode == 9883){
						 this.$confirm(err.tips , '温馨提示', {
						          confirmButtonText: '继续保存',
						          cancelButtonText: '取消',
						          type: 'warning'
						        }).then(() => {
									this.repeatFlag = 1;
									this.saveAndApproval(num);
						        }).catch(() => {
						                  
						        });
						
					}else{
						this.$message({
							type: 'error',
							message: err.tips
						})
					}
				})
			},
			close(){
				window.close();
			},
			//获取时间
			getChangeDate(e){
				
				if(e.proType == 10901){
					this.SET_TkQuery({
						depDate: e.departDate,
					})
				}else if(e.proType == 10904){
					this.trInfo.depDate = e.departDate;
				}
				
			},
			// 关闭本页面
			closePage(){
				window.close();
			}
		}
	}
</script>

<style scoped lang="scss">
	.atf-main{
		background-color: #EEF1F6;
		font-family: '微软雅黑';
		.seth1{
			font-size: 23px;			
			font-weight: 800;
			color: #282828;
			line-height: 32px;
			text-align: left;
			
		}
		.main{
			width: 1200px;
			margin: 0 auto;
			padding-top: 20px;
			.setPad{
				padding: 39px 29px 0px 29px;
				.setTitleLeft{
					width: 6px;
					height: 19px;
					background: #1C83ED;
					display: inline-block;
					vertical-align: text-bottom;
				}
				.setTitle{
					font-size: 18px;
					font-weight: 500;
					color: #000000;
					line-height: 24px;
					display: inline-block;
					padding-left: 10px;
					
				}
				.setText{
					font-size: 12px;
					font-weight: 400;
					color: #808080;
					line-height: 17px;
					display: inline-block;
					padding-left: 10px;
				}
			
				.table-main{
					margin-top: 17px;
					border: 1px solid #DFE2E9;
					.tabTitle{
						display: flex;
						width: 100%;
						font-size: 12px;
						font-weight: 400;
						color: #808080;
						background-color: #F7F7F7;
						height: 49px;
						line-height: 49px;
						div{
							border-right:1px solid #DFE2E9;
						}
						div:last-child{
							border-right:none;
						}
					}
					.tabContent{
						width:100%;
						display: flex;
						border-top: 1px solid #DFE2E9;
						line-height: 40px;
						.cityInput{
							.carInput{
								/deep/ .el-input__inner{
									border-right: 1px solid #DFE2E9;
								}
							}
							div{
								border-right: none;
							}
							/deep/ .el-input__inner{
								border-right: none;
								padding:0 5px;
							}
						}
						.htInput{
							/deep/ .el-input__prefix{
								display: none;
							}
							/deep/ .el-input__inner{
								padding-left: 5px;
							}
						}
						
						.model-k{
							padding: 15px 8px;
							/deep/ .el-range-separator {
									line-height: 38px;
									width: 12%;
								}
							/deep/ .el-range__icon	{
								width: 0;
								margin-left: 0;
							}
							/deep/ .el-range__close-icon{
								width: 0;
							}
							/deep/ .el-range-input{
								width: 43%;
							}
							.dateW94{
								width: 96px;
								.el-input{
									width: 96px;
								}
								/deep/ .el-input__inner{
									width: 96px;
									padding:0 5px;
								}
							}
							
							.dateW90{
								width: 90px;
								.el-input{
									width: 90px;
								}
								/deep/ .el-input__inner{
									width: 90px;
									padding:0 5px;
								}
							}
							.dateW140{
								width: 140px;
								.el-input{
									width: 140px;
								}
								/deep/ .el-input__inner{
									width: 140px;
									padding:0 5px;
								}
							}
							.dateW180{
								width: 180px;
								.el-input{
									width: 180px;
								}
								/deep/ .el-input__inner{
									width: 180px;
									padding:0 5px;
								}
							}
							.dateW280{
								width: 280px;
								.el-input{
									width: 280px;
								}
								/deep/ .el-input__inner{
									width: 280px;
									padding:0 5px;
								}
							}
							.dateW340{
								width: 340px;
								.el-input{
									width: 340px;
								}
								/deep/ .el-input__inner{
									width: 340px;
									padding:0 5px;
								}
							}
							.against{
								width: 96px;
								/deep/ .el-input__inner{
									width: 96px;
									padding:0 5px;
								}
							}
							
						}
						.relative{
							position: relative;
						}
						.absolute{
							position: absolute;
						}
						.setdiv{
							text-align: left;
							padding-left:5px;
							width: 78px;
							height: 40px;
							line-height: 40px;
							border-radius: 2px;
							border: 1px solid #DFE2E9;
						}
						div{	
							border-right:1px solid #DFE2E9;
						}
						div:last-child{
							border-right:none;
						}
					}
					
					.el-input{
						width: 76px; 
					}
					.input56{
						.el-input{
							width: 56px;
						}
						.inputw56{
							/deep/ .el-input__inner{
								width: 56px;
								padding: 0 5px;
							}
						}
					}
					.setBtn{
						width: 68px;
						height: 29px;
						border-radius: 2px;
						text-align: center;
					}
					.carInput{
						.el-input{
							width: 120px;
						}
						.inputw124{
							/deep/ .el-input__inner{
								width: 120px;
								padding: 0 5px;
							}
						}
					}
					/deep/ .el-input__inner{
							height: 40px;
							line-height: 40px;
							border-radius: 2px;
							border: 1px solid #DFE2E9;
					}
					/deep/ .el-button--small{
						padding: 0 ;
					}
					.btn39{
						width: 40px;
						height: 40px;
						border-radius: 0px;
						font-size: 14px;
						line-height: 37px;
					}
					
					.inputw94{
						/deep/ .el-input__inner{
							width: 94px;
							padding: 0 5px;
						}
					}
					
					.inputw76{
						/deep/ .el-input__inner{
							width: 76px;
							padding: 0 5px;
						}
					}
					
					.inputw96{
						/deep/ .el-input__inner{
							width: 96px;
							padding: 0 5px;
						}
					}
					
					.w50{
						width: 50px;
					}
					.w59{
						width: 59px;
					}
					.w77{
						width: 77px;
					}
					.w94{
						width: 94px;
					}
					.w97{
						width: 97px;
					}
					.w114{
						width: 114px;
					}
					.w120{
						width: 120px;
					}
					.w116{
						width: 116px;
					}
					.w130{
						width: 130px;
					}
					.w136{
						width: 136px;
					}
					.w220{
						width: 220px;
					}
					.w240{
						width: 240px;
					}
					.w360{
						width: 360px;
					}
					.carDate{
						div:first-child{
							border-right: 1px solid #DFE2E9;
						}
						
					}
					/deep/ .el-input__inner{
						padding: 0 5px;
					}
					
				}
				
			}
			.setPadTop{
				padding: 19px 29px 39px 29px;
				.setBtn1{
					min-width: 78px;
					height: 40px;
					border-radius: 2px;
					text-align: center;
					/deep/ .el-button--small{
						padding: 0 ;
					}
				}
				.setBtn2{
					min-width: 97px;
					height: 40px;
					border-radius: 2px;
					text-align: center;
					/deep/ .el-button--small{
						padding: 0 ;
					}
				}
			}
			
		}
		/deep/ .el-card__body{
			padding: 0;
		}
		/deep/ .el-table .cell{
			text-overflow: initial;
		}
		
		.tr-search{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.5);
			.dialog-main{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background-color: #EEF1F6;
				width: 1200px;
				padding: 20px;
				.dialogTitle{
					display: flex;
					justify-content: space-between;
					line-height: 30px;
					height: 40px;
					.iconClose{
						font-size: 24px;
						cursor: pointer;
					}
					.iconClose:hover{
						color:#00A7FA;
					}
				}
				.searchTop{
					display: flex;
					.searchCon{
						display: flex;
						line-height: 45px;
						.label{
							display: inline-block;
							width: 80px;
						}
						/deep/ .el-input__inner {
							height: 45px;
							width: 200px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.iconhc{
						width: 45px;
						height: 45px;
						line-height: 45px;
						margin:0 10px;
						font-size: 30px;
						color: #00A7FA;
						
					}
					.iconRank{
						width: 45px;
						height: 45px;
						line-height: 45px;
						margin:0 10px;
						font-size: 30px;
						color: #FFFFFF;
						background-color: #00A7FA;
					}
					.searchDate{
						/deep/ .el-input__inner {
							height: 45px;
							width: 225px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.button{
						border-radius: 0;
						height: 45px;
						width: 150px;
						font-size: 15px;
						font-weight: 600;
						margin-left: 20px;
					}
				}
				.search-content{
					width: 100%;
					height: 600px;
					overflow-x: hidden;
					overflow-y: auto;
					margin-top: 20px;
					.skelet{
						width: 100%;
						height:auto;
						background-color: rgba(0,0,0,0.2);
						border-radius: 4px;
						.flex{
							display: flex;
						}
						.between{
							justify-content: space-between;
						}
					}
				}
			
					
			}
		}
		
					
		.cxrDialog{
			text-align: left;
			/deep/ .el-dialog__body {
				padding: 10px 20px 30px 20px;
			}
			/deep/ .el-dialog{
				width: 790px;
			}
			.cxr-con{
				border: 1px solid #ccc;
				padding: 20px 0 20px 20px;
				background-color: #F7F7F7;
			}
			.cxr-content{
				width: 100%;
				text-align: left;
				line-height: 40px;
				span{
					display: inline-block;
				}
			}
		}

		.tch-search{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.5);
			.search-main{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background-color: #FFFFFF;
				width: 1200px;
				padding: 20px;
				.searchTitle{
					display: flex;
					justify-content: space-between;
					line-height: 30px;
					height: 40px;
				}
				.iconClose{
					font-size: 24px;
					cursor: pointer;
				}
				.iconClose:hover{
					color:#00A7FA;
				}
				.searchTop{
					display: flex;
					.searchCon{
						display: flex;
						line-height: 45px;
						.label{
							display: inline-block;
							width: 80px;
						}
						/deep/ .el-input__inner {
							height: 45px;
							width: 200px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.iconRank{
						width: 45px;
						height: 45px;
						line-height: 45px;
						margin:0 10px;
						font-size: 30px;
						color: #FFFFFF;
						background-color: #00A7FA;
					}
					.searchDate{
						/deep/ .el-input__inner {
							height: 45px;
							width: 225px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.button{
						border-radius: 0;
						height: 45px;
						width: 150px;
						font-size: 15px;
						font-weight: 600;
						margin-left: 20px;
					}
				}
				.list-main{
					max-height: 300px;
					min-height: 500px;
					overflow-y: auto;
					text-align: left;
					.list-con{
						display: flex;
						min-height: 40px;
						margin-bottom: 3px;
						background-color: #eeeeee;	
						cursor: pointer;
						.img{
							width: 25px;
							height: 25px;
						}
					}
					.list-con:hover{
						background-color: #dddddd;	
						box-shadow: 0 0 2px 0 rgba(0,0,0,0.2);
						
					}
				}
			}
			
		}
		
		.ht-query{
			position: fixed;
			top: 0;
			left: 0;
			right: 0;
			bottom: 0;
			z-index: 100;
			width: 100%;
			height: 100%;
			background-color: rgba(0,0,0,0.5);
			.ht-main{
				position: absolute;
				top: 50%;
				left: 50%;
				transform: translate(-50%,-50%);
				background-color: #FFFFFF;
				width: 1200px;
				padding: 20px;
				height: 700px;
				overflow: hidden;
				.searchTitle{
					display: flex;
					justify-content: space-between;
					line-height: 30px;
					height: 40px;
					.font18{
						font-size: 18px;
					}
					.iconClose{
						font-size: 24px;
						cursor: pointer;
					}
					.iconClose:hover{
						color:#00A7FA;
					}
				}
				.searchTop{
					display: flex;
					.searchCon{
						display: flex;
						line-height: 45px;
						.label{
							display: inline-block;
							width: 80px;
						}
						/deep/ .el-input__inner {
							height: 45px;
							width: 200px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.iconRank{
						width: 45px;
						height: 45px;
						line-height: 45px;
						margin:0 10px;
						font-size: 30px;
						color: #FFFFFF;
						background-color: #00A7FA;
					}
					.searchDate{
						/deep/ .el-input__inner {
							height: 45px;
							width: 225px;
							line-height: 45px;
							background: #F9FBFF;
							border: 2px solid #D9E1ED;
							border-radius: 1px;
						}
					}
					.button{
						border-radius: 0;
						height: 45px;
						width: 150px;
						font-size: 15px;
						font-weight: 600;
						margin-left: 20px;
					}
				}
			}
		}
		
		
		/deep/ .el-form-item__label{
			text-align: left;
			line-height: 49px;
		}
		/deep/ .el-form-item{
			margin-right: 0;
		}
		/deep/ .el-textarea__inner{
			height: 117px;
			background: #FFFFFF;
			border: 1px solid #DFE2E9;
		}
		.setInputw{
			/deep/ .el-input__inner{
				width:76px;
			
			}
			
		}
		.cxrCheckbox{
			.line-h40{
				line-height: 40px;
			}
			span{
				display: inline-block;
			}
		}
	
		
		.ys-main{
			margin-top: 17px;
			border: 1px solid #DFE2E9;
			.ys-header{
				display: flex;
				width: 100%;
				font-size: 12px;
				font-weight: 400;
				color: #808080;
				background-color: #F7F7F7;
				height: 49px;
				line-height: 49px;
				div{
					width: 33%;
					border-right:1px solid #DFE2E9;
				}
				div:last-child{
					border-right:none;
				}
			}
			.ys-content{
				width:100%;
				display: flex;
				border-top: 1px solid #DFE2E9;
				line-height: 40px;
				div{
					width: 33%;
					border-right:1px solid #DFE2E9;
				}
				div:last-child{
					border-right:none;
				}
				.el-input{
					width: 260px; 
					margin: 15px 0;
				}
			}
			.ys-footer{
				border-top: 1px solid #DFE2E9;
				height: 67px;
				text-align: right;
				padding-right: 20px;
				
				.footer1{
					font-size: 14px;
					font-weight: 400;
					color: #808080;
					line-height: 67px;
					display: inline-block;
					padding-right: 15px;
					float:right;
				}
				.footer2{
					font-size: 23px;
					font-weight: 800;
					color: #1C83ED;
					line-height: 67px;
					display: inline-block;
					float:right;
				}
			}
			.setBtn{
				width: 68px;
				height: 29px;
				border-radius: 2px;
				text-align: center;
			}
			/deep/ .el-input__inner{
					height: 40px;
					border-radius: 2px;
					border: 1px solid #DFE2E9;
			}
			/deep/ .el-button--small{
				padding: 0 ;
			}
			.btn39{
				width: 40px;
				height: 40px;
				border-radius: 0px;
				font-size: 14px;
				line-height: 25px;
			}
			
			.w50{
				width: 50px;
			}
			.w59{
				width: 59px;
			}
			.w94{
				width: 94px;
			}
			.w97{
				width: 97px;
			}
			.w114{
				width: 114px;
			}
			.w116{
				width: 116px;
			}
			.w220{
				width: 220px;
			}
		}
		
	
		.setRemove{
			font-size: 14px;
			font-weight: 400;
			color: #1C83ED;
			line-height: 40px;
			cursor: pointer;
		}
		.toptableR{
			right: 8px;
			top: 10px;
			cursor: pointer;
		}
		.bottomtableR{
			right: 8px;
			bottom: 10px;
			cursor: pointer;
		}
		
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.wrap{
			flex-wrap: wrap;
		}
		.first{
			justify-content: flex-start;
		}
		.textRight{
			text-align: right;
		}
		.tql-icon-t{
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-bottom: 8px solid #ccc;
		}
		.tql-icon-b{
			border-left: 6px solid transparent;
			border-right: 6px solid transparent;
			border-top: 8px solid #ccc;
		}
		.tql-icon-t:hover{
			border-bottom: 8px solid #1C83ED;
		}
		.tql-icon-b:hover{
			border-top: 8px solid #1C83ED;
		}
		
		
		

		
		.borderB-F0{
			border-bottom:1px solid #f0f0f0;
		}
		
		

		.marT17{
			margin-top: 17px;
		}
		.marT20{
			margin-top: 20px;
		}
	
		
	}
</style>
