<template>
	<div class="book">
		<!-- <a @click="goToPath()">去机票查询</a> -->
			<router-view/>
	</div>
		
</template>

<script>
	export default {
		name:'TkQuery',
		data(){
			return {
				
			}
		},
		methods:{
			goToPath(){
				this.$router.push({
					name:'TkQuery',
					query:{
						id:'673893319'
					}
				})
			}
		}
	}
</script>

<style scoped lang="scss">
	// .book {
	// 	padding-top: 30px;
	// }
</style>
