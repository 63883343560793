<template>
	<div>
		<router-view />
	</div>
</template>

<script>
	export default{
		name: 'Tr'
	}
</script>

<style>
</style>
