import { render, staticRenderFns } from "./TkOrderDetail.vue?vue&type=template&id=2205a14d&scoped=true&"
import script from "./TkOrderDetail.vue?vue&type=script&lang=js&"
export * from "./TkOrderDetail.vue?vue&type=script&lang=js&"
import style0 from "./TkOrderDetail.vue?vue&type=style&index=0&id=2205a14d&scoped=scoped&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "2205a14d",
  null
  
)

export default component.exports