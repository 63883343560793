<template>
	<div class="sh-main">
		<div class="title">
			<div class="title-t">长路悠悠,嘉相伴。</div>
			<div class="title-f">200家企业合作，100万服务人次，更多企业选择嘉华商旅。</div>
		</div>
		<div class="home-title">
			<div class="home-hd">
				<span v-for="(item, index) in businessTypeList" :key="item.key" @click="chooseType(item,index)">
					<span :style="chooseColor(item)"  :class="[{'home-base':true,shape1:item.params!=0,'gray':item.params==0 && gkfsparams==1}]">{{item.text}}</span>
				</span>
				
				<span :style="chooseStyle()"></span>
				<span :style="chooseStyleLeft()"></span>
				<span :style="chooseStyleRight()"></span>
				
			</div>
		</div>
		
		<div class="search">
			<!-- 机票 -->
			<div v-if="currentPathObj.key==0 || currentPathObj.key==1" style="padding:2px 0 0px 20px ;">
				<tk-query-search @queryTk="searchList"></tk-query-search>
			</div>
			<!-- 酒店 -->
			<div v-if="currentPathObj.key==2" style="padding:2px 0 0px 20px ;">
				<htcn-query-search @queryHtCn="searchList" @useNameclbz="getUseNameclbz"></htcn-query-search>
			</div>
			<!-- 火车票 -->
			<div v-if="currentPathObj.key==3" style="padding:2px 0 0px 20px ;">
				<train-query-search @queryTr="searchList" @useNameclbz="getUseNameclbz"></train-query-search>
			</div>
			<!-- 国际机票 -->
			<div v-if="currentPathObj.key==4" style="text-align: left; padding: 30px 0 0 30px;">
				
				<p>国际机票预订功能暂未开放，</p>
				<p>您可以填写国际机票需求单，客服人员会即时处理！</p>
				<p class="text-center" style="padding-top: 25px;">
					<el-button type="primary" plain class="setBtn" @click="searchList">去填写机票需求单</el-button>
				</p>
		
				
			</div>
			<!-- 国际酒店 -->
			<div v-if="currentPathObj.key==5" style="text-align: left;;padding: 30px 0 0 30px;">
				<p>国际酒店预订功能暂未开放，</p>
				<p>您可以填写国际酒店需求单，客服人员会即时处理！</p>
				<p class="text-center" style="padding-top: 25px;">
					<el-button type="primary" plain class="setBtn" @click="searchList">去填写酒店需求单</el-button>
				</p>
				
			</div>
            <!-- 会议会展 -->
			<div v-if="currentPathObj.key==6" style="text-align: left;;padding: 30px 0 0 30px;">
                <mice-card></mice-card>
				<!-- <p>国际酒店预订功能暂未开放，</p> -->
				<!-- <p>您可以填写国际酒店需求单，客服人员会即时处理！</p> -->
				<!-- <p class="text-center" style="padding-top: 25px;">
					<el-button type="primary" plain class="setBtn" @click="searchList">去填写酒店需求单</el-button>
				</p> -->
				
			</div>
		</div>
	</div>
</template>

<script>
	import htCnQueryCard from '@/views/htCn/HtCnComponent/htCnQueryCard.vue';
import miceCard from '@/views/mice/MiceComponent/miceCard.vue';
import TrainTicketQuery from '@/views/tr/TrainTicketQuery';
import { mapGetters, mapMutations, mapState } from 'vuex';
	// 机票查询弹框
	import TkQueryCard from '@/views/tk/tkComponent/tkQuery/TkQueryCard.vue';
	export default{
		name: 'HeaderSearch',
        components:{
				'htcn-query-search':htCnQueryCard,
				'train-query-search': TrainTicketQuery,
				'tk-query-search':TkQueryCard,
                'mice-card':miceCard
        },
		data() {
			return {
				/*
				 * 业务类型列表 
				 *	setGreyHide	设置置灰隐藏
				 */
				
				currentPathObj:{},
				useNameclbz:'',
				currentType: 1, //当前业务类型
			}
		},
		
		computed: {
			...mapState({
				ccsqdInfo: state => state.common.ccsqdInfo,
			}),
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			
			// 1 置灰 2隐藏 模块控制
			gkfsparams(){
				return this.getVipParamterByParNo(20029,3);
			},
			// 业务模块
			businessTypeList(){
				let list = [];
				list = [{
							text: '机票',
							key: 0,
							params: this.getVipParamterByParNo(20007,3),
							proType: 10901,
							path:'/yjTravel/book/tkRouter/tkQuery',
							query:{},
						},
						{
							text: '政采票',
							key: 1,
							params: this.getVipParamterByParNo(20030,3),
							proType: 10901,
							path:'/yjTravel/book/tkRouter/tkZcQuery',
							query:{
								tkType:1
							},
						},
						{
							text: '酒店',
							key: 2,
							params: this.getVipParamterByParNo(20009,3),
							proType: 10903,
							path:'/yjTravel/book/htCnRouter/htCnQuery', 
							query:{
								home:true
							},
						},
						{
							text: '火车票',
							key: 3,
							params: this.getVipParamterByParNo(20011,3),
							proType: 10904,
							path:'/yjTravel/book/trRouter/trQuery',
							query:{},
						},
						{
							text: '国际机票',
							key: 4,
							params: this.getVipParamterByParNo(20008,3),
							proType: 10902,
							path:'/yjTravel/book/inTkRouter/inTkEdit',
							query:{},
						},
						{
							text: '国际酒店',
							key: 5,
							params: this.getVipParamterByParNo(20010,3),
							proType: 10910,
							path:'/yjTravel/book/inHtRouter/inHtEdit',
							query:{},
						},
                        {
							text: '会议',
							key: 6,
							params: this.getVipParamterByParNo(20044,0),
							proType: 10918,
							path:'/yjTravel/book/inHtRouter/inHtEdit',
							query:{},
						}
					];
				var newList = [];	
				// 在处理下隐藏数据
				for(var i=0; i<list.length; i++){
					if(list[i].params ==0 && this.gkfsparams==2){
						
					}else{
						newList.push(list[i])
					}
				}
				return newList
			},
		},
		mounted() {
			let userInfo = this.$common.getUserInfo() || {};
			let vipCorp = userInfo.vipCorp || {};
			let bookStyle = {
				bookStyle: vipCorp.bookStyle,
			}
			this.setTkQuery(bookStyle)
			// 默认给查询机票
			// 默认给第一项有效的
			this.initCurrentPathObj(this.businessTypeList)
			
		},
		methods: {
			...mapMutations({
				setCcsqdInfo: 'common/setCcsqdInfo',	
				setTkQuery: 'tk/setTkQuery'
			}),
			//选中类型 更改文字颜色
			chooseColor(item){
				var styleObj = {
					transition: 'all 0.4s ease',
				}
				if(item.key == this.currentPathObj.key && item.params!=0){
					styleObj.color = '#00A7FA';
				}
				return styleObj
			},
			// 默认给第一项有效的
			initCurrentPathObj(businessTypeList){
				
				for(var k=0;k<businessTypeList.length;k++){
					let pathObj = businessTypeList[k] || {};
					// 当存在默认条件时，默认取第一条
					
					if(pathObj.params == 1 || pathObj.params == 2 || pathObj.params == 3){
						this.currentPathObj = pathObj;
						this.currentType = k + 1;
						break;
					}
				}
				
			},
			// 选中业务类型
			chooseType(item, index) {
				if(item.params==0 && this.gkfsparams==1){
					return 
				}
				this.currentPathObj = item;
				this.currentType = index + 1;
			},
			// 选中 使用滑块样式左侧
			chooseStyleLeft(){
				var styleObj = {
					position: 'absolute',
					left: `${(this.currentType-1)*130}px`,
					top: 0,
					transition: 'all 0.4s ease',
					'z-index': 0,
					width: 10 + 'px',
					height: 40 + 'px',
					'border-top': '20px solid transparent',
					'border-left': '5px solid transparent',
					'border-bottom': '20px solid #FFFFFF',
					'border-right': '5px solid #FFFFFF',
					'box-sizing': 'border-box',
				}
				if(this.currentType==1){
					styleObj['left'] = 0;
					styleObj['border-top'] = '20px solid #FFFFFF';
					styleObj['border-left'] = '5px solid #FFFFFF';
					styleObj['border-bottom'] = '20px solid #FFFFFF';
					styleObj['border-right']= '5px solid #FFFFFF';
				}
				return styleObj;
			},
			// 选中 使用滑块样式
			chooseStyle() {
				var styleObj = {
					position: 'absolute',
					left: `${(this.currentType-0-1)*130+10}px`,
					top: 0,
					transition: 'all 0.4s ease',
					'z-index': 0,
					width: 110 + 'px',
					height: 40 + 'px',
					background: '#FFFFFF',
					color: '#000000',
				}
				//表示点击到最后一个
				return styleObj;
			},
			//选中 使用滑块样式右侧
			chooseStyleRight(){
				var styleObj = {
					position: 'absolute',
					left: `${(this.currentType-0)*130-10}px`,
					top: 0,
					transition: 'all 0.4s ease',
					'z-index': 0,
					width: 10 + 'px',
					height: 40 + 'px',
					'border-top': '20px solid transparent',
					'border-left': '5px solid #FFFFFF',
					'border-bottom': '20px solid #FFFFFF',
					'border-right': '5px solid transparent',
					'box-sizing': 'border-box',
				}
				if(this.businessTypeList.length == this.currentType){
					styleObj['border-top'] = '20px solid #FFFFFF';
					styleObj['border-left'] = '5px solid #FFFFFF';
					styleObj['border-bottom'] = '20px solid #FFFFFF';
					styleObj['border-right']= '5px solid #FFFFFF';
				}
				return styleObj;
			},
			// 搜索跳转对应的业务模块
			searchList(){
				let path = this.currentPathObj.path;
					if(path){
						this.$router.push({
							path:path,
							query:this.currentPathObj.query || {}
						})
					}else{
						this.$alert('请配置路由信息','温馨提示',{
							type: 'warning'
						})
					}
			},
			// 最低员工名称
			getUseNameclbz(name){
				this.useNameclbz = name;
			}
		}
	}
</script>

<style scoped lang="scss">
   

	.sh-main{
		font-family: '微软雅黑';
		width: 1200px;
		margin: 0 auto;
		.title {
			padding: 30px 0;
			color: #FFFFFF;
			text-align: left;
			font-weight: bold;
			
			.title-t {
				font-size: 30px;
				line-height: 30px;
				letter-spacing: 5px;
			}
				
			.title-f {
				margin-top: 10px;
				font-size: 12px;
				line-height: 12px;
				letter-spacing: 2px;
			}
		}
		.setBtn{
			    width: 160px;
			    height: 40px;
			    border-radius: 5px;
			    font-size: 14px;
			    font-weight: 500;
		}
		.home-title{
			text-align: left;
			.home-hd{
				position: relative;
				height: 40px;
				width:auto;
				text-align: left;
				color: #FFFFFF;
				display: inline-block;
				background-color: rgba(0,0,0,0.2);
				.home-base{
					display: inline-block;
					width:130px;
					height:40px;
					line-height:40px;	
					position: relative;		
					text-align: center;
					cursor: pointer;
					z-index: 1;
				}
				
				.shape1{
					width: 130px;
					height: 40px;
					line-height: 40px;
					color: #FFFFFF;
					font-size: 14px;
					font-weight: 400;
					cursor: pointer;	
				}
				.gray{
					width: 130px;
					height: 40px;
					line-height: 40px;
					opacity: 0.5;
					font-size: 14px;
					font-weight: 400;
					cursor: not-allowed;
					color: #ccc;
				}
				.chooseShape1{
					color: #00A7FA;
					transition: 'all 0.6s ease',
				}
				
			}	
				
			
		}	
		
		.search{
			font-family: '微软雅黑';
			min-height: 200px;
			background-color: #FFFFFF;
			.search-top{
				text-align: left;
				padding: 20px;
				border-bottom:1px solid #EBF3FF;
				display: flex;
				justify-items: center;
				.wf-left{
						display: inline-block;
						height: 30px;
						margin-right: 36px;
					.dcwf{
						width: 108px;
						height: 24px;
						line-height: 24px;
						border-radius: 24px;
						background-color: #E9EFF7;
						font-size: 12px;
						font-weight: 400;
						cursor: pointer;
						position: relative;
						overflow: hidden;
						text-align: center;
						.dc,.wf{
							display: inline-block;
							width: 54px;
							height: 24px;
							color: #0B387A;
							position: absolute;
						}
						.dc{
							left: 0;
							top: 0;
							z-index: 4;
						}
						.wf{
							left: 54px;
							top: 0;
							z-index: 3;
						}
						.color{
							color: #FFFFFF;
							transition: all 0.3s ease;
						}
					}
				}
				.gs{
					line-height: 30px;
					margin-right: 20px;
				}
				
			}
		
			.search-item{
				width: 100%;
				height: 45px;
				margin: 19px 20px 25px;
				/deep/ .el-input__inner{
					height: 45px;
					border: 2px solid #D9E1ED;
					border-radius: 0px;
					}
				.setCityInput{
					display: inline-block;
					/deep/ .el-input__inner{
						width: 295px;
					}
				}
				.setHtCityInput{
					display: inline-block;
					/deep/ .el-input__inner{
						width: 200px;
					}
				}
				
				.dateLeft{
					margin-left: 15px;
					.dateInput1 {
						/deep/ .el-input__inner {
							width: 220px;
						}
					}
					/deep/ .el-range-separator{
						line-height: 35px;
						display: inline-block;
					}
					.dateInput2 {
						width: 360px;
						/deep/ .el-input__inner {
							width: 360px;
						}
						
					}
				}
				.search-btn{
					width: 110px;
					height: 45px;
					font-size: 15px;
					font-weight: 400;
					color: #FFFFFF;
					border-radius: 0;
					margin-left: 45px;
				}
				.search-jb{
					display: flex;
					height: 45px;
					.setInput{
						
					}
					/deep/ .el-input__prefix{
						color: #006BB9;
						text-align: center;
						width:25px;
					}
					.iconFont{
						transition: all 0.3s ease;
						font-size: 24px;
						line-height: 44px;
						height: 44px;
						width: 45px;
						color: #006BB9;
						cursor: pointer;
					}
				}
			}
			
			.search-footer{
				height: 30px;
				background: #F1F4F9;
				font-size: 12px;
				line-height: 30px;
				padding-left: 20px;
				text-align: left;
				font-weight: 400;
				color: #637697;
			}
		}	
		
	}
	
</style>
