<template>
	<div class="ad-main-a">
		<div class="main">
			<el-card>
				<div class="marB10 flex between">
					<p>出差申请单号：<span>{{dataDetail.ccsqdNo}}</span></p>
					<p v-if="query.overdueFlag==1" style="color:red;">行程过期</p>
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						出差人信息
					</div>
					<div class="right-main flex">
						
						<div class="topItem">
							<span class="title-width">申请状态：</span>
							<span v-if="dataDetail.status==0">草稿</span>
							<span v-if="dataDetail.status==1">审批中</span>
							<span v-if="dataDetail.status==2">审批通过</span>
							<span v-if="dataDetail.status==3">审批拒绝</span>
							<span v-if="dataDetail.status==4">已删除</span>
							<span v-if="dataDetail.status==5">报销中</span>
							<span v-if="dataDetail.status==6">已报销</span>
						</div>
						<div class="topItem">
							<span class="title-width">申请时间：</span>
							<span>{{dataDetail.reqTime}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">出差类型：</span>
							<span v-if="dataDetail.project==0">非项目</span>
							<span v-if="dataDetail.project==1">项目</span>
						</div>	
						<div class="topItem">
							<span class="title-width">成本中心：</span>
							<span>{{dataDetail.costCenterName}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">出差人：</span>
							<span>{{dataDetail.ccr}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">随行人：</span>
							<span>{{dataDetail.sxr?dataDetail.sxr:''}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">申请标题：</span>
							<span>{{dataDetail.mdd}}</span>
						</div>
						<div class="topItem">
							<span class="title-width">出差日期：</span>
							<span>
								<span>{{dataDetail.dateBegin}}</span>
								<span>至</span>
								<span>{{dataDetail.dateEnd}}</span>
							</span>
						</div>
						<div class="topItem">
							<span class="title-width">出差事由：</span>
							<span>{{dataDetail.ccsy}}</span>
						</div>
					</div>
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						行程计划
					</div>
					<div class="xc-main">
						<el-table :data="jhList" style="width:996px" border :header-cell-style="header">
						    <el-table-column  type="index" width="50" label="序号"></el-table-column>
						    <el-table-column prop="proType" label="行程类型" width="80">
								<template slot-scope="scope">{{ scope.row.proType=='10901'?'飞机':'火车' }}</template>
						    </el-table-column>
						    <el-table-column prop="departDate" label="出发日期" width="100"></el-table-column>
							<el-table-column prop="departCityName" label="出发城市" width="100"></el-table-column>
							<el-table-column prop="arriveCityName" label="到达城市" width="100"></el-table-column>
							<el-table-column prop="cxr" label="出行人" width="139"></el-table-column>
							<el-table-column prop="flightNo" label="航班号/车次" width="100"></el-table-column>
							<el-table-column prop="cabin" label="舱位/席别" width="100"></el-table-column>
							<el-table-column prop="fyys" label="费用预算" width="86"></el-table-column>
							<el-table-column prop="wbyy" label="违背备注" width="100"></el-table-column>
						  </el-table>
					</div>	
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						住宿安排
					</div>
					<div class="xc-main">
						<el-table :data="hotelList" style="width:996px" border :header-cell-style="header">
						    <el-table-column  type="index" width="50" label="序号"></el-table-column>
						    <el-table-column prop="departCityName" label="入住城市" width="80"></el-table-column>
						    <el-table-column prop="departDate" label="入住日期" width="100"></el-table-column>
							<el-table-column prop="arriveDate" label="离店日期" width="100"></el-table-column>
							<el-table-column prop="cxr" label="入住人" width="120"></el-table-column>
							<el-table-column prop="flightNo" label="酒店名称" width="150"></el-table-column>
							<el-table-column prop="cabin" label="房型" width="100"></el-table-column>
							<el-table-column prop="fjs" label="房间数" width="75"></el-table-column>
							<el-table-column prop="fyys" label="费用预算" width="80"></el-table-column>
							<el-table-column prop="wbyy" label="违背备注" width="100"></el-table-column>
						</el-table>
					</div>	
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						用车出行
					</div>
					<div class="xc-main">
						<el-table :data="carList" style="width:996px" border :header-cell-style="header">
						    <el-table-column  type="index" width="50" label="序号"></el-table-column>
						    <el-table-column prop="departCityName" label="用车城市" width="120"></el-table-column>
						    <el-table-column prop="departDate" label="开始时间" width="120"></el-table-column>
							<el-table-column prop="arriveDate" label="结束时间" width="120"></el-table-column>
							<el-table-column prop="cxr" label="出行人" width="120"></el-table-column>
							<el-table-column prop="dj" label="价格" width="100"></el-table-column>
							<el-table-column prop="fyys" label="费用预算" width="125"></el-table-column>
							<el-table-column prop="wbyy" label="违背备注" width="200"></el-table-column>
						</el-table>
					</div>	
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						其他备注
					</div>
					<div class="xc-main ">
						<div class="xc-text">{{dataDetail.remark || dataDetail.by1}}</div>
					</div>	
				</div>
				<div class="marB10 border flex h80">
					<div class="leftTitle">
						费用合计
					</div>
					<div class="ys-main">
						<div class="xc-ys">
							<span class="bfb-w25">交通预算: ￥{{dataDetail.jtys}}</span>
							<span class="bfb-w25">住宿预算: ￥{{dataDetail.zsys}}</span>
							<span class="bfb-w25">其他预算: ￥{{dataDetail.qtys}}</span>
						</div>
						<div class="xc-ys">
							预计本次出差总预算：￥{{dataDetail.zys}}
						</div>
					</div>	
				</div>
				<div class="marB10 border flex h80" v-if="dataDetail.status == 0 && dataDetail.appId">
					<div class="leftTitle">
						审批规则详情
					</div>
					<yj-approval-rule-detail  
					class="sp-main"  
					:detail="gzdetail" 
					:appId="dataDetail.appId" 
					></yj-approval-rule-detail>	
				</div>
				
				<div class="marB10 border flex h80" v-if="dataDetail.status>0 && dataDetail.appId">
					<div class="leftTitle">
						审批进度
					</div>
					<yj-approval-progress class="sp-main" 
						:orderId="query.orderId"
						:orderType="query.orderType"
						:showProgress="showProgress"
						:resetspjd="resetspjd"
						></yj-approval-progress>
				</div>
				<!-- 公共组件审批 -->
				<!-- <div v-if="query.workItemId && dataDetail.status==1 && ifApprover==1">
					<yj-approval-cao-zuo :appType="dataDetail.status" :workItemId="query.workItemId" @closePage="closePage"></yj-approval-cao-zuo>
				</div> -->
				<!-- 自定义审批 -->
				<!-- <div class="marB10 border flex h80" v-if="query.workItemId && dataDetail.status==1 && ifApprover==1">
					<div class="leftTitle">
						是否审批
					</div>
					<div class="sp-main flex">
						<div class="ss-item">
							<div>
								<el-radio label="1" v-model="active">同意</el-radio>
							</div>
							<div>
								<el-radio label="0" v-model="active">拒绝</el-radio>
							</div>
						</div>
						<div class="ss-right">
							<el-input
							  type="textarea"
							  :rows="2"
							  placeholder="请输入内容"
							  v-model="appRemark">
							</el-input>
						</div>
					</div>	
				</div> -->
				<!-- <div style="margin:20px 0;">
					<el-button class="btn" v-if="query.workItemId  && dataDetail.status==1 && ifApprover==1" type="primary" @click="approvalSend">提交审批</el-button>
          			<el-button class="btn" v-if="dataDetail.status==1  && dataDetail.cancelFlag" type="primary"  @click="revokeCcsqd()">撤回</el-button>
				</div> -->

				<yj-approval-cao-zuo 
				:appType="query.appType" 
				:workItemId="query.workItemId" 
				:orderType="11099" 
				:orderId="query.orderId"
				@closePage="spResetDetail" >
				<template slot="orderFooterSlot">
					<div class=" pad20" style="text-align: center;">

          				<el-button class="btn" v-if="dataDetail.status==1  && dataDetail.cancelFlag" type="primary"  @click="revokeCcsqd()">撤回</el-button>
					</div>
				</template>
			</yj-approval-cao-zuo>
			</el-card>
		</div>
	</div>
</template>

<script>
	export default{
		name:'CcsqdDetail',
		data(){
			return {
				header:{
					background:'#FAFAFA',
					height:40+'px',
				},
				dataDetail:{}, // 详情信息
				jhList:[], //行程列表
				hotelList:[], // 住宿列表
				carList:[], //用车列表
				approvalInfo:{}, //审批进度信息 
				status:1, // 审批人 审批高度
				
				active:'1', // 是否同意
				appRemark:'',// 审批备注
				outTime: false, // 审批是否已过期
				
				gzdetail:{
					appId:'',
					currentLevel:0,
					flowState: 1,
				},
				resetspjd:false,
			}
		},
		computed:{
			// 接收传值
			query(){
				return this.$route.query || {};
			},
			// 
			empInfo(){
				let userInfo = this.$common.getUserInfo() || {}
				return userInfo.empInfo || {};
			},
			//当前登录人是否是审批人
			ifApprover(){
				return this.empInfo.ifApprover || 0;
			}
		},
		mounted(){
			this.outTime = (this.query.appType == 3)
			this.getCcsqdInfo();
			// this.getOrderAppProgress();
		},
		methods:{
			// 审批
			spResetDetail(){
				this.$router.replace({
					path: this.$dict.detailConfig(11099),
					query: {
						...this.$route.query,
						appType:2  // 已审批     0 订单详情 1.待审批 2.已审批 3. 审批过期 
	
					}
				})
				// 审批成功 通知父页面 刷新
				window.opener.needMyApprovalRefresh(1);
				this.getCcsqdInfo();
				this.resetspjd = true;
				return 
			},
			// 出差申请单详情
			getCcsqdInfo(){
				let queryParams = {};
					queryParams = {
						ccsqdId: this.query.orderId
					}
				this.$conn.getConn('travelRequest.travelRequestInfo',{jsfile:false})(queryParams).then((res)=>{
					let data = res.data || {};
					this.dataDetail = data;
					this.jhList = [];
					this.hotelList = [];
					for(let i=0; i<data.allRouteList.length;i++){
						if(data.allRouteList[i][0].proType == '10901' || data.allRouteList[i][0].proType == '10904'){
							this.jhList.push(data.allRouteList[i][0])
						}else if(data.allRouteList[i][0].proType == '10903'){
							this.hotelList.push(data.allRouteList[i][0])
						}else if(data.allRouteList[i][0].proType == '10906'){
							this.carList.push(data.allRouteList[i][0])
						}
						
					}
				}).catch((err)=>{
					this.$message({
						type:'error',
						message: err.tips
					})
				})	
			},
			revokeCcsqd() { 
				let param = {
							orderId : this.dataDetail.id,
							orderType : "11099",
						}
						this.$confirm('撤回此条申请单？', '提示', {
							confirmButtonText: '确定',
							cancelButtonText: '取消',
							type: 'warning'
						}).then(() => {	
							this.$conn.getConn('commu.vipBpmSendAppCancel', {jsfile: false})(param).then((res)=>{
								this.$message({
									type: 'success',
									message: '撤回成功'
								})
								this.getCcsqdInfo();
							}).catch((err)=>{
								this.$message({
									type: 'error',
									message: err.tips
								})	
							})
						}).catch(() => {
							this.$message({
							type: 'info',
							message: '已取消撤回'
							});            
						});
			},
			// 提交审批
			approvalSend(){
				
				let queryParams = {};
					queryParams = {
					    appRemark: this.appRemark,
					    completeState: this.active,
					    completeWay: 1,
					    orderId: this.query.orderId,
					    workItemId: this.query.workItemId,
					}
				
				let errMsg = "";	
				if(!queryParams.workItemId){
					errMsg = "工作项id不能为空";
				}else if(!queryParams.orderId){
					errMsg = "订单id不能为空";
				}else if(queryParams.completeState=='0' && !queryParams.appRemark){
					errMsg = "请填写拒绝原因";
				}	
				
				if(errMsg){
					this.$message.error(errMsg);
					return false
				}	
				
				this.$conn.getConn('commu.myAppDetail')(queryParams,(res)=>{
					this.$message({
						type:'success',
						message: '审批成功'
					})
					this.getCcsqdInfo();
					// 审批成功 通知父页面 刷新
					window.opener.needMyApprovalRefresh(1);
					
					setTimeout(()=>{
						window.close();
					},300)
				},(err)=>{
					this.$message({
						type:'error',
						message: err.tips
					})
				})
			},
			showProgress(info,currentSpjd,status){
					// 进度
		
					this.spjd = info.spjd; //当前审批进度（1/2）
					this.gzdetail.currentLevel = currentSpjd; //当前到谁审批
					this.gzdetail.flowState = status;  // 当前审人状态
					console.log(this.gzdetail)
			},
		
		}
	}
</script>

<style scoped lang="scss">
	 
	.ad-main-a {
		background-color: #EEF1F6;
		padding:40px 0;
		.main{
			margin: 0 auto;
			width: 1200px;
			.flex{
				display: flex;	
			}
			.between{
				justify-content: space-between;
			}
			.marB10{
				margin-bottom: 10px;
			}
			.border{
				border: 1px solid #E6E6E6;
			}
			.h80{
				min-height: 80px;
			}
			.btn{
				width: 80px;
				height: 36px;
				font-size: 14px;
			}
			.leftTitle{
				display: flex;
				justify-content: center;
				align-items: center;
				background-color: #FAFAFA;
				border-right: 1px solid #E6E6E6;
				width: 120px;
			}
			.padTB30{
				padding:30px 0;
			}
			.sp-main{
				width: 1036px;
				padding: 20px 0 20px 40px;
				.ss-item{
					text-align: left;
					line-height: 30px;
					width: 100px;
				}
				.ss-right{
					width: 700px;
				}
				.sp-item{
					font-size: 14px;	
				}
			}
			.ys-main{
				width: 1036px;
				text-align: left;
				padding: 15px 0;
				.xc-ys{
					padding-left: 40px;
					line-height: 40px;
					.bfb-w25{
						width: 25%;
						display: inline-block;
					}
				}
			}
			.xc-main{
				width: 1036px;
				text-align: left;
				padding:20px 40px;
				
				.xc-text{
					padding: 24px 0 ;
					line-height: 30px;
				}
				
				.bfb-w5{
					width: 5%;
				}
				.bfb-w8{
					width: 8%;
				}
				.bfb-w10{
					width: 10%;
				}
				.bfb-w20{
					width: 20%;
				}
			}
			.right-main{
				width: 1036px;
				flex-wrap: wrap;
				padding: 10px 0;
				.topItem{
					width: 33%;
					text-align: left;
					padding-left: 40px;
					line-height: 30px;
				}
				.title-width{
					width: 80px;
					display: inline-block;
				}
			}
		}
	}
</style>
