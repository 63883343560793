<template>
	<div class="trf-main" :style="{'min-height':height+'px'}">
		<div class="main">
			<train-refund-person ref="trainRefundPerson" :allData="trRefundData"></train-refund-person>
			<yj-contact-info :linkInfo="linkInfo"></yj-contact-info>
			<yj-reason :orderType="11010" :params="143" :marginTop="'30px'" @refundChangeReason="getReason"></yj-reason>
			<div style="margin-top: 45px;padding-bottom: 50px;">
				<el-button class="w240 h64 font16" @click="cancel">取消</el-button>
				<el-button class="w240 h64 font16"  type="primary" @click="submitRefund">
					<span v-if="loading==true">
						<span class="el-icon-loading"></span>
						退票申请中....
					</span>
					<span v-if="loading==false">提交退票申请</span>
				</el-button>
			</div>
		</div>
		<!-- 12306 -->
		<train-login
			:showTrLogin="false" 
			:orderVisible="visible"
			:checkVisible="checkVisible"
			:cusTomerSendSmsProp="cusTomerSendSmsProp"
			:isQueryOrderAccount="isQueryOrderAccount"
			:orderInfo="orderInfo"
			@needSuccess="needSuccess" 
			@loginSuccess="loginSuccess" 
			@cancelLogin="cancelLogin"
			class="orderTrLogin"
		></train-login>
	</div>
</template>

<script>
	import TrainRefundPerson from "./trTGComponent/TrainRefundPerson";
	import TrainLogin from '@/views/tr/trComponents/trEdit/trainYdEdit/TrainLogin';
	export default{
		name: 'TrainRefund',
		data() {
			return {
				trRefundData: {}, //可退票乘车人信息
				reasonName:'', // 退票原因
				timestamp: 0, // 可退票时间
				tripType:1, //退票类型因公 因私
				loading: false,
				// query:{},
				linkInfo:{},
				visible: false, //是否显示 12036 登录页面
				isQueryOrderAccount: true,// 是否查询订单关联的12306账号
				cusTomerSendSmsProp: true, // 走true-正常核验还是false-主动触发的核验
				checkVisible: false, // 是否显示核验页面
				orderInfo: { // 选择的订单信息
					// orderType: '',
					// orderId: '',
				},
				orderType: '', // 业务类型 
			}
		},
		components:{
			'train-refund-person': TrainRefundPerson,
			'train-login':TrainLogin,
		},
		computed:{
      //传过来的参数
			query(){
				return this.$route.query || {};
			},
      
      //计算页面高度
      height(){
      	let height = document.body.clientHeight;
      	return height;
      }
		},
		mounted(){
			// this.query = this.$route.query;
			this.getApplyTrRefund();
		},
		methods: {
			// 获取  可申退的乘车人
			getApplyTrRefund(){
				let id = this.query.orderId;
				// let applyType = this.query.applyType || '';
				let obj = {
					orderId: id,
					// applyType: applyType,
				};
				
				this.$conn.getConn('trRefund.applyTrRefund',{jsfile:false})(obj).then((res)=>{
					let data = res.data || {};
					data.ticketList.map(item=>{
						item.checked = false;
						if(data.ticketList.length==1){
							item.checked = true;
						}
						return item
					});

					// 订单类型
					this.orderType = data.orderType;

					this.linkInfo = {
						linkman: data.linkman,
						linkMobile: data.linkMobile,
						linkTel: data.linkTel,
						linkEmail: data.linkEmail,
					}
					this.trRefundData = data;
					this.tripType = data.tripType;
					this.timestamp = res.timestamp;
				}).catch((err)=>{
					this.$alert(err.tips,'温馨提示',{
						type: 'error' 
					})
				})
			},
			// 取消 关闭本页面
			cancel(){
				this.$confirm('此操作将关闭本页面, 是否继续?', '提示', {
							  confirmButtonText: '确定',
							  cancelButtonText: '取消',
							  type: 'warning'
				        }).then(() => {
							// this.$common.sessionremove('trRefundData')
							window.close();
				        }).catch(() => {
				            this.$message({
				            type: 'info',
				            message: '已取消关闭'
				        });          
				});
			},
			// 获取退票原因
			getReason(val){
				this.reasonName = val.reasonName;
			},
			// 验证退票时间
			timeValid(){
				//火车票出发时间
				var time = this.trRefundData.fromDate + ' '+ this.trRefundData.fromTime;
				var fromDate = new Date(time);
				var bool = false;
				//   如果出发时间大于想要退票时间 可以退票
				
				if(fromDate.getTime()>this.timestamp){
					bool = true;
				}else{
					bool = false;
				}
				return bool;
			},
			// 提交退票申请
			submitRefund(){	
				var needApprove = this.$refs['trainRefundPerson']['needApprove'];
				var chooseSpgz = this.$refs['trainRefundPerson']['chooseSpgz'] || {};
				var ticketList = this.$refs['trainRefundPerson']['ticketList'] || [];
				var spgzList =  this.$refs['trainRefundPerson']['spgzList'] || [];
				var timeValid = this.timeValid();
				if(this.loading){
					return
				}
				if(!timeValid){
					this.$alert('车次已过出发时间，不能退票','温馨提示',{
						  confirmButtonText: '确定',
						  type: 'warning',
						})
					return false;
				}
				if(ticketList.length<1){
					this.$alert('请选择要退票的人员','温馨提示',{
						type:'error',
					})
					return false
				}
				// 审批
				if(needApprove && !chooseSpgz.gzId && spgzList.length>0 && this.tripType==1){
					this.$alert('请选择审批规则','温馨提示',{
						type:'error',
					})
					return false
				}
				if(!this.reasonName){
					this.$alert('请选择退票原因','温馨提示',{
						type:'error',
					})
					return false
				}
				
				this.loading = true;
				
				
				let queryParams = {
						"appId": chooseSpgz.gzId || '',
						"linkEmail": this.trRefundData.linkEmail || '',
						"linkMobile": this.trRefundData.linkMobile || '',
						"linkTel": this.trRefundData.linkTel || '',
						"linkman": this.trRefundData.linkman || '',
						"orderFrom": 10503,
						"orderId": this.trRefundData.orderId,
						"refundReasonVip": this.reasonName,
						"ticketList": ticketList,
				}
				
				this.$conn.getConn('trRefund.saveTrRefund2',{jsfile:false})(queryParams,(res)=>{
					let data = res.data || {};  
					this.loading = false;
					if(chooseSpgz.gzId){
						this.$router.push({path:'/yjTravel/myTravel/userCenter/order',query:{findTypeIndex:'3'}})
						
					}else{
						this.$router.push({path:'/completed',query:{orderId:this.query.orderId,refundId:data,orderType:11010,}})
					}
					
				},(err)=>{
					this.loading = false;
					if (err.errCode == '7527') {
					  // 重新登录
					  this.checkLogin(err.errCode);
					} else if (err.errCode == '7524' || err.errCode == '7530') {
					  // 需要核验
					  this.checkLogin(err.errCode);
					} else {						
						this.$alert(err.tips,'温馨提示',{
							type: 'error' 
						})
					}
				})
			},
			// 12306核验
			checkLogin(errCode){
				let query = this.$route.query || {};
				this.orderId = query.orderId;
				this.orderInfo = {
					orderId: query.orderId,
					orderType: this.orderType,
					errCode: errCode
				};				
				setTimeout(()=>{
					if (errCode == 7530 || errCode == 7524) {
						this.checkVisible = true;
						this.cusTomerSendSmsProp = errCode == 7530? false: true;
					} else {
						this.visible = true;
					}
				},200)	
			},
			//登录成功
			loginSuccess(){
				this.visible = false;
				this.checkVisible = false;
			},
			// 核验成功 重置订单详情
			needSuccess(){
				this.visible = false;
				this.checkVisible = false;
			},
			// 取消登录
			cancelLogin(){
				this.loginSuccess();
			}
		}
	}
</script>

<style scoped lang="scss">
	.trf-main{
		background-color: #EEF1F6;
		.main{
			width: 1200px;
			margin: 0 auto;
			padding-top: 20px;
		}
		.box-card{
			margin-top: 28px;
		}
		.w240{
			width: 240px;
		}
		.h64{
			height: 64px;
		}
		.font16{
			font-size: 16px; 
		}
	}
</style>
