<template>
	<div class="task">
		<div class="task-main">
			<div style="text-align: left; margin:10px 0;font-size: 18px;font-weight: 900;">任务管理器</div>
			<el-card >
				<div class="flex">
					<div class="flex setDateInput">
						<p style="width:80px;line-height: 40px;">起始日期</p>
						<el-date-picker
						      v-model="query.dateStart"
							  value-format="yyyy-MM-dd"
							  :clearable="false"
							  prefix-icon="false"
						      type="date"
							  :picker-options="pickerOptions()"
							  @change="getStartDate"
						      placeholder="选择开始日期">
						</el-date-picker>
					</div>
					<div class="flex setDateInput">
						<p style="width:80px;line-height: 40px;">结束日期</p>
						<el-date-picker
						      v-model="query.dateEnd"
						      type="date"
							  value-format="yyyy-MM-dd"
							  :clearable="false"
							  prefix-icon="false"
							  :picker-options="pickerOptions()"
							  @change="getEndDate"
						      placeholder="选择结束日期">
						</el-date-picker>
					</div>
					<div style="width:30%;margin-left:40px;" class="flex">
						<p style="width:80px;line-height: 40px;">操作人</p> 
						<el-input v-model="cuName" readonly @click.native="getCzrInfo"></el-input>
					</div>
					
					<el-button type="primary" class="btn" @click="getTaskList">查 询</el-button>
				</div>
			</el-card>
			<el-card class="t-table">
				<el-table
				    :data="taskList"
					border
					:header-cell-style="headerStyle"
				    style="width: 100%">
				    <el-table-column
						align="center"
						header-align="center"
				        type="index"
				        label="序号"
				        width="50">
				    </el-table-column>
				    <el-table-column
				        prop="status"
						align="center"
						header-align="center"
				        label="状态"
				        width="100">
						<template slot-scope="scope" >
							<div v-if="scope.row.status==1">正在执行</div>
							<div v-if="scope.row.status==2">终止</div>
							<div v-if="scope.row.status==3">完成</div>
							<div v-if="scope.row.status==4">失败</div>	
						</template>
				    </el-table-column>
				    <el-table-column
				        label="操作"
						align="center"
						header-align="center"
						width="100">
						<template slot-scope="scope" >
							<div v-if="scope.row.status==3" class="xz" @click="xzOnLoad(scope.row.dclj)" >下载</div>
							<div v-else>下载</div>	
						</template>
				    </el-table-column>
					<el-table-column
					    prop="creator"
						align="center"
						header-align="center"
					    label="创建人">
					</el-table-column>
					<el-table-column
					    prop="createdate"
						align="center"
						header-align="center"
					    label="创建时间">
					</el-table-column>
					<el-table-column
					    prop="rwmc"
						align="center"
						header-align="center"
					    label="下载任务">
					</el-table-column>
					<el-table-column
						align="center"
						header-align="center"
					    label="任务说明">
						<template slot-scope="scope" >
							<div v-html="scope.row.rwsm"></div>
						</template>
					</el-table-column>
				</el-table>	
				<el-pagination
					class="textLeft"
					v-if="pageInfo.total"
					background
				    @current-change="handleCurrentChange"
				    :current-page.sync="pageInfo.pageNum"
				    :page-size="pageInfo.count"
				    layout="total, prev, pager, next"
				    :total="pageInfo.total">
				</el-pagination>						
			</el-card>
			<yj-cxr-list
				:visible="visible"
				:cxrList="czrList"
				:maxLength="1"
				:clkShow="false"
				@chooseCxrList="chooseCzrList" 
				@cancleCxrList="chooseCancel"			 
			></yj-cxr-list>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'TaskList',
		data(){
			return {
				visible:false,
				czrList:[], //操作人
				query:{
					cuId:"", //创建者 ,
					dateStart:"",
					dateEnd:"",
					pageNum:1,
					count:10,
					mkbh:"", // 模块编号
					mkmc:"", // 模块名称
					rwlx:"", //任务类型 1导入2导出 ,
					rwmc:"", //任务名称 ,
					status:"", //任务状态1正在执行2终止3完成4失败
				},
				cuName:"",
				pageInfo:{ //分页数据
					count:10,
					pageNum:1,
					total:0,
				},
				
				taskList:[], //导出列表
				headerStyle:{
					"background-color":"#EEEEEE",
				},
				
			}
		},
		mounted(){
			this.query.dateEnd = this.$common.getToDay();
			this.query.dateStart = this.$common.getAppointDay(this.$common.getToDay(),-6);
			this.getTaskList();
		},
		methods:{
			// 不可选择日期
			pickerOptions(){
				let today = this.$common.getToDay(); 
				let date = new Date(today).getTime()
				return {
					//定义不可选择时间 暂无预约功能 查找有效日期
					// 查询出差申请单预订行程日期可否变更 0不可更改  1 按行程更改 2 出差申请单日期范围更改
					disabledDate(time) {
						return time.getTime() > date;
					},
				}
			},
			// 查询列表
			getTaskList(){
				let params = {
					cuId:this.query.cuId, //创建者 ,
					dateStart:this.query.dateStart,
					dateEnd:this.query.dateEnd,
					pageNum:this.pageInfo.pageNum,
					count:10,
					mkbh:this.query.mkbh, // 模块编号
					mkmc:this.query.mkmc, // 模块名称
					rwlx:this.query.rwlx, //任务类型 1导入2导出 ,
					rwmc:this.query.rwmc, //任务名称 ,
					status:this.query.status, //任务状态1正在执行2终止3完成4失败
				}
				this.$conn.getConn('travelRequest.findTaskList')(params,(res)=>{
					let data = res.data || {};
					this.taskList = data.list || [];
					this.pageInfo.total = data.total || 0;
					this.pageInfo.pageNum = data.pageNum;
				},(err)=>{
					
				})
			},
			// 获取开始时间
			getStartDate(e){
				if(this.query.dateStart>this.query.dateEnd){
					this.query.dateStart = this.query.dateEnd
				}
			},
			// 获取结束时间
			getEndDate(){
				if(this.query.dateStart>this.query.dateEnd){
					this.query.dateStart = this.query.dateEnd
				}
			},
			// 分页方法
			handleCurrentChange(e){
				this.pageInfo.pageNum = e;
				this.getTaskList();
			},
			// 下载文档
			xzOnLoad(e){
				window.location.href = e;
			},
			
			// 获取操作人信息
			getCzrInfo(){
				this.visible = true;
			},
			// 选中操作人
			chooseCzrList(list){
				if(list.length>0){
					this.query.cuId = list[0].empId || '';
					this.cuName = list[0].empName;
					this.czrList = list;
					this.visible = false;
				}else{
					this.query.cuId = '';
					this.cuName = '';
					this.czrList = [];
					this.visible = false;
				}
			},
			// 关闭控件
			chooseCancel(){
				this.czrList = this.czrList?this.$common.deepCopy(this.czrList): [];
				this.visible = false;
			}
		}
	}
</script>

<style lang="scss" scoped>
	.el-card.is-always-shadow{
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
	}
	.el-tabs--border-card{
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
	}
	.task{
		background-color: #F1F4F9;
		height: 100%;
		.task-main{
			width: 1200px;
			margin: 0 auto;
			padding-top: 10px;
			/deep/ .el-input__inner{
				border: none;
				background-color: #F5F5F5;
				height: 40px;
				line-height: 40px;
				border-radius: 0;
				font-size: 14px;
			}
			.flex{
				display: flex;
			}
			
			.setDateInput{
				width: 20%;
				.el-input{
					width: 110px;		
				}
				/deep/ .el-input__inner{
					width: 140px;
					padding-left: 15px;
					padding-right: 15px
				}
				
				/deep/ input::-webkit-input-placeholder {
					color: #B3B3B3;
				  }
				/deep/ input::-moz-input-placeholder {
					color: #B3B3B3;
				  }
				/deep/ input::-ms-input-placeholder {
					color: #B3B3B3;
				  }
			}
			.btn{
				padding: 0 30px;
				font-size: 14px;
				margin-left: 30px;
			}
			
			
		}
		.t-table{
			margin-top: 20px;
			.xz{
				color: #007FE9;
				cursor: pointer;
			}
			.textLeft{
				text-align: left;
			}
			.el-pagination{
				padding:5px 0;
			}
			/deep/ .el-pagination__total{
					background-color: #f4f4f5;
					padding: 0 5px;
			}
		}
	}
</style>
