<template>
	<div class="ol-main">
		<!-- 全部订单列表 -->
		<all-order-list 
			:orderList="orderList" 
			:titleType="titleType" 
			:tabIndex="tabIndex" 
			v-if="titleType!=4" 
			@resetOrderList="resetOrderList"
			:params20032="params20032"
			></all-order-list>
		<!-- 需求订单列表 -->
		<need-order-list :orderList="orderList" :tabIndex="tabIndex" :titleType="titleType" v-if="titleType==4"></need-order-list>
		
		<el-pagination
			class="textLeft"
			v-if="pageInfo.total"
			background
		    @current-change="handleCurrentChange"
		    :current-page.sync="pageInfo.pageNum"
		    :page-size="pageInfo.count"
		    layout="total, prev, pager, next"
		    :total="pageInfo.total">
		</el-pagination>
	</div>
</template>

<script>
	import AllOrderList from './orderList/AllOrderList';
	import NeedOrderList from './orderList/NeedOrderList';
	
	export default{
		name: 'OrderList',
		props:{
			// 订单列表
			orderList:{
				type:Array,
				default (){
					return []
				}
			},
			
			//分页
			pageInfo:{
				type:Object,
				default (){
					return {}
				}
			},
			//业务类型
			titleType:{
				type: [Number,String],
				default: 0 || '0',
			},
			// 当前选项， 
			tabIndex:{
				type: [Number,String],
				default: 1 || '1',
			},
			params20032:{
				type: [Number,String],
				default: 1 || '1',
			}
		},
		data(){
			return {
				orderItemList:[]
			}
		},
		components:{
			'all-order-list': AllOrderList,
			'need-order-list':NeedOrderList,
			
		},
		methods: {
			// 当前页码
			handleCurrentChange(e){
				this.$emit('choosePageNum',e)
			},
			// 重置订单列表
			resetOrderList(number,tabIndex){
				if(tabIndex){	
				}else{
					tabIndex = 1;
				}
				this.$emit('reSetOrderList',number,tabIndex)
			},
		}
	}
</script>

<style lang="scss" scoped>
	.ol-main{
		font-family: '微软雅黑';
		font-weight: 400;
		color: #424242;
		.ol-title{
			padding: 30px 0;
			font-size: 12px;
			font-weight: bold;
			color: #808080;
			line-height: 12px;
			border-bottom:  1px solid #F2F2F2;
		}
		margin-top: 10px;
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		/deep/ .el-pagination__total{
				background-color: #f4f4f5;
				padding: 0 5px;
		}
		.setHiden{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.setPrice{
			font-size: 18px;
			font-weight: bold;
			color: #FF9524;
			line-height: 70px;
		}
		.textLeft{
			text-align: left;
		}
		.textRight{
			text-align: right;
		}
		.textCenter{
			text-align: center;
		}
		.bold{
			font-weight: bold;
		}
		.w65{
			width: 65px;
		}
		.w80{
			width: 80px;
		}
		.w100{
			width: 100px;
		}
		.w120{
			width: 120px;
		}
		.w130{
			width: 130px;
		}
		.w140{
			width: 140px;
		}
		.w150{
			width: 150px;
		}
		.w190{
			width: 190px;
		}
		.w210{
			width: 210px;
		}	
	}
</style>
