<template>
	<div class="tkp-main">
		<div v-if="orderType==11002 || orderType == 11005">
			<div class="marTop25">
				<span class="title">退票订单已提交，
					<span>{{refundChangeInfo.refundStatusRemark?refundChangeInfo.refundStatusRemark:'等待办理'}}</span>
				</span>
			</div>
			<div class="gq-top">
				<div v-for="tkItem in refundChangeInfo.refundRangeList" :key="tkItem.rangeId">
					退票航程：{{tkItem.departDate}} - {{tkItem.arriveDate}} ({{tkItem.week}}) {{tkItem.airlineName}}
				</div>
				<div class="marTop20">
					<span>乘机人：{{refundChangeInfo.tkOrderPsgBean.psgName}}</span> 
					<span class="seexq" @click="goToRefundDetail(refundChangeInfo.refundId)">查看详情</span>
				</div>
			</div>		
		</div>
		<div v-if="orderType == 11003 || orderType == 11006">
			<div class="marTop25">
				<span class="title">改签订单已提交，
					<span>{{refundChangeInfo.orderStatusRemark?refundChangeInfo.orderStatusRemark:'等待办理'}}</span>
				</span>
			</div>
			<div class="gq-top" >
				<div v-for="(item, index) in refundChangeInfo.rangeList" :key="index">
					改签航程：{{item.nDepartDate}} - {{item.nArriveDate}} {{ ' ( '+item.nDepartWeek+ ' ) '+item.nDepartCityName+' 至 '+item.nArriveCityName}}
				</div>
				<div class="marTop20">
					<span>乘机人：{{refundChangeInfo.psg}}</span> 
					<span class="seexq" @click="goToChangeDetail(refundChangeInfo.changeId)">查看详情</span>
				</div>
			</div>	
		</div>
			
	</div>
</template>

<script>
	export default{
		name: 'TkPage',
		props:{
			refundChangeInfo:{
				type:Object,
				default(){
					return {};
				}
			},
			orderType:{
				type:String,
				default:'',
			}
			
		},
		methods:{
			goToChangeDetail(changeId){
				this.$emit('goToChangeDetail',changeId)
			},
			goToRefundDetail(refundId){
				this.$emit('goToRefundDetail',refundId)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.tkp-main{
		.gq-top{
			margin: 40px 0;
		}
		.gq-top-i{
			margin: 20px 0 40px 0;
		}
		.marTop20{
			margin-top: 20px;
		}
		.marTop25{
			margin-top: 25px;
		}
		.title{
			font-size: 24px;
			color: #006BB9;
		}
		.seexq{
			cursor: pointer;
			color: #006BB9;
			margin-left: 20px;
		}
		.seexq:hover{
			color: #007FE9;
		}
	}
</style>
