<template>
	<div class="rd-main" :style="{'min-height':height+'px'}">
		<div class="main">
			<el-card>
				<div class="rd-top">
					<h1 class="to-title">退票订单 
						<span class="to-title-span" v-show="refundDetail.payDate">退款时间：{{refundDetail.payDate}}</span>
					</h1>
					<div class="to-status">
						<span >订单状态：</span>
						<span class='colorzs' >{{refundDetail.refundStatusRemark}}</span>
					</div>
				</div>
				<div class="rd-f">
					
					<div>
						<span>原订单号：{{refundDetail.orderNo}}</span>			
						<span class="right" style="float: right;">应退合计：<span style="color: orange;font-size: 20px;">￥{{refundDetail.cuRefundAmount}}</span></span>
					</div>
					<div>申请时间：{{refundDetail.applyDate}}</div>
					<div>服务商：{{refundDetail.supplierName}}</div>
					<div>
						<span class="orange" style="color:orangered">{{refundDetail.refundType==1?'自愿':'非自愿'}}</span>
						<span>退票原因：
							<span v-show="refundDetail.refundType==2">{{refundDetail.refundResaon || refundDetail.refundResaonVip}}</span>
							<span v-show="refundDetail.refundType==1">{{refundDetail.refundResaonVip || refundDetail.refundResaon}}</span>
						</span>
						<span v-show="refundDetail.refundProveUrl">&#x3000;<a :href="refundDetail.refundProveUrl" target="_blank">查看退票证明</a></span>
					</div>
					
				</div>
			</el-card>
			<el-card class="marT20">
				<h1 class="rd-title">退票信息</h1>
				<div class="rd-info" v-for="(item, index) in refundDetail.refundRangeList " :key="index">
					<div class="borderR rd-l1">
						<img :src="item.logo" class="rd-img">
						<span class="rd-text">{{item.airlineName}}</span>
					</div>
					<div class="borderR rd-l2">
						<div class="rd-l2-l" >
							<p class="time">{{item.departTime}}</p>
							<p class="city">{{item.departAirportName?item.departAirportName:'-'}}{{item.departTerminal}}</p>
						</div>
						<div class="rd-l2-c">
							<p>{{item.flightTime}}</p>
							<p class="rd-jt"></p>
						</div>
						<div class="rd-l2-r">
							<p class="time">{{item.arriveTime}}</p>
							<p class="city">{{item.arriveAirportName?item.arriveAirportName:'-' }}{{item.arriveTerminal}}</p>
						</div>
					</div>
					<div class="borderR rd-l3">{{item.departDate+' '+item.week}}</div>
					<div class="borderR rd-l4">{{item.cabinName?item.cabinName:"-"}}</div>
					<el-button type="text" class="rd-l5" @click="visible = true">退改签规则</el-button>
					<el-dialog title="退改签规则" class="tggzDialog" :visible.sync="visible" :lock-scroll="false">
						<div class="tgqDialog">
							<div class="gzleft">退票规则</div>
							<div class="gzright">
								<p>{{item.refundRule}}</p>
							</div>
						</div>
						<div class="tgqDialog marT20">
							<div class="gzleft">改签规则</div>
							<div class="gzright">
								<p>{{item.changeRule}}</p>
							</div>
						</div>
						<div class="tgqDialog marT20">
							<div class="gzleft">升舱规则</div>
							<div class="gzright">
								<p>{{item.upgradeRule}}</p>
							</div>
						</div>
						<span slot="footer" class="dialog-footer">
							<el-button type="primary" @click="visible = false">关 闭</el-button>
						</span>
					</el-dialog>
				</div>
				<div class="rd-person" v-for=" psgItem in refundDetail.refundPsgList" :key="psgItem.psgId">
					<div class="rd-pl1">
						<span>{{psgItem.psgName}}</span>
						<span>{{' ('+ psgItem.idNo +')'}}</span>
					</div>
					<div class="rd-pl2">
						票号：{{psgItem.tkno}}
					</div>
					<div class="rd-pl3">
						<span v-if="psgItem.psgType==1">成人</span>
						<span v-if="psgItem.psgType==2">儿童</span>
						<span v-if="psgItem.psgType==3">婴儿</span>
					</div>
					<div class="rd-pl4">
						票价：¥{{psgItem.salePrice}}
					</div>
					<div class="rd-pl5">
						机建燃油：¥{{psgItem.tax + psgItem.airportFee }}
					</div>
					<div class="rd-pl6">
						服务费：¥{{psgItem.serviceFee}}
					</div>
				</div>
			</el-card>

			<yj-contact-info :marginTop="'20px'" ></yj-contact-info>
			<el-card v-if="refundDetail.appId && refundDetail.vipStatus==0" class="marT20">
				<div class="rd-title">审批人规则</div>
				<div class="rd-jd">
					<yj-approval-rule-detail :detail="detail" :appId="refundDetail.appId"></yj-approval-rule-detail>	
				</div>	
			</el-card>
			<el-card v-if="refundDetail.appId && refundDetail.vipStatus>0 && refundDetail.vipStatus<4" class="marT20">
				<div class="rd-title">审批进度</div>
				<div class="rd-jd">
					<yj-approval-progress :orderId="query.orderId" :orderType="query.orderType" :resetspjd="resetspjd" @showProgress="showProgress"></yj-approval-progress>
				</div>	
			</el-card>


			<!-- <yj-need-my-approval v-if="isSpStatus && query.workItemId" :workItemId="query.workItemId" :orderId="query.orderId" @spSuccess="resetDetail"></yj-need-my-approval> -->
			<yj-approval-cao-zuo 
				:appType="query.appType" 
				:workItemId="query.workItemId" 
				:orderType="query.orderType" 
				:orderId="query.orderId"
				@closePage="spResetDetail" 
			>
				<template slot="orderFooterSlot">
					<div class="marT20">
						<el-button class="button" v-show="refundDetail.isShowApproval==1"  @click="orderCaoZuo(3,refundDetail)">送 审</el-button>	
						<el-button class="button" v-show="refundDetail.isShowCancleRefund ==1"  @click="orderCaoZuo(4,refundDetail)">取消退票</el-button>	
						<el-button class="button" v-show="refundDetail.isShowCancelApprove ==1"  @click="orderCaoZuo(16,refundDetail)">撤 回</el-button>
					</div>
				</template>
			</yj-approval-cao-zuo>
		</div>
		
	</div>
</template>

<script>
import caozuoMixin from '@/mixin/models/caozuoMixin.js';
	export default{
		name: 'TkRefundDetail',
		mixins:[caozuoMixin.commonConfig],
		data() {
			return {
				refundDetail: {}, // 退票详情
				visible: false,
				// query:{},
				isSpStatus: false,
				resetspjd: false,
				detail:{
					appId: '',
					currentLevel: 0,
					flowState: 3,
				}
			}
		},
		computed:{
			userInfo(){
				return this.$common.getUserInfo() || {};
			},
			empId(){
				return this.userInfo.empInfo.empId;
			},
			height(){
				let height = document.body.clientHeight;
				return height
			},
			query(){
				return this.$route.query || {}
			}
		},
		mounted() {
	
			this.getTkRefundDetail();
		},
		methods: {
			spResetDetail(){
				this.$router.replace({
					path: this.$dict.detailConfig(11002),
					query: {
						...this.$route.query,
						appType:2  // 已审批     0 订单详情 1.待审批 2.已审批 3. 审批过期 
	
					}
				})
				this.getTkRefundDetail();
				this.resetspjd = true;
			},

			// 机票退票详情   GET /2.0.0/et/refundDetail
			getTkRefundDetail(){
				let obj = {
					refundId:  this.query.orderId
				}
				let queryUrl = "tkQuery2023.refundDetail2"; // 'tkRefund.refundDetail'
				this.$conn.getConn(queryUrl)(obj,(res)=>{
					let data = res.data || {}
					this.refundDetail = data;
					
					this.detail.appId = data.appId;

				},(err)=>{
					this.$message.error(err.tips)
				})
			},
			// 审批进度
			showProgress(info,currentSpjd,status){
				// this.spjd = info.spjd; //当前审批进度（1/2）
				this.resetspjd = false;
				this.detail.currentLevel = currentSpjd; //当前到谁审批
				this.detail.flowState = status;  // 当前审人状态
				this.getCurrentSprLevel()
			},
			// 获取当前审批人层级列表
			getCurrentSprLevel(){
				let params = {
					spgzId: this.detail.appId
				}
				this.$conn.getConn('commu.appRulesDetail')(params,(res)=>{
					let data =  res.data || {};
					let applyList = data.gznr || [];
					this.getGznrList(applyList)
				},(err)=>{
					
				})
			},
			// 获取审批人列表
			getGznrList(list){
				if(list.length>0){
					// 遍历审批人
					for(var i=0; i<list.length; i++){
						// 需要审批的当前层级 与 列表中层级相等表示 轮到当前人审批
						if(list[i].level == this.detail.currentLevel){
							// 判断登录人empid 与 层级中id 是否相等， 相等则可以审批
							for(var j=0; j<list[i].empList.length;j++){
								if(list[i].empList[j].empId == this.empId && this.detail.flowState==3){
									this.isSpStatus = true;
								}else{
									this.isSpStatus = false;
								}
							}
						}
					}
				}
				
			},
			// 刷新页面
			resetDetail(){
				this.getTkRefundDetail();
				this.resetspjd = true;
			},
			orderCaoZuo(type,detail){
				if(type == 3){ // 送审
					this.$confirm('您确认要送审吗?', '温馨提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
						}).then(() => {

							var userInfo = this.$common.getUserInfo();
							//判断是否需要二次送审
							if(userInfo.vipCorp.bookStyle == 2 && detail.isShowCancleRefund == 1) {
								this.twice = 1
							}
							let queryUrl = "commu.sendApp";
							var queryString = {
								// id:detail.orderId ,
								orderId: detail.refundId,
								orderType: this.query.orderType || '11002',
								spgzId: detail.appId,
								againApp: this.twice
							}
							this.$conn.getConn(queryUrl)(queryString,(res)=>{
								this.$message({
									type: 'success',
									message: '送审成功！'
								});  
								this.resetDetail();
							},(err)=>{
								this.$message.error(err.tips)
							})
						}).catch(() => {
						              
						});
				}else if(type == 4){  //取消退票   POST /1.0.0/et/refundCancel

					this.$confirm('您确认要取消退票?', '温馨提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
						}).then(() => {
							let queryUrl = "tkQuery2023.refundCancel";
							var queryString = {
								id:detail.refundId ,
								version:detail.version
							}
							this.$conn.getConn(queryUrl)(queryString,(res)=>{
								this.$message({
									type: 'success',
									message: '取消退票成功！'
								});  
								this.resetDetail();
							},(err)=>{
								this.$message.error(err.tips)
							})
						}).catch(() => {
						              
						});

				}else if(type == 16){
					this.goToCancelApprove({
							orderId: detail.refundId,
							orderType:detail.orderType || '11002',
						},(res)=>{

							this.resetDetail();
						},(err)=>{
	 	
					})
				}	
			},
		}
	}
</script>

<style lang="scss" scoped>	
	.el-card.is-always-shadow{
		box-shadow: 1px 2px 2px 0px rgba(13, 2, 0, 0.02);
		border-radius: 5px ;
	}
	.rd-main{
		background-color: #EEF1F6;
		font-family: '微软雅黑';
		/deep/ .el-card__body{
			padding: 30px;
		}
		.main{
			width: 1200px;
			margin: 0 auto;
			padding: 40px 0 50px;
			height: 100%;
			.marT20{
				margin-top: 20px;
			}
			.borderR{
				border-right: 1px solid #D1DBE7;
			}
			.rd-top{
				display: flex;
				justify-content: space-between;
				.to-title{
					font-size: 24px;
					font-weight: 600;
					color: #424242;
					line-height: 24px;
					.to-title-span{
						font-size: 15px;
						font-weight: 400;
						color: #808080;
						padding-left: 20px;
						line-height: 24px;
					}
				}
				.to-status{
					font-size: 18px;
					font-weight: 400;
					color: #808080;
					padding-top: 2px;
					line-height: 24px;
					.colorzs{
						color: #FF8400;
					}
				}
				
			}
			.rd-f{
				margin-top: 29px;
				border-top: 1px solid #E6E6E6;
				font-size: 15px;
				font-weight: 400;
				color: #424242;
				text-align: left;
				div{
					padding-top: 22px;
				}
			}
			.rd-title{
				font-size: 24px;
				font-weight: 600;
				color: #424242;
				line-height: 24px;
				text-align: left;
			}
			.rd-info{
				display: flex;
				height: 80px;
				background: #F3F5F8;
				border-radius: 5px;
				margin-top: 27px;
				box-sizing: border-box;
				padding: 20px 0;
				.tggzDialog{
					/deep/ .el-dialog {
						width: 600px;
					}
					.tgqDialog {
						display: flex;
						justify-content: space-between;
						text-align: left;
						border: 1px solid #e6e6e6;
					
						.gzleft {
							display: flex;
							align-items: center;
							justify-content: center;
							background-color: #FAFAFA;
							width: 92px;
							min-height: 40px;
							border-right: 1px solid #e6e6e6;
							font-size: 15px;
						}
					
						.gzright {
							width: 508px;
							line-height: 24px;
							padding: 15px 15px 15px 20px;
						}
					}
				}
				.rd-l1{
					width: 215px;
					display: flex;
					align-items: center;
					justify-content: center;
				}
				.rd-l2{
					width: 480px;
					display: flex;
					padding:0 10px;
					.rd-l2-l,.rd-l2-r{
						width: 153px;
						.time{
							font-weight: bold;
						}
						.city{
							padding-top:5px;
						}
					}
					.rd-l2-c{
						width: 154px;
						.rd-jt{
							height: 1px;
							background-color: #D1DBE7;
							width: 100%;
							margin-top: 10px;
						}
					}
				}
				.rd-l3{
					width: 194px;
					line-height: 40px;
				}
				.rd-l4{
					width: 120px;
					line-height: 40px;
				}
				.rd-l5{
					font-size: 15px;
					width: 152px;
				}
				.rd-img{
					width: 25px;
					height: 25px;
				}
				.rd-text{
					padding-left: 5px;
					font-size: 14px;
					font-weight: bold;
					color: #223344;
					
				}
			}
			
			.rd-person{
				display: flex;
				height: 40px;
				background: #F3F5F8;
				border-radius: 5px;
				margin-top: 15px;
				box-sizing: border-box;
				padding: 10px 0;
				.rd-pl1,.rd-pl2,.rd-pl3,.rd-pl4,.rd-pl5{
					border-right: 1px solid #D1DBE7;
				}
				.rd-pl1{
					width: 316px;
				}
				.rd-pl2{
					width: 209px;
					
				}
				.rd-pl3{
					width: 84px;
				}
				.rd-pl4{
					width: 174px;
				}
				.rd-pl5{
					width: 179px;
				}
				.rd-pl6{
					width: 174px;
				}
			}
			.rd-jd{
				border: 1px solid #e6e6e6;
				padding: 20px;
				margin-top: 30px;
			}
		}
		
	}
</style>
