<template>
	<div class="header"> 
		<div class="headerImg">
			<el-image :src="banner" fit="cover" class="img"></el-image>
		</div>
		<div class="hp-main">
			<div class="main">
				<div class="navTop">
					<div >
						<img :src="xakLogo" class="logoImg">
					</div>
				</div>
				<div class="login-main">
					<div class="zc-left">
						<img :src="xakLogoText" class="xak-wbImg">
					</div>
					<div class="login-right">
						<div class="setBorder">
							<div class="xakgh" :class="{isActive:activeIndex==1}" @click="chooseCurrentType(1)">工号登录</div>
							<div class="xakgh" :class="{isActive:activeIndex==2}" @click="chooseCurrentType(2)">手机登录</div>
						</div>
						<div>
							<div class="ghform" v-if="activeIndex=='1'">
								<el-form ref="form" key="form" :model="form" :rules="rules" label-width="0px">
									<el-row >
										<el-col :span="18">
											<el-form-item prop="corpNo">
												<el-input  :placeholder="$t('login.corpNo_holder')"
													v-model="corpNo" clearable>
													<i slot="prefix" class="iconfont yj-icon-qiyebangonglou" :class="{iconColor:form.corpNo}"></i>
												</el-input>
											</el-form-item>
											<el-form-item prop="name">
												<el-input :placeholder="$t('login.name_holder')" v-model="name" clearable>
													<i slot="prefix" class="iconfont yj-icon-geren" :class="{iconColor:form.name}"></i>
												</el-input>
											</el-form-item>
											<el-form-item prop="password">
												<el-input :placeholder="$t('login.password_holder')" v-model="form.password"
													type="password" clearable>
													<i slot="prefix" class="iconfont yj-icon-mima" :class="{iconColor:form.password}"></i>
												</el-input>
											</el-form-item>
											<div class="zc-menu">								
												<div class="zcfx" @click="goToRegister">注册</div>
												<div class="zcfx" @click.prevent="openMsg">忘记密码</div>
											</div>
										</el-col>
										<el-col :span="6">
											<div class="button" @click="Login('form')">
												<div style="padding-top: 40px;"  >登 录</div>
												<div class="iconfont yj-icon-jiantou_xiangyou_o xak_ant"></div>
											</div>
										</el-col>
									</el-row>
								</el-form>
							</div>
							<div  class="sjform" v-if="activeIndex=='2'">
								<el-form ref="telForm" key="telForm" :model="telForm" :rules="telRules" label-width="0px">
									<el-row >
										<el-col :span="18">
											<el-form-item prop="phone" style="margin-bottom: 20px;">
												<el-input  :placeholder="$t('login.phone_tip')"
													v-model="telForm.phone" clearable>
													<i slot="prefix" class="iconfont yj-icon-shouji" :class="{iconColor:telForm.phone}"></i>
												</el-input>
											</el-form-item>
											<el-form-item prop="verifyCode" style="margin-bottom: 20px;">
												<el-input :placeholder="$t('login.verifyCode')" maxlength="6" v-model="telForm.verifyCode">
													<i slot="prefix" class="iconfont yj-icon-yanzhengma" :class="{iconColor:telForm.verifyCode}"></i>
													<i slot="suffix" class="suffix-item" @click.prevent="getYzmCode">
														{{showCode?$t('login.resend')+'('+ timer +')':$t('login.getYzmCode')}}
													</i>
												</el-input>
											</el-form-item>
											<div class="zc-menu">
												<div class="zcfx" @click="goToRegister">注册</div>
												<div class="zcfx" @click.prevent="openMsg">忘记密码</div>
											</div>
										</el-col>	
										<el-col :span="6">
											<div class="button" @click="Login('telForm')">
												<div style="padding-top: 20px;" >登 录</div>
												<div class="iconfont yj-icon-jiantou_xiangyou_o xak_ant"></div>
											</div>
										</el-col>
									</el-row >
								</el-form>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	
</template>

<script>
	import {
		mapState,
		mapGetters,
		mapActions,
		mapMutations
	} from "vuex";
	
	export default {
		name: 'Header',
		props:{
			compInfo:{
				type:Object,
				default(){
					return {};
				}
			}
		},
		data() {
			let valiPhone = (rule, value, callback) => {
			        var myreg = /^[1][3,4,5,6,7,8,9][0-9]{9}$/;
			        if(!myreg.test(this.telForm.phone)){
						callback(new Error(this.$t("register.invalidPhone")));
			        }else {
						callback();
			        }	
			    };
			return {
				xakLogo: require("@/assets/image/xak/xak_logo.png"),
				xakLogoText: require("@/assets/image/xak/xak_text.png"),
				form: {
					corpNo: '',//YJSL
					name: "",//SHC
					password: "",//123456
				},
				telForm:{
					phone: '',
					verifyCode:'',
				},
				rules: {
					corpNo: [{
						required: true,
						message: this.$t("login.corpNo_tip"),
						trigger: "blur"
						// validator: checkone
					}],
					name: [{
						required: true,
						message: this.$t("login.name_tip"),
						trigger: "blur"
						// validator: checkone
					}],
					password: [{
						required: true,
						message: this.$t("login.password_tip"),
						trigger: "blur"
					}]
				},
				telRules:{
					phone:[{required: true,message:this.$t("login.phone_tip"),trigger: "blur"},
							{validator:valiPhone,trigger: "blur"}],
					verifyCode:[{required: true,message:this.$t("login.verifyCode_tip"),trigger: "blur"}],
					
				},
				parameterData: {}, //企业参数信息

				activeIndex: 1, // 菜单切换
				
				showCode: false, // 验证码显示
				timer: 60, //验证码有效时间
				
				loginType: 1,// 登录类型
				loading: false,
				
		
				configData:{},// 公司信息
				passwordInfo:{},
				clearTime:'',
				banner: require("@/assets/image/xak/banner.jpg"),
			}
		},
		computed:{
			corpNo:{
				get(){
					return this.form.corpNo
				},
				set(val){
					this.form.corpNo = val.toUpperCase();
				}
			},
			name:{
				get(){
					return this.form.name
				},
				set(val){
					this.form.name = val.toUpperCase();
				}
			},
		},
		mounted() {
			this.configData = this.$common.getConfigData() || {};// 公司信息
			let loginPersonInfo = this.$common.localget('loginPersonInfo') || {};
			
			if(loginPersonInfo.corpNo){
				this.form.corpNo = loginPersonInfo.corpNo.toUpperCase();
			}
			if(loginPersonInfo.name){
				this.form.name = loginPersonInfo.name.toUpperCase();
			}
		},
		beforeDestroy(){
			clearTimeout(this.clearTime)
		},
		methods: {
			...mapActions({
				GETUserInfo: 'common/getUserInfo',
			}),
			//选择登录类型
			chooseCurrentType(e){
				this.activeIndex = e;
				this.loginType = e;
				this.loading = false;
			},
			// 获取验证码
			getYzmCode(){
				if(!this.telForm.phone){
					this.$message.warning(this.$t("login.phone_tip"))
					return 
				}
				if(this.showCode){
					return 
				}
				this.showCode = true;
				this.getTimer();
				let queryParams = {
						phoneNumber: this.telForm.phone,
						type:'登录',
						compId:this.configData.compId,
				}
				this.$conn.getConn('user.codeGet',{jsfile:false})(queryParams,(res)=>{
					
				},(err)=>{
					this.$message.error(err.tips)
					clearTimeout(this.clearTime)
					this.timer == 0;
					this.showCode = false;
				})
			},	
			// 计时器时间
			validationTime(){
				this.clearTime = setTimeout(()=>{
					if(this.timer == 0){
						this.showCode = false;
						this.timer = 60;
					}else{
						this.getTimer()
					}
				},1000)
			},
			// 倒计时
			getTimer(){
				this.timer--;
				this.validationTime()
			},
			// 注册 1个人 2企业
 			goToRegister(){
				this.$router.push({path:'/corpRegister'})
			},
			Login(formName) {
			
				this.$refs[formName].validate(valid => {
					if (valid) {
						let obj = {
							corpNo: this.form.corpNo,
							name: this.form.name,
						}
						this.$common.localset('loginPersonInfo',obj)
						this.loading = true;
						var queryString = {};
						if(this.loginType == 1){
							queryString.loginType = this.loginType;
							queryString.corpNo = this.form.corpNo;
							queryString.loginName = this.form.name;
							queryString.password = this.form.password;
						}else if(this.loginType == 2){
							queryString.loginType = this.loginType;
							queryString.compId = this.configData.compId;
							queryString.loginName = this.telForm.phone;
							queryString.password = this.telForm.verifyCode;
						}
						this.loading = true;
						
						if(this.$parent.toLoginAjax){
							// 调用父节点的方法
							this.$parent.toLoginAjax(queryString,{},(res)=>{
								this.loading = false;
								clearTimeout(this.clearTime)
								this.timer == 0;
							},(err)=>{
								clearTimeout(this.clearTime)
								this.timer == 0;
								this.loading = false;
								this.$message.error(err.tips)
							})
						}
						
					}
				});
			},
			// 用户详情
			getUserInfo() {

				let vm = this;
				this.GETUserInfo({
					vm,
					cb: (data) => {
						this.$router.replace({
							name: "Home"
						})
					}
				})


			},
			getMenu() {
				this.$http.get("getMenu").then(res => {
					// 提取菜单数组，交给本地存储
					let menu = res.data.menu;
					// 将原始数据进行本地存储
					localStorage.menu = JSON.stringify(menu);
					// 解析出路由配置表
					const _routes = generateRoutes(menu);
					// 动态加载路由配置表
					router.addRoutes(_routes);
					// 跳转到首页
					this.$router.push("/notes");
				});
			},
			openMsg() {
				this.$router.push({path:"/forgetPassword"})
				// 注意这里使用了国际化
				// this.$message.warning(this.$t("login.info"));
			},
			getPassword(val){
				if(val){
					
					let obj = {
						passwordInfo: this.form.password,
					}
					this.$common.localset('passwordInfo',obj)
					this.$common.localset('isMemery',true)
				}	
			}
		},
		
	}
</script>

<style lang="scss" scoped>
	.header{
		.headerImg{
			width:100%;
			height:791px;
			position: absolute;
			left:0;
			top:0;
			background-color: #333333;
			z-index: -10;
			.img{
				width:100%;
				height:100%;
				object-fit:cover;
				
			}
		}
	}
	.hp-main {
		height: 791px;
		width: 100%;
		
		.main {
			width: 1270px;
			margin: 0 auto;
			font-family: '微软雅黑';
			.navTop {
				display: flex;
				justify-content: space-between;
				padding-top: 35px;
				.logoImg{
					width: 300px;
					height: 56px;
				}
				.tel {
					font-size: 22px;
					font-weight: 100;
					color: #FFFFFF;
					line-height: 28px;
				}

			}

			.login-main {
				display: flex;
				justify-content: space-between;
				margin-top: 105px;

				.zc-left {
					// display: flex;
					// align-items: flex-end;
					margin-top: 150px;
					.xak-wbImg{
						width: 600px;
						height: 128px;
					}

					
				}

				.login-right {
					width: 450px;
					max-height: 290px;
					min-height: 230px;
					background: rgba(255,255,255,0.6);
					border-radius: 10px;
					padding: 0 30px;
					position: relative;
					.el-menu {
						border-bottom: none;
						text-align: center;
					}

					.setBorder {
						display: inline-block;
						position: relative;
						top:-10px;
						display: flex;
						justify-content: space-between;
						.xakgh{
							width: 168px;
							height: 50px;
							font-size: 18px;
							line-height: 50px;
							color: #0B3D95;
							cursor: pointer;
						}
						.xakgh:hover{
							text-decoration: underline;
						}
						.isActive{
							color: #FFFFFF;
							background: #0B3D95;
							letter-spacing: 1px;
							border-radius: 0 0 5px 5px;
							position: relative;
						}
						.isActive::before{
							content: '';
							position: absolute;
							left: -10px;
							border-top: 10px solid transparent;
							border-left: 10px solid #0C2B60;
							border-right: 10px solid transparent;
							border-bottom: 10px solid transparent;
							border-radius: 50%;
							transform: rotate(45deg);
							z-index: 1;    
						}
						.isActive::after{
							content: '';
							position: absolute;
							right: -10px;
							border-top: 10px solid transparent;
							border-left: 10px solid transparent;
							border-right: 10px solid #0C2B60;
							border-bottom: 10px solid transparent;
							border-radius: 50%;
							transform: rotate(-45deg);
							z-index: 1;    
						}
						
					}

					.ghform {
						padding: 19px 0;
						text-align: left;
						input::-webkit-input-placeholder { /* WebKit browsers */
						  font-size: 16px;
						  color:#BBBBBB;
						}
						input::-moz-placeholder {
						 font-size: 16px;
						 color:#BBBBBB;
						}
						input:-moz-placeholder {
						  font-size: 16px;
						  color:#BBBBBB;
						}
						input:-ms-input-placeholder {
						   font-size: 16px;
						   color:#BBBBBB;
						}  
						/deep/ .el-input{
							width: 276px;
							border: none;
							.iconfont{
								font-size: 22px;
							}
							.el-input__inner {
								border: none;
								height: 40px;
								background: #e3e3e3;
								border-radius: 2px;
								line-height: 40px;
								padding-left: 55px;
								cursor: pointer;
							}
					
							.el-input__prefix {
								top: 1px;
								left: 1px;
								width: 40px;
								height: 22px;
								margin: 8px 0;
								background: #e3e3e3;
								font-size: 22px;
								line-height: 22px;
								border-right: 1px solid #BBBBBB;
								color: #BBBBBB;
								cursor: pointer;
							}
						}
						
						/deep/ .iconColor{
							color: #FFC125;// 备用色 00A7FA color: #FFC125;
							
						}
						
						.button{
							width: 99px;
							height: 156px;
							text-align: center;
							font-size: 24px;
							font-weight: 500;
							color: #FFFFFF;
							border-radius: 4px;
							text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
							background: #0B3D95;
							cursor: pointer;
						}
						.xak_ant{
							margin-top: 20px;
							font-size: 30px;
						}
						.button:hover .xak_ant{
							animation: myMove 1s forwards;
						}
						@keyframes myMove{
							0%{
								padding-left:0px;
							}
							50%{
								padding-left:30px;
							}
							100%{
								padding-left:0px;
							}			
							
						}
						.zc-menu {
							display: flex;
							justify-content: space-between;
							width: 276px;
							.zcfx {
								font-size: 14PX;
								color: #666666;
								cursor: pointer;
							}
							.zcfx:hover {
								color: #007FE9;
							}
						}
					}
					
					.sjform{
						padding: 19px 0;
						text-align: left;
						input::-webkit-input-placeholder { /* WebKit browsers */
						  font-size: 16px;
						  color:#BBBBBB;
						}
						input::-moz-placeholder {
						 font-size: 16px;
						 color:#BBBBBB;
						}
						input:-moz-placeholder {
						  font-size: 16px;
						  color:#BBBBBB;
						}
						input:-ms-input-placeholder {
						   font-size: 16px;
						   color:#BBBBBB;
						} 
						/deep/ .el-input{
							width: 276px;
							.iconfont{
								font-size: 22px;
							}
							.el-input__inner {
								border: none;
								height: 40px;
								background: #e3e3e3;
								border-radius: 2px;
								line-height: 40px;
								padding-left: 55px;
								cursor: pointer;
								text-transform:Uppercase;
							}
							.el-input__prefix {
								top: 1px;
								left: 1px;
								width: 40px;
								height: 22px;
								margin: 8px 0;
								background: #e3e3e3;
								font-size: 22px;
								line-height: 22px;
								border-right: 1px solid #BBBBBB;
								color: #BBBBBB;
								cursor: pointer;
							}
							
						}
						
						.suffix-item{
							width: 100px;
							margin: 8px 0;
							display: inline-block;
							text-align: center;
							cursor: pointer;
							overflow: hidden;
							text-overflow: ellipsis;
							white-space: nowrap;
							border-left: 1px solid #BBBBBB;
						}
						
						/deep/ .el-input__suffix{
							line-height: 24px;
							top: 0px;
							right: 1px;
						}
						/deep/ .iconColor{
							color: #FFC125;
						}
						.button{
							width: 99px;
							height: 100px;
							text-align: center;
							font-size: 24px;
							font-weight: 500;
							color: #FFFFFF;
							border-radius: 4px;
							text-shadow: 0px 2px 2px rgba(0, 0, 0, 0.03);
							background: #0B3D95;
							cursor: pointer;
							.xak_ant{
								margin-top: 10px;
								font-size: 30px;
							}
						}
						
						.button:hover .xak_ant{
							animation: myMove 1s forwards;
						}
						@keyframes myMove{
							0%{
								padding-left:0px;
							}
							50%{
								padding-left:30px;
							}
							100%{
								padding-left:0px;
							}			
							
						}
						.zc-menu {
							display: flex;
							justify-content: space-between;
							width: 276px;
							.zcfx {
								font-size: 14PX;
								color: #666666;
								cursor: pointer;
							}
							.zcfx:hover {
								color: #007FE9;
							}
						}
					}
					
					.login-footer{
						width: 100%;
						background: #F0F0F0;
						position: absolute;
						bottom: 0;
						left: 0;
						padding: 10px 0;
						.footer1{
							line-height: 24px;
							font-size: 12px;
							color: #888888;
							vertical-align: sub;
						}
						
						.xyColor{
							color: #007AFF;
							cursor: pointer;
						}
						.xyColor:hover{
							opacity: 0.7;
						}
						.xy_icon{
							font-size: 20px;
							color: #999999;
							padding-right: 5px;
							cursor: pointer;
						}
						.icon_xy{
							color: #007AFF;
						}
						@keyframes shake-slow {  
							0% {  transform: translate(0px, 0px);}  					
							10% {  transform: translate(8px, 0px);}
							20% {  transform: translate(0px, 0px);}
							30% {  transform: translate(7px, 0px);}
							40% {  transform: translate(0px, 0px);}   
							50% {  transform: translate(6px, 0px);}   
							60% {  transform: translate(0px, 0px);} 
							70% {  transform: translate(5px, 0px);}  
							80% {  transform: translate(0px, 0px);} 
							90% {  transform: translate(4px, 0px);} 
							100% {  transform: translate(0px, 0);} 
									 
						 }  
						.shake-slow{  animation:shake-slow 1s infinite ease-in-out; }
					}
				}
			}

			
		}
	}
</style>
