

<template>
    <div class="main">
        <div class="container">
            <yj-progress-edit :statusList="statusList" :currentStatus="currentStatus"></yj-progress-edit>
            <el-card class="box-card">
                <div class="clearfix" slot="header">
                    <span class="fz-22">酒店退订 {{roomInfo.cityName}}</span>
                    <span class="fz-18 ml-10">({{roomInfo.jdmc}})</span>
                </div>
                <div v-if="needApprove && roomInfo.tripType==1">
                    <div class="trp-con-f" v-for="(item, index) in htCnticketList" :key="item.empId">
                        <el-checkbox v-model="item.checked" 
                                    :disabled="item.isReturn==0"
                                    class="checkBox" 
                                    @change="getTicket(item, index)">
                        </el-checkbox>
					<span class="trp-fl1">{{item.psgName}} (手机号{{item.psgPhone}})</span>
				</div>
                </div>

                <el-row class="padB20">
                    <el-col :span="6">
                        <div class="item">
                            <p>酒店名称</p>
                            <p>{{roomInfo.jdmc}}</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="item">
                            <p>房型</p>
                            <p>{{roomInfo.fxmc}}</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="item">
                            <p>间数</p>
                            <p>{{roomInfo.nights}} 晚 {{roomInfo.rzrq}}至{{roomInfo.ldrq}}</p>
                        </div>
                    </el-col>
                    <el-col :span="6">
                        <div class="item boder-none">
                            <p>入住人</p>
                            <p>{{roomInfo.rzrxm}}</p>
                        </div>
                    </el-col>
                </el-row>
                <el-row class="money" type="flex" justify="space-between" >
                    <el-col class="flex" :span="18">
                        <div class="money-item">
                            <span>房间单价：</span>
                            <span>¥{{roomInfo.xsjSum}}</span>
                        </div>
                        <div class="money-item ml-100">
                            <span>服务费：</span>
                            <span>¥{{roomInfo.fwfSum || 0}}</span>
                        </div>
                        <div class="money-item ml-100">
                            <span>取消规则：</span>
                            <span>{{roomInfo.changeRule}}</span>
                        </div>
                    </el-col>
                    <el-col class="flex flex-end" :span="6">
                        <div class="money-item">
                            <span>总房费：</span>
                            <span style="font-size:25px">¥{{roomInfo.totalPrice}}</span>
                        </div>
                    </el-col>
                </el-row>
            </el-card>
            <el-card class="box-card" v-if="needApprove && roomInfo.tripType==1">
			<!-- 审批规则 -->
				<el-row class="padB20 tl-l ">
                    <el-col :span="24">
                        <span  class="fz-22">审批规则</span>
                    </el-col>
                </el-row>
                <el-row class="mt-20 pb-20">
                    <el-col :span="12">
                        <el-select
                            style="width:100%"
                            v-model="chooseSpgz.gzmc" 
                            :loading="loadingSpgzList"
                            value-key
                            automatic-dropdown
                            @focus="getSpgzList()"
                            @change="getCurrentSpgz" 
                            :placeholder="!isWuXuShenPi?'请选择审批规则':'无需审批'"		
                            >
                            <el-option
                            v-for="item in spgzList"
                            :key="item.gzId"
                            :label="item.gzmc"
                            :value="item">
                            </el-option>
                        </el-select> 
                    </el-col>
                </el-row>

		</el-card> 


            <el-card class="box-card">
                <el-row class="padB20 tl-l">
                    <el-col :span="24">
                        <span style="color:red" class="fz-22">*</span>
                        <span  class="fz-22">退订要求</span>
                        <span  class="fz-14 ml-10">企业要求必填</span>
                    </el-col>
                </el-row>
                <el-row class="mt-20 pb-20">
                    <el-col :span="12">
                        <el-select v-model="reFundValue" placeholder="请选择退订原因" style="width:100%">
                            <el-option v-for="item in reasonList" :key="item.id" :label="item.cName" :value="item.cName"></el-option>
                        </el-select>
                    </el-col>
                </el-row>
            </el-card>
            <el-card class="box-card">
                <el-row class="padB20 tl-l">
                    <el-col :span="24">
                        <span  class="fz-22">选择退订房间</span>
                    </el-col>
                </el-row>
                <el-row class="padB20 tl-l mt-20">
                    <el-col :span="24">
                        <el-table :data="tableData" :span-method="objectSpanMethod"  show-summary  sum-text="总价" :header-cell-style="{background:'#FAFAFA',color:'#606266'}"  ref="multipleTable" border   :type="true">
                            <el-table-column  prop="roomNo" label="房型" align="center" ></el-table-column>
                            <el-table-column  prop="custName" label="入住人"  align="center" ></el-table-column>
                            <el-table-column  prop="day" label="日期" align="center" ></el-table-column>
                            <el-table-column  prop="xsj" label="房费" align="center" ></el-table-column>
							<!-- <el-table-column
								label="房费" align="center">
								<template slot-scope="scope">
									{{scope.row.xsj}}
									<span class="red" v-if="isWbPromptBool && scope.row.xsj > roomInfo.clbzj">(超标)</span>
								</template>
							</el-table-column> -->
                            <el-table-column  prop="fwf" label="服务费"  align="center" ></el-table-column>
                            <el-table-column  label="选择" align="center" >
                                <template slot-scope="scope">
                                    <el-radio v-model="radio" label="" v-show='!isShowEarlyDeparture'></el-radio>
                                    <el-checkbox v-model="scope.row.ischeck" v-show='isShowEarlyDeparture'></el-checkbox>
                                </template>
                            </el-table-column>
                        </el-table>
                    </el-col>
                </el-row>
            </el-card>
            <el-card class="box-card">
                <el-row class="padB20 tl-l">
                    <el-col :span="24"> <span class="fz-22"> 联系人</span></el-col>
                </el-row>
                <el-row class="pb-20 mt-20">
                    <el-col :span="3"> 
                        <p>姓名：{{roomInfo.linkman}}</p>                     
                    </el-col>
                    <el-col :span="3"> 
                    <p>电话：{{ roomInfo.linkTel ||roomInfo.linkMobile  }}</p>
                    </el-col>
                </el-row>
            </el-card>
            <el-row class="mt-20">
                <el-button class="padButton" type="primary" @click="handlerRefund">退订提交</el-button>
                <el-button class="padButton"  @click="close">关 闭</el-button>

            </el-row>
        </div>
    </div>
</template>

<script>
import {
mapGetters,
} from 'vuex';
	
export default {
	data() {
		return {
			statusList: [
				{
					status: '1',
					name: '申请',
				},
				{
					status: '2',
					name: '审批',
				},
				{
					status: '3',
					name: '支付',
				},
				{
					status: '4',
					name: '完成',
				},
			],
            empInfo:{},
            loadingSpgzList:false,
            isWuXuShenPi:true, // 是否无需审批
			radio: '',
            chooseSpgz:{},
            htCnticketList:[],
            isShowEarlyDeparture:'',
			reFundValue: '',
			reasonList: [],
            needApprove:false,
            spkzMap:'',
            tripType:'',
			roomInfo: [],
            spgzList:[],
			everyPriceList: [],
			currentStatus: 1,
			tableData: [],
            newOrderId:'',//退订生成的新订单号
		}
	},
	watch: {

	},
	props: {},
	computed: {
		...mapGetters({
			getVipParamterByParNo:'common/getVipParamterByParNo',
		}),
		// 判断是否需要提醒超标
		isWbPromptBool() {
		  let ht20043 = this.getVipParamterByParNo(20043,0),
		      isWb = ht20043 == 1 && this.roomInfo.tripType == 1 && !this.roomInfo.reasonDesc;
		  return isWb;
		},
  },
	created() {
        let userInfo = this.$common.getUserInfo() || {};
		this.empInfo = userInfo.empInfo || {};
	},
	mounted() {

        this.getHotelOrder()
		this.queryReasonList()
		this.raido = true
        this.isShowEarlyDeparture= this.$route.query.isShowEarlyDeparture
    },
	methods: {
			// 退票是否需要审批
			getNeedApprove(){
				let query = this.$conn.getConn('commu.getUserTravelInfo');
				query({},res=>{            
					var data = res.data||{};
					var approvalBean = data.approvalBean||{};
					if(approvalBean.appTravel==1 && approvalBean.appBook==1){
						this.needApprove = false;
					}else{
                        this.spkzMap = approvalBean?.spkzMap??{};
                        this.needApprove = this.spkzMap?.['11008']?.sfkq==1??false
						// if(approvalBean.spkzMap && JSON.stringify(approvalBean.spkzMap)!="{}"){
						// 	this.spkzMap = approvalBean.spkzMap;
						// 	this.needApprove = this.spkzMap['11008'].sfkq==1?true:false ;
						// }else{
						// 	this.needApprove = false;
						// }
						
					}

					if(this.needApprove){
						this.getSpgzList();
					}
                    
				},err=>{
					console.log(err,"err")
				})
			},
			// 审批规则模块
			getSpgzList(){
				let isWei = false;				
				// 退票的员工id
				this.loadingSpgzList = true;
				this.getSingleApprovalRules(isWei,this.chooseList);
				
			},
            getTicket(){
		        this.chooseList = [];
				this.ticketList = [];
                this.htCnticketList.map((item,index)=>{
                    if(item.checked==true){
                        this.chooseList.push(item.empId);
                    }
                })
                this.getSpgzList()
            },
            			// 单订模式下批配审批规则
			getSingleApprovalRules(isWei,psgArr){
                console.log
				let objParams = {};
				objParams = {
				  "bookEmpId": this.empInfo.empId,
				  "corpId":  this.empInfo.corpId,
				  "ifViolate": isWei?1:0,
				  "orderType": 11008, // 单据类型
				  "projectId": '',
				  "travelEmpIds":  psgArr.join(','),
				}
				this.$conn.getConn('commu.singleModelMatchSpgz')(objParams).then((res)=>{
					this.spgzList = res.data || [];
					this.loadingSpgzList = false;
					if(this.spgzList.length == 0 ){
						this.isWuXuShenPi = true;
						this.chooseSpgz = {
							gzId:'',
							gzmc:'',
							gzdm:'',
						}
					}else{
						if(this.spgzList.length==1){
							this.chooseSpgz.gzId = this.spgzList[0].gzid; // 审批规则id
							this.chooseSpgz.gzmc = this.spgzList[0].gzmc; // 审批规则名称
							this.chooseSpgz.gzdm = this.spgzList[0].gzdm; // 审批规则代码
						}
						this.isWuXuShenPi = false;
					}
				}).catch((err)=>{
					this.spgzList = [];
					this.isWuXuShenPi = false;
					this.loadingSpgzList = false;
				})
			},
            			// 获取当前的审批规则
			getCurrentSpgz(spgzItem){
				this.chooseSpgz.gzId = spgzItem.gzid; // 审批规则id
				this.chooseSpgz.gzmc = spgzItem.gzmc; // 审批规则名称
				this.chooseSpgz.gzdm = spgzItem.gzdm; // 审批规则代码
			},

            handlerRefund(){
                if(this.needApprove && !this.isWuXuShenPi){
                    if(this.chooseList.length<1){
                        this.$alert('请选择要退票的人员','',{
                            type:'error',
                        })
                        return false
                    }
                    if(this.needApprove && !this.chooseSpgz.gzId && this.roomInfo.tripType==1){
                        this.$alert('请选择审批规则','',{
                            type:'error',
                        })
                        return false
                    }
                    this.hotelRefundConfirm()
                }else{
                    this.hotelRefundConfirm()
                }

                
				},
					hotelRefundConfirm() {
					// PMS订单 并且是国际订单 需要提示罚金
					if (this.roomInfo.cgly == 'PMS' && this.roomInfo.international == 0) {
						this.getOrderCancelMoney().then(res => {
							this.$confirm('本次退订预计罚金' + 
								res.data.amount + 
								'元,提交酒店退房申请后等待平台处理。具体退房日期及金额，请以实际酒店处理结果为准！', '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								this.hotelRefundPrompt()
								// this.$message({
								// 	type: 'success',
								// 	message: '退订成功!'
								// });
							}).catch((e) => {
								console.log(e, "")
								this.$message({
									type: 'info',
									message: '已取消退订'
								});
							});
						});
						return;
					}

					this.$confirm('提交酒店退房申请后等待平台处理。具体退房日期及金额，请以实际酒店处理结果为准！', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						this.hotelRefundPrompt()
						// this.$message({
						// 	type: 'success',
						// 	message: '退订成功!'
						// });
					}).catch((e) => {
						console.log(e, "")
						this.$message({
							type: 'info',
							message: '已取消退订'
						});
					});
				},
				// 获取取消罚金
				getOrderCancelMoney() {
					return new Promise((resolve, reject) => {
						var params = {
							orderFrom: '10503',
							orderId: this.roomInfo.orderId
						}
						var interHotelOrderCancel = this.$conn.getConn("htOuter.interHotelOrderCancel")
						interHotelOrderCancel(params, (res) => {
							resolve(res)
						})
					})
				},
		// 部分退订-剩余未退间夜超标提醒
		hotelRefundPrompt() {
			let _this = this,
					idsStr = [],
					notCheckObj = {}; // 未选中的数据
			if(this.isShowEarlyDeparture==1){
					this.tableData.forEach(item=>{
							let id = item.id,
									roomNo = item.roomNo;
							if(item.ischeck==true){
								idsStr.push(id)
							} else {
								
								if (!notCheckObj[roomNo]) {
									notCheckObj[roomNo] = {
										custName: item.custName,
										price: item.xsj,
										priceNum: 1, 
									}
								} else {
									notCheckObj[roomNo].price = this.$common.add(notCheckObj[roomNo].price,item.xsj)
									notCheckObj[roomNo].priceNum++
								}
							}
					})
				 idsStr = idsStr.join(",")
			}else{
				 idsStr=this.tableData.map(item=>{
							return item.id
			}).join(",")
			}
			
			let noCheckList = []
			for (var k in notCheckObj) {
				let item =  notCheckObj[k] || {};
				let price = this.$common.divide(item.price,item.priceNum);
				if(this.roomInfo.clbzj < price) {
					noCheckList.push(item.custName + '' + price + '元');
				}
			}
			
			if (noCheckList.length > 0 && this.isWbPromptBool) {
				let text = noCheckList.join(",");
				this.$confirm('当前订单剩余未退夜间均价'+ text +'，订单已超出预订差标金额' + this.roomInfo.clbzj + '元。继续提交导致未退订单超标无法报销，请联系公司财务确认后再操作！', '提示', {
					confirmButtonText: '继续退单',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					_this.hotelRefund(idsStr);
				}).catch(() => {
					
				})
			} else {
				_this.hotelRefund(idsStr);
			}
		},
        hotelRefund(idsStr=[]){
            // 提交订单
                let obj = {
				appId: this.chooseSpgz.gzId || '' , //审批规则id
                linkEmail: this.roomInfo.linkEmail,
                linkTel: this.roomInfo.linkTel || this.roomInfo.linkMobile ,
                linker: this.roomInfo.linkman,
                orderFrom: 10503,
                orderId: this.$route.query.orderId,
                orderPrices: idsStr,
                vipRefundReason: this.reFundValue,
			}
			this.$conn
				.getConn('htRefund.htRefundSave', { error: true })(obj)
				.then((res) => {
                    this.newOrderId=res.data.htRefundId,
					this.$message({
				      type: 'success',
				      message: '订单提交成功'
				    });
					window.opener.location.reload();
                    this.$router.push({name:'HtCnRefundDetail',query:{orderId:this.newOrderId}});
				})
            this.$conn
				.getConn('commu.appProgress', { error: true })({
                    orderId: this.$route.query.orderId,
                    orderType: 11008
                }) //审批进度
				.then((res) => {
					console.log(res, 'res')
				})
        },
        close(){
			window.close();
        },
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			if (columnIndex === 0 || columnIndex === 1) {
				//用于设置要合并的列
				if (rowIndex % this.roomInfo.nights == 0) {
					//用于设置合并开始的行号
					return {
						// rowspan: this.roomInfo.nights, //合并的行数
						rowspan: this.roomInfo.nights, //合并的行数
						colspan: 1, //合并的列数，设为０则直接不显示
					}
				} else {
					return {
						rowspan: 0,
						colspan: 0,
					}
				}
			}
		},

		// 查询退房
		queryReasonList() {
			let obj = {
				parNo: 144,
			}
			// this.$conn
			// 	.getConn('commu.violateReasonList', { error: true })(obj)
			// 	.then((res) => {
			// 		console.log(res, 'res')
			// 		this.reasonList = res?.data ?? []
			// 		this.reFundValue = this.reasonList[0].reasonDesc
			// 	})
            this.$conn
				.getConn('commu.getBClass', { error: true })(obj)
				.then((res) => {
					console.log(res, 'res')
					this.reasonList = res?.data?.list ?? []
					this.reFundValue = this.reasonList[0].cName
				})
		},

		getHotelOrder() {
			//获取当前订单详情
			this.$conn
				.getConn('htRefund.applyHtRefund', { jsfile: false })({
					orderId: this.$route.query.orderId,
				})
				.then((res) => {
					this.roomInfo = res?.data ?? []
                    this.tripType=this.roomInfo.tripType
                    if( this.tripType==1){
                        this.getNeedApprove()
                    }
                    // if()
					let arr = this.roomInfo.orderRooms
					let newArr = []
                    this.htCnticketList=[],
					arr.forEach((item) => {
                        item.customers.forEach(cusItem=>{
                        cusItem.checked = false;
						if(arr.length==1&& item.customers.length==1){
							item.customers[0].checked = true;
						}
                            this.htCnticketList.push(cusItem)
                        })
						item.prices.forEach((e) => {
							e.roomNo = '房间' + item.roomNo
							e.custName = item.psgName
                            if(this.isShowEarlyDeparture==1){
                                    if(e.status==0){
                                        e.ischeck=true
                                    }
                            }
							newArr = newArr.concat(e)
						})
					})
                    this.htCnticketList=this.$common.deepCopy(this.htCnticketList)
                    					// 当退票人只有一人时，默认处理退票人
					if(this.htCnticketList && this.htCnticketList.length==1){
						this.getTicket()
					}
					this.tableData = this.$common.deepCopy(newArr)
                    
				})
		},
	},
}
</script>

<style scoped lang="scss">
			.trp-con-f{
				display: flex;
				height: 45px;
				padding: 13px 0;
				background-color: #F3F5F8 ;
				margin-bottom: 15px;
				box-sizing: border-box;
				.checkBox{
					width: 45px;
					line-height: 19px;
					border-right: 1px solid #D1DBE7;
				}
				.trp-fl1{
					width: 354px;
					border-right: 1px solid #D1DBE7;
					line-height: 19px;
				}
				.trp-fl2{
					width: 209px;
					border-right: 1px solid #D1DBE7;
					line-height: 19px;
				}
				.trp-fl3{
					width: 254px;
					border-right: 1px solid #D1DBE7;
					line-height: 19px;
				}
				.trp-fl4{
					width: 274px;
					line-height: 19px;
				}
			}
/deep/.el-input--small .el-input__inner {
	border: none;
	border-bottom: 1px solid #e6e6e6;
	border-color: #e6e6e6;
	border-radius: 0px;
	height: 50px;
	font-weight: bold;
	font-size: 20px;
}
/deep/ .el-select .el-input__inner:focus {
	border-color: #e6e6e6;
}
.padButton {
	padding: 10px 30px;
	font-size: 15px;
}
.pb-20 {
	padding-bottom: 20px;
}
.padB20 {
	padding-bottom: 20px;
	border-bottom: 1px solid #e6e6e6;
}
.ml-100 {
	margin-left: 100px;
}
.tl-l {
	text-align: left;
}
.flex-end {
	justify-content: flex-end;
}
.fz-22 {
	font-size: 22px;
	font-weight: bold;
}
.fz-14 {
	font-size: 14px;
}
.fz-18 {
	font-size: 18px;
}
.mt-20 {
	margin-top: 20px;
}
.ml-10 {
	margin-left: 10px;
}
.red {
	color: red;
}
.main {
	background-color: #eef1f6;
	padding-bottom: 50px;
	.container {
		width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		.box-card {
			width: 100%;
			margin-top: 20px;
			/deep/ .el-card__header {
				background-color: #006bb9;
			}
			.clearfix {
				text-align: left;
				color: #fff;
			}
			.item {
				display: flex;
				flex-direction: column;
				align-items: flex-start;
				font-size: 16px;
				border-right: 1px solid #e6e6e6;
				padding-left: 27px;
				p:nth-child(1) {
					color: #808080;
				}
				p:nth-child(2) {
					margin-top: 19px;
					color: #424242;
				}
			}
			.boder-none {
				border: none;
			}
			.money {
				height: 50px;
				.flex {
					display: flex;
					align-items: center;
					span:nth-child(1) {
						color: #808080;
					}
					span:nth-child(2) {
						color: #ff9524;
					}
				}
			}
		}
	}
}
</style>
