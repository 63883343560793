<template>
	<div class="eed-main">
		<el-dialog
			title="修改邮箱"
			:visible.sync="emailVisible"
			:close-on-click-modal="false"
			:before-close="handleClose"
			style="text-align: left;"
			>
			<el-form :model="form" :rules="rules" ref="form" label-width="60px" class="demo-ruleForm">
				<el-form-item label="邮箱" prop="email">
					<el-input v-model="form.email"></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="confirm('form')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: "EditEmailDialog",
		props:{
			emailVisible:{
				type:Boolean,
				default:false,
			},
			email:{
				type:String,
				default:'',
			}
		},
		data() {
			// 验证邮箱
			let vailEmail = (rule, value, callback) => {
					let res = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/;
					if(!res.test(this.form.email)){
						callback(new Error('邮箱格式不正确'));
					}else {
						callback();
			        }
			    };
			return {
				form:{
					email:'',
				},
				rules:{
					email: [{ required: true, message: '请输入邮箱',trigger: 'blur'},
							{ validator:vailEmail,trigger: "blur"}],
				}
			}
		},
		watch:{
			email(val){
				this.form.email = val;
			}
		},
		methods:{
			// 确定
			confirm(formName){
				this.$refs[formName].validate((valid) => {
				    if (valid) {
						let params = {
							email: this.form.email
						}
				        this.$conn.getConn('user.editInfo')(params,(res)=>{
							this.$message.success('修改完成')
							this.$emit('callParams','ok',false)
						},(err)=>{
							this.$emit('callParams','no',false)
							this.$message.error(err.tips)
						})
				    }
				});   
				
			},
			// 取消
			cancel(){
				this.$emit('callParams','no',false)
			},
			handleClose(){
				this.$emit('callParams','no',false)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.eed-main{
		/deep/ .el-dialog{
			width: 500px;
		}
	}
</style>
