<template>
	<div class="tks-main">
		<div class="tr-main-header">
			<yj-trip-type :tripType="tripType" :vipParams="vipParams" @changeTripType="changeTripType"></yj-trip-type>
			<yj-travel-apply   :proType="10904"
								:ccsqdNoOrId="searchData.ccsqdId || searchData.ccsqdNo"
								:routeId="searchData.routeId"
								@chooseTravelApply="chooseTravelApply"
								v-if="tripType==1 && bookStyle==2">
			</yj-travel-apply>
			<el-input v-model="chooseCXR" v-if="bookStyle==1 && tripType==1" class="tr-input"
				@click.native="selectCXR" readonly>
				<i slot="prefix" class="el-input__icon el-icon-user"></i>
			</el-input>
		</div>
		<div style="padding: 18px 0">
			<train-search
				@queryTr="queryTr"
				:bookDateRange="bookDateRange"></train-search>		
		</div>
		<!-- 单订模式 出行人-->
		<yj-cxr-list :visible="cxrShow" :maxLength="5" :cxrList="chooseCXRList" @chooseCxrList="selectCxrList"
			@cancleCxrList="cancelCXR"></yj-cxr-list>
	</div>
</template>

<script>
	import { mapGetters, mapMutations, mapState } from 'vuex';
import mixin from './../../mixin';
import TrainSearch from './trComponents/trQuery/TrainSearch';
	export default{
		name: 'TrainTicketQuery',
		mixins:[mixin.ccsqdMixin],
		data(){
			return {
				chooseCXR:'', // 出行人
				chooseCXRList:[], // 出行人列表
				clbzList:[], //差旅标准
				cxrShow: false, // 显示出行人列表
				maxDay: 15, // 火车票预定最大天数
			}
		},
		created(){
			this.SetTrainData({
				'trainData': {
					trainList:[],
					feeList: [],
				}
			})	
		},
		computed:{
			...mapState({
				searchData: state => state.train.trainQuery.searchData, // 查询搜索火车票数据
				trainData: state => state.train.trainData, // 火车票数据
				screenTrainList: state => state.train.screenTrainList, // 筛选后的火车票列表数据
				cxrList: state => state.train.cxrList, // 出行人
				lsCxrList: state => state.train.lsCxrList, // 临时出行人
			}),
			...mapGetters({
				getVipParamterByParNo: 'common/getVipParamterByParNo',
			}),
			// 因公因私参数控制
			vipParams() {
				/**
				 *   t系统   1因私开启 2因公开启
				 *   et  1因公开启，2因私开启  这里做一下换
				 */
			
				let params = this.getVipParamterByParNo(20011, 3)
				return params //  与t系统参数相反 //this.$common.getVipParams('20011',3);  火车票因公 ， 因私 开启
			},
			search(){
				return this.searchData.search;
			},
			// 日期最小，和最大值对象
			bookDateRange(){
			    let dateRangeObj = this.getBookDateRange(10904,this.searchData.tripType,(this.searchData.maxDay - 1),this.searchData.ccsqdId,this.searchData.routeId);
				return dateRangeObj
			},
			// 因公 因私
			tripType() {
				/* 默认当前因公因私*/
				return this.searchData.tripType
			},
			// 登录人信息
			userInfo() {
				let userInfo = this.$common.getUserInfo() || {};
				return userInfo;
			},
			vipCorp() {
				return this.userInfo.vipCorp || {};
			},
			empInfo() {
				return this.userInfo.empInfo || {};
			},
			// 1单订模式  2出差申请单模式
			bookStyle() {
				let bookStyle = this.vipCorp.bookStyle;
				return bookStyle
			},
			// 火车票差旅政策是否开启 1开启 0关闭
			zcHcp(){
				/*
				*  开启火车票差旅政策 1 匹配差旅规则
				* 
				* 	关差旅政策 0 不匹配差旅规则
				* */
				return this.vipCorp.zcHcp;
			},
			// 获取正文高度 
			scrollHeight(){
				return document.body.scrollHeight;
			},
			// 获取主题内容高度
			mainHeight(){
				return this.scrollHeight-312
			},
		},
		components: {
			'train-search':TrainSearch,
		},
		mounted() {
			if (this.cxrList.length == 0 && this.bookStyle == 1) {

				if(this.empInfo.opers && this.empInfo.opers.indexOf(3)<0 ){
					this.empInfo.useCardType = '1'; // 当前出行人使用证件类型  1 身份证  2 护照  3 港澳通行证   4 台胞证  5 回乡证  6 台湾通行证  7 学生证  8 军官证  9 其他证件
					this.empInfo.cxrType = '1'; //当前人的出行人类型   1.员工  2.常旅客 3. 其他
					this.empInfo.psgType = '1'; // 默认 1  1成人 2儿童 3婴儿 
					this.empInfo.cxrName = this.empInfo.empName; //当前出行人的中文名字
					this.chooseCXRList.push(this.empInfo)
					this.chooseCXR = this.empInfo.empName;
					this.SetCxrList(this.chooseCXRList)
				}

			}else{
				this.chooseCXRList = this.cxrList;
				if (this.cxrList.length > 0) {
					let nameList = [];
					this.cxrList.forEach(item => {
						nameList.push(item.cxrName)
					})
					this.chooseCXR = nameList.join(",");
				}
			}
			this.init();
			this.getBClass();
		},
		
		methods:{
			...mapMutations({
				SET_SearchData: 'train/setSearchData',
				SetTrainData: 'train/setTrainData',
				SetCxrList: 'train/setCxrList',
				SetLsCxrList:'train/setLsCxrList',
                setTrainEdit:'train/setTrainEdit'
			}),
			init() {
				// 出差申请单模式
				/*
				* 1出差申请单模式下 params10999
				*  a. 0不管控 显示 （差旅标准未管控） 匹配 （乘坐席别是否违背）
				*  b. 1按照出差申请单管控 ，匹配出差申请单预算
				*  c. 2按差旅标准管控， 
				*/
				let params10999 = this.getVipParamterByParNo(10999, 0);
				let params10991 = this.getVipParamterByParNo(10991, 0,'value1',10904);
				let params10992 = this.getVipParamterByParNo(10992, 0);
				let params10993 = this.getVipParamterByParNo(10993, 0);
				this.SET_SearchData({
					gkfsRole: params10999, //0 不管控  1出差申请单管控  2差旅标准管控
					changeRouteRole: params10991, //根据出差申请单预订时可更改行程
					changeDateRole: params10992, //"查询出差申请单预订行程日期可否变更
					changeDateRange: params10993, //根据出差申请单预订时可更改出行日期
				})
				
				// 匹配员工最低职级
				if (this.tripType == 1 && this.bookStyle == 2 && params10999==2 && this.searchData.ccsqdId) {
					this.getMinEmp();
				}else if(this.bookStyle == 1 && this.tripType == 1){
					//单订模式下匹配差旅标准
					this.getMinEmp();
				}
				
				this.trPrompt();
			},
			// 火车票票根打印提醒
			trPrompt() {
			  this.$conn.getConn('commu.getVipCorpParameter')({
			    paramNo: '2712'
			  }, (res) => {
			      var data = res.data || {};
			      if (data.value1 == '1') {
			        this.$alert(data.value2,'温馨提示',{
			        	type: 'warning' 
			        });
			      }
			  }, (err) => {
			
			  })
			},
			// 获取火车票最大预定日期
			getBClass(){
				this.$conn.getConn('commu.getBClass')({id: 14601},(res)=>{
					let data = res.data || {};
					let list = data.list || [];
					if(list.length>0){
						// 最大预定天数
						this.maxDay = list[0].by1;
						this.SET_SearchData({
							maxDay: this.maxDay,
						})
					}
				},(err)=>{
					
				})
			},
			
			// 查询火车票
			queryTr(){
				this.$emit('queryTr')	
			},
			
			// 获取最低员工职级
			getMinEmp(){
				let minEmp = this.$common.getMinEmpRank(this.chooseCXRList);
				let queryObj = {
					'empRank': minEmp.empRank, //   职级
					'useNameclbz': minEmp.cxrName || minEmp.empName, // 本次预订使用谁的差旅标准
					'minEmpRankEmpId':minEmp.empId, // 当前员工差旅标准的职级
				}
				
				this.$emit('useNameclbz',queryObj.useNameclbz)
				this.SET_SearchData(queryObj);
        this.getClbz(queryObj.empRank);
			},
			
			// 获取差旅规则
			getClbz(empRank){
				let query = { minZj : empRank};
        
        // 有职级时调用
        if((query.minZj || query.minZj=='0') && query.minZj>-1){
          this.$conn.getConn('trBook.trBookMatchClzc')(query,(res)=>{
            this.clbzList = res.data || [];
            this.$emit('currentClbzList',this.clbzList)
          },(err)=>{
            this.$alert(err.tips,'温馨提示',{
            	type: 'error' 
            })
          })
        }
			},
		
			// 选中因公因私
			changeTripType(tripType) {
				if(tripType == this.tripType){
					return
				}
				this.SetTrainData({
					'trainData': {
						trainList:[],
						feeList: [],
					}
				})	
				if(tripType==1 && this.bookStyle==1){
					let queryObj = {
							ccsqdNo:'',
							ccsqdId:'',
							routeId:'',
							detail:{}, //出差申请单信息
							currentRoute:{}, //多选行程中的其中一项
						}
					this.SET_SearchData(queryObj);	
				}else if(tripType==1 && this.bookStyle==2){
					var departAirportName = this.searchData.currentRoute.departAirportName || this.searchData.currentRoute.departCityName;
					var arriveAirportName = this.searchData.currentRoute.arriveAirportName || this.searchData.currentRoute.arriveCityName;
					var departAirport = this.searchData.currentRoute.departAirport;
					var arriveAirport = this.searchData.currentRoute.arriveAirport;
					let depInputName = departAirportName?departAirportName+'('+departAirport+')':'';
					let arrInputName = arriveAirportName?arriveAirportName+'('+arriveAirport+')':'';
					let queryObj = {
							depCity: departAirportName,
							depCityId: this.searchData.currentRoute.departCity,
							depCitySzm: departAirport,
							depInputName: depInputName,
							depDate: this.searchData.currentRoute.departDate,
							arrCity: arriveAirportName,
							arrCityId: this.searchData.currentRoute.arriveCity,
							arrCitySzm: arriveAirport,
							arrInputName:arrInputName,
						}
					this.SET_SearchData(queryObj);	
				}
				this.SET_SearchData({
					tripType: tripType,
				});
				if(tripType==2){
					this.SetCxrList([]);
				}else{
					let list = this.$common.deepCopy(this.lsCxrList);
					this.SetCxrList(list);
				}				
			},
			
			// 选中到出差申请单信息
			chooseTravelApply(obj) {
				if(!obj.ccsqdId){
					return
				}
				// 所有行程列表
				let allRouteList = [];
				// 出发城市ID集合
				let depCityIdList = [];
				// 到达城市ID集合
				let arrCityIdList = [];
				// 当前选中行程
				let routeObj = obj.currentRoute || {};
				
				let cxrList = obj.cxrList || [];	
				allRouteList = obj.detail.allRouteList || [];
									// 处理希安康和正常单的项目！
                if(this.tripType==1){
                    let customizeFlag=obj?.detail?.customizeFlag;
                    let route=obj?.currentRoute;
                    let detail=obj?.detail;
                    let trEditObj={
                            project:{
                                id:'',
                                projectCode:'',
                                projectName:'',
                            }
                        }
                        
                    if(customizeFlag==2){
                        trEditObj.project.id=route?.cclx??''
                        trEditObj.project.projectCode=route?.cclxCode??''
                        trEditObj.project.projectName=route?.cclxMc??''
                        this.setTrainEdit(trEditObj)
                    }else if(customizeFlag==1){
                        trEditObj.project.id=detail?.projectId??''
                        trEditObj.project.projectCode=detail?.projectCode??''
                        trEditObj.project.projectName=detail?.projectName??''
                        this.setTrainEdit(trEditObj)
                    }

                }
				// 出差申请单 火车票行程不可更改
				if(this.searchData.changeRouteRole == 0){
					let depObj = {};
					let arrObj = {};
					depObj = {
						cityId: routeObj.departCity,
						depSzm: routeObj.departAirport,
						arrSzm: routeObj.arriveAirport,
					};
					arrObj = {
						cityId: routeObj.arriveCity,
						depSzm: routeObj.departAirport,
						arrSzm: routeObj.arriveAirport,
					}
					
					depCityIdList.push(depObj);
					arrCityIdList.push(arrObj);
				// 出差申请单 2 火车票行程中城市任意更改  3 出发到达城市站点限制更改
				}
				
				if(this.searchData.changeRouteRole ==1 ||this.searchData.changeRouteRole ==2 || this.searchData.changeRouteRole ==3){
					
					for(var i=0; i<allRouteList.length; i++){
						var routeItemArr = allRouteList[i];
						
						for(var j=0;j<routeItemArr.length;j++ ){
							var routeItemObj = routeItemArr[j] || {};
							if(routeItemObj['proType'] == 10904){
								// 收集出发城市：cityId、szm
								if(depCityIdList.indexOf(routeItemObj.departCity)==-1){
									let obj = {};
									obj = {
										cityId: routeItemObj.departCity,
										szm: routeItemObj.departAirport,
									}
									depCityIdList.push(obj)
									
								}
								// 收集到达城市：cityId、szm
								if(arrCityIdList.indexOf(routeItemObj.arriveCity)==-1){
									let obj = {};
									obj = {
										cityId: routeItemObj.arriveCity,
										szm: routeItemObj.arriveAirport,
									}
									arrCityIdList.push(obj)
								}
								
								
							}
						}
						
					}
					
					// 处理兵器单点行程的 多城市问题
					if(obj.detail && obj.detail.totalTrip && obj.detail.totalTrip['routeId'] == obj.routeId){
						var ddcityList =obj.detail.totalTrip.cityList || [];
						for(var k = 0;k< ddcityList.length;k++ ){
							var cityItem = ddcityList[k] || {};
							depCityIdList.push({
								szm: cityItem.airportCode,
								// airportName:cityItem.airportName,
								cityId:cityItem.cityId,
								// cityName:cityItem.cityName,
								// selectType: type,
							});
							arrCityIdList.push({
								szm: cityItem.airportCode,
								// airportName:cityItem.airportName,
								cityId:cityItem.cityId,
								// cityName:cityItem.cityName,
								// selectType: type,
							});
						}
						
					}
					
				
				
				}
				
				let queryObj = {};
				// 获取最低员工职级
				let minEmp = {};
				
				if(cxrList.length > 0){
					minEmp = this.$common.getMinEmpRank(cxrList);
				}else{
					minEmp = {
						'empRank': 0, //   职级
						'cxrName': '', // 本次预订使用谁的差旅标准
						'empId':'', // 当前员工差旅标准的职级
					}
				}
				
				if(obj.changeType == "cxrListChange"){
					queryObj = {
						'empRank': minEmp.empRank, //   职级
						'useNameclbz': minEmp.cxrName || minEmp.empName, // 本次预订使用谁的差旅标准
						'minEmpRankEmpId':minEmp.empId, // 当前员工差旅标准的职级
					}
				}else{
					//rw11486
					var startDate = this.$common.getNewDepartDate({
					  departDate: routeObj.departDate||routeObj.routeStart,
					  routeDateStart: routeObj.routeStart,
					  routeDateEnd: routeObj.routeEnd
					});
					
					if(startDate === '0000-00-00'){
						this.$message({
							type: 'error',
							message: '出差行程已过期, 无法预订',
						})
						// return;
					  //此处只做行程过期提示， 不在控制代码执行逻辑
					  startDate = routeObj.departDate||routeObj.routeStart;
					}
					queryObj = {
						ccsqdNo:obj.ccsqdNo,
						ccsqdId:obj.ccsqdId,
						routeId:obj.routeId,
						currentRoute: obj.currentRoute, //多选行程中的其中一项
					
						ccsqdReset: true,// 重置选择的出差申请单
						
						depCity: routeObj.departCityName || '', //  出发城市
						depCitySzm: routeObj.departAirport || '', //  出发城市三字码
						depCityId:  routeObj.departCity || '',
						arrCity: routeObj.arriveCityName || '',//  到达城市
						arrCitySzm: routeObj.arriveAirport || '', //  到达城市三字码
						arrCityId: routeObj.arriveCity || '', 
						
						depInputName: routeObj.departCityName + `(${routeObj.departAirport})` || '', //
						arrInputName: routeObj.arriveCityName + `(${routeObj.arriveAirport})` || '',
						
						depDate:startDate , // 出发日期
						fyys:routeObj.fyys,  // 费用预算
						cabin:routeObj.cabin, // 舱位类型
						
						depCityIdList:depCityIdList, // 出发城市ID集合
						arrCityIdList:arrCityIdList, // 到达城市ID集合
						
						'empRank': minEmp.empRank, //   职级
						'useNameclbz': minEmp.cxrName || minEmp.empName, // 本次预订使用谁的差旅标准
						'minEmpRankEmpId':minEmp.empId, // 当前员工差旅标准的职级
						allLoading: true,
						partLoading: false,
						searchNum: 0,
					};
				}

				this.$emit('useNameclbz',queryObj.useNameclbz)
				this.SET_SearchData(queryObj);
				this.SetCxrList(this.$common.deepCopy(cxrList))
				//火车票临时出行人列表
				this.SetLsCxrList(this.$common.deepCopy(cxrList))
				let params10999 = this.getVipParamterByParNo(10999, 0);
				if(params10999==2){
					this.getClbz(minEmp.empRank)		
				}
				
			},
			
			// 加载动画
			// isLoading(num){
			// 	this.$emit('loading',num)
			// },
			// 选中的出行人
			selectCxrList(list) {
        let minEmp = this.$common.getMinEmpRank(list);
				this.SetCxrList(list)
				this.chooseCXRList = list;
				if (list.length > 0) {
					let nameList = [];
					list.forEach(item => {
						nameList.push(item.cxrName)
					})
					this.chooseCXR = nameList.join(",");
          if(this.tripType == 1){
            this.getClbz(minEmp.empRank);
          }
				}else{
					this.chooseCXR = "";
				}
				
				this.cxrShow = false;
			},
			// 关闭出行人
			cancelCXR(bool) {
				this.cxrShow = bool;
			},
			// 展示 选择出行人
			selectCXR() {
				this.cxrShow = true;
			},
		}
	} 
</script>

<style lang="scss" scoped>
	.tks-main{
		text-align: left;
		.tr-main-header{
			margin-top: 18px;
			min-height: 32px;
			display: flex;
		}
		.el-input {
			width: 200px;
			margin-left: 20px;
					
		}
					
		.tr-input {
					
			/deep/ .el-input__inner {
				width: 200px;
				height: 34px;
				
			}
		}
	}
</style>
