<template>
	<div class="epd-main">
		<el-dialog
			title="修改密码"
			:visible.sync="passVisible"
			:close-on-click-modal="false"
			:before-close="handleClose"
			style="text-align: left;"
			>
			<div class="marB20">
				<span class="inlineB bfb-w20">原密码：</span>
				<el-input v-model="oldPassword" placeholder="请输入原密码" style="width:50%;" show-password ></el-input>		
			</div>
			<div class="marB20">
				<span class="inlineB bfb-w20">新密码：</span><el-input v-model="newPassword" placeholder="请输入新密码"  style="width:50%;" show-password></el-input>	  
			</div>
			<div class="marB20">
				<span class="inlineB bfb-w20">确认新密码：</span><el-input v-model="comfirmNewPassword"  show-password placeholder="请输入确认新密码" style="width:50%;"></el-input>	  
			</div>
			<div class="colorB">
				密码由字母、数字组成/6-20位
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="confirm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'EditPasswordDialog',
		props: {
			passVisible:{
				type: Boolean,
				default: function(){
					return false
				}
			},
			
		},
		data() {
			return {
				oldPassword: '', //原密码
				newPassword: '', //新密码
				comfirmNewPassword: '', //确认新密码
			}
		},
		
		methods: {
			handleClose(){
				this.oldPassword= '', //原密码
				this.newPassword= '', //新密码
				this.comfirmNewPassword= '', //确认新密码
				this.$emit('callParams',false)
			},
			// 确定
			confirm(){
				let bool = this.verify();
				if(!bool){
					return false
				}
				let obj = {
					oldPwd: this.oldPassword,
					pwd: this.newPassword
				}
				this.$conn.getConn('user.savePwd',{jsfile:false})(obj).then(res=>{
					this.$message({
						type: 'success',
						message: '修改成功,密码再次登录时生效！'
					})
					this.oldPassword= '', //原密码
					this.newPassword= '', //新密码
					this.comfirmNewPassword= '', //确认新密码
					this.$emit('callParams',false)					
				}).catch(err=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 取消
			cancel(){
				
				this.oldPassword= '', //原密码
				this.newPassword= '', //新密码
				this.comfirmNewPassword= '', //确认新密码
				this.$emit('callParams',false)
			},
			// 验证信息
			verify(){
				let errMsg = "";
				if(this.oldPassword.length<6){
					errMsg = '原密码长度最小6位';
				}else if(this.newPassword.length<6){
					errMsg = '新密码长度最小6位';
				}else if(this.comfirmNewPassword.length<6){
					errMsg = '确认新密码长度最小6位';
				}else if(this.oldPassword.length>20){
					errMsg = '原密码长度超出限定';
				}else if(this.newPassword.length>20){
					errMsg = '新密码长度超出限定';
				}else if(this.comfirmNewPassword.length>20){
					errMsg = '确认新密码长度超出限定';
				}else if(!this.oldPassword){
					errMsg = '请输入原密码';
				}else if(!this.newPassword){
					errMsg = '请输入新密码';
				}else if(!this.comfirmNewPassword){
					errMsg = '请再次输入新密码';
				}else if(this.newPassword && this.comfirmNewPassword){
					if(this.newPassword != this.comfirmNewPassword){
						errMsg = '新密码输入不一致，请重新输入';
					}
				}
				if(errMsg){
					this.$message({
						type: 'error',
						message: errMsg
					})
					return false
				}
				return true
			},
			
		}
	}
</script>

<style scoped lang="scss">
	.epd-main{
		/deep/ .el-dialog{
			width: 500px;
		}
		.inlineB{
			display: inline-block;
		}
		.bfb-w20{
			width: 20%;
		}
		.marB20{
			margin-bottom: 20px;
		}
		.colorB{
			color: #BBBBBB;
		}
	}
</style>
