<template>
	<div class="tp-main" :style="{'min-height':height+'px',}">
		<div class="main">
			<el-card>
				<div class='marTop20'>
					<div class="el-icon-office-building font84"></div>
				</div>
				<tk-page :refundChangeInfo="refundChangeInfo" :orderType="orderType" v-if="orderType == 11001 || orderType == 11002 || orderType == 11003 || orderType == 11004 || orderType == 11005 || orderType == 11006" @goToChangeDetail="goToChangeDetail" @goToRefundDetail="goToRefundDetail"></tk-page>
				<ht-page :refundChangeInfo="refundChangeInfo" :orderType="orderType" v-if="orderType == 11007 || orderType == 11008 || orderType == 11022 || orderType == 11023"  @goToRefundDetail="goToRefundDetail"></ht-page>
				<tr-page :refundChangeInfo="refundChangeInfo" :orderType="orderType" v-if="orderType == 11009 || orderType == 11010 || orderType == 11011" @goToChangeDetail="goToChangeDetail" @goToRefundDetail="goToRefundDetail"></tr-page>
			</el-card>
		</div>
	</div>
</template>

<script>
	import tpOrder from './tpOrder.js';
	import TkPage from './TkPage';
	import TrPage from './TrPage';
	import HtPage from './HtPage';
	export default{
		name: 'Completed',
		data() {
			return {
				refundChangeInfo: {}, //退改签信息
			}
		},
		computed:{
			// 业务 机 酒 火 参数
			query(){
				return this.$route.query || {}
			},
			// 业务 机 酒 火 模块类型
			orderType(){
				return this.query.orderType;
			},
			orderId(){
				return this.query.orderId;
			},
			changeId(){
				return this.query.changeId;
			},
			refundId(){
				return this.query.refundId;
			},
			height(){
				let height = document.body.clientHeight;
				return height
			},
		},
		components:{
			'tk-page':TkPage,
			'tr-page':TrPage,
			'ht-page':HtPage,
		},
		mounted() {	
			// 获取订单详情，
			let orderId = "";
			// 改签单
			if(this.orderType == 11011 || this.orderType == 11003){
				orderId = this.changeId;
			}else if(this.orderType == 11010 || this.orderType == 11002 || this.orderType == 11008){
			// 退票单
				orderId = this.refundId;
			}else{
				// 正常单
				orderId = this.orderId
			}
			tpOrder.getOrderDetail(this.$conn, orderId, this.orderType,  this.getOrderDetail, this)
		},
		methods: {
			// 获取订单详
			getOrderDetail(res) {
				this.refundChangeInfo = res.data || {};
			},
			// 去改签详情页
			goToChangeDetail(){
				let path = this.$dict.refundchangeConfig(this.orderType);
				let query = {
						// changeId:changeId,
						orderType: this.orderType,
						orderId: this.changeId,
					}
				this.$router.push({path:path,query:query})
			},
			// 去退票详情
			goToRefundDetail(refundId){
				let path = this.$dict.refundchangeConfig(this.orderType);
				let query = {
						orderType: this.orderType,
						orderId: refundId,
					}
				this.$router.push({path:path,query:query})
			}
		}
		
	}
</script>

<style scoped lang="scss">
	.tp-main{
		background-color: #eef1f6;
		.main{
			width: 1200px;
			margin: 0 auto;
			padding-top: 40px;
			.marTop20{
				margin-top: 20px;
			}
			.font84{
				font-size: 84px;
			}
		}
		
	}
</style>

