<template>
	<div>
		<router-view/>
	</div>
</template>

<script>
	export default{
		name:"MyTravel",
		data(){
			return {
				
			}
		},

		created() {
			this.goToPath()
		},
		methods: {
			goToPath() {
				if(this.$route.path == "/yjTravel/myTravel"){
					this.$router.push('myTravel')
				}
				
			}
		}
	}
</script>

<style>
	
</style>
