<template>
	<div class="tr-main">
		<div class="bgWhite">
			<div class="main-top">
				<train-ticket-query @currentClbzList="getClbzList"  @queryTr="queryTr"></train-ticket-query>
			</div>
		</div>
		
		<div class="bgmain"
			:style="{minHeight:mainHeight+'px'}"
			>
			<div class="main-fooetr padTB20">
				<div class="tr-content">
					<div class="tr-content-right">
						<train-list-loading :loading="trQueryStatus==1 && allLoading"></train-list-loading>
						<train-query-list 
							v-if="trQueryStatus>1 || !allLoading"
							:trScreenData="trScreenData" 
							:trCityQuery="trCityQuery"
							@chooseDate="chooseDate" 
							@screenResult="screenResult">
						</train-query-list>
					</div>
					<div class="tr-content-left">
						<div  v-if="trQueryStatus>1 || !allLoading">
							<div class="tr-title-left">
								<el-card>
									<div class="tr-cb-mc" v-if="zcHcp==0">
										<span class="textCenter">差旅标准已关闭</span>	
									</div>
									<div v-if="zcHcp==1">
										<div class="tr-cb-mc" v-if="tripType==1 && bookStyle==2">
											<!-- <div v-if="searchData.gkfsRole==0"  class="textCenter">
												<span>差旅标准未管控</span>
												searchData.gkfsRole==0 管理端参数设置为不管控时，还是按照出差申请单管控
											</div> -->
											<div v-if=" searchData.gkfsRole==1 || searchData.gkfsRole==0">
												<el-popover
												    placement="bottom-start"
												    width="220"
												    trigger="hover"
												   >
													<slot name="content" >
														<span  class="cb-color" style="color: #f70;
															font-size: 12px;
															margin-top: 5px;
															letter-spacing: 1px;
															font-weight: 100;
															">预算金额不超过{{searchData.fyys}}</span>
													</slot>
													<div slot="reference" style="cursor: pointer;">
														<span>本次使用</span>
														<el-button type="text" class="tr-title-name" >出差申请单预算</el-button>
														<span>差标</span>
													</div>
												</el-popover>
												
											</div>
											
											<div v-if="searchData.gkfsRole==2">
												<el-popover
												    placement="bottom-start"
												    width="220"
												    trigger="hover"
												   >
													<slot name="content" >
														<div style="color: #f70;
															font-size: 12px;
															margin-top: 5px;
															letter-spacing: 1px;
															font-weight: 100;">
															<div v-for="(item, index) in clbzList" :key="index+'s'">{{item.item}}</div>
														</div>
													</slot>
													<div slot="reference"  style="cursor: pointer;">
														<span v-if="searchData.useNameclbz">
															<span>本次采用</span>
															<el-button type="text" class="tr-title-name" >{{searchData.useNameclbz}}</el-button>
															<span>的差标</span>
														</span>
														<span v-else>
															请选择出行人
														</span>
													</div>
												</el-popover>
											</div>
										</div>
										<div class="tr-cb-mc" v-if="tripType==1 && bookStyle==1">
											<div v-if="searchData.gkfsRole==2 || searchData.gkfsRole==1">
												<el-popover
												    placement="bottom-start"
												    width="220"
												    trigger="hover"
												   >
													<slot name="content" >
														<div style="color: #f70;
															font-size: 12px;
															margin-top: 5px;
															letter-spacing: 1px;
															font-weight: 100;">
															<div v-for="(item, index) in clbzList" :key="index+'n'">{{item.item}}</div>
														</div>
													</slot>
													<div slot="reference"  style="cursor: pointer;">
														<span>本次采用</span>
														<el-button type="text" class="tr-title-name" >{{searchData.useNameclbz}}</el-button>
									
														<span>的差标</span>
													</div>
												</el-popover>
											</div>
										</div>
										<div class="tr-cb-mc" v-if="tripType==2"  style="text-align: center;">因私订单</div>
									</div>
									
								</el-card>
							</div>
							
							<train-screen-check  @trCheckScreen="trCheckOption"></train-screen-check>
						</div>
						<train-screen-loading :loading="trQueryStatus==1 && allLoading"></train-screen-loading>
						
					</div>
					
				</div>
			</div>
		</div>
	</div>
</template>
<script>
	import {mapState,mapMutations,mapGetters} from 'vuex';
	import trainScreenCheck from './trComponents/trQuery/TrainScreenCheck';
	import TrainQueryList from './trComponents/trQuery/TrainQueryList';
	import TrainListLoading from '@/views/tr/trComponents/trQuery/TrainListLoading';
	import TrainScreenLoading from '@/views/tr/trComponents/trQuery/TrainScreenLoading';
	import TrainTicketQuery from './TrainTicketQuery';
	export default {
		name: 'TrQuery',
		data() {
			return {
				trScreenData: {}, // 火车票筛选数据
				trCityQuery:{}, // 火车票查询数据
				reFresh: 1, //刷新筛选条件
				clbzList:[], // 差旅标准列表
				textStyle:{
					'font-size': 16+'px',
					'color': '#407af3'
				},
				
				
			}
		},
		beforeRouteLeave(to, from , next){
			let obj = {
				seatInfo:{
					seatIdList:[],
					chooseSeatList:[],
				},
				bxOrderList:[],
			}
			//预订保险
			this.SET_TrainEdit(obj);
			next()
		},
		components: {
			'train-screen-check': trainScreenCheck,
			'train-query-list': TrainQueryList,
			'train-list-loading':TrainListLoading,
			'train-screen-loading':TrainScreenLoading,
			'train-ticket-query':TrainTicketQuery,
		},
		computed: {
			...mapState({
				searchData: state => state.train.trainQuery.searchData, // 查询搜索火车票数据
				trainData: state => state.train.trainData, // 火车票数据
				screenTrainList: state => state.train.screenTrainList, // 筛选后的火车票列表数据
			}),
			// 因公 因私
			tripType() {
				/* 默认当前因公因私*/
				return this.searchData.tripType;
			},
			// 登录人信息
			userInfo() {
				let userInfo = this.$common.getUserInfo() || {};
				return userInfo;
			},
			vipCorp() {
				return this.userInfo.vipCorp || {};
			},
			// 1单订模式  2出差申请单模式
			bookStyle() {
				let bookStyle = this.vipCorp.bookStyle;
				return bookStyle
			},
			// 火车票差旅政策是否开启 1开启 0关闭
			zcHcp(){
				/*
				*  开启火车票差旅政策 1 匹配差旅规则
				* 
				* 	关差旅政策 0 不匹配差旅规则
				* */
				return this.vipCorp.zcHcp;
			},
			// 获取正文高度 
			scrollHeight(){
				return document.body.scrollHeight;
			},
			// 获取主题内容高度
			mainHeight(){
				return this.scrollHeight-312
			},
			// 当前火车票状态
			trQueryStatus(){
				return this.searchData.trQueryStatus;
			},
			// 整体加载
			allLoading(){
				return this.searchData.allLoading;
			},
			
		},
		mounted(){
			this.trCityQuery = {
					depCity: this.searchData.depCity, //  出发城市
					arrCity: this.searchData.arrCity,//  到达城市
			}
		},
		methods: {
			...mapMutations({
				SET_SearchData: 'train/setSearchData',
				SET_TrainEdit:'train/setTrainEdit',
			}),
			// 筛选条件
			trCheckOption(screenTrainAllData) {
				this.trScreenData = screenTrainAllData;
			},
			// 火车列表选中日期
			chooseDate(date) {
				this.SET_SearchData({
					depDate: date,
				})
			},
			// 筛选后列表长度
			screenResult(newTrainData) {
				
			},
			// 获取差旅标准
			getClbzList(list){
				this.clbzList = list;
			},
			// 查询加载状态
			queryTr(){
				this.trCityQuery = {
						depCity: this.searchData.depCity, //  出发城市
						arrCity: this.searchData.arrCity,//  到达城市
				}
				this.reFresh = 0;
			}
		}

	}
</script>

<style lang="scss" scoped>
	.tr-main {
		font-family: '微软雅黑';
		width: 100%;
		background-color: #eef1f6;
		.line-h70{
			line-height: 70px;
		}
		.bgWhite {
			background-color: #FFFFFF;
			.main-top {
				width: 1200px;
				margin: 0 auto;
				.tr-main-header {
					text-align: left;
					height: 70px;
					.el-input {
						width: 200px;
						margin-left: 20px;
				
					}
				
					.tr-input {
						/deep/ .el-input__inner {
							width: 200px;
							height: 34px;
						}
					}
				}
			}
			
		}
		

		

		

		.padTB20 {
			padding: 20px 0;
		}

		.bgmain {
			background-color: #eef1f6;
			.main-fooetr {
				width: 1200px;
				margin: 0 auto;
			}
		}

		.el-card.is-always-shadow {
			border: 1px solid #E6E6E6;
			box-shadow: 0px 8px 8px 0px rgba(0, 0, 0, 0.02);
			border-radius: 0;
		}

		.tr-content {
			display: flex;
			justify-content: space-between;
			margin-top: 15px;

			.tr-content-left {
				width: 240px;
				.tr-title-left {
					width: 240px;
					text-align: left;
					.tr-cb-mc {
						font-size: 14px;
						font-weight: bold;
						color: #424242;
						line-height: 14px;
						
						.textCenter{
							text-align: center;
						}
						.cbfyys{
							color: #FFB65E;
						}
						.cb-color{
							color: #f70;
							font-size: 12px;
							margin-top: 5px;
							letter-spacing: 1px;
							font-weight: 100;
						}
					}
					
					.tr-title-name {
						font-size: 14px;
						padding: 0px;
						font-weight: bold;
						// color: #4089FF;
					}
				}
			}

			.tr-content-right {
				width: 940px;
			}
		}

	}
</style>
