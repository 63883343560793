<template>
	<div class="htp-main">
		<div class="marTop25">
			<span class="title">退票订单已提交，
				<span>{{refundChangeInfo.refundStatusRemark?refundChangeInfo.refundStatusRemark:'等待办理'}}</span>
			</span>
		</div>
		<div class="gq-top">
			<div>
				入住退订：{{refundChangeInfo.htRefundDays+ ' ( '+refundChangeInfo.jdmc+ ' ) '}}
			</div>
			<div class="marTop20">
				<span>入住人：{{refundChangeInfo.psgName}}</span> 
				<span class="seexq" @click="goToRefundDetail(refundChangeInfo.htRefundId)">查看详情</span>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'HtPage',
		props:{
			refundChangeInfo:{
				type:Object,
				default(){
					return {};
				}
			},
		},
		methods:{
			goToRefundDetail(htRefundId){
				this.$emit('goToRefundDetail',htRefundId)
			}
		}
	}
</script>

<style lang="scss" scoped>
	.htp-main{
		.gq-top{
			margin: 40px 0;
		}
		.gq-top-i{
			margin: 20px 0 40px 0;
		}
		.marTop20{
			margin-top: 20px;
		}
		.marTop25{
			margin-top: 25px;
		}
		.title{
			font-size: 24px;
			color: #006BB9;
		}
		.seexq{
			cursor: pointer;
			color: #006BB9;
			margin-left: 20px;
		}
		.seexq:hover{
			color: #007FE9;
		}
	}
</style>
