/* 
 *  国内机票订单支付    11001 
 *  国内机票改签单支付    11003
 *  火车票订单支付       11009
 *  火车改签支付         11011
 *  酒店订单支付         11007
 *  用车订单支付         11018
 *  代客泊车订单支付     11014
 *  机场服务订单支付     11012
 *  代办值机订单支付     11019
 *  国际机票订单支付     11004
 *  国际机票改签支付     11006
 *  餐饮订单支付         11024
 * 
 **/
var params = {
	//机票退单
	'11002': {
		//根据订单id,获取相关退票的详细信息
		conn: 'tkRefund.refundOrderDetail',
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				refundId: orderId
			}
		},
	},
	// 机票改签单
	'11003': {
		//根据改签单id获取改签单集合
		conn: 'change.tkChangeOrderDetail',
		//获取订单详情的参数 (此处需要改签单iD 不是订单id)
		getParams(orderId, orderType) {
			return {
				changeOrderId: orderId
			}
		},
	},

	//酒店正常单
	'11007': {
		//根据订单id,获取相关退票的详细信息
		conn: 'htRefund.hotelRefundList',
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				orderId: orderId
			}
		},
	},
	//酒店退单
	'11008': {
		//根据订单id,获取相关退票的详细信息
		conn: 'htRefund.htRefundDetail',
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				hotelRefundId: orderId
			}
		},
	},
	
	//火车票退票单
	'11010':{
		//获取退票订单参数
		conn: 'trRefund.trRefundDetail',
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				id: orderId
			}
		},
	},
	//火车票改签详
	'11011': {
		//获取改签订单集合
		conn: 'trChange.trChangeOrderDetail',
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				changeId: orderId
			}
		},
	},
	//休息室
	'11012': {
		//订单详情接口
		conn: 'airService.queryServiceOrderDetail',
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				orderId: orderId
			}
		},
	},

	//用车
	'11018': {
		//订单详情接口
		conn: 'carBook.queryCarOrderDetail',
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				orderId: orderId
			}
		},
	},

	//值机
	'11019': {
		//订单详情接口
		conn: 'dbzj.getCheckInDeatils',
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				id: orderId
			}
		},
	},
	//餐饮
	'11024': {
		//订单详情接口
		conn: 'food.cyOrderDetail',
		//获取订单详情的参数
		getParams(orderId, orderType) {
			return {
				orderId: orderId
			}
		},
	},
};

var orderjs = {};

//获取订单详情
orderjs.getOrderDetail = function($conn, orderId, orderType, callback, target) {
	if (!(orderId && orderType)) {
		return;
	}

	callback = callback || noop;
	target = target || this;
	var obj;
	if(params[orderType]){
		obj = params[orderType];
	}
	console.log(obj)
	var query = $conn.getConn(obj.conn);
	query(obj.getParams(orderId, orderType), res => {
		callback.call(target, res);
	}, (res) => {
		target.$alert(res.tips);
	})
};

export default orderjs