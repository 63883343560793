<template>
	<div class="itedit">
		<div class="inHeader">
			<span class="text">国际酒店需求单</span>
		</div>
		<div class="main">
			<div class="inmain">
				<el-form :model="ruleForm"  ref="ruleForm" label-width="30px" class="demo-ruleForm">
					<el-form-item>
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">行程信息</span>
						</div>
						<div class="textCon">
							<span class="textbt">
								<span class="redx" >*</span>
								<span>差旅类型</span>
							</span>
							<span class="ygys" :class="{active:ruleForm.tripType==1}" @click="changeTripType(1)">因公</span>
							<span class="ygys" :class="{active:ruleForm.tripType==2}" @click="changeTripType(2)">因私</span>
						</div>
						<div class="citytext">
							<span class="citybt">
								<span class="redx" >*</span>
								<span>入住城市</span>
							</span>
							<yj-tk-city
									@chooseCity="chooseDepCity"  
									:keywords="htCityName"
									>
								<div slot="referenceContent">
									<el-input size="large"
										v-model="ruleForm.cityName"
										prefix-icon="iconfont yj-icon-jiudian1"
										@input="queryDepCityName(ruleForm.cityName)"
										placeholder="可输入入住城市"
										>	
									</el-input>
								</div>	
							</yj-tk-city>
						</div>
						<div class="citytext">
							<span class="citybt">
								<span class="redx" >*</span>
								<span>入住日期</span>
							</span>
							<el-date-picker
								class="setCityInput"
							    v-model="ruleForm.rzrq"
							    type="date"
								value-format="yyyy-MM-dd"
								@change="getRzrq"
								:picker-options="pickerOptions"
							    placeholder="选择入住日期">
							</el-date-picker>
						</div>
						<div class="citytext">
							<span class="citybt">
								<span class="redx" >*</span>
								<span>离店日期</span>
							</span>
							<el-date-picker
								v-if="ruleForm.voyageType!=3"
								:disabled="ruleForm.voyageType==1"
								class="setCityInput"
							    v-model="ruleForm.ldrq"
								value-format="yyyy-MM-dd"
							    type="date"
								@change="getLdrq"
								:picker-options="ldPickerOptions"
							    placeholder="选择离店日期">
							</el-date-picker>
						</div>
					</el-form-item>
					<el-form-item class="qwprice">
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">期望价格</span>
						</div>
						<span class="qwjg">期望价格</span>
						<el-input v-model="ruleForm.expectBudget" placeholder="请输入期望价格"></el-input>
						<span class="words">元</span>
						<span class="words2">（期望金额作为预订参考，不是实际价格）</span>
					</el-form-item>
					<el-form-item class="link">
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">联系人信息</span>
						</div>
						<div class="marB20">
							<span class="lspan">
								<span class="redx">*</span>
								<span>联系人</span>
							</span>
							
							<el-input v-model="ruleForm.linkman" placeholder="请输入联系人" style="width: 100px;"></el-input>
						</div>
						<div class="marB20">
							<span class="lspan">
								<span class="redx">*</span>
								<span >联系电话</span>
							</span>
							<el-input v-model="ruleForm.linkMobile" placeholder="请输入联系电话"></el-input>
						</div>
						<div class="marB20">
							<span class="lspan">
								<span style="padding-right: 16px;"></span>
								<span >联系邮箱</span>
							</span>
							<el-input v-model="ruleForm.linkEmail" placeholder="请输入联系邮箱"></el-input>
						</div>
					</el-form-item>
					<el-form-item style="margin-bottom: 30px;">
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">入住信息</span>
						</div>
						<label><span style="width:91px;padding-left: 16px;display: inline-block;">房间数</span><el-input-number v-model="ruleForm.rooms" :min="1"></el-input-number></label>
					</el-form-item>
					
					
					<el-form-item class="bz">
						<div class="title">
							<span class="leftsk"></span>
							<span class="text">备注</span>
						</div>
						<div style="line-height: 14px;padding-bottom: 10px;">
							输入您的特殊需求，例如:指定航空公司，指定航班，出发时间。直飞或转机、价位需求等
						</div>
						<el-input 
							v-model="ruleForm.content" 
							type="textarea"
							 style="border-radius: 0;"
							:rows="2" 
							placeholder="输入您的特殊需求"></el-input>
						
					</el-form-item>
					<el-form-item class="line"></el-form-item>	
					<el-form-item style="margin-right: 30px;">
						<div class="btn">
							<p  style="line-height: 39px;">您的需求单提交后，客服会在工作时间（8：00-24:00）内处理并与您联系。如有疑问，可拨打24小时 <span class="padding-left:20px;">客服电话：{{configData.officalPhone}}</span>	</p>
							<el-button type="primary" v-if="loading==false" @click="submitNeed">提交需求单</el-button>
							<el-button type="primary" v-if="loading">
								<span class="el-icon-loading"></span>提交中...</el-button>
						</div>
					</el-form-item>	
				</el-form>
			</div>       
		</div>
	</div>
</template>

<script>
	export default{
		name:'InHtEdit',
		data(){
			return {
				loading: false,
				ruleForm:{
					content: "", //备注
					expectBudget: 0, //期望价格
					linkEmail: "", //联系邮箱
					linkMobile: "", //联系电话
					linkman: "", //联系人
					orderFrom: "10503", //订单来源
					rzrq: this.$common.getToDay(), // 入住日期
					ldrq: this.$common.getAppointDay(this.$common.getToDay(),1), //离店日期
					tripType: 1, //因公因私
					cityName: '',//城市名称
					cityId:'', //城市id
					rooms:1, //房间数
					nights:1,  //几晚
				},
				
				configData:{},// 总公司信息
				htCityName:'',
				pickerOptions: { // 默认当天之前 日期不能选择
				    disabledDate: (time) => {
						return time.getTime() < Date.now() - 24 * 60 * 60 * 1000;
				    }
				},
				ldPickerOptions: { // 默认离店日期最小是一天  ，当天及之前日期不能选择
				    disabledDate: (time) => {
						return time.getTime() < Date.now();
				    }
				},
				
			}
		},
		computed:{
			userInfo(){
				return this.$common.getUserInfo() || {};
			},
			empInfo(){
				return this.userInfo.empInfo || {};
			},
			
		},
		mounted(){
			this.configData = this.$common.getConfigData() || {};	
			this.ruleForm.linkEmail = this.empInfo.email;
			this.ruleForm.linkMobile = this.empInfo.phoneNumber;
			this.ruleForm.linkman = this.empInfo.empName;
		},
		methods:{
			// 获取入住日期
			getRzrq(e){
				if(this.ruleForm.rzrq && this.ruleForm.ldrq && this.ruleForm.rzrq>=this.ruleForm.ldrq){
					this.ruleForm.ldrq = this.$common.getAppointDay(this.ruleForm.rzrq,1)
				}
				this.getDateDiff();
			},
			// 获取离店日期
			getLdrq(e){
				if(this.ruleForm.rzrq && this.ruleForm.ldrq && this.ruleForm.rzrq>=this.ruleForm.ldrq){
					this.ruleForm.rzrq = this.$common.getAppointDay(this.ruleForm.ldrq,-1)
				}
				this.getDateDiff();
			},
			getDateDiff(){
				var firstDate = new Date(this.ruleForm.rzrq);
				var secondDate = new Date(this.ruleForm.ldrq);
				var diff = Math.abs(firstDate.getTime() - secondDate.getTime())
				this.ruleForm.nights = parseInt(diff / (1000 * 60 * 60 * 24));
			},	
			// 因公因私
			changeTripType(e){
				this.ruleForm.tripType = e
			},
			// 选择国际入住城市
			chooseDepCity(obj){
				this.ruleForm.cityId = obj.cityId;	
				this.ruleForm.cityName = obj.cityName;				
			},
			// 搜索输入触发 强制刷新页面 赋值出发/入住地址
			queryDepCityName(name){
				this.htCityName = name;
			},
			// 验证需求单参数
			getVaild(){
				var errMsg = "";				
				if(!this.ruleForm.linkman){
					errMsg = "请输入联系人"
				}else if(!this.ruleForm.linkMobile){
					errMsg = "请输入联系电话"
				}else if(!this.ruleForm.rzrq){
					errMsg = "请选择入住日期"
				}else if(!this.ruleForm.ldrq){
					errMsg = "请选择离店日期"
				}else if(!this.ruleForm.cityId){
					errMsg = "请选择入住城市"
				}
				
				if(errMsg){
					this.$alert(errMsg,'',{
						type: 'error'
					})
					return false;
				}
				return true
			},
			// 提交需求单
			submitNeed(){
				var bool = this.getVaild();
				if(!bool){
					return 
				}
				this.loading = true;
				let saveNeedOrder = this.$conn.getConn('hotelOrder.saveNeedOrder')
				saveNeedOrder(this.ruleForm,(res)=>{
					this.loading = false;
					let id = res.data;
					if(id){
						let query = {
							orderId: id,
						}
						this.$router.push({path:'/yjDetail/inHtDetail',query:query})
					}
					
					
				},(err)=>{
					this.loading = false;
					this.$alert(err.tips,'提示',{
						type: 'error'
					})
				})
			}
		}
	}
</script>

<style lang="scss" scoped>
	.itedit {
		width: 100%;
		height: 100%;
		background-color: #eef1f6;
		padding: 30px 0;
		font-family: '微软雅黑';
		.inHeader{
			margin: 0 auto;
			width: 1200px;
			height: 32px;
			font-size: 23px;
			font-weight: 800;
			color: #282828;
			line-height: 32px;
			text-align: left;
			margin-bottom: 20px;
		}
		.marB20{
			margin-bottom: 20px;
		}
		.main {
			width: 1200px;
			margin: 0 auto;
			padding: 39px 0 20px 0;
			background-color: #FFFFFF;
			.inmain{
				text-align: left;
				.title{
					display: flex;
					margin-bottom: 20px;
					.leftsk{
						display: inline-block;
						width: 6px;
						height: 19px;
						background: #007AFF;
						margin-top: 2px;
						margin-right:10px;
					}
					.text{
						height: 24px;
						font-size: 18px;
						font-weight: 500;
						color: #000000;
						line-height: 24px;
					}
				}
				.textCon{
					margin-bottom: 20px;
					.textbt{
						display: inline-block;
						width: 91px;
						font-size: 14px;
						font-weight: 400;
						color: #424242;
						line-height: 19px;
						.redx{
							display: inline-block;
							line-height: 19px;
							color: red;
							padding-right: 10px;
						}
					}
					.ygys{
						display: inline-block;
						width: 67px;
						height: 34px;
						background: #FFFFFF;
						border: 1px solid #CCCCCC;
						text-align: center;
						margin-right: 10px;
						cursor: pointer;
					}
					.active{
						border: 1px solid #007AFF;
						color: #007FE9;
						transition: all 0.3s ease;
					}
				}
				.citytext{
					display: flex;
					margin-bottom: 20px;
					.citybt{
						display: inline-block;
						width: 91px;
						font-size: 14px;
						font-weight: 400;
						color: #424242;
						line-height: 34px;
						.redx{
							display: inline-block;
							line-height: 19px;
							color: red;
							padding-right: 10px;
						}
					}
					/deep/ .el-input__inner{
						width: 243px;
						height: 34px;
						line-height: 34px;
						background: #FFFFFF;
						border: 1px solid #DFDFDF;
						border-radius: 0px;
					}	
				}
				.qwprice{
					margin-bottom: 29px;
					.qwjg{
						width: 91px;
						padding-left: 16px;
						display: inline-block;
					}
					.el-input{
						width: 96px;
						height: 34px;
						background: #FFFFFF;
						/deep/ .el-input__inner{
							border-radius: 0px;
						}	
					}
					.words{
						padding: 0 5px;
					}
					.words2{
						font-size: 14px;
						color: #aaaaaa;
					}
				}					
				.padL20{
					padding-left: 20px;
				}
			
				.line{
					border-top: 1px solid #F0F0F0;
					margin: 39px 0 ;
				}
				.link{
					.lspan{
						display: inline-block;
						width: 91px;
						.prdR10{
							padding-right: 10px;
						}
						.redx{
							display: inline-block;
							line-height: 19px;
							color: red;
							padding-right: 10px;
						}
					}
					.el-input{
						width: 200px;
						line-height: 34px;
						height: 34px;
						/deep/ .el-input__inner{
							border-radius: 0px;
						}	
					}
					
				}
				.bz{
					.el-textarea{
						width: 70%;
						/deep/ .el-textarea__inner{
							border-radius: 0px;
						}
					}
				}
				.btn{
					width: 100%;
					display: flex;
					justify-content: space-between;
					.el-button {
						width: 156px;
						height: 39px;
						font-size: 14px;
						font-weight: 500;
					}
				}
			}
			
		}
	}
</style>
