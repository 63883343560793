<template>
	<div class="tkod-main">
        <yj-progress-edit :statusList="statusList" :currentStatus="orderStatus"/>
		<el-card class="textLeft marTop20">
            			<el-row
				type="flex"
				justify="space-between"
				style="margin-bottom: 10px"
			>
				<el-col class="flex-column" :span="9">
					<p>原订单号：{{ hotelOrderInfo.orderNo }}</p>
					<p  style="margin-left: 20px;" v-if="$route.query.orderRefundNo">退单单号：{{ $route.query.orderRefundNo }}</p>
				</el-col>
				<el-col class="flex-column text-right" :span="3">
					<p>
						{{ hotelOrderInfo.htRefundStatusRemark }}
					</p>
				</el-col>
			</el-row>
			<div class="tk-main">
				<div class="left-main-width">
					基本信息
				</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" :span="6"
						><div>
                            服务商：{{hotelOrderInfo.supplierCode}}{{ hotelOrderInfo.supplierName }}
						</div></el-col
					>
					<el-col class="flex-column" :span="6"
						><div>
							退房原因：{{hotelOrderInfo.vipRefundReason}}
						</div></el-col
					>
					<el-col class="flex-column" :span="6"
						><div>
							应退金额：<span style="color:#f5a337 ;font-size:22px">￥{{ hotelOrderInfo.cuRefundAmount }}</span>
						</div></el-col
					>
					<el-col class="flex-column" :span="6"
						><div>
							<!-- 支付方式:{{ hotelOrderInfo.payMothedName }} -->
						</div></el-col
					>
				</el-row>
			</div>
			<div class="tk-main">
				<div class="left-main-width">退房信息</div>
				<div class="rooms-right">
					<el-row class="romms-info">
						<el-col class="flex-column" :span="12"
							><div>
								原预订信息：{{ hotelOrderInfo.jdmc }}  {{ hotelOrderInfo.fxmc }}  {{hotelOrderInfo.rooms}} 间 {{hotelOrderInfo.nights}} 晚
							</div></el-col
						>
						<el-col class="flex-column" :span="6"
							><div>
								原订单合计：<span style="color:#f5a337 ;font-size:22px">￥{{ hotelOrderInfo.xsj }}</span>
							</div></el-col
						>
					</el-row>
					<div style="padding: 10px 0;">
						<div  v-for="(item, index) in hotelOrderInfo.roomList" :key="index" style="padding: 5px 0 5px 40px;text-align: left;width:996px;">
							<span style="display: inline-block;text-align: left;width:30%;">
								入住人:{{item.custName}}
							</span>
							<span style="display: inline-block;text-align: left;width:30%;">
								退订房间:{{item.roomNo}}号
							</span>
							<span style="display: inline-block;text-align: left;width:30%;">
								退订日期:{{hotelOrderInfo.htRefundDays}} 共 {{hotelOrderInfo.htRefundNights}} 晚
							</span>
						</div>
					</div>
					<!-- <el-row> -->
						
						<!-- <el-col class="flex-column" :span="8"
							><div>
								入住人:{{ hotelOrderInfo.psgName}}
							</div></el-col
						>
						<el-col class="flex-column" :span="8"
							><div>
								退订房间:退{{ hotelOrderInfo.fxmc}} {{hotelOrderInfo.htRefundRooms}}间
							</div></el-col
						>
						<el-col class="flex-column" :span="8"
							><div>
								退订日期:{{ hotelOrderInfo.htRefundDays}} 共 {{hotelOrderInfo.htRefundNights}} 晚
							</div></el-col
						> -->
					<!-- </el-row> -->
				</div>
			</div>
			<el-row
				type="flex"
				justify="center"
				class="left-main-right b-t-1"
				style="padding-top: 10px"
			>
				<el-button class="padButton" @click="close">关 闭</el-button>
			</el-row>
		</el-card>
	</div>
</template>

<script>

export default {
	name: 'tkOrderDetail',
	data() {
		return {
            statusList:[],
            currentStatus: 1,
			orderId: '', //订单id
			orderType: '', // 订单类型
            orderStatus:'',
			hotelOrderInfo: '', //订单信息
			hotelRefundInfo: '', //退订信息
			everyPriceList: [],
			orderIdRes: '', //酒店退订拿到的退订id，借此获取酒店id
		}
	},

	computed: {},
	mounted() {
		
			this.orderId = this.$route.query.orderId
			this.getHtRefundDetail(),
			this.getAppProgress()
	},
	methods: {
	
            getStatus(){
            let vipStatus=this.hotelOrderInfo.vipStatus;
            let zflx= this.hotelOrderInfo.zflx;
            // let orderStatus=this.hotelOrderInfo.orderStatus;
            let htRefundStatus=this.hotelOrderInfo.htRefundStatus
        //    willApproval("1","待送审"), //待送审
        // approvaling("2","审批中"), //审批中
        // willHandle("3","待办理"), //待办理
        // handling("4","办理中"), //办理中
        // refundRoom("5","已退房"),//已退票
        // refundMoney("6","已退款"),//已退票
        // refundCancel("7","已取消") //退票被取消
            if (vipStatus != '4'){
                    this.statusList= [
                    {status:"1",name:'送审'},
                    {status:"2",name:'申请已提交'},
                    {status:"3",name:'办理中'},
                    {status:"4",name:'已退房'},
                    {status:"5",name:'已退款'},
                   ]
                if(htRefundStatus==1 ||htRefundStatus==2 ){
                    this.orderStatus=1
                }else if(htRefundStatus == 3){
                    this.orderStatus=2
                }else if(htRefundStatus == 4){
                    this.orderStatus=3
                }else if(htRefundStatus == 5){
                    this.orderStatus=4
                }else if(htRefundStatus == 6){
                    this.orderStatus=5
                }else if(htRefundStatus == 7){
                    this.orderStatus=5
                }
            }else if(vipStatus==4){
                    this.statusList= [
                    {status:"1",name:'申请已提交'},
                    {status:"2",name:'办理中'},
                    {status:"3",name:'已退房'},
                    {status:"4",name:'已退款'},
                   ]
                 if(htRefundStatus == 3){
                    this.orderStatus=1
                }else if(htRefundStatus == 4){
                    this.orderStatus=2
                }else if(htRefundStatus == 5){
                    this.orderStatus=3
                }else if(htRefundStatus == 6){
                    this.orderStatus=4
                }else if(htRefundStatus == 7){
                    this.orderStatus=0
                }
            }

        },
		//酒店退订详情
		getHtRefundDetail() {
			this.$conn
				.getConn('htRefund.htRefundDetail', { error: true })({
					hotelRefundId:this.$route.query.orderId,
				})
				.then((res) => {
					this.orderIdRes = res.data.orderId
                    if(res.data.htRefundStatus ==3){
                        this.currentStatus=1
                    }else if(res.data.htRefundStatus ==4){
                        this.currentStatus=2
                    }else if(res.data.htRefundStatus ==5){
                        this.currentStatus=3
                    }else if(res.data.htRefundStatus ==6){
                        this.currentStatus=4
                    }
                    
					this.hotelOrderInfo = res.data
                    console.log(this.hotelOrderInfo,"this.hotelOrderInfo")
                    this.getStatus()
				})
		},


		// 关闭本页面
		close() {
			this.$confirm('此操作将关闭本页面, 是否继续?', '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning',
			})
				.then(() => {
					window.close()
				})
				.catch(() => {
					this.$message({
						type: 'info',
						message: '取消关闭',
					})
				})
		},



		getAppProgress() {
			this.$conn
				.getConn('commu.appProgress', { jsfile: false })({
					orderId: this.orderId,
					orderType: this.orderType,
				})
				.then((res) => {
					console.log(res, 'res')
				})
		},
	},
}
</script>

<style scoped="scoped" lang="scss">
.padButton {
	padding: 10px 30px;
	font-size: 15px;
}
.text-right {
	justify-content: end;
}
.b-t-1 {
	border: none;
	border-top: 1px solid #e6e6e6;
}
.p-r-20 {
	padding-right: 20px;
}
.textLeft {
	text-align: left;
}
.marTop20 {
	margin-top: 20px;
}
.between {
	justify-content: space-between;
}
.flex-column {
	display: flex;
	justify-items: center;
}
.tkod-main {
	margin: 0 auto;
	width: 1200px;
	padding-top: 20px;
	padding-bottom: 50px;

	.tk-main {
		display: flex;
		margin-bottom: 10px;
		min-height: 80px;
		border: 1px solid #e6e6e6;
		box-sizing: border-box;

		.left-main-width {
			width: 160px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #fafafa;
			border-right: 1px solid #e6e6e6;
		}
		.left-main-right {
			flex: 1;
			padding-left: 40px;
			display: flex;
			align-items: center;
		}
		.rooms-right {
			flex: 1;
			width: 100%;
			flex-direction: column;
			display: flex;
			align-items: center;
			.romms-info {
				padding-left: 40px;
				border-bottom: 1px solid #e6e6e6;
				width: 100%;
				min-height: 80px;
				display: flex;
				// justify-content: center;
				align-items: center;
			}
			.romms-info:nth-child(2) {
				border-bottom: none;
				margin-bottom: 20px;
			}
			.romms-info:nth-child(3) {
				border-bottom: none;
			}
		}
	}

	.flex {
		display: flex;
	}
}
</style>
