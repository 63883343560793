<template>
	<div class="pp-main">
		<div class="pured">
			<div class="flex between">
				<div class="zsmain">
					<div class="setcon">
						<img src="../../../assets/image/xak/xaklogin1.png" alt="OA无缝对接" width="240px" height="148px">
					</div>
					<div class="text-top">企业OA无缝对接</div>
					<div class="text-footer">
						近十家大型企业OA对接经验，提供标准的OA对接方案，与企业出差申请及审批流程无缝对接；业务情况特殊的企业，可派技术人员上门沟通需求，制定个性化OA对接方案。
					</div>
				</div>
				<div class="zsmain">
					<div class="setcon">
						<img src="../../../assets/image/xak/xaklogin2.png" alt="财务核算简单方便" width="240px" height="148px">
					</div>
					<div class="text-top">财务核算简单方便</div>
					<div class="text-footer">
						每月定时在系统中生成结算账单，并自动发送短信和邮件提醒；有专职的结算服务小组配合进行人工对账。
					</div>
				</div>
				<div class="zsmain">
					<div class="setcon">
						<img src="../../../assets/image/xak/xaklogin3.png" alt="优质的机场服务" width="240px" height="148px">
					</div>
					<div class="text-top">优质的机场服务</div>
					<div class="text-footer">
						VIP旅客免费提供接送机服务；在北京、上海、厦门、乌鲁木齐机场设有专职的服务人员，旅客到达机场即有服务人员接待，代办值机选座，行李托运，引领旅客到安检口。
					</div>
				</div>
				<div class="zsmain">
					<div class="setcon">
						<img src="../../../assets/image/xak/xaklogin4.png" alt="产品资源丰富" width="240px" height="148px">
					</div>
					<div class="text-top">产品资源丰富</div>
					<div class="text-footer">
						国内/国际机票、国内/国际酒店、火车票、用车、会议旅游、签证、保险一站式服务。
					</div>
				</div>
				<div class="zsmain">
					<div class="setcon">
						<img src="../../../assets/image/xak/xaklogin5.png" alt="预订便捷" width="240px" height="148px">
					</div>
					<div class="text-top">预订便捷</div>
					<div class="text-footer">
						支持APP、PC、微信、电话等多种渠道预订方式；
						{{compInfo.etServicePhone}}提供24小时人工服务。
					</div>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default{
		name: 'Pure',
		props:{
			compInfo:{
				type:Object,
				default(){
					return {};
				}
			}
		},
	}
</script>

<style lang="scss" scoped>
	.pp-main{
		width: 100%;
		.pured{
			width: 1270px;
			margin: 0 auto;
			text-align: center;
			
			.flex{
				display: flex;
			}
			
			.between{
				justify-content: space-between;
			}
			.zsmain{
				width: 240px;
				min-height: 300px;
				border-radius: 10px;
				box-shadow: 0 0 2px 2px rgba(0,0,0,0.1);
				overflow: hidden;
				font-size: 0px;
				.setcon{
					display: inline-block;
					width: 240px;
					height: 148px;
					overflow: hidden;
				}
				
					
				
				.text-top{
					font-size: 20px;
					color: #585858;
					line-height: 20px;
					margin: 25px 0;
					overflow: hidden;
					text-overflow: ellipsis;
					white-space: nowrap;
				}
				.text-footer{
					height: 170px;
					font-size: 14px;
					line-height: 24px;
					letter-spacing: 1px;
					font-weight: 300;
					color: #999999;
					padding: 0 15px;
					text-align: left;
					box-sizing: border-box;
				}
			}
			.zsmain:hover{
				box-shadow:0px 0px 10px 6px rgba(0,0,0,0.3);
				transition :all 0.5s ease;
				cursor: pointer;
			}
			
			.zsmain:hover img{
				-webkit-transform: scale(1.1);
				-moz-transform: scale(1.1);
				-ms-transform: scale(1.1);
				-o-transform: scale(1.1);
				transform: scale(1.1);
				transition: all 0.5s ease;
			}
		}
	}
</style>
