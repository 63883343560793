<template>
  <div>
    <el-dialog class="text-left" :destroy-on-close="true" top="25vh" :modal="true" :close-on-press-escape="false"
      :close-on-click-modal="false" :modal-append-to-body="true" :append-to-body="true" :visible.sync="value"
      :before-close="beforeClose" @closed="closeCallback()" :show-close="false" width="600px">
      <div slot="title">
        <div class="flex between  ">
          <div class="font18">{{ type == 1 ? '确认对账单' : '退回对账单' }}</div>
          <div>
            <span class="el-icon-close font18 pointer" @click="cancel"></span>
          </div>
        </div>
      </div>
      <div>
        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
          <el-form-item label="备注说明" prop="msg">
            <el-input type="textarea" v-model="ruleForm.msg"></el-input>
          </el-form-item>
          <el-form-item label="附件" prop="file">
            <el-upload ref="upload" name="file" :auto-upload="false" :multiple="false" :action="uploadPath"
              :before-upload="beforeAvatarUpload" :on-change="handleChange" :before-remove="beforeRemove" :limit="1"
              :on-exceed="handleExceed" :file-list="ruleForm.file">
              <el-button size="small" type="primary">点击上传</el-button>
              <div slot="tip" class="el-upload__tip">
                提示：单次只能上传一个文件，格式限制jpg|png|jpeg|gif|xlsx|xls|txt|doc|docx|pdf|pdf
              </div>
            </el-upload>
          </el-form-item>
          <el-form-item>
            <el-button @click="cancel()">取消</el-button>
            <el-button type="primary" :loading="loading" @click="confirm()">确定</el-button>
          </el-form-item>
        </el-form>

      </div>
    </el-dialog>
  </div>
</template>

<script>

import axios from "../../plugins/http/interceptor.js";

export default {
  name: 'ConfirmDzd',
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    type: {
      type: Number,
      default() {
        return 1
      }
    },
    uid: {
      type: String,
      default() {
        return ''
      }
    },
  },
  data() {
    let uploadPath = this.$conn.getUrl('common.upload');
    return {
      uploadPath,
      loading: false,
      ruleForm: {
        msg: '',
        file: [],
      },
      rules: {
        msg: [
          { required: false, message: '请输入备注', trigger: 'blur' },
          { min: 1, max: 500, message: '不能为空', trigger: 'blur' }
        ],
        file: [
          { required: false, message: '请选择文件', trigger: 'change' }
        ],
      },
      typeArr: [
        "jpg",
        "png",
        "jpeg",
        "gif",
        "xlsx",
        "xls",
        "txt",
        "doc",
        "docx",
        "pdf",
        "wps",
        "application/msword",
        "application/vnd.ms-excel",
      ]
    };
  },
  mounted() {

  },
  methods: {
    isRightType(name) {
      if (!name) return false;
      let bool = false;
      for (var k = 0; k < this.typeArr.length; k++) {
        if (name.indexOf(this.typeArr[k]) > -1) {
          bool = true;
          break;
        }
      }
      return bool;
    },

    confirm() {
      let self = this;
      self.loading = true;
      let formData = new FormData();
      formData.append('type', this.type);
      formData.append('id', this.uid);
      for (const key in this.ruleForm) {
        if (Object.hasOwnProperty.call(this.ruleForm, key)) {
          const element = this.ruleForm[key];
          if (!!element) {
            if (key == 'file' && element.length > 0) {
              formData.append(key, element[0]);
              continue;
            }
            formData.append(key, element);
          }
        }
      }

      let file = formData.get('file');
      // 1.验证文件格式
      if (!!file) {
        if (!this.isRightType(file.type)) {
          this.$message.warning(`文件格式不符合要求！`);
          self.loading = false;
          return
        }
      } else {
        formData.delete('file')
      }
      // else if (file.size / 1024 / 1024 >= 5) {
      //   message.error("文件不得超过5M");
      //   reject("文件不得超过5M");
      // } else {
      //   resolve(file);
      // }

      let dzdOnlineConfirmPath = this.$conn.getUrl('dzd.dzdOnlineConfirm');
      let config = {
        headers: { 'Content-Type': 'multipart/form-data' },
        noNeedToken: true,
      }; //添加请求头
      axios.post(dzdOnlineConfirmPath, formData, config)
        .then(response => {
          self.loading = false;
          if (response.httpStatus == 200) {
            let message = self.type == 1 ? '已确认对账单！' : '已退回对账单！'
            this.$confirm(message, '提示', {
              confirmButtonText: '确定',
              showCancelButton: false,
              closeOnClickModal: false,
              closeOnPressEscape: false,
              showClose: false,
              type: 'success'
            }).then(() => {
              self.closePage();
            }).catch(() => {

            });
          } else {
            this.$message({
              type: 'error',
              message: !!response.tips ? response.tips : "系统异常！",
            })
          }
        }).catch(() => {
          self.loading = false;
        })
    },
    closePage() {
      if (navigator.userAgent.indexOf("Firefox") != -1 || navigator.userAgent.indexOf("Chrome") != -1) {
        window.location.href = "about:blank";
        window.close();
      } else {
        window.opener = null;
        window.open("", "_self");
        window.close();
      }
    },
    // 关闭清除文件
    beforeClose() {
      this.ruleForm.file = [];
    },
    cancel() {
      this.$emit('input', false)
    },
    handleChange(uploadFile) {
      this.ruleForm.file = [uploadFile.raw];
    },
    handleExceed(files, fileList) {
      if (files && files.length == 1) {
        this.ruleForm.file = [files[0]];
        return;
      }
      this.$message.warning(`当前限制选择 1 个文件，本次选择了 ${files.length} 个文件`);
    },
    beforeRemove(file, fileList) {
      return this.$confirm(`确定移除 ${file.name}？`);
    },
    beforeAvatarUpload(file) {
      const isLt2M = file.size / 1024 / 1024 < 100;
      if (!isLt2M) {
        this.$message.error('上传文件大小不能超过100MB!');
      }
      return isLt2M;
    },
    closeCallback() {
      // 清除数据
      this.ruleForm = {
        msg: '',
        file: [],
      }
    }
  },
};
</script>

<style lang="scss" scoped>
.flex {
  display: flex;
}

.between {
  justify-content: space-between;
}
</style>