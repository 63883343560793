<template>
	<div class="ra-main">
		<div class="absolute">
			<span v-if="tabsIndex==0" @click="goToAllRouter">查看全部行程</span>
			<span v-if="tabsIndex==1" @click="goToAllApproval">查看全部审批</span>
		</div>
		<el-tabs type="border-card" @tab-click="getTabIndex">
			<el-tab-pane :label="'行程提醒'+'( '+xctotal+' )'">
				<el-table :data="orderList" style="width: 100%" 
					v-loading = "loading"
					element-loading-background = "rgba(255, 255, 255, .5)"
					element-loading-text = "加载中，请稍后..."
					element-loading-spinner = "el-icon-loading"
					:show-header="false">
				    <el-table-column  :width="bookStyle==1?100:60">
						<template slot-scope="scope">
							<el-button type="text" style="cursor: inherit;" :class="iconType[scope.row.orderType]"></el-button>						
						</template>
				    </el-table-column>
				    <el-table-column prop="routeName" :width="bookStyle==1?180:180"></el-table-column>
				    <el-table-column :width="bookStyle==1?250:180">
						<template slot-scope="scope">
							<span v-if="scope.row.orderType == 11003 || scope.row.orderType == 11011">{{scope.row.nDepartTime}}</span>
							<span v-else>{{scope.row.nDepartTime?scope.row.nDepartTime: scope.row.oDepartTime || scope.row.departTime}}</span>
						</template>
					</el-table-column>
					<el-table-column prop="travelEmp"  :width="bookStyle==1?250:190"></el-table-column>
					<el-table-column prop="oFlightNo" :width="bookStyle==1?200:100"></el-table-column>
					<el-table-column  :width="bookStyle==1?170:80">
						<template slot-scope="scope">
							<div class="textColor" @click="goToDetail(scope.row,tabsIndex)">详情</div>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
			<el-tab-pane :label="'需要我审批'+'( '+sptotal+' )'">
				<el-table :data="approvalList" style="width: 100%" 
					v-loading = "loading"
					element-loading-background = "rgba(255, 255, 255, .5)"
					element-loading-text = "加载中，请稍后..."
					element-loading-spinner = "el-icon-loading"
					:show-header="false">
				    <el-table-column :width="bookStyle==1?100:50">
						<template slot-scope="scope">
							<el-button type="text" style="cursor: inherit;" :class="iconType[scope.row.orderType]"></el-button>	
						</template>
				    </el-table-column>
				    <el-table-column prop="person" :width="bookStyle==1?250:160"></el-table-column>
				    <el-table-column :width="bookStyle==1?200:200">
						<template slot-scope="scope">
							<div v-if="scope.row.orderType=='11099'">{{scope.row.departDate}} 至 {{scope.row.endDate}}</div>
							<div v-else>{{scope.row.departDate}}</div>
						</template>
				    </el-table-column>
					<el-table-column :width="bookStyle==1?240:200">
						<template slot-scope="scope">
							<div v-if="scope.row.orderType=='11018'">用车 {{scope.row.cabin}}</div>
							<div v-if="scope.row.orderType=='11099'">{{scope.row.departCity || scope.row.mdd || scope.row.manyMdd }}</div>
							<div v-else>{{scope.row.departCity}}</div>
						</template>
					</el-table-column>
					<el-table-column :width="bookStyle==1?200:100">
						<template slot-scope="scope">
							<div style="color:#f5a337;">￥{{scope.row.price || 0}}</div>
						</template>
					</el-table-column>
					<el-table-column  :width="bookStyle==1?160:80">
						<template slot-scope="scope">
							<div class="textColor" @click="goToDetail(scope.row,tabsIndex)">审批</div>
						</template>
					</el-table-column>
				</el-table>
      </el-tab-pane>    
      <!--过期行程-->
      <el-tab-pane :label="'审批超时'+'( '+spccTotal+' )'">  
        <el-table :data="ccApprovalList" style="width: 100%"
        	v-loading = "loading"
        	element-loading-background = "rgba(255, 255, 255, .5)"
        	element-loading-text = "加载中，请稍后..."
        	element-loading-spinner = "el-icon-loading"
        	:show-header="false">
            <el-table-column :width="bookStyle==1?100:50">
        		<template slot-scope="scope">
        			<el-button type="text" style="cursor: inherit;" :class="iconType[scope.row.orderType]"></el-button>	
        		</template>
            </el-table-column>
            <el-table-column prop="person" :width="bookStyle==1?250:160"></el-table-column>
            <el-table-column :width="bookStyle==1?200:200">
        		<template slot-scope="scope">
        			<div v-if="scope.row.orderType=='11099'">{{scope.row.departDate}} 至 {{scope.row.endDate}}</div>
        			<div v-else>{{scope.row.departDate}}</div>
              <span style="color:red;">行程过期</span>
        		</template>
            </el-table-column>
        	<el-table-column :width="bookStyle==1?240:200">
        		<template slot-scope="scope">
        			<div v-if="scope.row.orderType=='11018'">用车 {{scope.row.cabin}}</div>
        			<div v-if="scope.row.orderType=='11099'">{{scope.row.departCity || scope.row.mdd || scope.row.manyMdd }}</div>
        			<div v-else>{{scope.row.departCity}}</div>
        		</template>
        	</el-table-column>
        	<el-table-column :width="bookStyle==1?200:100">
        		<template slot-scope="scope">
        			<div style="color:#f5a337;">￥{{scope.row.price || 0}}</div>
        		</template>
        	</el-table-column>
        	<el-table-column  :width="bookStyle==1?160:80">
        		<template slot-scope="scope">
        			<div class="textColor" @click="goToDetail(scope.row,tabsIndex)">审批</div>
        		</template>
        	</el-table-column>
        </el-table>
			</el-tab-pane>	
		</el-tabs>	
	</div>
</template>

<script>
	export default {
		name: 'RouyeApproval',
		data() {
			return {
				loading: false, // 加载
				orderList:[], //订单行程列表
				approvalList:[], //需要我申请单列表
        ccApprovalList:[], // 审批超时列表
				sptotal:0, //需要我审批总条数
				xctotal: 0, // 带出行
        spccTotal:0, // 审批超时
				tabsIndex:0,
				iconType:{
					"11001":'iconfont yj-icon-airplane-full', //国内机票正常单11001
					"11002":'iconfont yj-icon-airplane-full', //国内机票退票单11002
					"11003":'iconfont yj-icon-airplane-full', //国内机票改签单11003
					"11004":'iconfont yj-icon-airplane-full', // 国际机票正常单11004
					"11005":'iconfont yj-icon-airplane-full', //国际机票退票单11005
					"11006":'iconfont yj-icon-airplane-full',//国际机票改签单1100
					"11007":'iconfont yj-icon-jiudian1', //酒店正常单11007
					"11008":'iconfont yj-icon-jiudian1',//酒店退单11008
					"11009":'iconfont yj-icon-train2huoche', //火车票正常单11009
					"11010":'iconfont yj-icon-train2huoche',//火车票退票单11010
					"11011":'iconfont yj-icon-train2huoche',//火车票改签单11011
					"11012":'iconfont yj-icon-airplane-full', //机场服务订单 		
					"11014":'iconfont yj-icon-a-bochetingchetingchechang',//代客泊车订单
					"11015":'iconfont yj-icon-baoxiandingdan',//保险订单
					"11018":'iconfont yj-icon-yongchedingdan',//用车订单11018
					"11019":'iconfont yj-icon-zhijidengjibanli',//代办值机
					"110151":'iconfont yj-icon-baoxiandingdan', //保险退保
					"11020":'iconfont yj-icon-tuidan',// 机场服务退单
					"11022":'iconfont yj-icon-jiudian1', //国际酒店正常单
					"11023":'iconfont yj-icon-jiudian1',// 国际酒店退单
					"11024":'iconfont yj-icon-xinzengtubiao-19',//餐饮订单
					"11097":'iconfont yj-icon-qitadingdan', //其他订单11097
					"11098":'iconfont yj-icon-qitadingdan', //变更单
					"11099":'iconfont yj-icon-qitadingdan', //出差申请单
				},
				vipCorp:{}, // 企业信息
				bookStyle:'2', // 模式  1订单 2出差申请单
			}
		},
		created(){
			// 登录人信息
			let userInfo = this.$common.getUserInfo() || {};
			this.vipCorp = userInfo.vipCorp || {};
			this.bookStyle = this.vipCorp.bookStyle;
		},
		mounted(){
			// this.vipBpmForMyAppTotal();
			this.getTotalOrderList();
      this.getBpmForMyApp();
			this.getBpmForMyApp(2);
			// 接受子页面方法刷新需要我审批
			window['needMyApprovalRefresh'] = (val) =>{
				if(val==1){
					this.getBpmForMyApp();
				}
			}
		},
		methods:{
			// 获取待出发行程列表
			getTotalOrderList(){
				let queryParams = {};
				var startDate = this.$dateTime.getToDay() ;
				var endDate = this.$dateTime.getAppointDay(startDate,90);
					queryParams = {
						count: "5",
						dateStart: startDate,
						dateEnd: endDate,
						dateType: "2",
						findScope: "1",
						findType: "3",
						pageNum: "1",
					}
					this.$conn.getConn('tkOrder.totalOrderList')(queryParams,(res)=>{
						let data = res.data || {};
						this.orderList = data.list || [];
						this.xctotal = data.total || 0;
					},(err)=>{
						if(err.errCode=='3901'){
						
						}else{
							this.$alert(err.tips,'温馨提示',{
								type: 'error' 
							})
						}
					})
				this.loading = false;	
			},
			vipBpmForMyAppTotal(){
				this.$conn.getConn('user.vipBpmForMyAppTotal')({overdueFlag:0},(res)=>{
					let data = res.data || {};
					this.sptotal = res.data
				},(err)=>{
					
				})
			},
			// 获取需要我审批列表
			getBpmForMyApp(type){
				let queryParams = {};
        // 审批超时
        if(type==2){
          queryParams = {
          	count: "5",								
          	pageNum: "1",
            overdueFlag:1,
          }
        }else{
          queryParams = {
          	count: "5",								
          	pageNum: "1",
          }
        }
				
				this.$conn.getConn('user.myApp')(queryParams,(res)=>{
					let data = res.data || {};
					
          if(type==2){
            this.ccApprovalList =  data.list || [];
            this.spccTotal = data.total;
          }else{
            this.approvalList =  data.list || [];
            this.sptotal = data.total;
          }
				},(err)=>{
					if(err.errCode=='3901'){
					
					}else{
						this.$alert(err.tips,'温馨提示',{
							type: 'error' 
						})
					}
				})
				this.loading = false;
			},
			//去详情
			goToDetail(item,num){
				let orderType = item.orderType;
				let path = this.$dict.detailConfig(orderType);
				let query = {}
				if(num==1){
					query = {
						orderId: item.orderId,
						orderType: item.orderType,
						workItemId:item.workItemId, //工作项id
						appType: 1, // 操作 审批
						overdueFlag:item.overdueFlag,
					}
				}else{
					query = {
						orderId: item.orderId || item.id,
						orderType: item.orderType,
						workItemId:  item.workItemId,
					}
				}
				
				if(path){
					let routerUrl = this.$router.resolve({path:path,query:query})
					window.open(routerUrl.href,"_blank")
				}else{
					if(err.errCode=='3901'){
					
					}else{
						this.$alert('请配置路由信息','温馨提示',{
							type: 'error' 
						})
					}
					
				}
				
			},
			// 查看全部行程
			goToAllRouter(){
				this.$router.push("/yjTravel/myTravel/userCenter/order")
			},
			goToAllApproval(){
				this.$router.push("/yjTravel/myTravel/userCenter/needMyApproval")
			},
			// 获取tab 索引
			getTabIndex(e){
				if(e.index == this.tabsIndex){
					return 
				}
				this.loading = true;
				this.tabsIndex = e.index;	
				if(this.tabsIndex==0){
					this.getTotalOrderList();
				}else{
					this.getBpmForMyApp();
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.ra-main{
		font-family: '微软雅黑';
		color: #282828;
		position: relative;
		width: 100%;
		height: 100%;
		.absolute{
			position: absolute;
			top: 0;
			right: 20px;
			line-height: 50px;
			z-index: 10;
			font-size: 12px;
			font-weight: bold;
			color: #586F90;
			cursor: pointer;
			:hover {
				color: #00A7FA;
			}
		}
		.el-tabs {
			height: 235px;
			overflow: hidden;
			/deep/ .el-tabs__content{
				height: 180px;
				overflow-y: auto;
			}
			
		}
		
		.el-tabs--border-card{
			border: none;
			box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
			/deep/ .el-tabs__item{
				height: 50px;
				line-height: 50px;
			}
			.iconColor{
				color: #006BB9;
				font-size: 18px;
			}	
			.textColor{
				color: #00A7FA;
				cursor: pointer;
				text-align: right;
			}
			/deep/ .el-tabs__header{
				background-color: #EAEDF1;
			}
		}
			
			
		
		
		
		
	}
</style>
