



var loginFristAlert = {};



// 登录后加载弹窗
loginFristAlert = {
	data(){
		return {
	
		}
	},
	methods:{

		initFirstLoginInfo(){
      
			let firstLoginAlertBool = this.$common.localget('firstLoginAlertBool');
			
			// 如果未获取过，则获取并弹窗
			if(!(firstLoginAlertBool == 1)){
				this.getFirstLoginAlertInfo();
			}
		},
		//获取第一次登录加载弹窗信息
		getFirstLoginAlertInfo(){

			let queryObj = {};
			  let getFirstLoginInfo = this.$conn.getConn('user.getFirstLoginInfo');
			  getFirstLoginInfo(queryObj,(res)=>{
				let data = res.data ;
				if(data){
          this.$alert(`<pre style='white-space: pre-wrap;word-wrap: break-word;'><span >${data}</span></pre>`, 
          '温馨提示', 
          {
            dangerouslyUseHTMLString: true,
            confirmButtonText:'我已知晓'
          },
        );


					this.$common.localset('firstLoginAlertBool',1);
				}else{

					// 以下为测试代码：
// 					let tips = `
// 在线预订时必须保证个人信息正确无误才可下单成功，如有问题可自行在【我的】-【个人资料】中修改，需要检查信息如下：
// <span style="color:orange;">
// 1.机票必须确认身份证是否有误
// 2.火车票必须确认身份证是否有误
// 3.酒店必须确认身份证是否有误
// 4.用车必须确认手机号是否有误
// </span>
// `


//           this.$alert(`<pre style='white-space: pre-wrap;word-wrap: break-word;'><span >${tips}</span></pre>`, '温馨提示', 
//             {
//               dangerouslyUseHTMLString: true,
//               confirmButtonText:'我已知晓'
//             });

// 					this.$common.localset('firstLoginAlertBool',1);
				}

			  },(err)=>{
				console.error(err)
			  })
		},
		


		closeFirstLoginAlert(){
			this.isShowFirstAlert = false;

			
		},
	}
	
}




export default loginFristAlert
