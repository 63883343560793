<template>
  <div class="online">
    <div class="online-main" v-if="!!data">
      <div style="text-align: left; margin:10px 0;font-size: 24px;font-weight: 900;">待付款账单明细</div>
      <div class="online-card">
        <div class="header">
          <img :src="logo" style="width: '264px'; height: 136px;margin-left: -55px" alt="">
          <div>
            <div class="title" style="font-weight: 600;">北京空港嘉华服务有限公司</div>
            <div class="title" style="font-weight: 600;">Beijing Airport Jiahua Aviation Service Co.,Ltd.</div>
            <div class="title">账单号：{{ data.reconId }}</div>
            <div class="title">账单日：{{ data.dzdRq }}</div>
            <div class="title">账单周期：{{ data.dzdZq }}</div>
            <div class="title">最晚付款日：{{ data.dzdHk }}</div>
          </div>
        </div>

        <el-table class="margin-top" :data="tableData" border>
          <el-table-column prop="tk" label="机票"></el-table-column>
          <el-table-column prop="tr" label="火车票"></el-table-column>
          <el-table-column prop="ht" label="酒店"></el-table-column>
          <el-table-column prop="yc" label="用车"></el-table-column>
          <!-- <el-table-column prop="bx" label="保险"></el-table-column> -->
          <el-table-column prop="qz" label="签证"></el-table-column>
          <el-table-column prop="hy" label="会议"></el-table-column>
          <el-table-column prop="qt" label="其他"></el-table-column>
          <el-table-column prop="sum" label="欠款合计">
            <template slot-scope="scope">
              <div style="color: red">{{ scope.row.sum }}</div>
            </template>
          </el-table-column>
        </el-table>

        <div class="margin-top" style="padding-bottom: 60px;">
          <el-button type="primary" @click="confirm()">确认对账单无误</el-button>
          <el-button type="danger" @click="exece()">退回对账单</el-button>
          <el-button plain :loading="exportLoading" @click="exportExcel()">下载账单</el-button>

        </div>
      </div>

      <div>
        <div class="title">
          还款时请备注付款对应账单号、如果多月账单台并付款，请备注合并付款涉及到的账单号，名称之间以"/"进行分割。
        </div>
        <div class="title" style="color: red">
          请在最晚还款日期之前完成付款，超时未还款会关闭您单位的订票垫款额度届时将无法进行企业月结，逾期每日收取延迟部分金额的{{ data.fineRate }}%利息。
        </div>
        <div class="title">
          对账单有任何疑问请联系”结算专员°，如果结算负责人依然无法解决的复杂问题（例：结算模式，结算周期等） 请联系商务负责人解决。
        </div>
        <div class="title">结算专员：{{ data.jsName }} {{ data.jsPhone }}</div>
        <div class="title">结算负责人：孔成城</div>
        <div class="title">商务负责人（投诉）：{{ data.kwName }}</div>
        <div class="title" style="font-weight: 600; margin-top: 10px;">收款帐户</div>
        <div class="title">户 名：北京空港嘉华航空服务有限公司</div>
        <div class="title">账号：110 907 808 510 602</div>
        <div class="title">开户行：招商银行北京阜外大街支行</div>
      </div>

    </div>

    <confirm-dzd v-model="visible" :uid="uid" :type="type" @cancle="cancleDzd()" />
  </div>
</template>

<script>
import { Loading } from 'element-ui';
import ConfirmDzd from './ConfirmDzd.vue';

export default {
  name: 'CusOnlineReconilite',
  components: {
    'confirm-dzd': ConfirmDzd
  },
  data() {
    return {
      phone: '',
      data: null,
      visible: false,
      type: 0,
      exportLoading: false,
      uid: '',
      tableData: [],
      logo: require('@/assets/image/kg-logo.png'),
    };
  },
  mounted() {
    this.openPhone();
    // let loadingInstance = Loading.service({ fullscreen: true, lock: true, text: '正在加载...' });
    // this.$nextTick(() => { // 以服务的方式调用的 Loading 需要异步关闭
    //   loadingInstance.close();
    // });
  },

  methods: {
    openPhone() {
      this.$prompt('请输入手机号', '输入手机号加载对账信息', {
        confirmButtonText: '确定',
        showClose: false,
        showCancelButton: false,
        closeOnPressEscape: false,
        closeOnHashChange: false,
        closeOnClickModal: false,
      }).then(({ value }) => {
        if (!value) {
          this.$message({
            type: 'warning',
            message: '请输入手机号再操作'
          });
          this.openPhone()
          return;
        }
        this.phone = value;
        this.getDzdOnlie();
      }).catch(() => {
        // this.$message({
        //   type: 'info',
        //   message: '取消输入'
        // });
      });
    },
    getDzdOnlie() {
      if (!this.phone) return;
      let query = this.$route.query;
      if (!query || !query.uid) {
        this.$confirm('当前链接异常，请重新打开！', '提示', {
          confirmButtonText: '确定',
          showClose: false,
          showCancelButton: false,
          type: 'warning'
        }).then(() => {
          window.close();
        });
        return;
      }
      this.uid = query.uid;
      let loadingInstance = Loading.service({ fullscreen: true, lock: true, text: '正在加载...' });
      this.$conn.getConn('dzd.dzdOnline', {})({ phone: this.phone, id: this.uid },
        res => {
          this.data = res.data || {};
          if (!!res.data) {
            this.tableData = [res.data];
          }
          loadingInstance.close();
        }, (err) => {
          loadingInstance.close();
          this.$confirm(err.tips || '加载异常！', '提示', {
            confirmButtonText: '确定',
            showClose: false,
            showCancelButton: false,
            closeOnPressEscape: false,
            closeOnHashChange: false,
            closeOnClickModal: false,
            type: 'error'
          }).then(() => {
            this.openPhone();
          });
        });
    },
    cancleDzd() {
      this.visible = false;
    },
    exece() {
      this.type = 2;
      this.visible = true;
    },
    confirm() {
      this.type = 1;
      this.visible = true;
    },
    exportExcel() {
      let query = this.$route.query;
      this.exportLoading = true;
      let self = this;
      this.$conn.getConn('dzd.dzdOnlineExport')({ phone: this.phone, id: query.uid },
        res => {
          self.inter = setInterval(() => {
            self.getAsyncExportStatus(query.uid, res.data, (data) => {
              self.exportLoading = false;
              if (data) {
                // 去下载导出文件并保存在本地
                self.downloadFile(data.dclj);
                self.inter && clearInterval(self.inter);
              }
            });
          }, 5000);

        }, (err) => {
          this.exportLoading = false;
          this.$confirm(err.tips, '提示', {
            confirmButtonText: '确定',
            showCancelButton: false,
            type: 'error'
          }).then(() => {
          });
        });
    },
    downloadFile(path) {
      if (this.isPC()) {
        window.open(path, '_blank');
      } else {
        const a = document.createElement("a");
        a.href = path;
        var timestamp = new Date().getTime();
        let name = 'zxdz-' + timestamp;
        let names = path.split('/');
        if (names && names.length > 1) {
          name = names[names.length - 1];
        }
        a.download = name;
        a.click();
      }
    },
    isPC() {
      var userAgentInfo = navigator.userAgent;
      var Agents = ["Android", "iPhone", "SymbianOS", "Windows Phone", "iPad", "iPod"];
      var isPC = true;
      for (var i = 0; i < Agents.length; i++) {
        if (userAgentInfo.indexOf(Agents[i]) > 0) {
          isPC = false;
          break;
        }
      }
      return isPC;
    },
    // 获取导出异步的状态
    getAsyncExportStatus(id, taskId, callback) {
      let params = {
        id,
        taskId
      }
      this.$conn.getConn('travelRequest.findTaskList2')(params, (res) => {
        let data = res.data || {};
        if (data && data.status == 3) {
          callback && callback(data);
          return;
        }
      }, (err) => {
        this.$message.error(err.tips);
        callback && callback();
      })
    }
  }
};
</script>

<style lang="scss" scoped>
.online {
  height: 100%;
  background-color: #F3F5F7;

  .title {
    color: #333;
    font-size: 13px;
    text-align: left;
    line-height: 20px;
  }


  .online-main {
    width: 1000px;
    margin: 0 auto;
    padding: 10px 0;

    .online-card {
      margin: 15px 0;
      padding: 10px 20px;
      border-radius: 8px;
      background-color: #fff;

      .header {
        display: flex;
        align-items: center;
        justify-content: space-between;
        overflow: hidden;

      }



    }
  }

  .margin-top {
    margin-top: 40px;
  }


  .red {
    color: red;
  }
}

/deep/.el-descriptions--small.is-bordered th {
  padding: 12px
}

/deep/.el-descriptions--small.is-bordered td {
  padding-bottom: 12px
}

/deep/.el-descriptions-item__label.is-bordered-label {
  color: #333;
}
</style>