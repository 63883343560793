<template>
	<div class="uc-main" :style="{'min-height':minHeight+'px'}">
		<div class="flex between main">
			<div class="uc-main-width-left" >
				<center-left-menu></center-left-menu>
			</div>
			<div class="uc-main-width-right">
				<router-view/>
			</div>
		</div>
	</div>
</template>

<script>
	import CenterLeftMenu from './ucComponents/CenterLeftMenu';
	export default{
		name: 'UserCenter',
		data() {
			return {
				activeIndex: '0',
			}
		},
		components: {
			'center-left-menu' : CenterLeftMenu,
		},
		computed:{
			minHeight(){
				let height = document.body.clientHeight - 86
				return height
			}
		},
		methods: {
			
		
		},
		
		
	}
</script>

<style scoped lang="scss">
	.uc-main{
		background-color: #EEF1F6;
		.main{
			width: 1200px;
			margin: 0 auto;
			padding-top: 20px;
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.uc-main-width-left{
			width: 270px;
		}
		.uc-main-width-right{
			width: 910px;
		}
	}
</style>
