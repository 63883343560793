<template>
	<div class="ti-main">
		<el-tabs type="border-card" v-model="activeName"  @tab-click="handleType">
			<el-tab-pane label="国内机票"  name="zcGnjp" v-if="vipCorp.zcGnjp==1">
				<el-table :data="tkList" style="width: 100%" 
					v-loading = "loading"
					element-loading-background = "rgba(255, 255, 255, .5)"
					element-loading-text = "加载中，请稍后..."
					element-loading-spinner = "el-icon-loading"
					:show-header="false">	
				    <el-table-column style="width: 35%;">
						<template slot-scope="scope">
							{{scope.row.distanceS}} -- {{scope.row.distanceE}} 公里
						</template>
				    </el-table-column>
				    <el-table-column prop="routeName" style="width: 35%;">
						<template slot-scope="scope">
							{{scope.row.list[0].gkValue}}
						</template>
					</el-table-column>
				    <el-table-column prop="oDepartTime" style="width: 30%;">
						<template slot-scope="scope">
							<div style="text-align: right;padding-right:20px;">
								{{scope.row.list[0].gkfs==1?'只做提醒':'不允许预订'}}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
			<el-tab-pane label="国际机票"  name="zcGjjp" v-if="vipCorp.zcGjjp==1">
				<el-table :data="gjTkList" style="width: 100%"
					v-loading = "loading"
					element-loading-background = "rgba(255, 255, 255, .5)"
					element-loading-text = "加载中，请稍后..."
					element-loading-spinner = "el-icon-loading"
					:show-header="false">
				    <el-table-column style="width: 35%">
						<template slot-scope="scope">
							{{scope.row.distanceS}} -- {{scope.row.distanceE}} 公里
						</template>
				    </el-table-column>
				    <el-table-column prop="routeName" style="width: 35%">
						<template slot-scope="scope">
							{{scope.row.list[0].gkValue}}
						</template>
					</el-table-column>
				    <el-table-column prop="oDepartTime" style="width: 30%">
						<template slot-scope="scope">
							<div style="text-align: right;padding-right:20px;"  >
								{{scope.row.list[0].gkfs==1?'只做提醒':'不允许预订'}}
							</div>
						</template>
					</el-table-column>
				</el-table>
	
			</el-tab-pane>
			<el-tab-pane label="酒店" name="zcJd" v-if="vipCorp.zcJd==1">
				<el-table :data="htList" style="width: 100%" 
					v-loading = "loading"
					element-loading-background = "rgba(255, 255, 255, .5)"
					element-loading-text = "加载中，请稍后..."
					element-loading-spinner = "el-icon-loading"
					:show-header="false">
				    <el-table-column style="width: 35%">
						<template slot-scope="scope">
							<span>{{scope.row.cityLevelName}}</span>
						</template>
				    </el-table-column>
				    <el-table-column prop="routeName" style="width: 35%">
						<template slot-scope="scope">
							{{scope.row.gkValue?scope.row.gkValue:'未设置酒店价格标准'}}
						</template>
					</el-table-column>
				    <el-table-column prop="oDepartTime" style="width: 30%">
						<template slot-scope="scope">
							<div style="text-align: right;padding-right:20px;">
								{{scope.row.gkfs==1?'只做提醒':'不允许预订'}}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
			<el-tab-pane label="国际酒店"  name="zcGjjd"  v-if="vipCorp.zcGjjd==1">
				<el-table :data="gjHtList" style="width: 100%" 
					v-loading = "loading"
					element-loading-background = "rgba(255, 255, 255, .5)"
					element-loading-text = "加载中，请稍后..."
					element-loading-spinner = "el-icon-loading"
					:show-header="false">	
				    <el-table-column style="width: 35%">
						<template slot-scope="scope">
							{{ scope.row.cityLevelName}}
						</template>
				    </el-table-column>
				    <el-table-column prop="routeName"  style="width: 35%">
						<template slot-scope="scope">
							{{scope.row.gkValue}}
						</template>
					</el-table-column>
				    <el-table-column prop="oDepartTime"  style="width: 30%">
						<template slot-scope="scope">
							<div style="text-align: right;padding-right:20px;">
								{{scope.row.gkfs==1?'只做提醒':'不允许预订'}}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
			<el-tab-pane label="火车票" name="zcHcp" v-if="vipCorp.zcHcp==1">
				<el-table :data="trList" style="width: 100%" 
					v-loading = "loading"
					element-loading-background = "rgba(255, 255, 255, .5)"
					element-loading-text = "加载中，请稍后..."
					element-loading-spinner = "el-icon-loading"
					:show-header="false">
				    <el-table-column style="width: 35%">
						<template slot-scope="scope">
							{{ scope.row.cityLevelName}}
						</template>
				    </el-table-column>
				    <el-table-column prop="routeName" style="width: 35%">
						<template slot-scope="scope">
							{{scope.row.gkValue}}
						</template>
					</el-table-column>
				    <el-table-column prop="oDepartTime" style="width: 30%">
						<template slot-scope="scope">
							<div style="text-align: right;padding-right:20px;">
								{{scope.row.gkfs==1?'只做提醒':'不允许预订'}}
							</div>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
			<el-tab-pane label="用车" name="isCar" v-if="isCar">
				<el-table :data="carList" style="width: 100%"
					v-loading = "loading"
					element-loading-background = "rgba(255, 255, 255, .5)"
					element-loading-text = "加载中，请稍后..."
					element-loading-spinner = "el-icon-loading"
					>
				    <el-table-column :width="bookStyle==1?120:80" label="因公/用车">
						<template slot-scope="scope">
							{{scope.row.utilityVehicle==1?'管控':'不管控'}}
						</template>
				    </el-table-column>
				    <el-table-column :width="bookStyle==1?120:80" label="距离限制">
						<template slot-scope="scope">
							{{scope.row.distanceLimitXz==1?'限 '+scope.row.distanceLimit+' 公里':'不限制'}}
						</template>
					</el-table-column>
				    <el-table-column :width="bookStyle==1?120:100" label="用车时间段">
						<template slot-scope="scope">
							<span v-if="scope.row.carDayXz==0">不限制</span>
							<span v-if="scope.row.carDayXz==1">
								<span >
									限(
									<span>
										<span v-if="(item.carDay.split(',')).indexOf('1')!=-1">周一,</span>
										<span v-if="(item.carDay.split(',')).indexOf('2')!=-1">周二,</span>
										<span v-if="(item.carDay.split(',')).indexOf('3')!=-1">周三,</span>
										<span v-if="(item.carDay.split(',')).indexOf('4')!=-1">周四,</span>
										<span v-if="(item.carDay.split(',')).indexOf('5')!=-1">周五,</span>
										<span v-if="(item.carDay.split(',')).indexOf('6')!=-1">周六,</span>
										<span v-if="(item.carDay.split(',')).indexOf('7')!=-1">周日</span>
									</span>	
									)
									<span>{{item.carTime+'期间 '}}</span>
									可因公用车
								</span>
							</span>
						</template>
					</el-table-column>
					<el-table-column :width="bookStyle==1?120:100" label="同一城市">
						<template slot-scope="scope">
							{{scope.row.sameCity==1?'开启': '未开启'}}
						</template>
					</el-table-column>
					<el-table-column :width="bookStyle==1?120:90" label="单笔限额">
						<template slot-scope="scope">
							{{scope.row.singleLimitXz==0?'不限制': scope.row.singleLimit+'￥'}}
						</template>
					</el-table-column>
					<el-table-column :width="bookStyle==1?210:100" label="用车类型">
						<template slot-scope="scope">
							<div>
								<span class="padL5" v-if="scope.row.carType.includes('10,11')">
									专车
								</span>
								<span class="padL5" v-if="scope.row.carType.includes('8,9')">
									快车
								</span>
								<span class="padL5" v-if="scope.row.carType.includes('4')">
									接机
								</span>
								<span class="padL5" v-if="scope.row.carType.includes('5')">
									送机
								</span>
								<span class="padL5" v-if="scope.row.carType.includes('6')">
									接站
								</span>
								<span class="padL5" v-if="scope.row.carType.includes('7')">
									送站
								</span>
								<span class="padL5" v-if="scope.row.carType.includes('1,2')">
									包车
								</span>
							</div>
						</template>
					</el-table-column>
					<el-table-column :width="bookStyle==1?210:150" label="用车车型">
						<template slot-scope="scope">
							<div>
								<span class="padL5" v-if="(scope.row.carModel.split(',')).indexOf('15201')!=-1">经济型</span>
								<span class="padL5" v-if="(scope.row.carModel.split(',')).indexOf('15202')!=-1">舒适型</span>
								<span class="padL5" v-if="(scope.row.carModel.split(',')).indexOf('15203')!=-1">商务型</span>			
								<span class="padL5" v-if="(scope.row.carModel.split(',')).indexOf('15204')!=-1">豪华型</span>    
							</div>
						</template>
					</el-table-column>
					<el-table-column :width="bookStyle==1?150:110" label="超标管控">
						<template slot-scope="scope">
							<div>
								<span span class="padL20" v-if="scope.row.excessiveControl==0">超标不允许预订</span>
								<span span class="padL20" v-if="scope.row.excessiveControl==1">超标个人支付</span>
								<span span class="padL20" v-if="scope.row.excessiveControl==2">超标需填写原因</span>
							</div>
						</template>
					</el-table-column>
				</el-table>
			</el-tab-pane>
		</el-tabs>
	</div>
</template>

<script>
	export default {
		name: "TravelInfo",
		data() {
			return {
				tkList:[], //飞机差旅政策列表
				gjTkList: [], // 国际机票差旅政策
				htList:[], //酒店差旅政策列表
				gjHtList:[], // 国际酒店
				trList:[], //火车差旅政策列表
				carList:[], //用车差旅政策列表
				isCar:false, // 用车差旅政策是否开启
				empInfo:{}, //员工信息
				vipCorp:{}, // 企业信息
				bookStyle:'2', // 模式  1订单 2出差申请单
				activeName:'',
				inType:1, //调用接口类型为1 与 展示当前信息，就掉用接口， 默认展示第一项
				tabIndex:0,
				loading: false,
			}
		},
		created(){
			// 登录人信息
			let userInfo = this.$common.getUserInfo() || {};
			this.empInfo = userInfo.empInfo || {};
			this.vipCorp = userInfo.vipCorp || {};
			this.bookStyle = this.vipCorp.bookStyle;
		},
		mounted() {
			// 此顺序不能乱改
			if(this.vipCorp.zcGnjp && this.inType==1){
				this.getTravelTkList();
				this.activeName = 'zcGnjp';
				this.inType ++
			}
			if(this.vipCorp.zcGjjp && this.inType==1){
				this.getTravelGjTkList();	
				this.activeName = 'zcGjjp';
				this.inType ++
			}
			if(this.vipCorp.zcJd && this.inType==1){
				this.getTravelHtList();
				this.activeName = 'zcJd';
				this.inType ++
			}
			// 首页暂时不能显示用车差旅政策
			if(this.vipCorp.zcGjjd && this.inType==1){
				this.getTravelGjHtList();
				this.activeName = 'zcGjjd';
				this.inType ++
			}
			if(this.vipCorp.zcHcp && this.inType==1){
				this.getTravelTrList();
				this.activeName = 'zcHcp';
				this.inType ++
			}

			
		
		},
		methods: {
			// 切换查询类型
			handleType(tab, event){
				if(tab.index == this.tabIndex){
					return
				}
				this.tabIndex = tab.index;
				this.loading = true;
				switch(tab.name){
					case 'zcGnjp':
					this.getTravelTkList();
					break;
					case 'zcGjjp':
					this.getTravelGjTkList();
					break;
					case 'zcJd':
					this.getTravelHtList();
					break;
					case 'zcGjjd':
					this.getTravelGjHtList();
					break;
					case 'zcHcp':
					this.getTravelTrList();
					break;	
				}
			},
			// 获取火车差旅政策列表
			getTravelTrList(){
				let queryParams = {
						cplx: 10904,
					}
					
				this.$conn.getConn('travelZc.hcJdZcGet',{jsfile:false})(queryParams,(res)=>{
					let data = res.data || [];
					let empRank = this.empInfo.empRank;
					
					this.trList = [];
					data.forEach((item)=>{
						if(item.empRankS<=empRank && empRank<=item.empRankE){
							this.trList = item.list;							
						}
					})	
					this.loading = false;
				},(err)=>{
					if(err.errCode=='3901'){
					
					}else{
						this.$message.error(err.tips)
					}
					this.loading = false;
				})
			},
			// 获取飞机差旅政策列表
			getTravelTkList(){
				let queryParams = {
						cplx: 10901,
					}
				this.$conn.getConn('travelZc.tkZcGet',{jsfile:false})(queryParams,(res)=>{
					let data = res.data || [];
					let empRank = this.empInfo.empRank;
					this.loading = false;
					this.tkList = [];
					if(data.length>0){
						data.forEach((item)=>{
							
							if(item.empRankS<=empRank && empRank<=item.empRankE){
								this.tkList = item.list;							
							}
						})	
					}
				},(err)=>{
					if(err.errCode=='3901'){
					
					}else{
						this.$message.error(err.tips)
					}
					this.loading = false;
				})
			},
			// 获取国际机票
			getTravelGjTkList() {
				let queryParams = {
						cplx: 10902,
					}
				this.$conn.getConn('travelZc.tkZcGet',{jsfile:false})(queryParams,(res)=>{
					let data = res.data || [];
					let empRank = this.empInfo.empRank;
					this.loading = false;
					this.gjTkList = [];
					if(data.length>0){
						data.forEach((item)=>{	
							if(item.empRankS<=empRank && empRank<=item.empRankE){
								this.gjTkList = item.list;							
							}
						})	
					}
					
				},(err)=>{
					if(err.errCode=='3901'){
					
					}else{
						this.$message.error(err.tips)
					}
					this.loading = false;
				})
			},
			//获取酒店差旅政策列表
			getTravelHtList(){
				let queryParams = {
						cplx: 10903,
					}
				this.$conn.getConn('travelZc.hcJdZcGet',{jsfile:false})(queryParams,(res)=>{
					let data = res.data || [];
					let empRank = this.empInfo.empRank;
					this.loading = false;
					this.htList = [];
					data.forEach((item)=>{
						if(item.empRankS<=empRank && empRank<=item.empRankE){
							this.htList = item.list;							
						}
					})	
				},(err)=>{
					if(err.errCode=='3901'){
					
					}else{
						this.$message.error(err.tips)
					}
					this.loading = false;
				})
			},
			// 获取国际酒店
			getTravelGjHtList(){
				let queryParams = {
						cplx: 10910,
					}
				
				this.$conn.getConn('travelZc.hcJdZcGet',{jsfile:false})(queryParams,(res)=>{
					let data = res.data || [];
					let empRank = this.empInfo.empRank;
					this.loading = false;
					this.gjHtList = [];
					data.forEach((item)=>{	
						if(item.empRankS<=empRank && empRank<=item.empRankE){
							this.gjHtList = item.list;							
						}
					})	
				},(err)=>{
					if(err.errCode=='3901'){
					
					}else{
						this.$message.error(err.tips)
					}
					this.loading = false;
				})
			},
			//获取用车差旅政策列表
			getTravelCarList(){
				let queryParams = {
						count: 10,
						pageNum: 1,
					}
				this.$conn.getConn('carBook.vipCarClzcList',{jsfile:false})(queryParams,(res)=>{
					let data = res.data || {};
					let empRank = this.empInfo.empRank;
					this.loading = false;
					this.carList = [];
					data.list.forEach(item=>{
						if(item.empRankS<=empRank && item.empRankE>=empRank){
							this.carList.push(item)
						}
					})
					this.carList.forEach(item=>{
						if(item.carModel){
							let arr = item.carModel.split(',');
							arr.indexOf('15201')
						}
					})
					},(err)=>{
						if(err.errCode=='3901'){
						
						}else{
							this.$message.error(err.tips)
						}
						this.loading = false;
					})
			},
		}
	}
		
</script>

<style lang="scss" scoped>
	.ti-main{
		color: #282828;
		.el-tabs {
			height: 230px;
			overflow: hidden;
			/deep/ .el-tabs__content{
				height: 186px;
				overflow-y: auto;
			}	
		}
		.el-tabs--border-card{
			border: none;
			box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);
			/deep/ .el-tabs__item{
				height: 50px;
				line-height: 50px;
			}
			.iconColor{
				color: #006BB9;
				font-size: 18px;
			}	
			.textColor{
				color: #00A7FA;
				cursor: pointer;
			}
			/deep/ .el-tabs__header{
				background-color: #EAEDF1;
			}
		}
		// .el-empty{
		// 	padding:0;
		// 	/deep/ .el-empty__image{
		// 		height:100px;
		// 	}
		// 	/deep/ .el-empty__description{
		// 		margin: 0;
		// 		line-height: 40px;
		// 	}
		// }
		.padL5{
			padding-left: 5px;
		}
	}
</style>
