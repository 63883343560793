<template>
	<div class="tkod-main">
		<!-- yj-progress-edit(:statusList="statusList" :currentStatus="currentStatus") -->
		<yj-progress-edit :statusList="statusList" :currentStatus="orderStatus"></yj-progress-edit>
		<el-card class="textLeft marTop20">
			<el-row type="flex" justify="space-between" style="margin-bottom: 10px">
				<el-col class="flex-column" :span="6">
					<p>
						<span class="label-span">订单号：</span>
						{{ hotelOrderInfo.orderNo }}</p>
				</el-col>
				<el-col class="flex-column text-right" :span="6">
					<p :style="getTextColor()">
						{{ hotelOrderInfo.orderStatusRemark }}
						{{
							hotelOrderInfo.orderStatus == 17
								? ': ' + hotelOrderInfo.errMsg
								: ''
						}}
					</p>
				</el-col>
			</el-row>
			<div style="padding:20px 0 20px 40px;border: 1px solid #e6e6e6; margin-bottom: 10px;"
				v-if="hotelOrderInfo.appId && hotelOrderInfo.vipStatus == 0">
				<yj-approval-rule-detail :detail="detail" :appId="detail.appId"
					@gznrList="getGznrList"></yj-approval-rule-detail>
			</div>
			<div style="padding:20px 0 20px 40px;border: 1px solid #e6e6e6;margin-bottom: 10px;"
				v-if="hotelOrderInfo.appId && hotelOrderInfo.vipStatus > 0 && hotelOrderInfo.vipStatus < 4">
				<yj-approval-progress
					:orderId="hotelOrderInfo.orderId"
					:orderType="11007"
					@showProgress="showProgress"
					:resetspjd="resetspjd"
					></yj-approval-progress>
			</div>
			<div class="tk-main">
				<div class="left-main-width">
					基本信息({{
						hotelOrderInfo.tripType == 1 ? '因公' : '因私'
					}})
				</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" :span="6">
						<div>
							<span class="label-span">预订人:</span>
							{{ hotelOrderInfo.bookerName }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="6">
						<div>
							<span class="label-span">服务商：</span>
							{{ hotelOrderInfo.supplierName }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="6">
						<div>
							<span class="label-span">预订时间：</span>
							{{ hotelOrderInfo.bookDate }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="6">
						<div>
							<span class="label-span">支付方式:</span>
							 {{ hotelOrderInfo.payStatus == 0 ? '未支付' : hotelOrderInfo.payMothedName }}
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="tk-main">
				<div class="left-main-width">金额</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" :span="6">
						<div>
							<span class="label-span">总金额:</span>
							￥{{ hotelOrderInfo.totalPrice }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="6" v-if="hotelOrderInfo.cashPayAmt">
						<div>
							<span class="label-span">企业支付:</span>
							￥{{ (hotelOrderInfo.totalPrice - hotelOrderInfo.cashPayAmt) }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="6" v-if="hotelOrderInfo.cashPayAmt">
						<div>
							<span class="label-span">个人支付:</span>
							￥{{ hotelOrderInfo.cashPayAmt }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="6">
						<div>
							<span class="label-span">服务费：</span>
							￥{{ hotelOrderInfo.fwf }}</div>
					</el-col>
					<el-col class="flex-column" :span="6">
						<div>
							<span class="label-span">房间数量：</span>
							{{ hotelOrderInfo.rooms }}
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="tk-main" v-if="hotelOrderInfo.refundId">
				<div class="left-main-width">退订信息</div>
				<div class="rooms-right">
					<el-row class="romms-info">
						<el-col class="flex-column" :span="6">
							<div>
								<span class="label-span">应退合计: </span> ￥{{ hotelRefundInfo.cuRefundAmount }}
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div>
								<span class="label-span">退订日期: </span>  {{ hotelRefundInfo.htRefundDays }}
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div>
								<span class="label-span">退订房间: </span>{{ hotelRefundInfo.htRefundRooms }}间
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div>
								共退{{ hotelRefundInfo.htRefundNights }}晚
							</div>
						</el-col>
					</el-row>
					<el-row class="romms-info" type="flex" justify="space-between" style="margin-bottom: 0">
						<el-col class="flex-column" :span="6">
							<div>
								<span class="label-span">入住人: </span> {{ hotelOrderInfo.rzrxm }}
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div style="color:#337ab7;cursor: pointer" @click="jumpSpeed">
								查看退订详情
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="tk-main">
				<div class="left-main-width">预订信息</div>
				<div class="rooms-right">
					<el-row class="romms-info">
						<el-col class="flex-column" :span="8">
							<div>
								[{{ hotelOrderInfo.cityName }}]{{
									hotelOrderInfo.jdmc
								}}
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div>
								<span class="label-span">电话：</span>
								{{ hotelOrderInfo.phone }}
							</div>
						</el-col>
						<el-col class="flex-column" :span="6">
							<div>
								<span class="label-span">地址：</span>
								{{ hotelOrderInfo.address }}
							</div>
						</el-col>
					</el-row>

					<el-row class="romms-info b-t-1">
						<el-col class="flex-column" :span="4">
							<div>
								<span class="label-span">房型:</span>
								{{ hotelOrderInfo.fxmc
								}}{{ hotelOrderInfo.rooms }} 间
							</div>
						</el-col>
						<el-col class="flex-column" :span="8">
							<div>
								<span class="label-span">晚数:</span>
								共{{ hotelOrderInfo.nights }}晚
								{{ hotelOrderInfo.rzrq }}至{{
									hotelOrderInfo.ldrq
								}}
							</div>
						</el-col>
						<el-col class="flex-column" :span="4">
							<div>
								<span class="label-span">早餐:</span>
								{{ hotelOrderInfo.breakfastType | breakfastTypeName }}
							</div>
						</el-col>

					</el-row>
					<el-row class="romms-info b-t-1">
						<el-col :span="24">
							<div>
								<span class="gray">取消规则:</span>{{ hotelOrderInfo.changeRule }}
							</div>
						</el-col>
					</el-row>
					<el-row class="romms-info b-t-1" v-for="(item, index) in hotelOrderInfo.orderTips" :key="item + index">
						<el-col :span="24">
							<div>
								<span class="gray">{{ item }}</span>
							</div>
						</el-col>
					</el-row>
					<el-row class="romms-info b-t-1" v-show="hotelOrderInfo.bookEquity">

						<el-col :span="24">
							<div>
								<span class="gray">嘉享权益:</span>
								{{ hotelOrderInfo.bookEquity }}
							</div>
						</el-col>
					</el-row>
				</div>
			</div>

			<div class="tk-main" v-if="hotelOrderInfo.cancelReferenceNo || hotelOrderInfo.referenceNo">
				<div class="left-main-width">酒店确认信息</div>
				<div class="rooms-right">
					<el-row class="romms-info b-t-1" v-if="hotelOrderInfo.referenceNo">
						<el-col :span="24">
							<div>
								<span class="gray">确认号:</span>{{ hotelOrderInfo.referenceNo }}
							</div>
						</el-col>
					</el-row>
					<el-row class="romms-info b-t-1" v-if="hotelOrderInfo.cancelReferenceNo">
						<el-col :span="24">
							<div>
								<span class="gray">取消确认号:</span>{{ hotelOrderInfo.cancelReferenceNo }}
							</div>
						</el-col>
					</el-row>
				</div>
			</div>
			<div class="tk-main">
				<div class="left-main-width">入住人信息</div>
				<div class="left-main-right">
					<div v-for="(roomItem, roomIndex) in hotelOrderInfo.roomList" :key="roomIndex">
						<div class="fx-name" style="padding-top: 15px;">房间{{ roomItem.roomNo }}：{{ roomItem.custName }}
						</div>
						<div>
							<el-table :data="roomItem.everyPriceList" :span-method="objectSpanMethod" :header-cell-style="{
								background: '#eef1f6',
								color: '#606266',
							}" border style="width: 100%; margin-top: 20px">
								<el-table-column prop="day" width="180" label="日期">
								</el-table-column>
								<!-- <el-table-column prop="xsj" :formatter="xsjFormatter" width="180" label="房费(¥)">
								</el-table-column> -->
								<el-table-column label="房费" width="180">
									<template slot-scope="scope">
										{{ scope.row.xsj }}
										<span class="red" v-if="isWbPromptBool && scope.row.xsj > hotelOrderInfo.clbzj">(超标)</span>
									</template>
								</el-table-column>

							</el-table>
						</div>
					</div>

				</div>

			</div>
			<div class="tk-main">
				<div class="left-main-width">发票信息</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" :span="12">
						<div>
							公司支付：发票将在月底统一邮寄至贵公司财务；
						</div>
					</el-col>
					<el-col class="flex-column" :span="12">
						<div>
							个人支付：请联系客服{{ company.etServicePhone }} 开取发票
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="tk-main">
				<div class="left-main-width">报销方式</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" :span="12">
						<div>配送方式：</div>
					</el-col>
					<el-col class="flex-column" :span="12">
						<div>快递方式：</div>
					</el-col>
				</el-row>
			</div>
			<div class="tk-main" v-if="hotelOrderInfo.tripType == 1 ">
				<div class="left-main-width">差旅信息</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" :span="6" v-if="hotelOrderInfo.ccsqdNo">
						<div>
							<span class="label-span">出差申请单号：</span>
							{{ hotelOrderInfo.ccsqdNo }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="24" v-if=" hotelOrderInfo.violateitem">
						<div>
							<span class="label-span">违背事项：</span>
							{{ hotelOrderInfo.violateitem  }}
						</div>
					</el-col>
					<el-col class="flex-column" :span="24" v-if=" hotelOrderInfo.reasonDesc">
						<div>
							<span class="label-span">违背原因：</span>
							{{ hotelOrderInfo.reasonDesc }}
						</div>
					</el-col>
				</el-row>
			</div>
			<div class="tk-main" v-show="hotelOrderInfo.pointsType == 1">
				<div class="left-main-width">嘉享积分</div>
				<el-row class="left-main-right">
					<el-col class="flex-column" :span="12">
						<div>离店后可获得</div>
						<div style="margin-left: 30px; color: #ff6605">+{{ !!intergralObj ? intergralObj.points : 0 }}积分</div>
					</el-col>
				</el-row>
			</div>
			<!-- v-if="isSpStatus" -->
			<yj-approval-cao-zuo :appType="appType" :workItemId="workItemId" :orderType="orderType" :orderId="orderId"
				@closePage="resetDetail">
				<template slot="orderFooterSlot">
					<el-row type="flex" justify="end" class="left-main-right b-t-1" style="padding-top: 10px">
						<el-button class="padButton" v-if="hotelOrderInfo.isShowCancel == 1" @click="cancel">取消</el-button>
						<el-button class="padButton" v-if="hotelOrderInfo.isShowPay == 1" @click="payment">支付</el-button>
						<el-button class="padButton" v-if="hotelOrderInfo.isShowApproval == 1"
							@click="sendApp(hotelOrderInfo)">送审</el-button>
						<el-button class="padButton" v-if="hotelOrderInfo.isShowRefund == 1"
							@click="refundRoom(hotelOrderInfo)">退房</el-button>
						<el-button type="primary" class="padButton" v-if="canExtend" @click="beforeAgainOrder()">续住</el-button>
						<el-button class="padButton" v-if="hotelOrderInfo.isShowCancelApprove == 1"
							@click="orderCaoZuo(hotelOrderInfo, 16)">撤回</el-button>
						<!-- <el-button class="padButton" @click="close">关 闭</el-button>					 -->
					</el-row>
				</template>
			</yj-approval-cao-zuo>

		</el-card>
	</div>
</template>

<script>
// 因私模式下初始化请求3个接口
// 订单详情：hotelOrder.hotelOrderDetail  http://39.106.172.100:8090/1.0.0/hotelOrderDetail?orderId=a9481613213a4c839256670dc180b74d
// 酒店退单列表：htRefund.hotelRefundList http://39.106.172.100:8090/1.0.0/hotelRefundList
import {
mapGetters
} from 'vuex';

import caozuoMixin from '@/mixin/models/caozuoMixin.js';
// 审批订单跟踪 :commu.appProgress        http://39.106.172.100:8090/1.0.0/vipOrderAppProgress
export default {
	name: 'tkOrderDetail',
	mixins: [caozuoMixin.commonConfig],
	data() {
		return {
			// orderId: '', //订单id
			// orderType: '', // 订单类型
			// appType: '', //审批类型
			// workItemId: '', //工作项id
			hotelOrderInfo: {}, //订单信息
      hotelRefundInfo:{},//退款信息
			everyPriceList: [],
			detail: {
				flowState: 3, // 审批中
				currentLevel: 0, //审批人层级
				appId: '',
			},
			orderStatus: '',
			statusList: [],
			// statusList: [
			// 	{
			// 		status: "3",
			// 		name: '预订',
			// 	},
			// 	{
			// 		status:"4",
			// 		name: '支付',
			// 	},
			// 	{
			// 		status: "5",
			// 		name: '确认',
			// 	},
			// 	{
			// 		status: "6",
			// 		name: '完成',
			// 	}],
			isSpStatus: false,
			company: {},
			intergralObj: null,
			resetspjd:false,
		}
	},

	computed: {
		...mapGetters({
			getVipParamterByParNo: 'common/getVipParamterByParNo',
		}),
		userInfo() {
			return this.$common.getUserInfo() || {};
		},
		empId() {
			return this.userInfo.empInfo.empId;
		},
		query() {
				return this.$route.query || {}
			},
			appType() {
				return this.query.appType
			},

			// 获取订单id
			orderId() {
				return this.query.orderId
			},
			// 获取订单类型
			orderType() {
				return this.query.orderType || '11007'
			},
			workItemId() {
				return this.query.workItemId
			},
		// 是否可以续住 国际酒店隐藏续住
		canExtend() {
			let orderDetail = this.hotelOrderInfo;
			if (!orderDetail) return false;
			if (
				(orderDetail.orderStatus == 5 ||
					orderDetail.orderStatus == 6 ||
					orderDetail.orderStatus == 7 ||
					orderDetail.orderStatus == 8 ||
					orderDetail.orderStatus == 9) &&
				orderDetail.international == 1
			) {
				return true;
			}
			return false;
		},
		// 判断是否需要提醒超标
		isWbPromptBool() {
			let ht20043 = this.getVipParamterByParNo(20043, 0),
				isWb = ht20043 == 1 && this.hotelOrderInfo.tripType == 1 && !this.hotelOrderInfo.reasonDesc;
			return isWb;
		},
	},
	mounted() {

		this.getHotelOrder();
		this.getHtRefund();
		// 获取登录企业信息
		let pageSettingInfo = this.$common.sessionget('pageSettingInfo') || {};
		this.company = pageSettingInfo;
	},
	methods: {
		getHtAmountPoints(orderDetail) {
			if (!orderDetail || orderDetail.pointsType != 1) return;
			let user = this.$common.getUserInfo() || {};
			var emp = user.empInfo || {};
			if (!emp.corpId) return;
			var query = this.$conn.getConn('intergral.htAmountPoints');
			query({
				// supplierId: this.infoObj.fyid,
				corpId: emp.corpId,
				// payAmount: totalPrice,
				orderId: orderDetail.orderId
			}, res => {
				var data = res.data || {};
				this.intergralObj = data;
			}, err => {

			})
		},
		getStatus() {
			let vipStatus = this.hotelOrderInfo.vipStatus;
			let zflx = this.hotelOrderInfo.zflx;
			let orderStatus = this.hotelOrderInfo.orderStatus;
			if (vipStatus == 4) {
				if (zflx == 1) {
					this.statusList = [
						{ status: "1", name: '预定' },
						{ status: "2", name: '确认' },
						{ status: "3", name: '完成' },
					]
					if (orderStatus == 4) {
						this.orderStatus = 1
					} else if (orderStatus == 5) {
						this.orderStatus = 2
					} else if (orderStatus == 6) {
						this.orderStatus = 3
					} else if (orderStatus == 7) {
						this.orderStatus = 4
					}

				} else if (zflx == 2) {
					this.statusList = [
						{ status: "1", name: '预定' },
						{ status: "2", name: '支付' },
						{ status: "3", name: '确认' },
						{ status: "4", name: '完成' },
					]
					if (orderStatus == 3) {
						this.orderStatus = 1
					} else if (orderStatus == 4) {
						this.orderStatus = 2
					} else if (orderStatus == 5) {
						this.orderStatus = 3
					} else if (orderStatus == '6' || orderStatus == '8' || orderStatus == '9') { //已取消应该也算完成了把
						this.orderStatus = 4
					} else if (orderStatus == '7') {
						this.orderStatus = 0
					}

				}
			} else if (vipStatus != 4) {
				if (zflx == 1) {
					this.statusList = [
						{ status: "1", name: '预定' },
						{ status: "2", name: '送审' },
						{ status: "3", name: '确认' },
						{ status: "4", name: '完成' },
					]
					if (orderStatus == 1) {
						this.orderStatus = 1
					} else if (orderStatus == 2 || orderStatus == 4) {
						this.orderStatus = 2
					} else if (orderStatus == 5) {
						this.orderStatus = 3
					} else if (orderStatus == '6' || orderStatus == '8' || orderStatus == '9') {
						this.orderStatus = 4
					} else if (orderStatus == '7') {
						this.orderStatus = 0
					}
				} else if (zflx == 2) {
					this.statusList = [
						{ status: "1", name: '预定' },
						{ status: "2", name: '送审' },
						{ status: "3", name: '支付' },
						{ status: "4", name: '确认' },
						{ status: "5", name: '完成' },
					]
					if (orderStatus == 1) {
						this.orderStatus = 1
					} else if (orderStatus == 2 || orderStatus == 3) {
						this.orderStatus = 2
					} else if (orderStatus == 4) {
						this.orderStatus = 3
					} else if (orderStatus == 5) {
						this.orderStatus = 4
					} else if (orderStatus == '6' || orderStatus == '8' || orderStatus == '9') {
						this.orderStatus = 5
					} else if (orderStatus == '7') {
						this.orderStatus = 0
					}

				}

			}

		},
		cancel() {
			//取消订单
			let url = 'htOuter.interHotelOrderCancel'
			let queryParams = {
				orderFrom: '10503',
				orderId: this.hotelOrderInfo.orderId,
			}
			this.$conn
				.getConn(url, { jsfile: false })(queryParams)
				.then((res) => {
					let amount = res.data?.amount ? data.amount : 0
					let text = ''
					if (amount == 0) {
						text = '您确定要取消订单吗?'
					} else {
						text = '取消会产生罚金: ￥' + data.amount
					}
					this.$confirm(text, '', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning',
					})
						.then((ss) => {

							this.hotelCacel()
						})
						.catch((dd) => {

							this.$message({
								type: 'info',
								message: '关闭取消',
							})
						})
				})
				.catch((err) => {
					this.$message({
						type: 'error',
						message: err.tips,
					})
				})
		},
		payment() {
			let query = {
				orderId: this.orderId,
				orderType: this.orderType,
			}
			let path = '/yjDetail/payment'

			let routeUrl = this.$router.resolve({ path: path, query: query })
			window.open(routeUrl.href, '_blank')
		},

		//取消订单调用接口
		hotelCacel() {
			this.$conn
				.getConn('hotelOrder.hotelOrderCancel', { jsfile: false })({
					orderFrom: '10503',
					orderId: this.hotelOrderInfo.orderId,
				})
				.then((res) => {
					this.getHotelOrder()
					this.$message({
						type: 'success',
						message: '取消成功!',
					})
				})
				.catch((err) => {
					this.$message({
						type: 'error',
						message: err.tips,
					})
				})
		},
		// 操作  16 撤回
		orderCaoZuo(orderDetail, type) {
			if (type == 16) {
				this.goToCancelApprove({
					orderId: this.orderId,
					orderType: this.orderType,
				}, (res) => {

					this.getHotelOrder();
				}, (err) => {

				})
			}
		},
		// 续住
		beforeAgainOrder: async function () {
			let self = this;
			const loading = this.$loading({
				target: 'aol-main',
				lock: true,
				text: '请稍后...',
				spinner: 'el-icon-loading',
				// background: 'rgba(0, 0, 0, 0.7)'
			});
			try {
				let hotelOrderData = this.hotelOrderInfo;
				let minEmp;
				// 先获取订单详情 
				// const {data: hotelOrderData} = await this.$conn.getConn('hotelOrder.hotelOrderDetail')({orderId: orderItem.id});
				// 验证有房
				if (hotelOrderData.tripType == 1) {
					const { httpStatus: validStatus, errMsg } = await this.$conn.getConn('htbook.isHotelValid')({ jdid: hotelOrderData.jdid });
					if (validStatus != 200) {
						this.$alert(errMsg, '', {
							type: 'error'
						})
						loading.close();
						return;
					}
				}
				let userInfo = this.$common.getUserInfo() || {};
				// 当前日期
				let nowDay = this.$common.getDateMing(new Date(), 0);
				let now = hotelOrderData.ldrq >= nowDay ? hotelOrderData.ldrq : nowDay;
				let next = this.$common.getDateMing(now, 1);

				// // tripType 1 因公 2 因私 bookStyle 1单订模式 2出差申请单模式
				// if (hotelOrderData.tripType == 2 || userInfo.vipCorp.bookStyle == 1) {
				// 	let empInfo = userInfo.empInfo;
				// 	empInfo.useCardType ='1'; // 当前出行人使用证件类型  1 身份证  2 护照  3 港澳通行证   4 台胞证  5 回乡证  6 台湾通行证  7 学生证  8 军官证  9 其他证件
				// 	empInfo.cxrType = '1'; //当前人的出行人类型   1.员工  2.常旅客 3. 其他
				// 	empInfo.psgType = '1'; // 默认 1  1成人 2儿童 3婴儿 
				// 	empInfo.cxrName = empInfo.empName; //当前出行人的中文名字
				// 	self.$store.commit('htCn/SET_LSR_LIST', [empInfo])
				// }
				// 出差申请单模式
				if (hotelOrderData.tripType == 1 && userInfo.vipCorp.bookStyle == 2 && hotelOrderData.ccsqdId) {
					// 获取申请单数据
					const { data: ccsqdInfo } = await this.$conn.getConn('travelRequest.travelRequestInfo')({ ccsqdId: hotelOrderData.ccsqdId });
					// 任务 7975 多人并行 【正式环境】【紧急更新】申请单已报销了，前端还关联上了出差申请单预订了
					// 如果出差申请单已经报销，则增加出差申请单已报销提醒
					// canBook (integer, optional): 0不可预订 1可预订 ,
					// status (string, optional): 0草稿；1审批中；2审批通过；3审批拒绝；4已删除；5报销中；6已报销 ,
					if (ccsqdInfo && ccsqdInfo.canBook == 0) {
						let reasonMap = {
							0: "草稿",
							1: "审批中",
							2: "审批通过",
							3: "审批拒绝",
							4: "已删除",
							5: "报销中",
							6: "已报销"
						}
						let ccsqdStatusTip = reasonMap[ccsqdInfo.status];
						this.$alert(`当前订单关联的出差申请单[${ccsqdInfo.ccsqdNo}]不可预订;申请单状态:${ccsqdStatusTip || ''}`, '温馨提示', {
							type: 'error'
						})
						loading.close();
						return;

					}
					// 根据routeId获取行程
					if (ccsqdInfo.allRouteList && ccsqdInfo.allRouteList.length > 0) {
						let allRouteList = [];
						ccsqdInfo.allRouteList.forEach(routers => {
							allRouteList = allRouteList.concat(routers);
						});
						let route = allRouteList.find(s => s.routeId == hotelOrderData.routeId)
						if (!route) {
							this.$alert('行程信息错误！', '', {
								type: 'error'
							})
							loading.close();
							return;
						}
						let book = now >= route.routeStart && next <= route.routeEnd;
						if (!book) {
							let alertStr = '';
							if (!route.routeStart || !route.routeEnd) {
								alertStr = '行程变更，不能预订';
							} else {
								alertStr = '预定日期不在出差单期限内,无法预订！可预订日期范围为：' + (route.routeStart || '无') + '-' + (
									route.routeEnd || '无')
							}
							this.$alert(alertStr, '', {
								type: 'error'
							})
							loading.close();
							return;
						}
						let obj = {
							cxrList: [], // 出行人列表
							detail: ccsqdInfo,  // 出差申请单信息
							proType: route.proType, // 业务类型 机票 火车票
							currentRoute: route, // 当前行程信息
							routeId: route.routeId,  //出行id
							ccsqdNo: ccsqdInfo.ccsqdNo, //出差单号
							ccsqdId: ccsqdInfo.id, //申请单ID ,
						}
						self.$common.sessionset('chooseCcsqdObj', obj)
					}
					// 设置出差申请单数据
					self.$store.commit('common/setCcsqdInfo', { ccsqdInfo })
					// 设置酒店出差申请单数据
					self.$store.commit('htCn/SET_HTCN_CCSQD', ccsqdInfo)
				}
				//房间人员信息
				let roomList = hotelOrderData.roomList;
				let cxrListArr = [];
				for (let i = 0; i < roomList.length; i++) {
					let room = roomList[i];
					let cxrList = room.customerList;
					for (let c = 0; c < cxrList.length; c++) {
						var cxr = cxrList[c];
						cxr.deptId = cxr.empDeptid;
						cxr.empName = cxr.psgName;
						cxr.phoneNumber = cxr.psgPhone;
						cxr.costCenterCode = cxr.costCenterCode;
						cxr.costCenterId = cxr.costCenterId;
						cxr.costCenterName = cxr.costCenterName;
						cxrListArr.push(cxr);
					}
				}
				// 设置出行人 根据订单上人员
				self.$store.commit('htCn/SET_CXR_LIST', cxrListArr);
				minEmp = this.$common.getMinEmpRank(cxrListArr)

				let hotelQuery = {
					tripType: hotelOrderData.tripType,
				};
				if (minEmp) {
					hotelQuery = Object.assign(hotelQuery, {
						empRank: minEmp.empRank || 0, //   职级
						useNameclbz: minEmp.cxrName || minEmp.empName, // 本次预订使用谁的差旅标准
						minEmpRankEmpId: minEmp.empId, // 当前员工差旅标准的职级
					})
				}
				self.$store.commit('htCn/SET_HTCN_QUERY', hotelQuery)

				// 跳转续住
				let hotelItem = {
					checkInDate: now,
					checkOutDate: next,
					jdid: hotelOrderData.jdid,
					cityId: hotelOrderData.cityId,
					delayOrdreType: 1, // 续住标识
				}
				let routeUrl = this.$router.resolve({
					name: 'HtCnDetails',
					query: hotelItem,
				})
				loading.close();
				window.open(routeUrl.href, '_blank')
			} catch (err) {
				let { tips } = err || {};
				tips && this.$alert(tips, '', {
					type: 'error'
				})
				loading.close();
			}
		},
		// 退票
		refundRoom(orderItem) {
			let path = this.$dict.orderRefundConfig(11007)
			let findType = this.$route.query.findType;

			let query = {};
			query = {
				orderId: orderItem.orderId,
				orderType: 11007,
				orderFrom: '10503',
			}
			if (path) {
				this.$confirm('酒店仅支持全退，如需部分退请联系客服人员!', '温馨提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					let routeUrl = this.$router.resolve({ path: path, query: query });
					window.open(routeUrl.href, "_blank")
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消'
					});
				});
			} else {
				this.$message({
					type: 'error',
					message: '该订单无法进行退票,如需退票，请联系客服处理！'
				})
			}
		},
		// 送审
		sendApp(info) {
			var queryParams = {};
			queryParams = {
				orderId: info.orderId,
				orderType: 11007,
				spgzId: info.appId,
			};
			this.$confirm('您确定要送审吗?', '', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.$conn.getConn('commu.sendApp')(queryParams, (res) => {
					this.$message({
						type: 'success',
						message: '送审成功'
					});
					this.getHotelOrder();
				}, (err) => {
					this.$message({
						type: 'error',
						message: err.tips
					});
				})
			}).catch(() => {
				this.$message({
					type: 'info',
					message: '关闭取消'
				});
			});
		},
		// 关闭本页面
		close() {
			window.close()
		},
		// 获取单号字体颜色
		// willApproval("1", "待送审"),
		// approvaling("2", "审批中"),
		// willPay("3", "待支付"),
		// willConfirm("4", "待确认"),
		// confirmed("5", "已确认"),
		// completed("6", "已完成"),
		// canceled("7", "已取消"),
		// partHtRefund("8", "部分退房"),
		// allHtRefund("9", "全部退房"),;
		getTextColor() {
			if (this.hotelOrderInfo?.orderStatus) {
				let styleObj = {}

				return (styleObj = {
					color: '#FFA558',
				})
			}
		},
		objectSpanMethod({ row, column, rowIndex, columnIndex }) {
			// if (
			// 	columnIndex === 0 ||
			// 	columnIndex === 3 ||
			// 	columnIndex === 4 ||
			// 	columnIndex === 5
			// ) {
			// 	//用于设置要合并的列
			// 	if (rowIndex === 0) {
			// 		//用于设置合并开始的行号
			// 		return {
			// 			rowspan: this.everyPriceList.length, //合并的行数
			// 			colspan: 1, //合并的列数，设为０则直接不显示
			// 		}
			// 	} else {
			// 		return {
			// 			rowspan: 0,
			// 			colspan: 0,
			// 		}
			// 	}
			// }
		},
		getHotelOrder() {
			//获取当前订单详情
			this.$conn
				.getConn('hotelOrder.hotelOrderDetail', { jsfile: false })({
					orderId: this.orderId,
				})
				.then((res) => {
					this.hotelOrderInfo = res.data || {};
					this.getHtAmountPoints(this.hotelOrderInfo);
					// 审批id
					this.detail.appId = this.hotelOrderInfo.appId;
					this.hotelOrderInfo.roomList = this.hotelOrderInfo.roomList.map(roomItem => {
						return {
							...roomItem,
							fxmc: this.hotelOrderInfo.fxmc
						}
					})
					this.getStatus()
					if(this.hotelOrderInfo.refundId){
            this.getHtRefundDetail()
					}
				})
		},
    //酒店退订详情
    getHtRefundDetail() {
      this.$conn
          .getConn('htRefund.htRefundDetail', { error: true })({
            hotelRefundId: this.hotelOrderInfo.refundId,
          })
          .then((res) => {
            this.orderIdRes = res.data.orderId
            this.hotelRefundInfo = res.data
          })
    },
    jumpSpeed() {
      let routeUrl = this.$router.resolve({ name: 'HtCnRefundSpeed', query: { orderId: this.hotelOrderInfo.refundId } });
      window.open(routeUrl.href, "_blank")
    },
		getHtRefund() {
			//获取退单列表
			this.$conn
				.getConn('htRefund.hotelRefundList', { jsfile: false })({
					orderId: this.orderId,
				})
				.then((res) => {

				})
		},
		// 审批进度
		showProgress(info, currentSpjd, status) {
			// this.spjd = info.spjd; //当前审批进度（1/2）
			this.detail.currentLevel = currentSpjd; //当前到谁审批
			this.detail.flowState = status;  // 当前审人状态
			this.getCurrentSprLevel()
		},
		// 获取当前审批人层级列表
		getCurrentSprLevel() {
			let params = {
				spgzId: this.detail.appId
			}
			this.$conn.getConn('commu.appRulesDetail')(params, (res) => {
				let data = res.data || {};
				let applyList = data.gznr || [];
				this.getGznrList(applyList)
			}, (err) => {

			})
		},
		// 获取审批人列表
		getGznrList(list) {
			if (list.length > 0) {
				// 遍历审批人
				for (var i = 0; i < list.length; i++) {
					// 需要审批的当前层级 与 列表中层级相等表示 轮到当前人审批
					if (list[i].level == this.detail.currentLevel) {
						// 判断登录人empid 与 层级中id 是否相等， 相等则可以审批
						for (var j = 0; j < list[i].empList.length; j++) {
							if (list[i].empList[j].empId == this.empId && this.detail.flowState == 3) {
								this.isSpStatus = true;
							} else {
								this.isSpStatus = false;
							}
						}
					}
				}
			}

		},
		// 重新渲染详情
		resetDetail() {
			this.$router.replace({
					path: this.$dict.detailConfig(11007),
					query: {
						...this.$route.query,
						appType:2  // 已审批     0 订单详情 1.待审批 2.已审批 3. 审批过期 
	
					}
				})
				// return 
			
			// this.close()
			this.getHotelOrder();
			this.resetspjd = true;
		}
	},
	filters: {
		breakfastTypeName(val = 0) {
			let retStr = '';
			if (val == 1 || val == 2) {
				retStr = val + '份早';
			} else if (val > 2) {
				retStr = '多份早';
			} else {
				retStr = '不含早'
			}
			return retStr
		}
	}
}
</script>

<style scoped="scoped" lang="scss">
.padButton {
	padding: 10px 30px;
	font-size: 15px;
}

.text-right {
	justify-content: end;
}

.b-t-1 {
	border: none;
	border-top: 1px solid #e6e6e6;
}

.p-r-20 {
	padding-right: 20px;
}
.label-span {
    color: #909399;
}
.textLeft {
	text-align: left;
}

.marTop20 {
	margin-top: 20px;
}

.between {
	justify-content: space-between;
}

.flex-column {
	display: flex;
	justify-items: center;
}

.red {
	color: red;
}

.tkod-main {
	margin: 0 auto;
	width: 1200px;
	padding-top: 20px;
	padding-bottom: 50px;


	.tk-main {
		display: flex;
		margin-bottom: 10px;
		min-height: 80px;
		border: 1px solid #e6e6e6;
		box-sizing: border-box;

		.left-main-width {
			width: 160px;
			display: flex;
			justify-content: center;
			align-items: center;
			background: #fafafa;
			border-right: 1px solid #e6e6e6;
		}

		.left-main-right {
			flex: 1;
			padding-left: 40px;
			display: flex;
			align-items: center;
			flex-wrap: wrap;
		}

		.rooms-right {
			flex: 1;
			width: 100%;
			flex-direction: column;
			display: flex;
			align-items: center;

			.romms-info {
				padding-left: 40px;
				border-bottom: 1px solid #e6e6e6;
				width: 100%;
				min-height: 80px;
				display: flex;
				// justify-content: center;
				align-items: center;
			}

			.romms-info:nth-child(2) {
				border-bottom: none;
				margin-bottom: 20px;
			}

			.romms-info:nth-child(3) {
				border-bottom: none;
			}
		}
	}

	.flex {
		display: flex;
	}
}
</style>
