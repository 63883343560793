<template>
	<div class="epd-main">
		<el-dialog
			title="修改手机号"
			:visible.sync="newPhoneVisible"
			:before-close="handleClose"
			:close-on-click-modal="false"
			style="text-align: left;"
			>
			<div class="marB20">
				<span class="inlineB bfb-w18">新手机号：</span><el-input v-model="phoneNumber"  style="width:50%;"></el-input>		
			</div>
			<div>
				<span class="inlineB bfb-w18">验证码：</span>
				<el-input v-model="vailNumber" placeholder="请输入密码" style="width:50%;"></el-input>	 
				<el-button type="primary" style="margin-left: 10px;" @click="getVerifyCode">{{isTimer==0?'获取验证码':'重新发送'+'( '+isTimer+' )'}}</el-button>
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="confirm">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'NewEditPhoneDialog',
		props: {
			newPhoneVisible:{
				type: Boolean,
				default: function(){
					return false
				}
			},
			vailRepeatInfo:{
				type: Object,
				default: function(){
					return {}
				}
			}
		},
		data() {
			return {
				phoneNumber: '', //手机号
				vailNumber: '', //密码
				isTimer: 0,
				isRepeat:true,
				clearTime:'',
			}
		},
		beforeDestroy(){
			clearTimeout(this.clearTime);
		},
		methods: {
			handleClose(){
				this.$emit('callParams',false)
			},
			// 验证参数
			verify(){
				let errMsg = "";
				if(!this.phoneNumber){
					errMsg = "请输入手机号码"
				}else if(!this.vailNumber){
					errMsg = "请输入验证码"
				}
				if(errMsg){
					this.$message({
						type: "error",
						message: errMsg
					})
					return false
				}
				return true
			},
			// 确定
			confirm(){
				let bool = this.verify();
				if(!bool){
					return false;
				}
				let obj = {
					code: this.vailNumber,
					phoneNumber: this.phoneNumber,
				}
				this.$conn.getConn('user.modifyPhoneNumber',{jsfile:false})(obj).then((res)=>{
					this.$emit('callParams',false,1)
				}).catch((err)=>{
					this.$message({
						type: "error",
						message: err.tips
					})
					
				})
				
			},
			// 取消
			cancel(){
				this.$emit('callParams',false)
			},
			// 验证是否重复
			getVerifyCode(){
				if(!this.isRepeat){
					return
				}
				this.isRepeat = false;
				if(!this.phoneNumber){
					this.$message({
						type: "error",
						message: "请输入手机号码"
					})
					return 
				}
				let obj = {
						phoneNumber: this.phoneNumber,
						empId: this.vailRepeatInfo.empInfo.empId,
						corpId: this.vailRepeatInfo.empInfo.corpId
					}
				// 判断验证码是否重复	
				this.$conn.getConn('user.hasSamePhone',{jsfile:false})(obj).then((res)=>{
					if(res.data=='0'){
						this.sendOutVeilfyCode()
					}else{
						this.$message({
							type: "error",
							message: '当前手机号已被员工'+res.data+'使用，不允许重复使用!'
						})
					}
				})
			},
			// 设置计时器
			setTimer(isTimer){
				this.clearTime = setTimeout(()=>{
					this.isTimer--;	
					this.setTimer(this.isTimer)
				},1000)
				if(this.isTimer == 0){
					clearTimeout(this.clearTime)
					this.isRepeat = true;
				}
			},
			// 发送验证
			sendOutVeilfyCode(){
				this.isTimer = 60;
				this.setTimer(this.isTimer);
				
				let compId = window.apiconfig.compId;
				let obj = {
					phoneNumber: this.phoneNumber,
					type: "修改手机号码",
					compId: compId
				}
				this.$conn.getConn('user.codeGet',{jsfile:false})(obj).then((res)=>{
					this.$message({
						type: "success",
						message: '手机号更换成功'
					})
				}).catch((err)=>{
					clearTimeout(this.clearTime)
					this.isTimer = 0;
					this.isRepeat = false;
					this.$message({
						type: "error",
						message: err.tips
					})
				})				
			},
			
			
		}
	}
</script>

<style scoped lang="scss">
	.epd-main{
		/deep/ .el-dialog{
			width: 500px;
		}
		.inlineB{
			display: inline-block;
		}
		.bfb-w18{
			width: 18%;
		}
		.marB20{
			margin-bottom: 20px;
		}
	}
</style>

