<template>
	<div class="tn-main">
		<p class="tn-text">点击下一步表示已阅读并同意<span class="tn-bg-bule" @click="showNotice">《火车票信息服务协议》</span></p>
		<el-dialog
			title="预订须知"
			:visible.sync="dialogVisible"
			width="50%"
			class="tn-text"
		 >
		 <div class="marginTop15 tn-con">
		 	<p>《火车票信息服务协议》（以下简称“本协议”）是北京空港嘉华航空服务有限公司（以下简称 “我司”）与平台用户 (以下简称“您”)就火车票购票、12306账号授权托管使用等相关事宜所订立的合约。您通过网络页面点击“确认”或“同意”或开始使用本公司提供的服务，即表示您同意接受本协议的全部约定内容以及与本协议有关的已经发布或将来可能发布的各项规则、页面展示、操作流程、公告或通知（以下统称“规则 ”）。</p>
		 	<p>在接受本协议之前，请您仔细阅读本协议的全部内容（特别是以粗体下划线标注的内容）。如果您不同意本协议的任意内容，或者无法准确理解该条款的含义，请不要进行任何操作或拒绝使用本公司提供的火车票购票服务。</p>
		 	<h4>第一条 服务描述</h4>
		 	<p>1、我司通过铁路官方网站为您提供火车票信息服务，或由您自行登录已有12306账号进行查询、购票、退票、改签等操作，如您未注册12306账号，您同意我司先使用您的姓名、身份证号、手机号等信息为您提供12306账号代为注册的便捷服务。</p>
		 	<p>2、全国各铁路局对火车票售票有不同的规定，我司不对服务成功率做100%承诺。如预定失败，订单金额将退回您的支付账户，到账日期一般为3-7个工作日。</p>
		 	<p>3、通过我司平台，您可以自由选择包括但不限于保险、景点代金劵、酒店代金劵、用车劵套餐服务等附加服务，但我司承诺不会强制您购买任何产品或服务。</p>
		 	<h4>第二条 购票说明</h4>
		 	<p>1、我司接受二代身份证及护照、台胞证、港澳通行证预订，其他证件暂不支持。</p>
		 	<p>2、一张有效身份证件同一乘车日期同一车次限购一张车票。（儿童用成人的证件号情况除外）</p>
		 	<p>3、同一笔订单可购买的车票数量不超过5张，如购票乘车人数超出5位，请分开预订。</p>
		 	<h4>第三条 取票说明</h4>
		 	<p>1、使用二代身份证预订的客户，可持预订时所使用的乘车人有效二代身份证到车站售票窗口、铁路客票代售点或车站自动售票机上办理取票手续，部分高铁站可持二代居民身份证直接检票进站，以各铁路站点实际情况为准。</p>
		 	<p>2、如预订时使用的乘车人二代身份证无法识别或使用护照预订的客户，请持预订时留下的有效证件原件及本公司给您发送的火车票订单号至火车站售票点，由售票员核实后办理换票手续。</p>
		 	<p>3、若您在预订成功后、换票前，不慎遗失有效身份证件，须由您本人到乘车站铁路公安制证口办理临时身份证明。</p>
		 	<p>4、纸质火车票作为唯一的报销凭证，如您需要报销，请提前至火车站换取纸质车票并妥善保管。如您未提前换票或纸质票遗失，我司概不提供火车票票款发票。</p>
		 	<h4>第四条 退票及改签说明</h4>
		 	<p>1、在线申请退票须同时满足以下条件：</p>
		 	<p>①服务时间：7:00—22:55；②发车时间外35分钟；③未取纸质票</p>
		 	<p>2、根据铁路局规定，开车前16天22点不收取退票费。开车前48小时以上，手续费5%；开车前24-48小时之间，手续费10%；开车前24小时内，手续费20%。最终退票手续费以铁路局实际收取为准。</p>
		 	<p>3、支持在线改签（热门线路除外），一张车票只能改签一次，且须同时满足以下条件：</p>
		 	<p>①服务时间：7:00—22:55；②发车时间外35分钟；③未取纸质票。</p>
		 	<p>开车前48小时（不含）以上，可改签预售期内的其他列车；开车前48小时以内，可改签至票面日期当日24:00之间的列车；不办理票面日期次日及以后的改签。开车前48小时-15天期间内，改签至距开车15天以上的其他列车，又在距开车15天前退票的，仍核收5%的退票费。铁路部门规定，对于改签后新车票价格低于原车票价格的，退还差额，并对差额部分收取改签手续费：新票距发车时间15天以上的，差额不收改签费；48小时-15天（含）的，收取差额5%的改签费；24-48小时（含）的，收取10%；不足24小时（含）的，收取20%。</p>
		 	<p>4、如发生网络技术故障等情形，造成我司临时性暂停在线退票、改签服务，您可以在发车前携带纸质车票及购票时使用的有效身份证件至火车站的退票窗口办理。</p>
		 	<h4>第五条 免责申明</h4>
		 	<p>1、因全国各铁路局会随时调整车次、票价、坐席等信息，或者网络传输误差，故我司显示产品信息（包括但不限于车次、票价、坐席）可能不是最新或者存在误差，供您做一般参考，最终以实际购买的产品为准。</p>
		 	<p>2、因全国各铁路局会随时调整车次，或因自然灾害等不可抗力因素导致的火车停运等问题，本公司不承担责任。</p>
		 	<p>3、如因您提供错误的订单信息（姓名、证件号码、日期、车次、座位类型等）或者因您自身原因导致无法取票、车票丢失、车票损毁等情况所导致的损失，您需自行承担相关损失后果。</p>
		 	<p>4、我司将按承诺时限向客户订单支付的原渠道发起退款，退款到帐时间由支付渠道和银行决定。</p>
		 	<p>5、我司提供的是火车票信息服务，您接受本协议，意味着您同意我司使用您填写的乘客信息或12306账号进行相关操作，包括但不限于授权使用您的乘客信息为您或他人执行查询、购票、退票、改签、注册等操作，同时您必须遵守12306购票规定的服务条款(https://kyfw.12306.cn/otn/regist/rule)。</p>
		 	<p>6、如果您提供自有的12306账号用于购票，表示您同意将您的12306账号和密码授权给本平台托管，以方便为您或他人执行查询、购票、退票、改签等操作，平台也将严格保护您的隐私信息。如发生账号及密码信息变更，请及时在本平台更新，本平台不对因此带来的查询、购票、退票、改签等操作失败问题承担责任。</p>
		 	<h4>第六条 解决争议适用法律法规约定</h4>
		 	<p>如果您在本协议履行过程中，您对相关事宜发生争议，您同意按照中华人民共和国颁布的相关法律法规来解决争议，并同意接受本协议签订地苏州市工业园区人民法院的管辖。</p>
		 	<h4>第七条 其他</h4>
		 	<p>1、在使用我司提供的服务前务必认真阅读本协议，一旦您使用本公司提供的服务即表示您同意与我司签订本协议且同意受本协议之约束。</p>
		 	<p>2、您同意并授权我司有权随时对本协议内容进行单方面的变更，并以在本公司及关联公司网站、APP、微信等第三方渠道公告的方式提前予以公布，无需另行单独通知您；若您在本协议内容公告变更生效后继续使用我司服务的，表示您已充分阅读、理解并接受变更修改后的协议内容，也将遵循变更修改后的协议内容使用相关服务；若您不同意变更修改后的协议内容，您应在变更生效前停止使用本公司提供的火车票购票服务及其他相关服务。</p>
		 	<p>3、本协议未尽事宜，以我公司后续公布的相关规则为准，按照我公司公布的相关规则亦未对所涉事项进行规定的，以铁路部门公布的规定、政策为准。</p>
		 	<p>4、本协议于铁路部门现有相关规定、政策的基础上进行制定，您知晓并同意您有义务知悉铁路部门公布的相关规定、政策，如后续因铁路部门相关规定、政策变动导致本协议相关条款与实际不符的，您与我司双方均同意以实际的规定、政策为准，同时如发生前述情形，您同意就给您造成的损失不予追究我司责任。</p>
		 </div>
		  <span slot="footer" class="dialog-footer">
		    <el-button type="primary" @click="dialogVisible = false">关闭</el-button>
		  </span>
		</el-dialog>

	</div>
</template>

<script>
	export default{
		name: 'TrainNotice',
		data() {
			return {
				dialogVisible:false
			}
		},
		methods: {
			showNotice() {
				this.dialogVisible = true;
			}
		}
	}
</script>

<style scoped lang="scss">
	.tn-main{
		font-family: '微软雅黑';
		.tn-text{
			margin-top:60px;
			padding-left:30px;
			text-align: left;
			font-size: 12px;
			font-weight: 400;
			color: #424242;
			line-height: 15px;
			.tn-bg-bule{
				padding-left:2px;
				color: #4089FF;
				cursor: pointer;
			}
			
			
		}
		
		.tn-con{
			padding:0 20px;
			text-align: left;
			height: 400px;
			overflow-y: auto;
			p{
				line-height:24px;
			}
			h4{
				margin-top:20px;
				line-height: 24px;
			}
		}
	}
</style>
