<template>
	<div class="hp-main">
		<div class="header" style="display: inline-block;background-size: cover;background-position: center;background-color: #BBBBBB;"  :style="{backgroundImage:'url('+require('@/assets/image/home.jpg')+')'}">
			<header-search></header-search>
		</div>
		<div class="main">
			<div class="con-main">
				<div class="con-l" v-if="bookStyle==2">
					<ccsqd-info></ccsqd-info>
				</div>
				<div :class="{'con-r':bookStyle==2,'con-rdd':bookStyle==1,}">
					<div class="con-rt">
						<route-approval></route-approval>
					</div>  
					<div class="con-rf">
						<travel-info></travel-info>
					</div>
				</div>
			</div>
		</div>
		<div class="menu">
			<div class="flex">
				<div v-for="item in menuList" :key="item.id" class="setkjys">
					<div style="width:100%;height:30px;">
						<el-button type="text" :class="item.icon" class="icon24"></el-button>
					</div>
					
					<div class="menuTitle">{{item.title}}</div>
					<div class="menuText">{{item.text}}</div>
				</div>
			</div>
		</div>
		
		<yj-copy-right :compInfo="compInfo" :copyRightClass="copyRightClass"></yj-copy-right>
	</div>
</template>

<script>
	import { mapGetters, mapMutations } from 'vuex';
import CcsqdInfo from './ccsqd/CcsqdInfo';
import RouteApproval from './routeAndApproval/RouteApproval';
import HeaderSearch from './search/HeaderSearch';
import TravelInfo from './travelInfo/TravelInfo';

import loginFristAlert from '@/mixin/models/loginFristAlert';
	export default {
		name: 'Home',
		mixins:[loginFristAlert],
		data() {
			return {
				
				menuList: [ // 菜单列表
					{
						id: 0,
						icon: "iconfont yj-icon-xzzy_icon",
						title: "全球优质资源",
						text: "全球酒店、机票、机场VIP、VVIP服务等优质资源整合。",
					},
					{
						id: 1,
						icon: "iconfont yj-icon-fwqy_icon",
						title: "200家服务企业",
						text: "服务企业包裹央企、上市、大中小民企等200多家。",
					},
					{
						id: 2,
						icon: "iconfont yj-icon-chxyj_icon",
						title: "超惠协议价",
						text: "低于官网价的超惠协议机票、酒店。更少 的花费，更好的享受。",
					},
					{
						id: 3,
						icon: "iconfont yj-icon-a-24xs_icon",
						title: "7X24小时客服",
						text: "我们随时为您服务 - 每周7天，每天24小时为您服务。",
					},
				],

				
				vipCorp:{}, //员工所属 企业信息
				bookStyle:'2', // 模式  1订单 2出差申请单
				
				copyRightClass:{
					'width': 100+'%',
					'min-height': 56+'px',
					'cursor':'initial',
				}
			}
		},
		components: {
			'route-approval': RouteApproval,
			'travel-info': TravelInfo,
			'header-search': HeaderSearch,
			'ccsqd-info': CcsqdInfo,
		},
		created(){
			// 登录人信息
			let userInfo = this.$common.getUserInfo() || {};
			this.vipCorp = userInfo.vipCorp || {};
			this.bookStyle = this.vipCorp.bookStyle;
			// 获取主题颜色
			let color = this.$common.localget('colorTheme');
			if(color){
				this.copyRightClass.background = color
			}
			// 获取首次登录后信息
			this.initFirstLoginInfo();
		},
		computed:{
            
			...mapGetters({
				getVipParamterByParNo:'common/getVipParamterByParNo',
			}),
			// // 出差申请单按钮
			// ccsqdButton() {			
			//    let params = this.getVipParamterByParNo(20023,0)
			// 	return params ;
			// },
			compInfo(){
				let text = this.$common.getPageSettingInfo();
				return text;
			},
		},
		mounted(){
            this.initRulers()
			
			// 接受子页面方法刷新需要
			window['travelFormAdminRefresh'] = (val) =>{
				if(val==1){
					
				}
			}
			
		},
		methods: {
            ...mapMutations({
				SET_HTCN_QUERY: 'htCn/SET_HTCN_QUERY', // 保存因共模式下的自定义信息
			}),
            initRulers() {
				// 初始化的时候就把管控规则存入vux里面
				// 出差审请单模式
				if (this.bookStyle == 2) {
					//1单订模式  2出差申请单模式
					this.getkgfs()
				} else {
					// this.getClbz();
				}
			},
            getkgfs() {
				//这是出差申请单模式的管控规则
				let params10999 = this.getVipParamterByParNo(10999, 0)
				let params10991 = this.getVipParamterByParNo(10991, 0,'value1',10901)
				let params10992 = this.getVipParamterByParNo(10992, 0)
				let params10993 = this.getVipParamterByParNo(10993, 0)
				let params10995 = this.getVipParamterByParNo(10995, 0)
				let params10996 = this.getVipParamterByParNo(10996, 0)
				let params20002 = this.getVipParamterByParNo(20002, 0)
				let params20003 = this.getVipParamterByParNo(20003, 0)
				this.SET_HTCN_QUERY({
					gkfsRole: params10999, //0 不管控  1出差申请单管控  2差旅标准管控
					changeRouteRole: params10991, //根据出差申请单预订时可更改行程
					changeDateRole: params10992, //"查询出差申请单预订行程日期可否变更
					changeDateRange: params10993, //根据出差申请单预订时可更改出行日期
					params10995, //  "成本中心是否必填"
					params10996, //   "项目是否必填"
					params20002, //   "订单填写页面是否显示成本中心，1显示，0不显示"
					params20003, //   "订单填写页面是否显示项目，1显示，0不显示"
				})
			},
			
			
			
			
			
		}
	}
</script>

<style lang="scss" scoped>
	.hp-main {
		box-sizing:border-box;
		.header {
			width: 100%;
			height: 400px;
		}

		.main {
			width: 100%;
			background-color: #F1F4F9;
			padding: 25px 0;
			font-family: '微软雅黑';
			.con-main {
				display: flex;
				justify-content: space-between;
				width: 1200px;
				margin: 0 auto;

				.con-l {
					width: 340px;
					height: 475px;
					// padding: 20px 22px;
					background-color: #FFFFFF;
					box-shadow: 0px 2px 5px 0px rgba(0, 0, 0, 0.04);

				}

				.con-r {
					width: 850px;
					height: 475px;

					.con-rt {
						height: 235px;

					}

					.con-rf {
						height: 230px;
						margin-top: 10px;
					}
				}
				.con-rdd {
					width: 1200px;
					height: 475px;
				
					.con-rt {
						height: 235px;
				
					}
				
					.con-rf {
						height: 230px;
						margin-top: 10px;
					}
				}
			}
		}

		.menu {
			width: 100%;
			background-color: #FFFFFF;
			margin: 20px 0;
			.flex {
				display: flex;
				justify-content: space-between;
				width: 1200px;
				margin: 0 auto;

				.setkjys {
					width: 25%;
					padding: 30px 36px 50px;
					cursor: pointer;
					height: 210px;
					.icon24 {
						height: 30px;
						font-size: 24px;
						line-height: 30px;
					}

					.menuTitle {
						font-size: 16px;
						font-weight: 500;
						color: #424242;
						line-height: 36px;
						padding-top: 12px;
					}

					.menuText {
						font-size: 12px;
						font-weight: 400;
						color: #5F7391;
						line-height: 22px;
					}
				}
				.setkjys:hover{
					transition: all 0.3s ease; 
					box-shadow: 0 0 5px 0 rgba(0,0,0,0.2);
				}
				.setkjys:hover .icon24 {
					font-size: 30px;
					transition: all 0.2s ease; 
				}
			}



		}

		.footer {
			width: 100%;
			background-color: #006BB9;

			.text {
				width: 1200px;
				margin: 0 auto;
				height: 41px;
				line-height: 41px;
				font-size: 12px;
				font-weight: bold;
				color: #FFFFFF;
				opacity: 0.5;
			}
		}
	}
</style>
