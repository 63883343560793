<template>
	<el-card class="clm-main simHei">
		<div class="padTLR20">
			<div class="borderB padB20">	
				<div class="flex">
					<div class="textLeft w60">
						<el-avatar class="w60 h60" :src="headImg"></el-avatar>
					</div>
					<div class="marL14 hide-d w154 textLeft">
						
							<el-tooltip class="item" effect="dark" placement="top">
								<div slot="content">
									<span>
										<span style="margin-right: 10px;">{{loginPerson.empNo}}</span>
										<span>{{loginPerson.empName}}</span>
									</span>
									
								</div>
								<p class='clm-name-t line-h38' v-if="loginPerson.empNo && loginPerson.empNo.length<12">
							      <span :class="{font15:loginPerson.empNo.length>6}">{{loginPerson.empNo}}</span>
							      <span class="marL10" :class="{font15:loginPerson.empNo.length>6}">{{loginPerson.empName}}</span>
								</p>
								<p class='clm-name-t clm-hhcl' v-if="loginPerson.empNo && loginPerson.empNo.length>11">
								  <span class="font15" :class="{font15:loginPerson.empNo.length>12}">{{loginPerson.empNo}}</span>
								  <span class="marL10 font15" :class="{font15:loginPerson.empNo.length>6}">{{loginPerson.empName}}</span>
								</p>
								
							</el-tooltip>
							
						
						<p class='clm-name-b'>
							<span class='borderRC padR10'>{{loginPerson.deptName}}</span>
							<span class="marL10">{{loginPerson.empRankName}}</span>
						</p>
					</div>
				</div>
				
				<div class="marT15 flex setButton">
					<el-button class="w140" @click="goToPersonCenter">编辑账号</el-button> 
					<el-button class="w80 setAutoBtn" type="info" @click="loginOut">退出</el-button>
				</div>
			</div>
			<div class="padTB30 borderB setfontsize">
				<p class="textLeft clm-left-t">账号权限</p>
				<p class="marT30 colorBlue textLeft">
					<span v-if="loginPerson.bookRange==0"><span class="iconfont yj-icon-wqgsyd_icon padR5 font17 "></span>为本人预订</span>
					<span v-if="loginPerson.bookRange==1"><span class="iconfont yj-icon-wqgsyd_icon padR5 font17"></span>为本部门订票</span>
					<span v-if="loginPerson.bookRange==2"><span class="iconfont yj-icon-wqgsyd_icon padR5 font17"></span>为全公司人员预订</span>
					<span v-if="loginPerson.bookRange==3"><span class="iconfont yj-icon-wqgsyd_icon padR5 font17"></span>指定部门</span>	
				</p>
				<p class="marT30 textLeft flex between set-qx-b">
					<span :class="{colorBlue:loginPerson.bookOutside==1,colorB3:loginPerson.bookOutside==0}">
						<span class="iconfont yj-icon-wwlryd_icon padR5 font17"></span>
						<span>为外来人员预订<span v-if="loginPerson.bookOutside==0" class="padL35">暂无此权限</span></span>
					</span>	
				</p>
				<p class="marT30 textLeft flex between set-qx-b">
					<span :class="{colorBlue:loginPerson.appBook==1,colorB3:loginPerson.appBook==0}">
						<span class="iconfont yj-icon-ydqm_icon padR5 font17"></span>
						<span>所有预订免审批<span v-if="loginPerson.appBook==0" class="padL35">暂无此权限</span></span>
					</span>	
				</p>
			</div>
		</div>
		<div class="padB30 textLeft font17">
			<el-menu :default-active="defaultIndex" mode="vertical"   @select="handleSelect">

			    <el-menu-item index="order">
			        <span slot="title">订单中心</span>
			    </el-menu-item>
				<el-menu-item index="travelFormAdmin" v-if="loginCorp.bookStyle==2">
				    <span slot="title">出差管理</span>
				</el-menu-item>
				<el-menu-item index="needMyApproval">
				    <span slot="title">需要我审批</span>
				</el-menu-item>
				<el-menu-item index="approvalRecord">
				    <span slot="title">审批记录查询</span>
				</el-menu-item>
				<el-menu-item index="travelInfo">
					<span slot="title">差旅信息</span> 
				</el-menu-item> 
				<el-menu-item index="comInfo">
				    <span slot="title">常用信息</span>
				</el-menu-item>
			    <!-- <el-menu-item index="commonLinkman">
			        <span slot="title">常用联系人</span>
			    </el-menu-item>
				<el-menu-item index="commonAddress">
				    <span slot="title">常用地址</span>
				</el-menu-item> -->
			</el-menu>
		</div>
	</el-card>
</template>

<script>
	import {mapState,mapMutations} from 'vuex';
	export default{
		name: 'CenterLeftMenu',
		data() { 
			return { 
				loginPerson:{}, 
				loginCorp:{},
				defaultIndex:'orderInfo', // 默认显示差旅信息
				router: true, // 激活路由
			} 
		},
		computed:{ 
			...mapState({
				headImg: state => state.common.headImg
			}),
			path(){ 
				let path = this.$route.path;
				let pathArr = path.split("/");
				let pathName= pathArr.pop();
				return pathName 
			},
		},
		mounted() {
			// 处理刷新页面 防止回到默认项
			this.defaultIndex = this.path; 
			this.getLoginInfo()
		},
		methods: { 
			// 获取登录人信息 与企业信息 
			getLoginInfo(){
				this.$conn.getConn('user.userInfo')({},(res)=>{
					let data = res.data || {};
					this.loginPerson = data.empInfo;
					this.loginCorp = data.vipCorp;
				},(err)=>{
					this.$message({
						type: 'error',
						message: err.tips
					})
				})
			},
			// 获取业务类型 
			handleSelect(key){	
				this.$router.push({
					path: key
				})
			},
			// 跳转个人中心 
			goToPersonCenter(){
				this.defaultIndex = '';
				this.$router.push({
					path: 'personCetner'
				})
			},
			loginOut(){
				this.$confirm('确认退出?', '提示', {
				        confirmButtonText: '确定',
				        cancelButtonText: '取消',
				        type: 'warning'
				}).then(() => {
				    this.$conn.getConn('user.loginOut')({},(res)=>{
						this.$common.localremove('access_token');
						this.$common.localremove('tmpaccess');
						this.$common.sessionclear();
						this.$initVuex.vuexCache();
						this.$router.push('/')
					},(err)=>{
						this.$message({
							type: 'error',
							message: err.tips
						}); 
					})   
				}).catch(() => {
				     this.$message({
				       type: 'info',
				       message: '已取消退出'
				     });             
				}); 
			}
		}
	}
</script>

<style scoped lang="scss">
	.el-card.is-always-shadow{
		box-shadow: 0px 0px 8px 0px rgba(0, 0, 0, 0.04);
		border-radius: 5px;	
	}
	.font15{
		font-size: 15px;
	}
	.clm-main{
		font-family: '微软雅黑';
		.clm-name-t{
			font-size: 22px;
			font-weight: 400;
			font-style: italic;
			color: #262633;
		}
		.clm-hhcl{
			word-wrap: break-word;
			word-break: normal;
			white-space: pre-wrap;
			line-height: 20px;
			padding: 5px 0;
		}
		.clm-name-b{
			font-size: 14px;
			font-weight: 400;
			color: #262633;
		}
		.clm-left-t{
			font-size: 18px;
			line-height: 18px;
			font-weight: 400;
			color: #262633;
		}
		.setfontsize{
			font-size: 14px;
			font-weight: 400;
		}
		.set-qx-b{
			font-size: 14px;
			font-weight: 400;
		}
		.inblock{
			display: inline-block;
		}
		.textRight{
			text-align: right;
		}
		.padL35{
			padding-left: 35px;
		}
		/deep/ .el-card__body{
			padding: 0;
		}
		/deep/ .el-menu{
			border-right: none;
		}
		/deep/ .el-menu .is-active{
			border-left: 5px solid #1C83ED;
		}
		.setButton{
			.el-button{
				border-radius: 3px;
				font-size: 12px;
				font-weight: 400;
			}
			.setAutoBtn{
				border: none;
				font-size: 12px;
				font-weight: 400;
				color: #808080;
				background-color: #EBEBEB;
				padding: 0;
				width: 80px;
				height: 32px;
				border-radius: 3px;
			}
			.setAutoBtn:hover{
				color: #FFFFFF; 
				background-color: #cccccc;
			}
		}
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.flexEnd{
			align-items: flex-end;
		}
		.w60{
			width: 60px;
		}
		.w154{
			width: 154px;
		}
		.h60{
			height: 60px;
		}
		.padR10{
			padding-right: 10px;
		}
		.marL10{
			margin-left: 10px;
		}
		.marL14{
			margin-left: 14px;
		}
		.font15{
			font-size: 15px;
		}
		.font22{
			font-size: 22px;
		}
		.line-h38{
			line-height: 38px;
		}
		.hide-d{
			overflow: hidden;
			text-overflow: ellipsis;
			white-space: nowrap;
		}
		.italic{
			font-style: italic
		}
		.simHei{
			font-family: SimHei;	
		}
		.weight400{
			font-weight: 400;
		}
		.borderRC{
			border-right:2px solid #CCCCCC;
		}
		.letter2{
			letter-spacing: 2px;
		}
		.marT15{
			margin-top: 15px;
		}
		.font14{
			font-size: 14px;
		}
		.font15{
			font-size: 15px;
		}
		.font17{
			font-size: 17px;
		}
		.w140{
			width: 140px;
		}
		.w80{
			width: 80px;
		}
		.padTLR20{
			padding: 20px 20px 10px;
		}
		.padB20{
			padding-bottom: 20px;
		}
		.padT10{
			padding-top: 10px;
		}
		.padB30{
			padding-bottom: 30px;
		}
		.padTB30{
			padding: 30px 0;
		}
		.padR5{
			padding-right: 5px;
		}
		.marT30{
			margin-top: 30px;
		}
		.textLeft{
			text-align: left;
		}
		.borderB{
			border-bottom: 1px solid #EBEBEB;
		}
		.colorB3{
			color: #B3B3B3;
		}
		.colorBlue{
			color: #1C83ED;
		}
		.pointer{
			cursor: pointer;
		}
	}
</style>
