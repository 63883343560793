<template>
	<div class="epid-main">
		<el-dialog
			title="个人信息编辑"
			:visible.sync="personVisible"
			:close-on-click-modal="false"
			:before-close="handleClose"
			style="text-align: left;"
			>
			<div class="flex marB20">
				<el-avatar  class="w50 h50" :src="empInfo.profilePhotoUrl"></el-avatar>
				<div class="padL20 line-h50 font18">
					<span>{{empInfo.empNo}}</span>
					<span class="borderR1 padL10 padR10">{{empInfo.empName}}</span>
					<span class="padL10">{{empInfo.deptName}}</span>
				</div>
			</div>
			<el-form :model="ruleForm" :inline="true" :rules="rules" ref="ruleForm" label-width="100px" class="demo-ruleForm">
				<el-form-item label="国籍" prop="nation" >
					<el-select v-model="ruleForm.nation" filterable :filter-method="sreachGJ"  placeholder="请选择国籍">
						<el-option
							v-for="item in nationList"
							:key="item.id"
							:label="item.cName"
							:value="item.id">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="英文名字">
					<el-input v-model="ruleForm.engName"  placeholder="请输入英文名字"></el-input>
				</el-form-item>
				<el-form-item label="常用手机" prop="phoneNumber">
					<el-input v-model="ruleForm.phoneNumber" type="number" placeholder="请输入常用手机"></el-input>
				</el-form-item>
				<el-form-item label="出生年月">
					<el-date-picker
					    v-model="ruleForm.born"
					    type="date"
						value-format="yyyy-MM-dd"
					    placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="固定电话">
					<el-input v-model="ruleForm.tel" type="number" placeholder="请输入固定电话"></el-input>
				</el-form-item>
				<el-form-item label="备用手机">
					<el-input v-model="ruleForm.phoneNumber2" type="number" placeholder="请输入备用手机"></el-input>
				</el-form-item>
				<el-form-item label="身份证号" prop="idNumber">
					<el-input v-model="ruleForm.idNumber" placeholder="请输入身份证号"></el-input>
				</el-form-item>
				<el-form-item label="邮箱">
					<el-input v-model="ruleForm.email" type="email" placeholder="请输入邮箱"></el-input>
				</el-form-item>
				<el-form-item label="护照有效期"  placeholder="请选择护照有效期">
					<el-date-picker
					    v-model="ruleForm.passportPeriod"
					    type="date"
						value-format="yyyy-MM-dd"
					    placeholder="选择日期">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="护照号码">
					<el-input v-model="ruleForm.passport"  placeholder="请输入护照号码"></el-input>
				</el-form-item>
				<el-form-item label="签发地">
					<el-select v-model="ruleForm.placeOfIssue" filterable :filter-method="sreachQFD"  placeholder="请选择签发地">
						<el-option
							v-for="item in placeOfIssueList"
							:key="item.id"
							:label="item.cName"
							:value="item.id">
						</el-option>
					</el-select>	
				</el-form-item>
				<el-form-item label="出生地">
					<el-select v-model="ruleForm.birthPlace" filterable :filter-method="sreachCSD"  placeholder="请选择出生地">
						<el-option
							v-for="item in birthPlaceList"
							:key="item.id"
							:label="item.cName"
							:value="item.id">
						</el-option>
					</el-select>	
				</el-form-item>
				<el-form-item label="个人偏好">
					<el-input v-model="ruleForm.preference" type="text" placeholder="请输入个人偏好"></el-input>
				</el-form-item>
				<el-form-item label="性别">
					<el-radio v-model="ruleForm.sex"	 label="M">男</el-radio>
					<el-radio v-model="ruleForm.sex	" label="F">女</el-radio>
				</el-form-item>
			</el-form>	
					
			<span slot="footer" class="dialog-footer">
				<el-button @click="cancel">取 消</el-button>
				<el-button type="primary" @click="confirm('ruleForm')">确 定</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	export default{
		name: 'EditPersonInfo',
		props: {
			personVisible: {
				type: Boolean,
				default: false,
			}
		},
		data() {
			return {
				ruleForm: {
				    birthPlace: "",
				    born: "",
				    email: "",
				    engName: "",
				    idNumber: "",
				    nation: "",
				    passport: "",
				    passportPeriod: "",
				    phoneNumber: "",
				    phoneNumber2: "",
				    placeOfIssue: "",
				    preference: "" , //个人喜好
				    sex: "",
				    tel: "",
				    },
				rules: {
				    phoneNumber: [
				        { required: true, message: '请输入手机号', trigger: 'blur' },
				        { min: 11, max: 11, message: '手机号长度为11位', trigger: 'blur' },
				    ],
					nation: [
						{ required: true, message: '请选择国家', trigger: 'change'},
					],
					idNumber :[
						{ required: true, message: '请输入证件号', trigger: 'blur' },
						{ min: 18, max: 18, message: '证件号长度为18位', trigger: 'blur' }
					]
				},
				sex: 'M',
				nationList:[], // 国家列表
				newNationList: [], // 过滤国家
				birthPlaceList: [], // 出生地列表
				placeOfIssueList: [],
				empInfo:{},
			}
		},
		
		mounted() {
			this.getNontion();
		},
		watch:{
			personVisible(val){
				if(val){
					this.getpersonInfo();		
				}
				
			}
		},
		methods: {
			
			// 获取个人信息
			getpersonInfo(){
				let user = this.$common.getUserInfo() || {};
				this.empInfo = user.empInfo || {};
				let empInfo = this.$common.deepCopy(this.empInfo)
				this.ruleForm = {
				    birthPlace: empInfo.birthPlace,
				    born: empInfo.born,
				    email: empInfo.email,
				    engName: empInfo.engName,
				    idNumber: empInfo.idNumber,
				    nation: empInfo.nation || '',
				    passport: empInfo.passport,
				    passportPeriod: empInfo.passportPeriod,
				    phoneNumber: empInfo.phoneNumber,
				    phoneNumber2: empInfo.phoneNumber2 || "",
				    placeOfIssue: empInfo.placeOfIssue,
				    preference: empInfo.preference , //个人喜好
				    sex: empInfo.sex,
				    tel: empInfo.tel || '',
				}
			},
			// 获取国家信息
			getNontion(type) {
				this.$conn.getConn('commu.baseData',{jsfile:true})({}).then((res)=>{
					let data = res || {};
					let dataList = [];
					// 国家
					this.nationList = [];
					this.birthPlaceList = [];
					this.placeOfIssueList = [];
					for(let key in data.country){
						dataList.push(data.country[key]);	
					}
					if(type == 'nation'){
						this.nationList = dataList;
					}else if(type == 'placeOfIssue'){
						this.placeOfIssueList = dataList;
					}else if(type == 'birthPlace'){
						this.birthPlaceList = dataList;
					}else{
						this.nationList = dataList;
						this.placeOfIssueList = dataList;
						this.birthPlaceList = dataList;
					}

					this.newNationList = dataList;
				})
				
			},
			// 关闭页面
			handleClose(){
				this.$emit('callParams',false)
			},
			// 提交信息
			confirm(formName){
				this.$refs[formName].validate((valid) => {
				    if (valid) {
						this.$conn.getConn('user.editInfo',{jsfile:false})(this.ruleForm).then(res=>{
							this.$message({
								type:'success',
								message: '保存成功'
							})
							this.$emit('callParams',false,'info')
						}).catch(err=>{
							this.$message({
								type:'error',
								message: err.tips
							})
						})
				    } else {
				      return false;
				    }      
				});
			
				
				
			},
			// 取消
			cancel(){
				this.$emit('callParams',false)
			},
			// 搜索国家
			sreachGJ(e){
				if(e){
					this.nationList = [];
					this.newNationList.forEach((item,index)=>{
						if(item.by1.indexOf(e.toUpperCase())!=-1){
							this.nationList.push(item)
						}
					})
				}else{
					this.getNontion('nation')
				}	
			},
			// 签发地
			sreachQFD(e){
				if(e){
					this.placeOfIssueList = [];
					this.newNationList.forEach((item,index)=>{
						if(item.by1.indexOf(e.toUpperCase())!=-1){
							this.placeOfIssueList.push(item)
						}
					})
				}else{
					this.getNontion('placeOfIssue')
				}
			},
			// 出生地
			sreachCSD(e){
				if(e){
					this.birthPlaceList = [];
					this.newNationList.forEach((item,index)=>{
						if(item.by1.indexOf(e.toUpperCase())!=-1){
							this.birthPlaceList.push(item)
						}
					})
				}else{
					this.getNontion('birthPlace')
				}
			}
		}
	}
</script>

<style scoped lang="scss">
	.epid-main{
		/deep/ .el-dialog{
			width: 660px;
		}
		/deep/ .el-dialog__body{
			padding: 10px 20px 30px;
		}
		/deep/ .el-select .el-input__inner{
			width: 200px;
		}
		/deep/ .el-date-editor--date{
			width: 200px;
		}
    /deep/  input::-webkit-outer-spin-button,
    /deep/  input::-webkit-inner-spin-button {
      -webkit-appearance: none;
    }
    /deep/ input[type="number"]{
      -moz-appearance: textfield;
    }
		.flex{
			display: flex;
		}
		.between{
			justify-content: space-between;
		}
		.inlineB{
			display: inline-block;
		}
		.bfb-w18{
			width: 18%;
		}
		.marB10{
			margin-bottom: 10px;
		}
		.marB20{
			margin-bottom: 20px;
		}
		.padT13{
			padding-top: 13px;
		}
		.padL10{
			padding-left: 10px;
		}
		.padR10{
			padding-right: 10px;
		}
		.padL20{
			padding-left: 20px;
		}
		.borderR1{
			border-right: 1px solid #CCCCCC;
		}
		.bfb-w40{
			width: 50%;
		}
		.font18{
			font-size: 18px;
		}
		.line-h50{
			line-height: 50px;
		}
		.w50{
			width: 50px;
		}
		.h50{
			height: 50px;
		}
	}
</style>
