<template>
	<div>
		<el-header>
			<div class="el-header-content">
				<div class="logo">
					<!-- <img :src="vipCorp.logoUrl" alt />
					<span class="shortname">{{vipCorp.shortname}}</span> -->
					<yj-nav-logo></yj-nav-logo>

				</div>
				<el-menu :default-active="defaultActive" class="el-menu-demo" mode="horizontal"
					@select="handleSelect" :router="router">
					<el-menu-item index="Home">
						<template slot="title">
							<span>{{$t("homeTab.home")}}</span>
						</template>
					</el-menu-item>
					<el-menu-item index="Book">
						<template slot="title">
							<span>{{$t("homeTab.book")}}</span>
						</template>
					</el-menu-item>
					<el-menu-item index="MyTravel">
						<template slot="title">
							<span>{{$t("homeTab.myTravel")}}</span>
						</template>
					</el-menu-item>
					<el-menu-item index="MyBill" v-show="isKaiQiFeiKong">
						<template slot="title">
							<span>{{$t("homeTab.myBill")}}</span>
						</template>
					</el-menu-item>
					<!-- <el-menu-item index="Download">
						<template slot="title">
							<span>{{$t("homeTab.myApp")}}</span>
						</template>
					</el-menu-item> -->
		
				</el-menu>
				
				<!-- 中英文切换 -->
				<!-- <div class="block">
					<el-dropdown @command="command1">
						<span class="el-dropdown-link">
							{{langType }}
							<i class="el-icon-arrow-down el-icon--right"></i>
						</span>
						<el-dropdown-menu slot="dropdown" class="theme-index">
							<el-dropdown-item v-for="(langItem,langkey) in homeLanguageMap" :key="langkey"
								v-text="langItem" :command="langkey"></el-dropdown-item>
						</el-dropdown-menu>
					</el-dropdown>
				</div> -->
				<!-- 颜色切换 -->
				<div class="block">
					<colorTheme class="color-theme" />
				</div>
				<div class="block" v-if="qrCode">
					<el-popover
					    placement="bottom"
					    width="150"
					    trigger="hover"
						v-model="appVisible"
					   >
						<slot name="content">
							<img :src="qrCode" alt="" style="max-width:124px;max-height:124px;overflow: hidden;">
							<div style="text-align: center;font-size: 12px;color: #808080;">手机扫描二维码下载</div>
						</slot>
						<div slot="reference" class="xzApp" :class="{cxzApp:appVisible}">下载app</div>
					</el-popover>
					
				</div>
				<div class="task">
					<el-popover
					    placement="bottom"
					    width="60"
					    trigger="hover"
						
					    >
							<div slot="reference" class="rwglq" @click="gotoTaskList">
								<el-button type="text" class="iconfont yj-icon-shituguanliqi" style="font-size: 20px;"></el-button>
							</div>
							<div style="text-align: center;">
								任务管理器
							</div>
					</el-popover>
				</div>
				
				
				<div class="block pointer setAvatar" style="position: relative;">
					<span class="setname" style="position: relative;">{{empInfo.empName}}</span>
					<el-popover
					    placement="bottom-end"
					    width="280"
						popper-class="setPopover"
					    trigger="click"
						v-model="visible"
					    >
							<el-avatar slot="reference" :size="35" :src="headImg"></el-avatar>
							<div class="flex flexStart" style="padding:  25px 15px 0px;15px">
								<el-avatar :size="60" :src="headImg"></el-avatar>
								<div class="marL20">
									<div class="setAutoMain"  :class="{hhsz:empInfo.empNo && empInfo.empNo.length>11}">
										<span class="setSpan1">{{empInfo.empNo}}</span>
										<span class="setSpan">{{empInfo.deptName}}</span>
									</div>
									<div class="setQX flex flexStart">
										<div class="setBorder1 setwidth">
											<span v-if="empInfo.bookRange==0 && empInfo.appBook==1"  class="iconfont yj-icon-mian_icon" style="color:#67C2A5;font-size: 24px;"></span>
											<span v-if="empInfo.bookRange==1"  class="iconfont yj-icon-bumen_icon" style="color:#64B2F5;font-size: 24px;"></span>
											<span v-if="empInfo.bookRange==2" class="iconfont yj-icon-quan_icon" style="color:#4E90F1;font-size: 24px;"></span>
										</div>
										<div class="iconfont yj-icon-wai_icon" style="color:#FFA21D;font-size: 24px;" v-if="empInfo.bookOutside==1"></div>	
									</div>
										
									
								</div>
							</div>
							<div class="textCenter" @click="goToPersonInfo">查看个人资料</div>
							<el-button class="setButton"  @click="signOut">退出登录</el-button>
					</el-popover>
				</div>
			</div>
			<book-tab v-if="defaultActive == 'Book'"></book-tab>
		</el-header>
	</div>
</template>

<script>
	import colorTheme from "./Theme";
	import bookTab from "./BookTab";
	import homeLanguageMap from './../../i18n/langs/homeLanguageMap.js';
	import {mapState,mapMutations } from 'vuex';
	let langs = window.localStorage.getItem('langs') || 'cn';
	export default{
		data(){
			return{
				router: false,
				defaultActive: "",
				isShow: false,
				langs: langs,
				visible: false,
				appVisible: false,
			}
		},
		components: {
			colorTheme,
			bookTab,
		},
		
		mounted() {
			if (this.$route.path.indexOf("home") != -1) {
				this.defaultActive = "Home"
			} else if (this.$route.path.indexOf("book") != -1) {
				this.defaultActive = "Book"
			} else if (this.$route.path.indexOf("myTravel") != -1) {
				this.defaultActive = "MyTravel"
			} else if (this.$route.path.indexOf("myBill") != -1) {
				this.defaultActive = "MyBill"
			} 
			// else if (this.$route.path.indexOf("download") != -1) {
			// 	this.defaultActive = "Download"
			// }
			if(this.headImg){
			
			}else{
				this.setHeadImg(this.empInfo.profilePhotoUrl);	
			}
			
		},
		watch: {
			'$route.path'(val, old) {
				if (val.indexOf("home") != -1) {
					this.defaultActive = "Home"
				} else if (val.indexOf("book") != -1) {
					this.defaultActive = "Book"
				} else if (val.indexOf("myTravel") != -1) {
					this.defaultActive = "MyTravel"
				} else if (val.indexOf("myBill") != -1) {
					this.defaultActive = "MyBill"
				}
				//  else if (val.indexOf("download") != -1) {
				// 	this.defaultActive = "Download"
				// }
			},
		},
		computed: {
			...mapState({
				headImg: state => state.common.headImg
			}),
			
			langType() {
				if (this.langs && this.homeLanguageMap[this.langs]) {
					return this.homeLanguageMap[this.langs]
				} else {
					return ''
				}
			},
		
			// 当前使用语言类型map
			homeLanguageMap() {
				if (homeLanguageMap && this.langs && homeLanguageMap[this.langs]) {
					return homeLanguageMap[this.langs]
				} else {
					return {}
				}
			},
			// 用户详情
			empInfo(){
				let userInfo = this.$common.getUserInfo();
				return userInfo.empInfo || {}
			},
			// 企业详情
			vipCorp(){
				let userInfo = this.$common.getUserInfo();
				return userInfo.vipCorp || {}
			},
			// 是否开启了费控
			isKaiQiFeiKong(){
				return this.$common.isKaiQiFeiKong()
			},
			// 下载二维码
			qrCode(){
				return this.$common.getPageSettingInfo().qrCode || '';
			}
			
		},
		
		methods: {
			...mapMutations({
				setHeadImg: "common/setHeadImg",
			}),
			// ...mapActions({
			// 	SetClearInfo:"train/SetClearInfo"
			// }),
			handleSelect(key) {
				this.isShow = this.defaultActive != key;
				this.defaultActive = key
				if (key != 'book' && key != 'MyBill') {
					this.$router.push({
						name: key
					})
				}else if(key == 'MyBill'){ // 报销中心
					var returnUrl = this.$common.getReverseDomain();
					var queryObj = {
					  toPage: 'home', //  home  / approvelist  / billList
					  // platform: "pc", //platform=h5|pc
					  platform: "10503", // 
					  returnUrl:returnUrl, // var domain = document.domain;
					};
							
					this.$conn.getConn("user.etclLogin")(queryObj,(res)=>{
						var path = res.data || '';
						window.open(path,'费控中心','',true)
						// window.location.href = path;
					},(err)=>{
						this.$alert(err.tips,'提示', {
							confirmButtonText: '确定',
							type:'error',
							callback: action => {
							
							}
						})
						
					});
				}
			},
			// 语言切换
			command1(command) {
				if (command == "cn") {
					this.$moment.locale('zh-cn')
				} else if (command == "en") {
					this.$moment.locale('en')
				}
				this.langs = command;
				this.$i18n.locale = command;
				window.localStorage.setItem('langs', this.$i18n.locale)
			},
			// 跳转任务管理器页面
			gotoTaskList(){
				window.open('#/taskList',"_blank")
			},
			// 退出 清除缓存信息
			signOut(){
				this.$confirm('确认退出?', '提示', {
				        confirmButtonText: '确定',
				        cancelButtonText: '取消',
				        type: 'warning'
				}).then(() => {
				    this.$conn.getConn('user.loginOut',)({},(res)=>{
						this.$common.localremove('access_token');
						this.$common.sessionclear();
						this.$common.localremove('tmpaccess')
						this.$initVuex.vuexCache();
						this.$router.push('/')
					})  
				}).catch(() => {
				                
				}); 
			},
			//获取头像信息
			getAvatar(){
				this.visible = !this.visible; 
			},
			// 个人信息
			goToPersonInfo(){
				this.visible = false;
				this.$router.push({path:'/yjTravel/myTravel/userCenter/personCetner'})
			},
			// 跳转至费控
			goToFeiKong(){
				var returnUrl = this.$common.getReverseDomain();
				var queryObj = {
				  toPage: 'home', //  home  / approvelist  / billList
				  platform:"pc", //platform=h5|pc
				  returnUrl:returnUrl, // var domain = document.domain;
				};
						
				var feiKong = this.$conn.getConn("user.etclLogin");
				feiKong(queryObj,(res)=>{
					var path = res.data || '';
					window.open(path,'费控中心','',true)
					
				},(err)=>{
					this.$message.error(err.tips)
				});
			},
		
		}
	}
</script>

<style scoped lang="scss">
	.textCenter{
		text-align: center;
		line-height: 40px;
		cursor: pointer;
	}
	.textCenter:hover{
		color: #007AFF;
	}
	.task{
		margin: 15px 0;
	}
	.rwglq{
		width:40px;
		height:30px;
		line-height: 30px;
		margin:auto 0;
		cursor: pointer;
	}
</style>
